import { Text } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import { ContractBroker } from 'mlp-client/src/contracts/types'
import MaskedData from 'mlp-client/src/utils/masks/MaskedData'

const Wrapper = styled.div`
  white-space: pre-line;
`

const Line = styled(Text)`
  font-size: inherit;
`

interface Props {
  broker: ContractBroker
}

const BrokerInformation: React.FC<Props> = ({ broker }) => (
  <Wrapper>
    {broker?.name && (
      <Line bold={true}>
        <MaskedData type="Broker Name">{broker?.name}</MaskedData>
      </Line>
    )}
    {broker?.email && (
      <Line>
        <MaskedData type="Email">{broker?.email}</MaskedData>
      </Line>
    )}
    {broker?.phone && (
      <Line>
        <MaskedData type="Phone Number">{broker?.phone}</MaskedData>
      </Line>
    )}
  </Wrapper>
)

export default BrokerInformation
