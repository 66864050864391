import React from 'react'

import { IconStyled, RootStyled } from './MakeModelCell.styled'
import { TableData } from './types'

import { useIsDesktopView } from 'mlp-client/src/utils/hooks/breakpoints'

export interface MakeModelCellProps {
  item: TableData
}

const MakeModelCell: React.FC<MakeModelCellProps> = ({ item }) => {
  const isDesktopView = useIsDesktopView()
  const icon = isDesktopView ? (
    <IconStyled data-e2e-id="makeModelCellIcon" size="small" />
  ) : null
  return (
    <RootStyled data-e2e-id="makeModelCell">
      {icon}
      <div>{item.makeModel}</div>
    </RootStyled>
  )
}

export default MakeModelCell
