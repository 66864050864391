import {
  MyAccountPictogram,
  OfficeBuildingPictogram,
} from '@velocity/icons/pictogram'
import { DropdownList, DropdownListItem, Headline } from '@velocity/ui'
import { FlexBox } from '@velocity/ui/draft'
import React, { useEffect } from 'react'

import {
  Neutral500Text,
  Neutral800Text,
} from 'mlp-client/src/components/styled/TextStyle'
import { setCurrentContractId } from 'mlp-client/src/contracts/contractsSlice'
import { useCurrentContractId } from 'mlp-client/src/contracts/hooks/useContracts'
import { useAppDispatch } from 'mlp-client/src/hooks'
import TranslatedRedirect from 'mlp-client/src/localization/TranslatedRedirect'
import Translation from 'mlp-client/src/localization/Translation'
import {
  CardStyled,
  ChevronRightIconStyled,
  DropdownListItemWrapper,
  LicensePlateStyled,
} from 'mlp-client/src/select-account/SelectAccount.styled'
import { useGetUserAccounts } from 'mlp-client/src/user/hooks/useUser'
import { AccountTypes, UserAccount, UserRole } from 'mlp-client/src/user/types'
import {
  setSelectedAccountId,
  useSelectedAccountId,
} from 'mlp-client/src/user/userSlice'
import MaskedLicensePlate from 'mlp-client/src/utils/masks/MaskedLicensePlate'

const AccountSubLabel: React.FC<{ account: UserAccount }> = ({ account }) => {
  const isDriver = account.roles.some(role => role === UserRole.DRIVER)
  const { contractsCount, vehicle } = account

  if (isDriver && contractsCount === 1) {
    return (
      <Neutral500Text>
        {vehicle.make} {vehicle.model}
        <MaskedLicensePlate>
          <LicensePlateStyled>
            {vehicle.registrationNumber.toUpperCase()}
          </LicensePlateStyled>
        </MaskedLicensePlate>
      </Neutral500Text>
    )
  }

  return (
    <Neutral500Text>
      <Translation
        id="myPortal.accountSwitching.multipleVehicles.subtitle"
        replace={{ contractsCount }}
      />
    </Neutral500Text>
  )
}

const SelectAccount: React.FC = () => {
  const userAccounts = useGetUserAccounts()
  const selectedAccountId = useSelectedAccountId()
  const contractId = useCurrentContractId()
  const dispatch = useAppDispatch()

  const selectAccount = (accountId: string) => {
    dispatch(setSelectedAccountId(accountId))
  }

  useEffect(() => {
    if (!selectedAccountId && contractId) {
      dispatch(setCurrentContractId(null))
    }
  }, [selectedAccountId, contractId])

  if (selectedAccountId) {
    return <TranslatedRedirect to="myPortal.homepage" />
  }

  return (
    <FlexBox
      alignItems="center"
      justifyContent="center"
      data-e2e-id="selectAccount"
    >
      <div>
        <Headline variant="200">
          <Translation id="myPortal.accountSwitching.title" />
        </Headline>
        <CardStyled>
          <DropdownList role="listbox" aria-label="Select an account">
            {userAccounts.map(account => (
              <DropdownListItemWrapper key={account.id}>
                <DropdownListItem
                  data-e2e-id={`accountListItem${account.id}`}
                  role="option"
                  startAdornment={
                    account.accountType === AccountTypes.COMPANY ? (
                      <OfficeBuildingPictogram
                        size="small"
                        data-e2e-id="officeBuildingPictogram"
                      />
                    ) : (
                      <MyAccountPictogram
                        size="small"
                        data-e2e-id="myAccountPictogram"
                      />
                    )
                  }
                  label={
                    <Neutral800Text fontWeight="700">
                      {account.name}
                    </Neutral800Text>
                  }
                  subLabel={<AccountSubLabel account={account} />}
                  onClick={() => selectAccount(account.id)}
                />
                <ChevronRightIconStyled size="m" />
              </DropdownListItemWrapper>
            ))}
          </DropdownList>
        </CardStyled>
      </div>
    </FlexBox>
  )
}
export default SelectAccount
