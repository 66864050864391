import { Button, Divider, Grid, Headline } from '@velocity/ui'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { EmptyPage } from 'mlp-client/src/components/empty-page/EmptyPage'
import MyleaseplanPage from 'mlp-client/src/components/layout/my-leaseplan-page/MyleaseplanPage'
import Loader from 'mlp-client/src/components/loader/Loader'
import { SomethingWentWrong } from 'mlp-client/src/components/something-went-wrong/SomethingWentWrong'
import { PageTitle } from 'mlp-client/src/enums'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/types'
import { useAppDispatch, useAppSelector } from 'mlp-client/src/hooks'
import InvoiceFilters from 'mlp-client/src/invoices/components/InvoiceFilters/InvoiceFilters'
import { InvoicesList } from 'mlp-client/src/invoices/components/InvoicesList/InvoicesList'
import { NotFoundInvoices } from 'mlp-client/src/invoices/components/NotFoundInvoices/NotFoundInvoices'
import OldInvoices from 'mlp-client/src/invoices/components/OldInvoices/OldInvoices'
import useInvoiceQuery from 'mlp-client/src/invoices/hooks/useInvoiceQuery'
import {
  setInvoicesSearchFilter,
  setInvoicesDateFilter,
  setPage,
  hasDateFilter,
  hasSearchFilter,
  hasSomeFilter,
  getSearchTerm,
  getStartDate,
  getEndDate,
  getPage,
} from 'mlp-client/src/invoices/invoicesSlice'
import { formatDateString } from 'mlp-client/src/invoices/utils'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'
import {
  is404Error,
  isServerError,
  isBadRequestError,
} from 'mlp-client/src/services/api/utils'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

const ShowMoreButtonWrapper = styled('div')`
  display: flex;
  justify-content: center;
  padding-top: var(--vel-fixed-spacing-03);
`

const Invoices: React.FC = () => {
  const dispatch = useAppDispatch()

  // Required for fetching
  const accountId = useSelectedAccountId()

  // Pagination
  const page = useAppSelector(getPage)

  // Filters
  const searchTerm = useAppSelector(getSearchTerm)
  const startDate = useAppSelector(getStartDate)
  const endDate = useAppSelector(getEndDate)

  const isDateFilterPresent = useAppSelector(hasDateFilter)
  const isSomeFilterPresent = useAppSelector(hasSomeFilter)
  const isSearchFilterPresent = useAppSelector(hasSearchFilter)

  const { invoices, isFetching, isSuccess, error, totalCount } =
    useInvoiceQuery({
      accountId,
      page,
      startDate,
      endDate,
      invoiceNumber: searchTerm,
    })

  useEffect(() => {
    if (isSearchFilterPresent) {
      dispatch(setPage(0))
    }
  }, [invoices, isSearchFilterPresent])

  const { translate } = useTranslations()
  const goLiveDate = translate('myPortal.invoices.oldInvoices.goLiveDate')
  const currentDate = formatDateString()

  const showMoreInvoices = () => {
    dispatch(setPage(page + 1))
  }

  const showFilters = isSomeFilterPresent || invoices?.length > 0

  const isError404 = is404Error(error)

  const showTechnicalError = isServerError(error) || isBadRequestError(error)

  const noInvoicesFound =
    ((!isFetching && !invoices?.length) || isError404) && !showTechnicalError
  const showEmptyPage = noInvoicesFound && !isSomeFilterPresent
  const showNotFoundFilteredByDate = noInvoicesFound && isDateFilterPresent
  const showNotFoundFilteredBySearch = noInvoicesFound && isSearchFilterPresent

  const showMoreButtonVisibility = invoices?.length < totalCount

  const clearFilters = () => {
    dispatch(setInvoicesSearchFilter(''))
    dispatch(
      setInvoicesDateFilter({
        startDate: null,
        endDate: null,
      }),
    )
  }

  return (
    <MyleaseplanPage metaTitle={PageTitle.INVOICES}>
      <div data-e2e-id="invoicesPage">
        <Headline
          component="h1"
          variant="400"
          margin={{ top: '02', bottom: '04' }}
        >
          <Translation
            data-e2e-id="invoices-title"
            id="myPortal.invoices.title"
          />
        </Headline>

        {showFilters && (
          <>
            <InvoiceFilters />
            <Divider layoutMargin={{ top: '04', bottom: '06' }} />
          </>
        )}

        {(isSuccess || error) && (
          <Grid>
            <Grid.Item XS={4} MD={6} LG={8}>
              {invoices?.length ? (
                <>
                  {!isFetching && isSearchFilterPresent && (
                    <Headline variant="200">
                      <Translation
                        data-e2e-id="searchFilterResultsTitle"
                        id="myPortal.invoices.searchFilter.results.title"
                      />
                    </Headline>
                  )}
                  {!isFetching && isDateFilterPresent && (
                    <Headline variant="200">
                      <Translation
                        id="myPortal.invoices.dateFilter.results.title"
                        replace={{
                          startDate: startDate
                            ? formatDateString(startDate)
                            : goLiveDate,
                          endDate: endDate
                            ? formatDateString(endDate)
                            : currentDate,
                        }}
                      />
                    </Headline>
                  )}
                  <InvoicesList invoices={invoices} />
                  {showMoreButtonVisibility && (
                    <ShowMoreButtonWrapper>
                      <Button
                        variant="secondary-outlined"
                        disabled={isFetching}
                        onClick={showMoreInvoices}
                        data-e2e-id="showMoreButton"
                      >
                        <Translation id="myPortal.invoices.invoices.showMore" />
                      </Button>
                    </ShowMoreButtonWrapper>
                  )}
                </>
              ) : (
                <>
                  {showEmptyPage && (
                    <EmptyPage
                      title="myPortal.invoices.empty.title"
                      subtitle="myPortal.invoices.empty.summary"
                      linkButton={{
                        to: 'myPortal.dashboard',
                        label: 'myPortal.invoices.empty.button.goToDashboard',
                      }}
                    />
                  )}
                  {showNotFoundFilteredBySearch && (
                    <NotFoundInvoices
                      searchTerm={searchTerm}
                      clearFilter={clearFilters}
                    />
                  )}
                  {showNotFoundFilteredByDate && (
                    <NotFoundInvoices
                      startDate={
                        startDate ? formatDateString(startDate) : goLiveDate
                      }
                      endDate={
                        endDate ? formatDateString(endDate) : currentDate
                      }
                      clearFilter={clearFilters}
                    />
                  )}
                  {showTechnicalError && !isSomeFilterPresent && (
                    <SomethingWentWrong
                      title="myPortal.error.invoices.noRetrieve.title"
                      subtitle="myPortal.error.invoices.noRetrieve.content"
                      linkButton={{
                        to: 'myPortal.error.invoices.noRetrieve.linkFaqs',
                        label: 'myPortal.error.invoices.noRetrieve.button',
                      }}
                      withMarginTop={false}
                      dataE2eId="invoicesTechnicalError"
                    />
                  )}
                  {showTechnicalError && isSomeFilterPresent && (
                    <SomethingWentWrong
                      title="myPortal.error.invoices.noInvoices.title"
                      subtitle="myPortal.error.invoices.noInvoices.content"
                      button={{
                        label: 'myPortal.error.invoices.noInvoices.button',
                        variant: 'secondary-filled',
                        onClick: () => clearFilters(),
                      }}
                      withMarginTop={false}
                      dataE2eId="invoicesSearchTechnicalError"
                    />
                  )}
                </>
              )}
            </Grid.Item>
            <Grid.Item XS={4} MD={6} LG={4}>
              <OldInvoices />
              {(!showTechnicalError || isSomeFilterPresent) && (
                <FAQ faqKey={FAQKeys.INVOICE} />
              )}
            </Grid.Item>
          </Grid>
        )}
        {isFetching && <Loader fixed={true} />}
      </div>
    </MyleaseplanPage>
  )
}

export default Invoices
