import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import NotFound from 'mlp-client/src/components/not-found/NotFound'
import { getShowAllFeatures } from 'mlp-client/src/debug/debugSlice'
import {
  MyleaseplanConfig,
  MyLeaseplanFeatureName,
  MyLeaseplanFeatureProps,
} from 'mlp-client/src/features-configuration/types'
import { useCountry } from 'mlp-client/src/localization/LocaleProvider'

interface Props {
  feature: MyLeaseplanFeatureName
}

const getFeatureConfig = (
  config: MyleaseplanConfig,
  feature: MyLeaseplanFeatureName,
): MyLeaseplanFeatureProps => {
  const featureConfig = config[feature]

  return {
    feature,
    featureConfig: {
      configuration: { ...featureConfig.configuration },
      enabled: featureConfig.enabled,
    },
    view: featureConfig.view,
  }
}

const MyleaseplanFeature: React.FC<Props> = props => {
  const context = useContext(MyLeaseplanContext)
  const country = useCountry()
  const showAllFeatures = useSelector(getShowAllFeatures)

  const { feature, ...rest } = props
  const config = getFeatureConfig(context, feature)
  const { featureConfig, view: View } = config
  const viewProps = {
    feature,
    country,
    ...rest,
    ...config,
  }

  if (!featureConfig.enabled) {
    return <NotFound />
  }

  if (!View) {
    throw new Error(
      `You probably forgot to declare the view for your feature ${feature}`,
    )
  }

  if (featureConfig.enabled || showAllFeatures) {
    return <View {...viewProps} />
  }

  return <NotFound />
}

export default MyleaseplanFeature
