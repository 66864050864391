import React from 'react'

import { useCurrentContractId } from 'mlp-client/src/contracts/hooks/useContracts'
import TranslatedRedirect from 'mlp-client/src/localization/TranslatedRedirect'
import { useGetUserContractIdsCount } from 'mlp-client/src/user/hooks/useUser'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

const HomePage: React.FC = () => {
  const contractsCount = useGetUserContractIdsCount()
  const showMultipleVehicles = contractsCount > 1
  const contractId = useCurrentContractId()
  const accountId = useSelectedAccountId()

  return showMultipleVehicles && !contractId ? (
    <TranslatedRedirect to="myPortal.vehicles" params={{ accountId }} />
  ) : (
    <TranslatedRedirect
      to="myPortal.dashboard"
      params={{ accountId, contractId }}
    />
  )
}

export default HomePage
