import { Button, ButtonProps } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'

export interface TranslatedButtonProps extends ButtonProps {
  label: string
}

const TranslatedButton: React.FC<TranslatedButtonProps> = ({
  label,
  ...props
}) => (
  <Button {...props}>
    <Translation id={label} />
  </Button>
)

export default TranslatedButton
