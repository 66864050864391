import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Navigate, useLocation } from 'react-router-dom'

import { setNextRoute } from 'mlp-client/src/auth/authSlice'
import { useAppDispatch } from 'mlp-client/src/hooks'
import { useLocalizedRoute } from 'mlp-client/src/localization/RoutesProvider'
import {
  getAccessToken,
  isUserAuthorized,
} from 'mlp-client/src/services/auth/auth'

export const AuthRouteProtector: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [shouldRedirect, setShouldRedirect] = useState(null)
  const getLocalizedRoute = useLocalizedRoute()
  const redirectRoute = getLocalizedRoute('myPortal.login')
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    getShouldRedirect()
  }, [])

  useEffect(() => {
    if (shouldRedirect) {
      const { pathname, search } = location

      if (pathname) {
        dispatch(setNextRoute(`${pathname}${search}`))
      }
    }
  }, [shouldRedirect, location])

  const getShouldRedirect = async () => {
    let isAuthenticated = await isUserAuthorized()

    if (!isAuthenticated) {
      try {
        // This is here so that if a user token expires, we still have a chance to refresh it with the refresh token.
        // isUserAuthorized returns false if the accessToken has expired, but there is still a chance the user has a refreshToken
        await getAccessToken()
      } catch (err: unknown) {
        // Don't need to actually do something with the error, if it errors we redirect to the login page.
        // getAccessToken might return a `Login required` error.
      } finally {
        isAuthenticated = await isUserAuthorized()
      }
    }
    setShouldRedirect(!isAuthenticated)
  }

  if (shouldRedirect === null) {
    return null
  }

  if (shouldRedirect) {
    return <Navigate to={redirectRoute} />
  }

  return (
    <>
      <Helmet meta={[{ name: 'robots', content: 'noindex,nofollow' }]} />
      {children}
    </>
  )
}

export default <T extends PropsWithChildren>(
  ProtectedComponent: React.ComponentType<T>,
) => {
  const ProtectAuthRoute: React.FC<T> = props => (
    <AuthRouteProtector>
      <ProtectedComponent {...props} />
    </AuthRouteProtector>
  )

  ProtectAuthRoute.displayName = `protectAuthRoute(${
    ProtectedComponent.displayName ||
    ProtectedComponent.name ||
    'ProtectedComponent'
  })`

  return ProtectAuthRoute
}
