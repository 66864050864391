import {
  Loader as VelocityLoader,
  LoaderVariant,
  useLoading,
} from '@velocity/ui'
import React, { PropsWithChildren, useEffect } from 'react'
import styled from 'styled-components'

interface Props extends PropsWithChildren {
  fixed?: boolean
  loadingActiveMessage?: string
  loadingDoneMessage?: string | null
  variant?: LoaderVariant
  size?: 's' | 'm' | 'l'
}

const LoaderContainer = styled.div<Props>`
  position: ${props => (props.fixed ? 'fixed' : 'relative')};
  ${props =>
    props.fixed &&
    `
      top: 0;
      left: 0;
  `};
  ${props =>
    !props.fixed &&
    `
      padding: var(--vel-layout-spacing-07) 0
  `};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Loader: React.FC<Props> = ({
  children,
  fixed = false,
  loadingActiveMessage = 'Loading',
  loadingDoneMessage = null,
  variant = 'primary',
  size = 'l',
}) => {
  const { setLoading, messageElement } = useLoading({
    loadingActiveMessage,
    loadingDoneMessage,
  })

  useEffect(() => {
    setLoading(true)
    return () => setLoading(false)
  }, [])

  return (
    <LoaderContainer fixed={fixed} data-e2e-id="loader">
      {messageElement}
      <VelocityLoader variant={variant} size={size}>
        {children}
      </VelocityLoader>
    </LoaderContainer>
  )
}

export default Loader
