import React, { ReactNode, useContext } from 'react'

import TopNavigation from 'mlp-client/src/components/layout/top-navigation/TopNavigation'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { Navigation } from 'mlp-client/src/features-configuration/types'
import { NavigationItem } from 'mlp-client/src/types'
import {
  useGetUserContractIdsCount,
  useIsUserClient,
  useUser,
} from 'mlp-client/src/user/hooks/useUser'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

interface Props {
  upperNavigationPopup?: ReactNode
}

const getNavigationStructure = (
  navigation: Navigation,
  showEmpty: boolean,
  userHasContract: boolean,
  showMultipleVehicles: boolean,
  isUserClient: boolean,
) => {
  if (showEmpty) {
    return navigation.EMPTY_NAVIGATION_STRUCTURE
  }

  if (!userHasContract) {
    return navigation.NO_CONTRACT_NAVIGATION_STRUCTURE
  }

  const navigationStructure: NavigationItem[] = []

  if (showMultipleVehicles) {
    navigationStructure.push(
      ...navigation.MULTIPLE_VEHICLES_NAVIGATION_STRUCTURE,
    )
  } else {
    navigationStructure.push(...navigation.NAVIGATION_STRUCTURE)
  }

  if (isUserClient) {
    navigationStructure.push(...navigation.INVOICES_NAVIGATION_ITEMS)
  }

  return navigationStructure
}

const TopNavigationStructure: React.FC<Props> = ({ upperNavigationPopup }) => {
  const isAccountSelected = Boolean(useSelectedAccountId())
  const { navigation } = useContext(MyLeaseplanContext)

  const { data: user } = useUser()

  const contractsCount = useGetUserContractIdsCount()
  const userHasContract = contractsCount > 0
  const showMultipleVehicles = contractsCount > 1

  const showEmpty = !user || !isAccountSelected
  const isClient = useIsUserClient()

  const navigationItems = navigation
    ? getNavigationStructure(
        navigation,
        showEmpty,
        userHasContract,
        showMultipleVehicles,
        isClient,
      )
    : []

  return (
    <TopNavigation
      navigationItems={navigationItems}
      upperNavigationPopup={upperNavigationPopup}
    />
  )
}

export default TopNavigationStructure
