import React, { PropsWithChildren, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import useContracts, {
  useCurrentContractId,
} from 'mlp-client/src/contracts/hooks/useContracts'
import { useLocale } from 'mlp-client/src/localization/LocaleProvider'
import { useMatchedRoute } from 'mlp-client/src/localization/RoutesProvider'
import { getParamsFromPath } from 'mlp-client/src/localization/utils'
import {
  getRedirectRouteMatch,
  setRedirectRouteMatch,
  clearRedirectRouteMatch,
} from 'mlp-client/src/routes/routesSlice'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

type MatchParams = {
  locale: string
  accountId: string
  contractId: string
}

export const UniversalRouteRedirectComponent: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const currentParams = useParams<MatchParams>()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const locale = useLocale()
  const accountId = useSelectedAccountId()
  const contractId = useCurrentContractId()
  const { data: contracts } = useContracts()

  const matchedRoute = useMatchedRoute()
  const redirectRouteMatch = useSelector(getRedirectRouteMatch)
  const redirectRoutePath = redirectRouteMatch?.route.path
  const redirectRouteParams = getParamsFromPath(redirectRoutePath)
  const matchedRouteParams = getParamsFromPath(matchedRoute?.route.path)
  const allParams = { locale, accountId, contractId }
  const mergedParams = {
    ...currentParams,
    ...allParams,
  }

  const areParamsComplete = (
    paramsList: string[],
    params: Partial<MatchParams> = mergedParams,
  ) =>
    (paramsList as (keyof MatchParams)[]).every(param => {
      if (param === 'contractId' && contracts?.length === 0) {
        return true
      }
      return params[param]
    })

  const areRedirectRouteParamsComplete = areParamsComplete(redirectRouteParams)
  const areMatchedRouteParamsComplete = areParamsComplete(
    matchedRouteParams,
    currentParams,
  )

  useEffect(() => {
    if (!areMatchedRouteParamsComplete && !redirectRouteMatch) {
      dispatch(setRedirectRouteMatch(matchedRoute))
    }

    if (redirectRouteMatch && areRedirectRouteParamsComplete) {
      const routeParams = { ...(redirectRouteMatch?.params || {}) }

      if (routeParams['*'] === '') {
        delete routeParams['*']
      }

      const route = generatePath(redirectRoutePath, {
        ...mergedParams,
        ...routeParams,
      })
      navigate(route)
    }
  }, [...Object.values(allParams), redirectRoutePath])

  useEffect(() => {
    // Redirect process is fully completed
    if (
      redirectRoutePath &&
      areRedirectRouteParamsComplete &&
      matchedRoute?.route.path === redirectRoutePath &&
      redirectRouteMatch.params['*'] === matchedRoute.params['*']
    ) {
      dispatch(clearRedirectRouteMatch())
    }
  }, [matchedRoute, redirectRouteMatch])

  return <>{children}</>
}

const universalRouteRedirectHOC = <T extends PropsWithChildren>(
  ProtectedComponent: React.ComponentType<T>,
) => {
  const UniversalRouteRedirect: React.FC<T> = props => (
    <UniversalRouteRedirectComponent>
      <ProtectedComponent {...props} />
    </UniversalRouteRedirectComponent>
  )

  UniversalRouteRedirect.displayName = `universalRouteRedirect(${
    ProtectedComponent.displayName ||
    ProtectedComponent.name ||
    'ProtectedComponent'
  })`

  return UniversalRouteRedirect
}

export default universalRouteRedirectHOC
