import React from 'react'

import { DateFormat } from 'mlp-client/src/localization/components/DateFormat'
import BaseTimelineCard, {
  TimelineItemProps,
} from 'mlp-client/src/timeline/components/TimelineCard/BaseTimelineCard'
import { EndOfContractTimelineItem } from 'mlp-client/src/timeline/types'

const EndOfContractTimelineCard: React.FC<
  TimelineItemProps<EndOfContractTimelineItem>
> = ({ item, config }) => (
  <BaseTimelineCard
    item={item}
    config={config}
    status={item.status}
    title="myPortal.timeline.events.mlc.titles.contractEnded"
  >
    <DateFormat value={item.endDate} literal="-" />
  </BaseTimelineCard>
)

export default EndOfContractTimelineCard
