import { Text } from '@velocity/ui'
import React from 'react'

import {
  BookingDetails,
  MaintenanceDetails,
} from 'mlp-client/src/bookings/types'
import { DateTime } from 'mlp-client/src/localization/components/DateTime'
import { Mileage } from 'mlp-client/src/localization/components/Mileage'
import TimelineEventStatus from 'mlp-client/src/timeline/components/TimelineEventStatus/TimelineEventStatus'
import {
  AddressFormatter,
  fromPascalCaseToSpacedString,
} from 'mlp-client/src/utils/formatters/formatters'
import MaskedData from 'mlp-client/src/utils/masks/MaskedData'

const renderServices = (service: string) => (
  <Text key={service} bold={true}>
    {fromPascalCaseToSpacedString(service)}
  </Text>
)

export const TIME_LOCATION_SECTION = {
  name: 'TIME_LOCATION_SECTION',
  title: 'Time and location',
  getProperties: (
    bookingDetails: BookingDetails,
    formatAddress: AddressFormatter,
  ) => [
    bookingDetails.appointmentDate && {
      label: 'Date & time',
      key: 'Date & time',
      value: (
        <Text bold={true}>
          <DateTime value={bookingDetails.appointmentDate} month="long" />
        </Text>
      ),
    },
    bookingDetails.status && {
      label: 'Status',
      key: 'Status',
      value: <TimelineEventStatus status={bookingDetails.status} />,
    },
    bookingDetails.bookingNumber && {
      label: 'Booking ID',
      key: 'Booking ID',
      value: <Text bold={true}>{bookingDetails.bookingNumber}</Text>,
    },
    bookingDetails?.pickupAddress && {
      label: 'Pickup address',
      key: 'Pickup address',
      value: (
        <Text bold={true}>
          <MaskedData type="Address">
            {formatAddress(bookingDetails?.pickupAddress)}
          </MaskedData>
        </Text>
      ),
    },
  ],
  hasMap: true,
  hasButtons: true,
}

export const SERVICE_REQUIRED = {
  name: 'SERVICE_REQUIRED',
  title: 'Service required',
  getProperties: (
    bookingDetails: MaintenanceDetails,
    formatAddress: AddressFormatter,
  ) => [
    bookingDetails.serviceRequired?.services?.length && {
      label: 'Service',
      key: 'Service',
      value: <>{bookingDetails.serviceRequired.services.map(renderServices)}</>,
    },
    bookingDetails.serviceRequired?.additionalServices?.length && {
      label: 'Extra services',
      key: 'Extra services',
      value: (
        <>
          {bookingDetails.serviceRequired.additionalServices.map(
            renderServices,
          )}
        </>
      ),
    },
    bookingDetails.serviceRequired?.mileage && {
      label: 'Mileage',
      key: 'Mileage',
      value: (
        <Text bold={true}>
          <Mileage
            value={bookingDetails.serviceRequired.mileage.value}
            mileageUnit={bookingDetails.serviceRequired.mileage.unit}
          />
        </Text>
      ),
    },
    bookingDetails.serviceRequired?.pickupAddress && {
      label: 'Pickup address',
      key: 'Pickup address',
      value: (
        <Text bold={true}>
          <MaskedData type="Address">
            {formatAddress(bookingDetails.serviceRequired.pickupAddress)}
          </MaskedData>
        </Text>
      ),
    },
    bookingDetails?.serviceRequired?.replacementVehiclePickupAddress && {
      label: 'Replacement vehicle pickup address',
      key: 'Replacement vehicle pickup address',
      value: (
        <Text bold={true}>
          <MaskedData type="Address">
            {formatAddress(
              bookingDetails.serviceRequired.replacementVehiclePickupAddress,
            )}
          </MaskedData>
        </Text>
      ),
    },
    bookingDetails.serviceRequired?.description && {
      label: 'Additional information',
      key: 'Additional information',
      value: (
        <Text bold={true}>{bookingDetails.serviceRequired.description}</Text>
      ),
    },
  ],
}

export const CONTRACT_INFORMATION = {
  name: 'CONTACT_INFORMATION',
  title: 'Contact information',
  getProperties: (bookingDetails: BookingDetails) => [
    bookingDetails.supplier?.name && {
      label: 'Name',
      key: 'Name',
      value: (
        <Text bold={true}>
          <MaskedData type="Supplier Name">
            {bookingDetails.supplier.name}
          </MaskedData>
        </Text>
      ),
    },
    bookingDetails.supplier?.email && {
      label: 'Email',
      key: 'Email',
      value: (
        <Text bold={true}>
          <MaskedData type="Email">{bookingDetails.supplier.email}</MaskedData>{' '}
        </Text>
      ),
    },
    bookingDetails.supplier?.phone && {
      label: 'Phone',
      key: 'Phone',
      value: (
        <Text bold={true}>
          <MaskedData type="Phone Number">
            {bookingDetails.supplier.phone}
          </MaskedData>{' '}
        </Text>
      ),
    },
  ],
}
