import { CheckIcon } from '@velocity/icons/system'
import { Card, List } from '@velocity/ui'
import styled from 'styled-components'

export const ListStyled = styled(List)`
  margin-block: var(--vel-fixed-spacing-07);
`

export const CheckIconStyled = styled(CheckIcon)`
  color: rgba(var(--vel-color-semantic-success-high-raw));
`

export const BackdropStyled = styled.div`
  position: fixed;
  display: flex;
  inset: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(var(--vel-color-neutral-100-raw), 0.5);
  backdrop-filter: blur(2px);
  z-index: 1300;
`

export const CardStyled = styled(Card)`
  max-width: 586px;
  margin-left: auto;
  margin-right: auto;
`
