import { Toaster } from '@velocity/ui'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { IntlProvider } from 'react-intl'
import { useRoutes } from 'react-router-dom'

import { BuildInfoRibbon } from 'mlp-client/src/BuildInfoRibbon'
import {
  useLanguage,
  useLocale,
  useIsCountryVisible,
} from 'mlp-client/src/localization/LocaleProvider'
import useLocalizedRoutes from 'mlp-client/src/routes/routes'
import tenant from 'mlp-client/src/tenant/tenant'

const App: React.FC = React.memo(() => {
  const locale = useLocale()
  const languageCode = useLanguage()
  const isCountryVisible = useIsCountryVisible()

  const robotsDefault = isCountryVisible ? 'index,follow' : 'noindex,nofollow'

  const localizedRoutes = useLocalizedRoutes()
  const routes = useRoutes(localizedRoutes)

  return (
    <>
      <BuildInfoRibbon />
      <Helmet
        defaultTitle="LeasePlan"
        titleTemplate="%s | LeasePlan"
        meta={[
          { name: 'description', content: 'LeasePlan' },
          { name: 'robots', content: robotsDefault },
          { name: 'format-detection', content: 'telephone-no' },
        ]}
        htmlAttributes={{
          lang: languageCode,
        }}
      />
      <IntlProvider locale={locale} defaultLocale="en-gb">
        <div data-tenant={tenant}>
          {routes}
          <Toaster position="bottom-center" />
        </div>
      </IntlProvider>
    </>
  )
})

export default App
