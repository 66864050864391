import { LinkInline } from '@velocity/ui'
import React from 'react'

import { useToastsWithTranslation } from 'mlp-client/src/components/toasts/useToasts'
import { useLazyGetDownloadDocumentQuery } from 'mlp-client/src/documents/documentsApi'
import { Document } from 'mlp-client/src/documents/types'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'
import { trackDocumentDownloadsEvent } from 'mlp-client/src/services/analytics/analytics'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'
import { saveFile } from 'mlp-client/src/utils'
import { fromCamelCaseToUpperSnake } from 'mlp-client/src/utils/formatters/formatters'

interface Props {
  pageTitle: string
  component: 'documents' | 'car_and_contract'
}

const useDocumentDownload = ({ pageTitle, component }: Props) => {
  const { translate } = useTranslations()
  const { showError } = useToastsWithTranslation()
  const [getDocument] = useLazyGetDownloadDocumentQuery()
  const accountId = useSelectedAccountId()

  const downloadDocument =
    (
      document: Document,
      linkText = 'myPortal.vehicleDocuments.steps.documentDetail.buttons.download',
    ) =>
    async () => {
      const { id, name, documentType, gdmsOwnerEntity, gdmsOwnerApplication } =
        document

      const {
        data: documentBlob,
        isSuccess,
        isError,
      } = await getDocument({
        accountId,
        entity: gdmsOwnerEntity,
        application: gdmsOwnerApplication,
        gdid: id,
      })

      if (isError) {
        showError(
          'myPortal.error.noDownloadDocuments.title',
          <Translation
            id="myPortal.error.noDownloadDocuments.content"
            replace={{
              contentUrl: () => (
                <LinkInline
                  target="_blank"
                  href={translate(
                    'myPortal.error.noDownloadDocuments.contentURL',
                  )}
                  key="contentUrl"
                >
                  <Translation id="myPortal.error.noDownloadDocuments.contentUrlLabel" />
                </LinkInline>
              ),
            }}
          />,
        )
      }

      if (isSuccess) {
        const fileName = name || id
        saveFile(documentBlob, fileName, 'application/pdf')
      }

      trackDocumentDownloadsEvent({
        component,
        data: {
          component_type: `${component}${fromCamelCaseToUpperSnake(name)}`,
          component_title: translate(pageTitle),
          component_link_text: translate(linkText),
          component_document_name: translate(
            `myPortal.vehicleDocuments.titles.${documentType}`,
          ),
          component_document_type: name,
        },
      })
    }

  return {
    downloadDocument,
  }
}

export default useDocumentDownload
