import React from 'react'
import { FormattedDate, FormattedDateParts } from 'react-intl'

export interface Props
  extends React.PropsWithChildren<Intl.DateTimeFormatOptions> {
  value: string
  literal?: string
}

export const DateFormat: React.FC<Props> = ({
  value,
  literal,
  children,
  ...props
}) =>
  literal ? (
    <FormattedDateParts
      value={value}
      day={props.day ?? 'numeric'}
      month={props.month ?? 'numeric'}
      year={props.year ?? 'numeric'}
      {...props}
    >
      {parts => (
        <>
          {parts
            .filter(part => part.type !== 'literal')
            .map(part => part.value)
            .join(literal)}
        </>
      )}
    </FormattedDateParts>
  ) : (
    <FormattedDate
      value={value}
      day={props.day ?? 'numeric'}
      month={props.month ?? 'numeric'}
      year={props.year ?? 'numeric'}
      {...props}
    />
  )
