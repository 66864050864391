import React, { PropsWithChildren, useContext, useMemo } from 'react'

interface ComponentContextProps {
  /**
   * list of all parent components, separated by .
   */
  componentBreadCrumbs: string
  /**
   * last parent component, used in the use-rum-action hook to name the action
   */
  component: string
  /**
   * custom attributes passed to all actions under this context
   */
  customAttributes?: Record<string, unknown>
}

const RumComponentContext = React.createContext<ComponentContextProps>({
  componentBreadCrumbs: 'root',
  component: 'root',
})

interface Props extends PropsWithChildren {
  componentName: string
  customAttributes?: Record<string, unknown>
}

/**
 * Context Provider to add a new component to the action breadcrumbs. Useful for class Components.
 */
export const RumComponentContextProvider: React.FC<Props> = ({
  componentName,
  customAttributes,
  children,
}) => {
  const parentContext = useContext(RumComponentContext)
  const newContext = useMemo<ComponentContextProps>(
    () => ({
      component: componentName,
      customAttributes: {
        ...parentContext.customAttributes,
        ...customAttributes,
      },
      componentBreadCrumbs: `${parentContext.componentBreadCrumbs}.${componentName}`,
    }),
    [
      componentName,
      parentContext.componentBreadCrumbs,
      parentContext.customAttributes,
      customAttributes,
    ],
  )

  return (
    <RumComponentContext.Provider value={newContext}>
      {children}
    </RumComponentContext.Provider>
  )
}

/**
 * Decorator to add a new component to the breadcrumbs when using useRumAction or useRumTracking action hooks
 * the decorator is better than a just component because it will add the context to everything in your component
 */
export function WithRumComponentContext<TPropsType>(
  componentName: string,
  options:
    | {
        customAttributes?: Record<string, unknown>
      }
    | undefined,
  Component: React.FunctionComponent<TPropsType>,
): React.FunctionComponent<TPropsType>

export function WithRumComponentContext<TPropsType>(
  componentName: string,
  Component: React.FunctionComponent<TPropsType>,
): React.FunctionComponent<TPropsType>

export function WithRumComponentContext<TPropsType>(
  componentName: string,
  options: any,
  Component?: React.FunctionComponent<TPropsType>,
): React.FunctionComponent<TPropsType> {
  if (typeof Component === 'undefined') {
    return WithRumComponentContext(componentName, {}, options)
  }

  return (props: TPropsType) => (
    <RumComponentContextProvider
      componentName={componentName}
      customAttributes={options.customAttributes}
    >
      <Component {...props} />
    </RumComponentContextProvider>
  )
}
