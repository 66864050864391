import { api } from 'mlp-client/src/services/api/api'
import { User } from 'mlp-client/src/user/types'
import { capitalizeUserNames } from 'mlp-client/src/user/utils'

const userApi = api.injectEndpoints({
  endpoints: builder => ({
    getUser: builder.query<User, void>({
      query: () => ({
        url: `/user`,
        method: 'GET',
      }),
      transformResponse: capitalizeUserNames,
      providesTags: ['User'],
    }),
  }),
})

export default userApi

export const { useGetUserQuery } = userApi
