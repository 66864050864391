import { Headline } from '@velocity/ui'
import React from 'react'

import { Show } from 'mlp-client/src/components/utils/Show'
import VehicleModelLicense from 'mlp-client/src/components/vehicle-model-license/VehicleModelLicense'
import useContracts from 'mlp-client/src/contracts/hooks/useContracts'
import { Contract } from 'mlp-client/src/contracts/types'
import { getFormattedVehicle } from 'mlp-client/src/contracts/utils'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'
import { useIsMobileView } from 'mlp-client/src/utils/hooks/breakpoints'
import VehicleOverviewTileLinks from 'mlp-client/src/vehicles/components/vehicle-overview-tiles/VehicleOverviewTileLinks'
import {
  CardStyled,
  CarSedanIconStyled,
  ChevronRightIconStyled,
  FlexRowStyled,
  LinkStyled,
  LinksContainerStyled,
  TileContainerStyled,
  VehicleInfoContainerStyled,
  VehicleOverviewContainerStyled,
} from 'mlp-client/src/vehicles/components/vehicle-overview-tiles/VehicleOverviewTiles.styled'

export interface VehicleOverviewTileProps {
  contract: Contract
}

const VehicleOverviewTile: React.FC<VehicleOverviewTileProps> = ({
  contract,
}) => {
  const vehicle = getFormattedVehicle(contract)
  const accountId = useSelectedAccountId()

  const isMobileView = useIsMobileView()
  const testId = isMobileView
    ? 'vehicleOverviewMobile'
    : 'vehicleOverviewDesktop'

  return (
    <TileContainerStyled data-e2e-id={testId}>
      <CardStyled kind="elevated">
        <VehicleOverviewContainerStyled
          data-e2e-id={`vehicleOverview-${vehicle.licensePlate}`}
        >
          <VehicleInfoContainerStyled>
            <LinkStyled
              component={RouteLink}
              to="myPortal.dashboard"
              params={{ accountId, contractId: contract.id }}
            >
              <FlexRowStyled>
                <CarSedanIconStyled size="small" />
                <Headline variant="100">
                  <VehicleModelLicense
                    vehicle={vehicle}
                    bold={true}
                    textSize="300"
                  />
                </Headline>
                <Show until="MD">
                  <ChevronRightIconStyled size="xs" />
                </Show>
              </FlexRowStyled>
            </LinkStyled>
          </VehicleInfoContainerStyled>
          <Show from="MD">
            <LinksContainerStyled>
              <VehicleOverviewTileLinks contract={contract} />
            </LinksContainerStyled>
          </Show>
        </VehicleOverviewContainerStyled>
      </CardStyled>
    </TileContainerStyled>
  )
}

const VehicleOverviewTiles: React.FC = () => {
  const { data: contracts } = useContracts()

  return (
    <>
      {contracts?.map(contract => (
        <VehicleOverviewTile key={contract.id} contract={contract} />
      ))}
    </>
  )
}

export default VehicleOverviewTiles
