import React, { useEffect, PropsWithChildren } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { setCurrentContractId } from 'mlp-client/src/contracts/contractsSlice'
import useContracts, {
  useCurrentContractId,
  useHasMultipleContracts,
} from 'mlp-client/src/contracts/hooks/useContracts'
import { useAppDispatch } from 'mlp-client/src/hooks'
import { useCurrentPath } from 'mlp-client/src/localization/RoutesProvider'
import TranslatedRedirect from 'mlp-client/src/localization/TranslatedRedirect'
import { getParamsFromPath } from 'mlp-client/src/localization/utils'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

type MatchParams = {
  accountId: string
  contractId: string
}

const SelectContractRedirect: React.FC<PropsWithChildren> = ({ children }) => {
  const params = useParams<MatchParams>()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const searchParams = new URLSearchParams(location.search)
  const urlContractId = params.contractId || searchParams.get('contractId')
  const showMultipleVehicles = useHasMultipleContracts()
  const accountId = useSelectedAccountId()
  const currentPath = useCurrentPath()
  const pathParams = getParamsFromPath(currentPath)
  const isContractIdInParams = pathParams.some(param => param === 'contractId')

  const { data: contracts } = useContracts()

  const currentContractId = useCurrentContractId()

  useEffect(() => {
    if (urlContractId && urlContractId !== currentContractId) {
      if (contracts?.some(({ id }) => id === urlContractId)) {
        dispatch(setCurrentContractId(urlContractId))
      } else {
        dispatch(setCurrentContractId(null))
      }
    }
  }, [urlContractId, currentContractId, contracts])

  if (
    isContractIdInParams &&
    !currentContractId &&
    !urlContractId &&
    showMultipleVehicles
  ) {
    return <TranslatedRedirect to="myPortal.vehicles" params={{ accountId }} />
  }

  return <>{children}</>
}

export default SelectContractRedirect
