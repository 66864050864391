import createConfig from './defaults'

import { CountrySpecific } from 'mlp-client/src/config/types'
import {
  CountryCode,
  LanguageCode,
  Locales,
} from 'mlp-client/src/localization/enums'

const specificConfig = (): CountrySpecific => ({
  label: 'Portugal',
  code: CountryCode.pt,
  tld: '.pt',
  languages: [
    {
      label: 'Portuguese',
      code: LanguageCode.pt,
      locale: Locales.PT,
      preferred: true,
    },
    {
      label: 'English',
      code: LanguageCode.en,
      locale: Locales.EN_PT,
      preferred: false,
    },
  ],
  footerLinks: [
    'myPortal.footer.globalPrivacyStatement',
    'myPortal.footer.personalDataRights',
    'myPortal.footer.termsOfUse',
    'myPortal.footer.creditIntermediaries',
  ],
})

export default createConfig(specificConfig)
