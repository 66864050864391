import { FilesPictogram } from '@velocity/icons/pictogram'
import { Button, Grid, Headline, Text } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'

const StyledGrid = styled(Grid)`
  flex-direction: column;
  text-align: center;
  margin: var(--vel-fixed-spacing-01) var(--vel-fixed-spacing-01);
  padding: var(--vel-fixed-spacing-07) 0 var(--vel-fixed-spacing-08);
  background-color: var(--vel-color-neutral-white);
`

interface Props {
  searchTerm?: string
  startDate?: string
  endDate?: string
  clearFilter: () => void
}

export const NotFoundInvoices: React.FC<Props> = ({
  searchTerm,
  startDate,
  endDate,
  clearFilter,
}) => {
  return (
    <StyledGrid
      columns={1}
      alignment="center"
      gutter={{ y: 'fixed-spacing-04' }}
      data-e2e-id="notFoundInvoices"
    >
      <Grid.Item XS={1}>
        <FilesPictogram
          size="large"
          style={{
            width: '128px',
            height: '128px',
          }}
        />
      </Grid.Item>
      <Grid.Item XS={1}>
        <Headline variant="200" component="h4">
          <Translation
            data-e2e-id="notFoundTitle"
            id="myPortal.invoices.notFound.title"
          />
        </Headline>
      </Grid.Item>

      <Grid.Item XS={1}>
        {searchTerm && (
          <Grid>
            <Grid.Item XS={1}>
              <Text>
                <Translation
                  id="myPortal.invoices.notFound.noResults"
                  replace={{
                    value: () => (
                      <Text bold={true} component="span" key={searchTerm}>
                        {searchTerm}
                      </Text>
                    ),
                  }}
                  data-e2e-id="invoiceNotFoundText"
                />
              </Text>
            </Grid.Item>
            <Grid.Item XS={1}>
              <Text>
                <Translation id="myPortal.invoices.notFound.note" />
              </Text>
              <Text>
                <Translation id="myPortal.invoices.notFound.example" />
              </Text>
            </Grid.Item>
          </Grid>
        )}
        {startDate && endDate && (
          <>
            <Text>
              <Translation
                id="myPortal.invoices.notFound.noInvoicesFrom"
                replace={{
                  startDate: () => (
                    <Text bold={true} component="span" key={startDate}>
                      {startDate}
                    </Text>
                  ),
                  endDate: () => (
                    <Text bold={true} component="span" key={endDate}>
                      {endDate}
                    </Text>
                  ),
                }}
                data-e2e-id="invoicesByDateNotFoundText"
              />
            </Text>
          </>
        )}
      </Grid.Item>

      <Grid.Item XS={1}>
        <Grid margin={{ top: '02' }}>
          <Grid.Item>
            <Button variant="secondary-filled" onClick={clearFilter}>
              <Translation id="myPortal.invoices.notFound.button" />
            </Button>
          </Grid.Item>
        </Grid>
      </Grid.Item>
    </StyledGrid>
  )
}
