import { mq } from '@velocity/styling'
import { Card, Grid, SkeletonLoader } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import { RootStyled } from 'mlp-client/src/dashboard/components/DashboardTopBanner.styled'
import { DashboardTilesLoader } from 'mlp-client/src/dashboard/components/loaders/DashboardTilesLoader'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { BREAKPOINT_TABLET } from 'mlp-client/src/utils/hooks/breakpoints'

interface Props {
  hasBiggerMargin: boolean
}

const BannerSkeletonLoader = styled(Card)`
  height: 68px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 var(--vel-fixed-spacing-07);

  > div {
    width: 60%;
  }
`

const DashboardTilesLoaderWrapper = styled.div<Props>`
  margin-top: var(
    ${props =>
      props.hasBiggerMargin
        ? '--vel-layout-spacing-08'
        : '--vel-layout-spacing-06'}
  );
  padding-top: var(--vel-fixed-spacing-06);

  ${mq.below(BREAKPOINT_TABLET)} {
    margin-top: var(
      ${props =>
        props.hasBiggerMargin
          ? '--vel-layout-spacing-08'
          : '--vel-layout-spacing-07'}
    );
    padding-top: var(
      ${props =>
        props.hasBiggerMargin
          ? '--vel-layout-spacing-07'
          : '--vel-fixed-spacing-06'}
    );
  }
`

const Banner: React.FC = () => (
  <RootStyled data-e2e-id="dashboardLoaderVehicle">
    <Grid>
      <Grid.Item MD={3} XS={12}>
        <BannerSkeletonLoader>
          <SkeletonLoader
            on="alt"
            text={{
              variant: '400',
            }}
          ></SkeletonLoader>
        </BannerSkeletonLoader>
      </Grid.Item>
    </Grid>
  </RootStyled>
)

const DashboardLoader: React.FC<Props> = ({ hasBiggerMargin = false }) => {
  const { translate } = useTranslations()
  const metaTitle = translate('myPortal.dashboard.title')
  const metaDescription = translate('myPortal.dashboard.subtitle')

  return (
    <MyleaseplanPage
      metaTitle={metaTitle}
      metaDescription={metaDescription}
      banner={<Banner />}
    >
      <DashboardTilesLoaderWrapper
        data-e2e-id="dashboardLoader"
        hasBiggerMargin={hasBiggerMargin}
      >
        <DashboardTilesLoader />
      </DashboardTilesLoaderWrapper>
    </MyleaseplanPage>
  )
}

export default DashboardLoader
