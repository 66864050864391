import { ChevronRightIcon } from '@velocity/icons/system'
import { Card, Headline } from '@velocity/ui'
import React, { PropsWithChildren } from 'react'

import { useHasMultipleContracts } from 'mlp-client/src/contracts/hooks/useContracts'
import { TimelineCardConfig } from 'mlp-client/src/features-configuration/features-types/LpisTypes'

import Translation from 'mlp-client/src/localization/Translation'
import {
  ButtonWrapper,
  CardContentWrapper,
  ChildrenWrapper,
  RelativeTime,
  RightBorderWrapper,
  StyledRouteLink,
  TimelineEventStatusWrapper,
  VehicleInfo,
} from 'mlp-client/src/timeline/components/TimelineCard/BaseTimelineCard.styled'
import TimelineEventStatus from 'mlp-client/src/timeline/components/TimelineEventStatus/TimelineEventStatus'
import {
  BaseTimelineItem,
  TimelineItemStatus,
} from 'mlp-client/src/timeline/types'
import { NavigationParams } from 'mlp-client/src/types'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'
import { formatRelativeDate } from 'mlp-client/src/utils'
import MaskedLicensePlate from 'mlp-client/src/utils/masks/MaskedLicensePlate'

interface Props extends PropsWithChildren {
  item: BaseTimelineItem
  config: TimelineCardConfig
  status: TimelineItemStatus
  title: React.ReactNode
  id?: string
}

export interface TimelineItemProps<T extends BaseTimelineItem> {
  item: T
  config: TimelineCardConfig
}

interface CardLinkWrapperProps extends PropsWithChildren {
  link?: string
  params?: NavigationParams
}

const CardLinkWrapper: React.FC<CardLinkWrapperProps> = ({
  link,
  params = {},
  children,
}) =>
  link ? (
    <StyledRouteLink to={link} params={params}>
      {children}
    </StyledRouteLink>
  ) : (
    <>{children}</>
  )

const BaseTimelineCard: React.FC<Props> = ({
  item,
  config,
  status,
  title,
  id,
  children,
}) => {
  const showMultipleVehicles = useHasMultipleContracts()
  const accountId = useSelectedAccountId()

  const { ilan, vehicle, creationDate, type } = item

  const defaultParams = { accountId, contractId: ilan }
  const link = config.linkToDetails || config.linkToEventDetails
  const params = config.linkToDetails
    ? defaultParams
    : { accountId, contractId: ilan, id }

  return (
    <CardLinkWrapper link={link} params={params}>
      <Card margin={{ bottom: '04' }} data-e2e-id={`${type}_timelineEvent`}>
        <Card.Body>
          <CardContentWrapper>
            <div>
              <TimelineEventStatusWrapper>
                <TimelineEventStatus status={status} />
              </TimelineEventStatusWrapper>
              <RelativeTime>{formatRelativeDate(creationDate)}</RelativeTime>
              <Headline variant="100" component="h6" margin={{ bottom: '03' }}>
                {typeof title === 'string' ? <Translation id={title} /> : title}
              </Headline>
              <VehicleInfo bold={true} loose={true}>
                {showMultipleVehicles && (
                  <RightBorderWrapper data-e2e-id="timelineItemVehicleModel">
                    {vehicle.model}
                  </RightBorderWrapper>
                )}
                <MaskedLicensePlate>
                  {vehicle.registrationNumber}
                </MaskedLicensePlate>
              </VehicleInfo>
              <ChildrenWrapper data-e2e-id="timelineItemVehicleContent">
                {children}
              </ChildrenWrapper>
            </div>
            {link && (
              <ButtonWrapper>
                <ChevronRightIcon size="m" />
              </ButtonWrapper>
            )}
          </CardContentWrapper>
        </Card.Body>
      </Card>
    </CardLinkWrapper>
  )
}

export default BaseTimelineCard
