/* stylelint-disable no-descending-specificity */

import { mq } from '@velocity/styling'
import styled from 'styled-components'

import { BREAKPOINT_LAPTOP } from 'mlp-client/src/utils/hooks/breakpoints'

const cellPadding = '15px'
const firstCellPadding = '25px'

export const TableStyled = styled.table`
  width: 100%;
  margin: 0 0 36px 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
  box-shadow: 1px 1px 6px var(--vel-color-neutral-300);

  tbody {
    ${mq.below(BREAKPOINT_LAPTOP)} {
      display: inline-table;
    }

    cursor: pointer;
    width: 100%;

    &:hover {
      --opacity: 0.1;
      background-color: rgba(
        var(--vel-color-brand-support1-500-raw),
        var(--opacity)
      );
    }

    tr:nth-child(even) {
      --opacity: 0.2;
      background-color: rgba(
        var(--vel-color-background-main-raw),
        var(--opacity)
      );
    }

    tr:nth-child(odd) {
      --opacity: 1;
      background-color: rgba(
        var(--vel-color-background-alt-raw),
        var(--opacity)
      );
    }
  }

  td:first-child {
    padding-left: ${firstCellPadding};
  }

  td:last-child {
    padding-right: ${firstCellPadding};
  }

  th:first-child {
    padding-left: ${firstCellPadding};
  }

  th:last-child {
    padding-right: ${firstCellPadding};
  }
`

export const CellStyled = styled.td`
  text-align: left;
  padding: ${cellPadding};
  word-break: break-word;

  ${mq.min(BREAKPOINT_LAPTOP)} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${mq.below(BREAKPOINT_LAPTOP)} {
    .field-radio__label {
      display: inline;
      margin-left: 20px;
    }
  }
`

export const HeaderCellStyled = styled.th`
  text-align: left;
  padding: ${cellPadding};
  color: var(--vel-color-text-on_primary);
  background-color: var(--vel-color-secondary-400);
  white-space: nowrap;

  ${mq.below(BREAKPOINT_LAPTOP)} {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 40%;
  }
`

export const MobileTableWrapperStyled = styled.div`
  margin-bottom: 24px;
`
