import { TravelAbroadConfiguration } from 'mlp-client/src/dashboard/types'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'

export const travelAbroadFeature: MyLeaseplanFeature<TravelAbroadConfiguration> =
  {
    enabled: true,
    configuration: {
      dialogModal: {
        title: 'myPortal.travelAbroad.dialog.title',
        summary: 'myPortal.travelAbroad.dialog.summary',
        secondaryButtonURL: 'myPortal.travelAbroad.vehicle.linkURL',
        buttonURL: 'myPortal.travelAbroad.foreignTravel.linkURL',
        secondaryButton: 'myPortal.travelAbroad.dialog.buttons.moreInformation',
        button: 'myPortal.travelAbroad.dialog.buttons.requestDocuments',
      },
    },
  }
