import React from 'react'

import { DateFormat } from 'mlp-client/src/localization/components/DateFormat'
import BaseTimelineCard, {
  TimelineItemProps,
} from 'mlp-client/src/timeline/components/TimelineCard/BaseTimelineCard'
import { ChangeDriverTimelineItem } from 'mlp-client/src/timeline/types'

const ChangeDriverTimelineCard: React.FC<
  TimelineItemProps<ChangeDriverTimelineItem>
> = ({ item, config }) => (
  <BaseTimelineCard
    item={item}
    config={config}
    status={item.status}
    title="myPortal.timeline.events.mlc.titles.congratulations"
  >
    <DateFormat value={item.startDate} literal="-" />
    <div>{item.productDescription}</div>
  </BaseTimelineCard>
)

export default ChangeDriverTimelineCard
