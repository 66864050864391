export const saveFile = (
  blob: BlobPart,
  fileName: string,
  documentType: string,
) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob], { type: documentType })

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  const nav = window.navigator as any

  if (nav.msSaveOrOpenBlob) {
    nav.msSaveOrOpenBlob(newBlob)

    return
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob)
  const link = document.createElement('a')

  link.href = data
  link.download = fileName
  link.dispatchEvent(
    new MouseEvent('click', { bubbles: true, cancelable: true, view: window }),
  )
  window.URL.revokeObjectURL(data)
}
