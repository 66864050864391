import { Select } from '@velocity/ui'
import { MultiSelectChangeEvent } from '@velocity/ui/core/selects/Select/Select'
import React, { useEffect } from 'react'

import useContracts from 'mlp-client/src/contracts/hooks/useContracts'
import { getFormattedVehicle } from 'mlp-client/src/contracts/utils'
import { useAppDispatch } from 'mlp-client/src/hooks'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { setSelectedVehicles } from 'mlp-client/src/timeline/timelineSlice'

const TimelineVehicleSelector: React.FC = () => {
  const dispatch = useAppDispatch()
  const { data: contracts } = useContracts()
  const allVehicles = contracts.map(getFormattedVehicle)
  const { translate } = useTranslations()
  const placeholderText = translate('myPortal.timeline.shared.allVehicles')

  const selectVehicles = (vehicles: string[]) => {
    dispatch(setSelectedVehicles(vehicles))
  }

  const handleSelect = (event: MultiSelectChangeEvent) => {
    selectVehicles(event.target.value)
  }

  useEffect(() => {
    return () => {
      dispatch(setSelectedVehicles([]))
    }
  }, [])

  return (
    <Select
      multiple={true}
      placeholder={placeholderText}
      defaultValue={[]}
      onChange={handleSelect}
      data-e2e-id="timelineVehicleSelector"
    >
      {allVehicles.map(vehicle => (
        <Select.Option key={vehicle.id} value={vehicle.id}>
          {`${vehicle.model} | ${vehicle.licensePlate}`}
        </Select.Option>
      ))}
    </Select>
  )
}

export default TimelineVehicleSelector
