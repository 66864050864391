import React from 'react'

import MileageUpdateWithContract from 'mlp-client/src/components/mileage-update-modal/MileageUpdateWithContract'
import RouteModal from 'mlp-client/src/components/route-modal/RouteModal'
import { TileType } from 'mlp-client/src/contracts/enums'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { useProtectContractTiles } from 'mlp-client/src/routes/protectContractTilesRoute/protectContractTilesRoute'
import { withOneInstance } from 'mlp-client/src/utils/hocs/hocs'

function MileageUpdateModal() {
  const { translate } = useTranslations()

  const title = 'myPortal.dashboard.default.allTiles.milageUpdates'

  return (
    <RouteModal
      title={title}
      path="mileage-update"
      protectRoute={useProtectContractTiles(TileType.MILEAGE_UPDATE)}
      data-e2e-id="mileageUpdateModal"
    >
      {({ closeModal }) => (
        <MileageUpdateWithContract
          closeModal={closeModal}
          title={translate(title)}
        />
      )}
    </RouteModal>
  )
}

export default withOneInstance(MileageUpdateModal)
