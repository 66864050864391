import { Button, Headline } from '@velocity/ui'
import React from 'react'

import { setNextRoute } from 'mlp-client/src/auth/authSlice'
import { useNextRoute } from 'mlp-client/src/auth/hooks/useAuth'
import {
  LoginSideBarHeadline,
  LoginButtonWrapper,
} from 'mlp-client/src/components/login/Login.styled'
import { useAppDispatch } from 'mlp-client/src/hooks'
import { useLanguage } from 'mlp-client/src/localization/LocaleProvider'
import { useLocalizedRoute } from 'mlp-client/src/localization/RoutesProvider'
import Translation from 'mlp-client/src/localization/Translation'
import { authorize } from 'mlp-client/src/services/auth/auth'

const LoginAction: React.FC = () => {
  const nextRoute = useNextRoute()
  const getLocalizedRoute = useLocalizedRoute()
  const language = useLanguage()
  const dispatch = useAppDispatch()

  const onLogin = async () => {
    if (!nextRoute) {
      /**
       * Normally nextRoute will already have been set by protectRoute, except
       * when a direct url to the (unprotected) login page is used, in which case
       * we set it to the default route here.
       */
      const defaultNextRoute = getLocalizedRoute('myPortal.homepage')
      dispatch(setNextRoute(defaultNextRoute))
    }
    await authorize(language)
  }

  return (
    <div>
      <LoginSideBarHeadline component="h3" variant="400">
        <Translation id="myPortal.loginPages.title" />
      </LoginSideBarHeadline>
      <Headline component="h4" variant="200">
        <Translation id="myPortal.loginPages.subtitle" />
      </Headline>
      <LoginButtonWrapper>
        <Button
          variant="primary-filled"
          component="a"
          onClick={onLogin}
          data-e2e-id="loginPageLinkButton"
        >
          <Translation id="myPortal.loginPages.newMyLeasePlan.linkText" />
        </Button>
      </LoginButtonWrapper>
    </div>
  )
}

export default LoginAction
