import { CarMissingPictogram } from '@velocity/icons/pictogram'
import { ChevronLeftBgIcon } from '@velocity/icons/system'
import React from 'react'

import PagePlaceholder from 'mlp-client/src/components/page-placeholder/PagePlaceholder'

const pictogram = (
  <CarMissingPictogram
    size="large"
    style={{
      width: '128px',
      height: '128px',
    }}
  />
)

const BookingNotFound: React.FC = () => {
  const title = 'myPortal.timeline.error.booking.notFound.title'

  const linkButton = {
    to: 'myPortal.timeline.base',
    label: 'myPortal.timeline.error.backButton',
    StartIcon: ChevronLeftBgIcon,
  }

  const subtitle = 'myPortal.timeline.error.booking.notFound.subtitle'

  return (
    <PagePlaceholder
      title={title}
      subtitle={subtitle}
      pictogram={pictogram}
      dataE2eId="bookingNotFound"
      linkButton={linkButton}
    />
  )
}

export default BookingNotFound
