/*
- Added support for external links.
External links are now stored in src/mlp-client/src/translations/{LOCALE}/routes.json in `external` section.
If `to` prop contains `myPortal.external` than we prepend url with `https://leaseplan.com`
*/

import React, { PropsWithChildren } from 'react'
import type { AriaAttributes } from 'react'
import { Link, NavLink, Location } from 'react-router-dom'
import parseUrl from 'url-parse'

import { useLocalizedRoute } from 'mlp-client/src/localization/RoutesProvider'
import { NavigationParams } from 'mlp-client/src/types'
import { isExternal, isMail, isPhone } from 'mlp-client/src/utils'

const DATA_E2E_ID = 'data-e2e-id'
const DATA_TAG_ID = 'data-tag-id'
const ARIA_CURRENT = 'aria-current'

export interface Props extends PropsWithChildren {
  to: string
  locationState?: Location['state']
  baseUrl?: string
  params?: NavigationParams
  [DATA_E2E_ID]?: string
  [DATA_TAG_ID]?: string
  [ARIA_CURRENT]?: AriaAttributes['aria-current']
  className?: string
  style?: React.CSSProperties
  activeClassName?: string
  rel?: string
  target?: string
  onClick?(event: React.MouseEvent<HTMLAnchorElement>): void
  sendAnalytics?(toLink: string | Location): void
}

const isExternalUrl = (toLink: string) => {
  return isExternal(toLink) || isPhone(toLink) || isMail(toLink)
}

const RouteLink: React.FC<Props> = ({
  to,
  params,
  locationState,
  activeClassName,
  baseUrl,
  className,
  target,
  rel,
  style,
  onClick,
  sendAnalytics,
  children,
  ...props
}) => {
  const getLocalizedRoute = useLocalizedRoute()
  const toUrl = getLocalizedRoute(to, params, false)
  const shouldAddLeaseplanBaseUrl = to?.includes('myPortal.external')
  const isExternalLink = isExternalUrl(toUrl)
  // NavLinks or Links should never get a target: _self, as it causes reload.
  const urlObject = parseUrl(toUrl, {})
  let location: Location

  if (toUrl?.[0] !== '/' && !isExternalLink) {
    return null
  }

  const shouldRenderCustomLink = (isExtLink: boolean) =>
    baseUrl || to?.includes('myPortal.external') || isExtLink

  const onLinkClick =
    (toLink: string | Location) =>
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      sendAnalytics?.(toLink)
      onClick?.(event)
    }

  const renderCustomLink = (toLink: string, shouldAddLeaseplanUrl: boolean) => {
    const withLeaseplanUrl = shouldAddLeaseplanUrl
      ? `https://www.leaseplan.com${toLink}`
      : `${baseUrl}${toLink}`
    const link = isExternalUrl(toLink) ? toLink : withLeaseplanUrl

    return (
      <a
        href={link}
        data-e2e-id={props[DATA_E2E_ID]}
        data-tag-id={props[DATA_TAG_ID] || `link-${to}`}
        aria-current={props[ARIA_CURRENT]}
        className={className}
        target={target === '_self' ? undefined : target}
        rel={rel || 'follow'}
        onClick={onLinkClick(link)}
      >
        {children}
      </a>
    )
  }

  // If baseUrl is set, create a custom link. Eg: it is not internal
  if (shouldRenderCustomLink(isExternalLink)) {
    return renderCustomLink(toUrl, shouldAddLeaseplanBaseUrl)
  }

  if (locationState) {
    location = {
      search: urlObject.query,
      pathname: urlObject.pathname,
      hash: urlObject.hash,
      state: locationState,
      key: 'default',
    }
  }

  const renderWithActiveClass = (toLink: string, locationUrl: Location) => {
    return (
      <NavLink
        to={locationUrl || toLink}
        data-e2e-id={props[DATA_E2E_ID]}
        data-tag-id={props[DATA_TAG_ID] || `link-${to}`}
        aria-current={props[ARIA_CURRENT]}
        className={({ isActive }) =>
          isActive ? `${className} ${activeClassName}` : className
        }
        style={style}
        target={target === '_self' ? undefined : target}
        rel={rel || 'follow'}
      >
        {children}
      </NavLink>
    )
  }

  const renderDefault = (toLink: string, locationUrl: Location) => {
    return (
      <Link
        to={locationUrl || toLink}
        data-e2e-id={props[DATA_E2E_ID] || to}
        data-tag-id={props[DATA_TAG_ID] || `link-${to}`}
        aria-current={props[ARIA_CURRENT]}
        className={className}
        style={style}
        target={target === '_self' ? undefined : target}
        rel={rel || 'follow'}
        onClick={onLinkClick(`${window.location.origin}${toLink}`)}
      >
        {children}
      </Link>
    )
  }

  return activeClassName
    ? renderWithActiveClass(toUrl, location)
    : renderDefault(toUrl, location)
}

export default React.forwardRef<null, Props>((props, _ref) => (
  <RouteLink {...props} />
))
