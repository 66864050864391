import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RouteMatch } from 'react-router-dom'

import { AppState } from 'mlp-client/src/types'

export interface RoutesState {
  // used for universal links
  redirectRouteMatch: RouteMatch | null
  // used for simple redirect
  redirectRoutePath: string | null
}

export const initialState: RoutesState = {
  redirectRouteMatch: null,
  redirectRoutePath: null,
}

const routesSlice = createSlice({
  name: '@@routes',
  initialState,
  reducers: {
    setRedirectRouteMatch: (state, action: PayloadAction<RouteMatch>) => {
      state.redirectRouteMatch = action.payload
    },
    clearRedirectRouteMatch: () => initialState,
    setRedirectRoutePath: (state, action: PayloadAction<string>) => {
      state.redirectRoutePath = action.payload
    },
    clearRedirectRoutePath: () => initialState,
  },
})

export const getRedirectRouteMatch = (state: AppState) =>
  state.routes.redirectRouteMatch

export const getRedirectRoutePath = (state: AppState) =>
  state.routes.redirectRoutePath

export const {
  setRedirectRouteMatch,
  clearRedirectRouteMatch,
  setRedirectRoutePath,
  clearRedirectRoutePath,
} = routesSlice.actions

export default routesSlice.reducer
