import React from 'react'

export const withOneInstance = <T,>(Component: React.ComponentType<T>) => {
  let count = 0
  return function (props: T) {
    const [isOnlyOne, setIsOnlyOne] = React.useState(false)
    React.useEffect(() => {
      count++
      if (count === 1) {
        setIsOnlyOne(true)
      }
      return () => {
        count--
      }
    }, [])
    return isOnlyOne ? <Component {...props} /> : <></>
  }
}
