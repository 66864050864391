import React from 'react'
import { useParams } from 'react-router-dom'

import { VehicleCollectionModalTrigger } from 'mlp-client/src/bookings/components/VehicleCollectionDetailsPage/VehicleCollectionModalTrigger'
import { BookingDetailsTypes } from 'mlp-client/src/bookings/enums'
import useCancelBooking from 'mlp-client/src/bookings/hooks/useCancelBooking'
import ModalTrigger from 'mlp-client/src/components/modal-trigger/ModalTrigger'
import Translation from 'mlp-client/src/localization/Translation'
import { useIsUserClient } from 'mlp-client/src/user/hooks/useUser'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

const titleId = 'myPortal.timeline.vehicleCollection.title'
const cancelButtonId =
  'myPortal.timeline.vehicleCollection.cancelAppointmentModal.button.confirm'

const VehicleCollectionButtons = () => {
  const accountId = useSelectedAccountId()
  const isClient = useIsUserClient()
  const params = useParams<{ contractId: string; id: string }>()

  const { cancelBooking, isCancelBookingLoading, isCancelBookingError } =
    useCancelBooking({
      type: BookingDetailsTypes.VEHICLE_COLLECTION,
      titleId,
      cancelButtonId,
    })

  const onConfirmCancelBooking = () => {
    cancelBooking({ accountId, bookingId: params.id })
  }

  if (!isClient) {
    return null
  }

  return (
    <>
      <VehicleCollectionModalTrigger />

      {!isCancelBookingError && (
        <ModalTrigger
          isLoading={isCancelBookingLoading}
          modalTitle="myPortal.timeline.vehicleCollection.cancelAppointmentModal.title"
          triggerButtonText="myPortal.timeline.vehicleCollection.button.cancelAppointment"
          triggerButtonSize="s"
          triggerButtonVariant="primary-outlined"
          confirmButtonText={cancelButtonId}
          cancelButtonText="myPortal.timeline.vehicleCollection.cancelAppointmentModal.button.cancel"
          triggerButtonDataE2eId="vehicleCollectionCancelBooking"
          confirmButtonDataE2eId="vehicleCollectionModalConfirmCancelBooking"
          cancelButtonDataE2eId="vehicleCollectionModalCloseCancelBooking"
          onConfirm={onConfirmCancelBooking}
        >
          <Translation id="myPortal.timeline.vehicleCollection.details.cancel.summary" />
        </ModalTrigger>
      )}
    </>
  )
}

export default VehicleCollectionButtons
