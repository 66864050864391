import { isObject } from 'lodash'
import { useSelector } from 'react-redux'

import { useMyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { getShowAllFeatures } from 'mlp-client/src/debug/debugSlice'
import { MyleaseplanConfig } from 'mlp-client/src/features-configuration/types'

export const useIsFeatureEnabled = (feature?: keyof MyleaseplanConfig) => {
  const config = useMyLeaseplanContext()
  const showAllFeatures = useSelector(getShowAllFeatures)

  if (showAllFeatures) {
    return true
  }

  if (!feature) {
    return config.enabled
  }

  const featureConfig = config[feature]

  if (typeof featureConfig === 'boolean') {
    return featureConfig
  }

  if (isObject(featureConfig) && 'enabled' in featureConfig) {
    return featureConfig.enabled === true
  }

  return false
}
