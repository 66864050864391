import { BodyRepairPictogram } from '@velocity/icons/pictogram'
import { Headline } from '@velocity/ui'
import React from 'react'
import { Helmet } from 'react-helmet-async'

import InstallMobileApp from 'mlp-client/src/components/instal-mobile-app/InstallMobileApp'
import {
  ContentLayout,
  MainWrapper,
} from 'mlp-client/src/components/layout/main-content-wrapper/MainContentWrapper.styled'
import TopNavigationStructure from 'mlp-client/src/components/layout/top-navigation/TopNavigationStructure'
import LoginAction from 'mlp-client/src/components/login/login-action/LoginAction'
import LoginErrorHandler from 'mlp-client/src/components/login/login-error/LoginErrorHandler'
import LoginTermsAndConditions from 'mlp-client/src/components/login/login-terms-and-conditions/LoginTermsAndConditions'
import {
  LoginSideBar,
  LoginSideBarBody,
  LoginInformationSection,
} from 'mlp-client/src/components/login/Login.styled'
import { SecondaryDarkText } from 'mlp-client/src/components/styled/TextStyle'
import { Show } from 'mlp-client/src/components/utils/Show'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'

const Login: React.FC = () => {
  const { translate } = useTranslations()

  const metaTitle = translate('myPortal.login.title')
  const metaDescription = translate('myPortal.login.subtitle')

  return (
    <MainWrapper>
      <Helmet
        title={metaTitle}
        meta={[{ name: 'description', content: metaDescription }]}
      />
      <LoginErrorHandler />
      <TopNavigationStructure upperNavigationPopup={<InstallMobileApp />} />
      <ContentLayout>
        <LoginSideBar data-e2e-id="loginSideBar">
          <LoginSideBarBody>
            <LoginAction />
            <LoginTermsAndConditions />
          </LoginSideBarBody>
        </LoginSideBar>
        <Show from="MD">
          <LoginInformationSection data-e2e-id="loginInformationSection">
            <Headline variant="200" component="h4">
              <Translation id="myPortal.introduction.faqProposal" />
            </Headline>
            <BodyRepairPictogram
              size="large"
              style={{ display: 'inline-block' }}
            />
            <SecondaryDarkText variant="300">
              <Translation id="myPortal.introduction.faqProposalSub" />
            </SecondaryDarkText>
          </LoginInformationSection>
        </Show>
      </ContentLayout>
    </MainWrapper>
  )
}

export default Login
