import { NotificationBanner } from '@velocity/ui'

import styled from 'styled-components'

import { BookingEventStatuses } from 'mlp-client/src/bookings/enums'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'

interface Props {
  status: BookingEventStatuses
}

const StyledNotificationBanner = styled(NotificationBanner)`
  margin-bottom: var(--vel-fixed-spacing-05);
`

const BookingStatusNotificationBanner: React.FC<Props> = ({ status }) => {
  const { translate } = useTranslations()

  if (status === BookingEventStatuses.FAILED) {
    return (
      <StyledNotificationBanner
        kind="warning"
        title={translate('myPortal.timeline.booking.unknownStatus.title')}
      >
        <Translation id="myPortal.timeline.booking.unknownStatus.content" />
      </StyledNotificationBanner>
    )
  }

  return null
}

export default BookingStatusNotificationBanner
