import { contractsConfig } from './contracts'
import { dashboardConfig } from './dashboard'
import { endOfLeaseFeature } from './endOfLease'
import { timelineConfig } from './timeline'
import { vehicleDocumentsPageFeature } from './vehicleDocuments'

import { baseMyLeaseplanConfig } from 'mlp-client/src/features-configuration/baseConfig'
import { travelAbroadFeature } from 'mlp-client/src/features-configuration/NGDACountries/travelAbroad'
import { MyleaseplanConfig } from 'mlp-client/src/features-configuration/types'

export const myleaseplanConfig: MyleaseplanConfig = {
  ...baseMyLeaseplanConfig,
  timeline: timelineConfig,
  contracts: contractsConfig,
  vehicleDocumentsPage: vehicleDocumentsPageFeature,
  dashboard: dashboardConfig,
  endOfLease: endOfLeaseFeature,
  travelAbroad: travelAbroadFeature,
}
