import { MailIcon, PhoneIcon } from '@velocity/icons/system'
import { Button, LinkStandalone, Modal, Text } from '@velocity/ui'
import React, { useContext } from 'react'

import BrokerInformation from 'mlp-client/src/components/broker-information/BrokerInformation'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import useContractDetails from 'mlp-client/src/contracts/hooks/useContractDetails'
import {
  ContentContainerStyled,
  FooterContainerStyled,
} from 'mlp-client/src/end-of-lease/modal/EndOfLeaseModal.styled'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'
import { trackEndOfLeaseContactEvent } from 'mlp-client/src/services/analytics/analytics'

interface Props {
  onClose: () => void
}

const EndOfLeaseModal: React.FC<Props> = ({ onClose }) => {
  const { data: contractDetails } = useContractDetails()
  const { translate } = useTranslations()
  const { endOfLease } = useContext(MyLeaseplanContext)

  const title = 'myPortal.endOfLease.modal.title'
  const description = 'myPortal.endOfLease.modal.summary'
  const emailButton = 'myPortal.endOfLease.modal.button.email'
  const callButton = 'myPortal.endOfLease.modal.button.call'

  const handleContactClick = (translationId: string) =>
    trackEndOfLeaseContactEvent({
      data: {
        component_title: translate(title),
        component_link_text: translate(translationId),
      },
    })

  const modalTitle = translate(title)

  return (
    <Modal
      title={modalTitle}
      onClose={onClose}
      size="m"
      open={true}
      data-e2e-id="eolBannerModal"
    >
      <Modal.Content>
        <ContentContainerStyled>
          <Text>
            <Translation id={description} />
          </Text>
          {endOfLease.configuration.showBrokerInfo &&
            contractDetails.contractBroker?.id && (
              <BrokerInformation broker={contractDetails.contractBroker} />
            )}
        </ContentContainerStyled>
      </Modal.Content>
      <Modal.Footer>
        <FooterContainerStyled>
          <LinkStandalone
            href={`mailto:${contractDetails?.contractBroker?.email}`}
          >
            <Button
              variant="primary-filled"
              data-e2e-id="eolBannerModalEmail"
              onClick={() => handleContactClick(emailButton)}
              StartIcon={MailIcon}
            >
              <Translation id={emailButton} />
            </Button>
          </LinkStandalone>
          <LinkStandalone
            href={`tel:${contractDetails?.contractBroker?.phone}`}
          >
            <Button
              data-e2e-id="eolBannerModalCall"
              variant="secondary-outlined"
              onClick={() => handleContactClick(callButton)}
              StartIcon={PhoneIcon}
            >
              {contractDetails?.contractBroker?.phone || (
                <Translation id={callButton} />
              )}
            </Button>
          </LinkStandalone>
        </FooterContainerStyled>
      </Modal.Footer>
    </Modal>
  )
}

export default EndOfLeaseModal
