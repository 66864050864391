import { Button, Card, List, Text } from '@velocity/ui'
import { ClickAwayAndEscapeListener } from '@velocity/ui/core/internal/ClickAwayAndEscapeListener/ClickAwayAndEscapeListener'
import React, { useState } from 'react'

import { useIsFeatureEnabled } from 'mlp-client/src/components/my-leaseplan-app/context/hooks'
import { useMyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { Show } from 'mlp-client/src/components/utils/Show'
import useContracts, {
  useHasContracts,
} from 'mlp-client/src/contracts/hooks/useContracts'
import Translation from 'mlp-client/src/localization/Translation'
import { useUser } from 'mlp-client/src/user/hooks/useUser'

import { makeTranslationPath } from 'mlp-client/src/utils'
import {
  BackdropStyled,
  CardStyled,
  CheckIconStyled,
  ListStyled,
} from 'mlp-client/src/welcome-screen/WelcomeScreen.styled'

const translatePath = makeTranslationPath('myPortal.welcomePopup')

const renderServiceItem = (item: string) => (
  <List.Item key={item}>
    <Text data-e2e-id={`welcomeScreenService_${item}`}>
      <Translation id={translatePath(item)} />
    </Text>
  </List.Item>
)

const WelcomeScreen: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true)
  const allowedServices =
    useMyLeaseplanContext()?.welcomeScreen?.allowedServices
  const { data: user } = useUser()
  const isWelcomeScreenEnabled = useIsFeatureEnabled('welcomeScreen')
  const hasContracts = useHasContracts()
  const { isFetching: areContractsLoading } = useContracts()
  const isWelcomeScreenVisible =
    isWelcomeScreenEnabled && !areContractsLoading && hasContracts

  const handleModalClose = () => {
    setIsOpen(false)
    if (user) {
      window.localStorage.setItem(`welcomeScreen-${user.id}`, '1')
    }
  }

  if (
    !user ||
    !isWelcomeScreenVisible ||
    !allowedServices ||
    !allowedServices.length ||
    !user.firstName ||
    window.localStorage.getItem(`welcomeScreen-${user.id}`)
  ) {
    return null
  }

  if (!isOpen) return null

  return (
    <Show from="MD">
      <BackdropStyled data-e2e-id="welcomeScreenBackdrop">
        <ClickAwayAndEscapeListener onAway={handleModalClose}>
          <CardStyled data-e2e-id="welcomeScreenModal" kind="elevated">
            <Card.Media
              src="/static/images/leaseplan/welcome-screen.png"
              alt="welcome"
            />
            <Card.Header>
              <Translation
                id={translatePath('welcome')}
                replace={{ username: user.firstName }}
              />
            </Card.Header>
            <Card.Body>
              <ListStyled kind="icon" Icon={CheckIconStyled}>
                {allowedServices.map(renderServiceItem)}
              </ListStyled>
            </Card.Body>
            <Card.ActionsContainer>
              <Button
                data-e2e-id="welcomeScreenGotIt"
                fullWidth={true}
                variant="primary-filled"
                onClick={handleModalClose}
              >
                <Translation id={translatePath('button')} />
              </Button>
            </Card.ActionsContainer>
          </CardStyled>
        </ClickAwayAndEscapeListener>
      </BackdropStyled>
    </Show>
  )
}

export default WelcomeScreen
