import React, { PropsWithChildren } from 'react'
import { useDispatch } from 'react-redux'

import TranslatedRedirect from 'mlp-client/src/localization/TranslatedRedirect'
import { clearRedirectRouteMatch } from 'mlp-client/src/routes/routesSlice'
import SelectAccountRedirect from 'mlp-client/src/select-account/SelectAccountRedirect'
import withUser from 'mlp-client/src/user/components/withUser'
import { useGetUserRoles } from 'mlp-client/src/user/hooks/useUser'
import { UserRole } from 'mlp-client/src/user/types'

interface Props extends PropsWithChildren {
  userRoles: UserRole[]
}

export const UserRoleRouteProtector: React.FC<Props> = ({
  userRoles,
  children,
}) => {
  const currentUserRoles = useGetUserRoles()
  const shouldRedirect = !currentUserRoles?.some(userRole =>
    userRoles.includes(userRole),
  )
  const dispatch = useDispatch()

  if (shouldRedirect) {
    dispatch(clearRedirectRouteMatch())
    return <TranslatedRedirect to="myPortal.notFound" />
  }

  return <>{children}</>
}

const protectUserRolesRouteHOC = <T extends PropsWithChildren>(
  ProtectedComponent: React.ComponentType<T>,
  hocProps: Props,
) => {
  const ProtectUserRolesRoute: React.FC<T> = props => (
    <SelectAccountRedirect>
      <UserRoleRouteProtector userRoles={hocProps.userRoles}>
        <ProtectedComponent {...props} />
      </UserRoleRouteProtector>
    </SelectAccountRedirect>
  )

  ProtectUserRolesRoute.displayName = `protectUserRolesRoute(${
    ProtectedComponent.displayName ||
    ProtectedComponent.name ||
    'ProtectedComponent'
  })`

  return withUser(ProtectUserRolesRoute)
}

export default protectUserRolesRouteHOC
