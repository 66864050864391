import React, { PropsWithChildren } from 'react'

import BookingDetailsPage from 'mlp-client/src/bookings/components/BookingDetailsPage'
import { TIME_LOCATION_SECTION } from 'mlp-client/src/bookings/components/BookingTablePropertiesConfig'
import VehicleCollectionButtons from 'mlp-client/src/bookings/components/VehicleCollectionDetailsPage/VehicleCollectionButtons'
import { TableSection } from 'mlp-client/src/bookings/types'
import { FAQKeys } from 'mlp-client/src/FAQ/types'
import { Route } from 'mlp-client/src/types'

const bookingTablePropertiesConfig: TableSection[] = [TIME_LOCATION_SECTION]

export type Props = PropsWithChildren<Route>

const VehicleCollectionDetailsPage: React.FC<Props> = ({ pageTitle }) => (
  <BookingDetailsPage
    Buttons={VehicleCollectionButtons}
    bookingTablePropertiesConfig={bookingTablePropertiesConfig}
    metaTitle={pageTitle}
    faqKey={FAQKeys.VEHICLE_COLLECTION_PAGE}
    headerTitle="myPortal.timeline.vehicleCollection.title"
    dataE2eId="vehicleCollection"
  />
)

export default VehicleCollectionDetailsPage
