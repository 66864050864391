import moment from 'moment'

// This module exports the build-time constants (and derived values) as regular constants, so we don't need any
// global declarations.

export interface BuildTimeEnv {
  rawBuildInfo: RawBuildInfo
  DD_CLIENT_TOKEN: string
  DD_APPLICATION_ID: string
  DD_SITE: string
  DD_SERVICE: string
  DD_ENV: string
  E2E_BOOKING_API: string
  TYRES_SERVICE_URL: string
  PROFILE_MANAGEMENT_API: string
  FAQ_API: string
  ANDROID_INSTALL_APP_URL: string
  IOS_INSTALL_APP_URL: string
}

export interface RawBuildInfo {
  buildTime: string
  versionDirty: string
  commitSha: string
  branch: string
  isProductionBuild: boolean
  isDevServer: boolean
}

/**
 * This constant is set by the webpack.DefinePlugin at build time. It is a single global constant containing properties
 * for each of the build-time constants.
 */
declare const BUILD_TIME_ENV: BuildTimeEnv

export const E2E_BOOKING_API: string = BUILD_TIME_ENV.E2E_BOOKING_API
export const TYRES_SERVICE_URL: string = BUILD_TIME_ENV.TYRES_SERVICE_URL

export const DD_CLIENT_TOKEN: string = BUILD_TIME_ENV.DD_CLIENT_TOKEN
export const DD_APPLICATION_ID: string = BUILD_TIME_ENV.DD_APPLICATION_ID
export const DD_SITE: string = BUILD_TIME_ENV.DD_SITE
export const DD_SERVICE: string = BUILD_TIME_ENV.DD_SERVICE
export const DD_ENV: string = BUILD_TIME_ENV.DD_ENV

export const PROFILE_MANAGEMENT_API: string =
  BUILD_TIME_ENV.PROFILE_MANAGEMENT_API

export const FAQ_API: string = BUILD_TIME_ENV.FAQ_API
export const ANDROID_INSTALL_APP_URL: string =
  BUILD_TIME_ENV.ANDROID_INSTALL_APP_URL
export const IOS_INSTALL_APP_URL: string = BUILD_TIME_ENV.IOS_INSTALL_APP_URL

const {
  buildTime,
  versionDirty,
  commitSha,
  branch,
  isProductionBuild,
  isDevServer,
} = BUILD_TIME_ENV.rawBuildInfo

const [version, dirtyString] = versionDirty.split('-') // BuildInfo.versionDirty format: 'v3.45.2' or 'v3.45.2-dirty'

export const buildInfo = {
  branch,
  version,
  isDirty: dirtyString === 'dirty',
  sha: commitSha.slice(0, 8),
  buildTime: moment(buildTime).format('DD-MMM-YYYY HH:mm:ss'),
  isProductionBuild,
  isDevServer,
}
