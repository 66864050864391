import { CarSedanPictogram } from '@velocity/icons/pictogram'
import { ChevronDownIcon, ChevronUpIcon } from '@velocity/icons/system'
import { isBreakpointBelow, useBreakpoint } from '@velocity/styling'
import { Dropdown } from '@velocity/ui'
import React, { useRef, useState } from 'react'

import {
  ChevronIconWrapper,
  StyledDropdownList,
  StyledDropdownListItem,
  StyledVehicleModel,
  StyledLicensePlate,
  SelectedCarWrapper,
  SelectedItemWrapper,
  StyledSelectedVehicleModel,
  Wrapper,
} from 'mlp-client/src/components/vehicle-select/VehicleSelect.styled'
import { FormattedVehicle } from 'mlp-client/src/contracts/types'
import { trackVehicleSelectionEvent } from 'mlp-client/src/services/analytics/analytics'

interface Props {
  selectedVehicleId: string
  vehicles: readonly FormattedVehicle[]
  compactView?: boolean
  onSelect(vehicleId: string): void
}

const getVehicleById = (
  vehicles: readonly FormattedVehicle[],
  id: string,
): FormattedVehicle | undefined => vehicles.find(vehicle => vehicle.id === id)

const VehicleSelect: React.FC<Props> = ({
  selectedVehicleId,
  vehicles,
  compactView,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const placementRef = useRef<HTMLDivElement>(null)
  const breakpoint = useBreakpoint()
  const isMobile = isBreakpointBelow('SM', breakpoint)

  const selectedVehicle = getVehicleById(vehicles, selectedVehicleId)

  const toggle = () => {
    if (vehicles.length > 1) {
      setIsOpen(isOpen => !isOpen)
    }
  }

  const onItemClick = (vehicle: FormattedVehicle) => {
    onSelect(vehicle.id)
    toggle()
    trackVehicleSelectionEvent({
      eventLabel: `${vehicle.model} - ${vehicle.licensePlate}`.toLowerCase(),
    })
  }

  return (
    <div ref={placementRef} data-e2e-id="vehicleSelect">
      <Wrapper selectable={vehicles.length > 1} isOpen={isOpen}>
        <SelectedItemWrapper
          data-e2e-id="selectedVehicle"
          onClick={toggle}
          compact={compactView}
        >
          {!compactView && (
            <CarSedanPictogram data-e2e-id="vehicleIcon" size="small" />
          )}
          <SelectedCarWrapper
            flexWrap={!compactView && isMobile}
            isMobile={isMobile}
          >
            <StyledSelectedVehicleModel variant="300">
              {selectedVehicle.model}
            </StyledSelectedVehicleModel>
            <StyledLicensePlate>
              {selectedVehicle.licensePlate}
            </StyledLicensePlate>
          </SelectedCarWrapper>
          <ChevronIconWrapper selectable={vehicles.length > 1}>
            {isOpen ? <ChevronUpIcon size="s" /> : <ChevronDownIcon size="s" />}
          </ChevronIconWrapper>
        </SelectedItemWrapper>
        {isOpen && (
          <Dropdown
            placementRef={placementRef}
            width="minimal-as-placement-ref"
            role="dialog"
            open={true}
            onClose={toggle}
            data-e2e-id="vehiclesList"
            rootStyle={{ marginTop: '0', borderRadius: '0' }}
          >
            <StyledDropdownList role="listbox" aria-label="Car selection">
              {vehicles
                .filter(vehicle => vehicle.id !== selectedVehicle.id)
                .map(vehicle => (
                  <StyledDropdownListItem
                    key={vehicle.id}
                    label={
                      <StyledVehicleModel variant="100">
                        {vehicle.model}
                      </StyledVehicleModel>
                    }
                    labelSuffix={
                      <StyledLicensePlate>
                        {vehicle.licensePlate}
                      </StyledLicensePlate>
                    }
                    startAdornment={
                      !compactView && (
                        <CarSedanPictogram
                          data-e2e-id="vehicleIcon"
                          size="small"
                        />
                      )
                    }
                    role="option"
                    onClick={() => onItemClick(vehicle)}
                    data-e2e-id={vehicle.licensePlate}
                  />
                ))}
            </StyledDropdownList>
          </Dropdown>
        )}
      </Wrapper>
    </div>
  )
}

export default VehicleSelect
