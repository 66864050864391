import { datadogRum } from '@datadog/browser-rum'
import {
  GoogleMapsContextProvider,
  EnsureLatestGoogleMapsSdk,
} from '@velocity/ui'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import { config } from './app-config'

import Initializer from './components/my-leaseplan-app/Initializer'
import { buildInfo } from './config/buildTimeEnv'
import { polyfillLocale } from './localization/polyfill/intl.polyfill'
import RoutesProvider from './localization/RoutesProvider'
import log from './log' // Main FE logger, see https://leaseplan-digital.atlassian.net/browse/FCQ-428
import { createAppStore } from './store'
import ThemeProvider from './theme/ThemeProvider'

import { RumComponentContextProvider } from 'mlp-client/src/datadog/RumComponentContext'
import Debug from 'mlp-client/src/debug/Debug'
import LocaleProvider from 'mlp-client/src/localization/LocaleProvider'
import { initAuth } from 'mlp-client/src/services/auth/auth'
import RuntimeEnv from 'mlp-client/src/utils/runtimeEnv'

import 'mlp-client/src/styles/main.css'
import 'mlp-client/src/styles/leaseplan.css'

const startApplication = async () => {
  const params = new URLSearchParams(window.location.search)

  // Only enable mocks on dev env and NEVER install the service worker on ACC or PROD
  // search params should be ?mocks=true
  if (RuntimeEnv.isDevelopment && params.get('mocks') === 'true') {
    const { worker } = await import('./mocks/browser')

    await worker.start()
  }

  await initAuth({
    domain: config.IDENTITY_API,
    clientId: config.IDENTITY_CLIENT_ID,
    audience: config.IDENTITY_AUDIENCE,
  })

  const { store } = createAppStore()

  await polyfillLocale()

  // datadog init
  datadogRum.init({
    applicationId: config.DD_APPLICATION_ID,
    clientToken: config.DD_CLIENT_TOKEN,
    site: config.DD_SITE,
    service: config.DD_SERVICE,
    env: config.DD_ENV,
    version: buildInfo.version,
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackViewsManually: true,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
    allowedTracingUrls: [config.API_BASE_URL],
  })

  // Show welcome message on console

  // When built from a GitLab pipeline, the branch is always HEAD and isDirty is false, so we don't show them.
  const [formattedBranch, formattedDirty] =
    buildInfo.branch !== 'HEAD'
      ? [
          `\n\n branch: ${buildInfo.branch}`,
          `(${buildInfo.isDirty ? 'dirty' : 'clean'})`,
        ]
      : ['', '']

  const formattedBuildInfo =
    `${buildInfo.version} [${buildInfo.sha}] ${formattedDirty}`.padEnd(
      48 - buildInfo.buildTime.length,
    ) +
    buildInfo.buildTime +
    formattedBranch

  // eslint-disable-next-line no-console
  console.log(`
    _                         _____  _
   | |                       |  __ \\| |
   | |     ___   _ _ ___  ___| |__) | |  _ _ _ __
   | |    / _ \\ /_\` / __|/ _ \\  ___/| | /_\` | '_ \\
   | |___|  __//(_| \\__ \\  __/ |    | |/(_| | | | |
my |______\\__/ \\__,_|___/\\___|_|    |/ \\__,_|_| |_|

 ${formattedBuildInfo}
 ------------------------------------------------
            Interested in joining us?
         Visit https://join.leaseplan.com
 ------------------------------------------------

`)

  log.info(
    `Client start: ${buildInfo.version} [${buildInfo.sha}] (build time: ${buildInfo.buildTime})`,
  )

  const appNode = document.getElementById('app')

  const root = createRoot(appNode)

  // Render the app
  root.render(
    <Provider store={store}>
      <HelmetProvider>
        <LocaleProvider>
          <RoutesProvider>
            <BrowserRouter>
              <ThemeProvider>
                <GoogleMapsContextProvider apiKey={config.GOOGLE_MAPS_API_KEY}>
                  <EnsureLatestGoogleMapsSdk>
                    <Debug>
                      <Initializer>
                        <RumComponentContextProvider componentName="AppComponent">
                          <App />
                        </RumComponentContextProvider>
                      </Initializer>
                    </Debug>
                  </EnsureLatestGoogleMapsSdk>
                </GoogleMapsContextProvider>
              </ThemeProvider>
            </BrowserRouter>
          </RoutesProvider>
        </LocaleProvider>
      </HelmetProvider>
    </Provider>,
  )
}

startApplication()
