import { Headline } from '@velocity/ui'
import React from 'react'

import TechnicalError from 'mlp-client/src/components/error/TechnicalError'
import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import NoContracts from 'mlp-client/src/components/no-contracts/NoContracts'
import ViewContract from 'mlp-client/src/contracts/components/ViewContract/ViewContract'
import useContracts from 'mlp-client/src/contracts/hooks/useContracts'
import { useHasContracts } from 'mlp-client/src/contracts/hooks/useContracts'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { isServerError } from 'mlp-client/src/services/api/utils'

const ViewContractFlow: React.FC = () => {
  const { translate } = useTranslations()
  const { error } = useContracts()

  const userHasContract = useHasContracts()

  const metaDescription = translate('myPortal.contract.car.title')
  const metaTitle = translate('myPortal.contract.title')

  let children = <ViewContract />

  if (isServerError(error)) {
    children = <TechnicalError />
  } else if (!userHasContract) {
    children = <NoContracts />
  }

  return (
    <MyleaseplanPage metaTitle={metaTitle} metaDescription={metaDescription}>
      <Headline variant="400" component="h1" withMarginBottom={true}>
        {metaTitle}
      </Headline>
      {children}
    </MyleaseplanPage>
  )
}

export default ViewContractFlow
