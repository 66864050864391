import { Text } from '@velocity/ui'
import React from 'react'

import { TableLeaseVehicleData } from 'mlp-client/src/components/overview-table/types'
import MaskedLicensePlate from 'mlp-client/src/utils/masks/MaskedLicensePlate'

interface Props {
  item: TableLeaseVehicleData
}

const LicenceCell: React.FC<Props> = ({ item }) => (
  <Text variant="200" bold={true}>
    <MaskedLicensePlate>{item?.licence || '-'}</MaskedLicensePlate>
  </Text>
)

export default LicenceCell
