import {
  FindInvoiceParams,
  GetInvoicesParams,
  useFindInvoiceQuery,
  useGetInvoicesQuery,
} from 'mlp-client/src/invoices/invoicesApi'
import { toInvoiceList } from 'mlp-client/src/invoices/utils'

interface Props extends GetInvoicesParams, FindInvoiceParams {}

const useInvoiceQuery = ({
  accountId,
  page,
  startDate,
  endDate,
  invoiceNumber,
}: Props) => {
  if (invoiceNumber) {
    const {
      currentData: invoiceSearchResult,
      isFetching,
      isSuccess,
      error,
    } = useFindInvoiceQuery({
      accountId,
      invoiceNumber,
    })

    return {
      invoices: toInvoiceList(invoiceSearchResult),
      totalCount: 1,
      isFetching,
      isSuccess,
      error,
    }
  }

  const {
    currentData: invoicesResult,
    isFetching,
    isSuccess,
    error,
  } = useGetInvoicesQuery({
    accountId,
    page,
    ...(startDate && { startDate }),
    ...(endDate && { endDate }),
  })

  return {
    invoices: invoicesResult?.invoices,
    totalCount: invoicesResult?.metadata?.totalCount || 0,
    isFetching,
    isSuccess,
    error,
  }
}

export default useInvoiceQuery
