import React from 'react'

import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import { Show } from 'mlp-client/src/components/utils/Show'
import {
  useHasMultipleContracts,
  useHasManyContracts,
} from 'mlp-client/src/contracts/hooks/useContracts'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import TranslatedRedirect from 'mlp-client/src/localization/TranslatedRedirect'
import Translation from 'mlp-client/src/localization/Translation'
import { useCachedUser } from 'mlp-client/src/user/hooks/useUser'
import TopBanner from 'mlp-client/src/vehicles/components/TopBanner'
import VehicleOverviewTiles from 'mlp-client/src/vehicles/components/vehicle-overview-tiles/VehicleOverviewTiles'
import VehiclesOverviewTable from 'mlp-client/src/vehicles/components/vehicles-overview-table/VehiclesOverviewTable'
import { HeadlineStyled } from 'mlp-client/src/vehicles/Vehicles.styled'
import WelcomeScreen from 'mlp-client/src/welcome-screen/WelcomeScreen'

const Vehicles: React.FC = () => {
  const { translate } = useTranslations()
  const metaTitle = translate('myPortal.dashboard.title')
  const metaDescription = translate('myPortal.dashboard.subtitle')
  const { data: user } = useCachedUser()
  const showMultipleVehicles = useHasMultipleContracts()
  const showCompactLayout = useHasManyContracts()

  if (!showMultipleVehicles) {
    return <TranslatedRedirect to="myPortal.dashboard" />
  }

  if (showCompactLayout) {
    return (
      <MyleaseplanPage metaTitle={metaTitle} metaDescription={metaDescription}>
        <WelcomeScreen />
        <VehiclesOverviewTable />
      </MyleaseplanPage>
    )
  }

  return (
    <MyleaseplanPage
      metaTitle={metaTitle}
      metaDescription={metaDescription}
      banner={<TopBanner name={user?.firstName} />}
    >
      <WelcomeScreen />
      <Show until="MD">
        <HeadlineStyled variant="200" data-e2e-id="mobileHomepageSubheader">
          <Translation id="myPortal.multipleCars.titles.subtitle" />
        </HeadlineStyled>
      </Show>
      <VehicleOverviewTiles />
    </MyleaseplanPage>
  )
}

export default Vehicles
