import { Grid, Text } from '@velocity/ui'
import React from 'react'

import { SectionProperties } from 'mlp-client/src/bookings/types'

interface Props {
  properties: SectionProperties
}

export const BookingTable: React.FC<Props> = ({ properties }) => (
  <>
    {properties.map(({ label, key, value }) => (
      <Grid
        key={key}
        gutter={{
          XS: { x: 'fixed-spacing-01', y: 'fixed-spacing-01' },
          LG: { y: 'fixed-spacing-03' },
        }}
      >
        <Grid.Item LG={4}>
          <Text variant="300" loose={true} component="span">
            {label}
          </Text>
        </Grid.Item>
        <Grid.Item LG={4} style={{ display: 'flex', alignSelf: 'center' }}>
          <div>{value}</div>
        </Grid.Item>
      </Grid>
    ))}
  </>
)
