import { mq } from '@velocity/styling'
import styled from 'styled-components'

import { BREAKPOINT_TABLET } from 'mlp-client/src/utils/hooks/breakpoints'

export const MainPage = styled.div`
  width: 100%;
`

/*
 * To avoid footer logo from overlapping on content
 */
export const MainPaddings = styled.div`
  padding-bottom: var(--vel-layout-spacing-06);
  margin-bottom: var(--vel-layout-spacing-08);

  ${mq.min(BREAKPOINT_TABLET)} {
    padding-top: var(--vel-layout-spacing-06);
  }

  ${mq.below(BREAKPOINT_TABLET)} {
    padding-top: var(--vel-layout-spacing-05);
  }
`
