import { TravelAbroadPictogram } from '@velocity/icons/pictogram'
import { FlexBox } from '@velocity/ui/draft'
import styled from 'styled-components'

export const ContainerWithBottomMargin = styled.div`
  margin-bottom: var(--vel-fixed-spacing-07);
`

export const BreadcrumbsContainerStyled = styled.div`
  margin-bottom: var(--vel-fixed-spacing-07);
  margin-top: var(--vel-fixed-spacing-04);
  margin-right: var(--vel-fixed-spacing-04);
`

export const LinkStyled = styled.a`
  text-decoration: none;
  color: inherit;
  margin-right: var(--vel-fixed-spacing-04);
`

export const GridStyled = styled(FlexBox)`
  align-items: center;
`

export const ButtonsWrapperStyled = styled.div`
  display: flex;
  justify-content: left;
`

export const TravelAbroadPictogramStyled = styled(TravelAbroadPictogram)`
  width: 104px;
  height: 104px;
  margin-right: var(--vel-layout-spacing-02);
`

export const StyledLinksList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--vel-layout-spacing-03);
`
