import { Navigation } from 'mlp-client/src/features-configuration/types'
import { NavigationItem } from 'mlp-client/src/types'

const EMPTY_NAVIGATION_STRUCTURE: NavigationItem[] = []

const NO_CONTRACT_NAVIGATION_STRUCTURE: NavigationItem[] = [
  {
    title: 'myPortal.subNavigation.dashboard',
    to: 'myPortal.dashboard',
  },
  {
    title: 'myPortal.timeline.base.title',
    to: 'myPortal.timeline.base',
  },
  {
    title: 'myPortal.subNavigation.carContract',
    to: 'myPortal.contract.detail',
  },
]

const NAVIGATION_STRUCTURE: NavigationItem[] = [
  {
    title: 'myPortal.subNavigation.dashboard',
    to: 'myPortal.dashboard',
    useContractId: true,
  },
  {
    title: 'myPortal.timeline.base.title',
    to: 'myPortal.timeline.base',
  },
  {
    title: 'myPortal.subNavigation.carContract',
    to: 'myPortal.contract.detail',
    useContractId: true,
  },
]

const MULTIPLE_VEHICLES_NAVIGATION_STRUCTURE: NavigationItem[] = [
  {
    title: 'myPortal.subNavigation.vehicles',
    to: 'myPortal.vehicles',
  },
  {
    title: 'myPortal.timeline.base.title',
    to: 'myPortal.timeline.base',
  },
]

const INVOICES_NAVIGATION_ITEMS: NavigationItem[] = [
  {
    title: 'myPortal.subNavigation.invoices',
    to: 'myPortal.invoices.base',
  },
]

export const navigation: Navigation = {
  EMPTY_NAVIGATION_STRUCTURE,
  NO_CONTRACT_NAVIGATION_STRUCTURE,
  NAVIGATION_STRUCTURE,
  MULTIPLE_VEHICLES_NAVIGATION_STRUCTURE,
  INVOICES_NAVIGATION_ITEMS,
}
