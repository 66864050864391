import React from 'react'

import HeaderCellWrapper from './HeaderCellWrapper'
import { TableStyled } from './Table.styled'
import TableWideBody from './TableWideBody'
import { TableProps } from './types'

const TableWide: React.FC<TableProps> = ({
  columns,
  rows,
  data,
  emptyState,
  defaultValue,
}) => (
  <TableStyled data-e2e-id="tableWide">
    <thead>
      <tr>
        {columns.map(({ header, headerCell, ...props }) => (
          <HeaderCellWrapper
            {...props}
            key={props.key}
            header={header}
            headerCell={headerCell}
            data-e2e-id={props.key}
          />
        ))}
      </tr>
    </thead>
    <TableWideBody
      columns={columns}
      data={data}
      rows={rows}
      emptyState={emptyState}
      defaultValue={defaultValue}
    />
  </TableStyled>
)

export default TableWide
