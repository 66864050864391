import React from 'react'

import { DateFormat } from 'mlp-client/src/localization/components/DateFormat'
import Translation from 'mlp-client/src/localization/Translation'
import BaseTimelineCard, {
  TimelineItemProps,
} from 'mlp-client/src/timeline/components/TimelineCard/BaseTimelineCard'
import { BalloonRefinanceTimelineItem } from 'mlp-client/src/timeline/types'

const BalloonRefinanceTimelineCard: React.FC<
  TimelineItemProps<BalloonRefinanceTimelineItem>
> = ({ item, config }) => (
  <BaseTimelineCard
    item={item}
    config={config}
    status={item.status}
    title={
      <Translation id="myPortal.timeline.events.mlc.titles.contractExtended" />
    }
  >
    <Translation
      id="myPortal.timeline.events.mlc.subtitles.contractExtended"
      replace={{
        leaseContractEndDate: () => (
          <DateFormat value={item.endDate} key="endDate" literal="-" />
        ),
      }}
    />
  </BaseTimelineCard>
)

export default BalloonRefinanceTimelineCard
