import ViewContract from 'mlp-client/src/contracts/components/ViewContractFlow'
import {
  ViewContractSections,
  VehicleSectionProperties,
  ContractSectionProperties,
  CustomerSectionProperties,
  ServicesSectionProperties,
} from 'mlp-client/src/contracts/enums'
import { ContractsFeatureConfig } from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import { Currencies } from 'mlp-client/src/localization/enums'

export const contractsConfig: MyLeaseplanFeature<ContractsFeatureConfig> = {
  enabled: true,
  view: ViewContract,
  configuration: {
    documents: true,
    contractStatusBlock: true,
    currency: { code: Currencies.EUR, format: '0.00' },
    contractDetails: {
      [ViewContractSections.VEHICLE]: [
        VehicleSectionProperties.MAKE,
        VehicleSectionProperties.MODEL,
        VehicleSectionProperties.BODY_TYPE,
        VehicleSectionProperties.VEHICLE_TYPE,
        VehicleSectionProperties.CHASSIS_NUMBER,
        VehicleSectionProperties.LICENSE_PLATE,
        VehicleSectionProperties.EXTERIOR,
        VehicleSectionProperties.INTERIOR,
        VehicleSectionProperties.NUMBER_OF_DOORS,
        VehicleSectionProperties.ENERGY_TYPE,
        VehicleSectionProperties.TRANSMISSION,
        VehicleSectionProperties.FUEL_TANK_CAPACITY,
        VehicleSectionProperties.BATTERY_CAPACITY,
        VehicleSectionProperties.CO2_EMISSION,
        VehicleSectionProperties.EURO_EMISSION_STANDARD,
        VehicleSectionProperties.MOT_EXPIRY_DATE,
        VehicleSectionProperties.REGISTRATION_DATE,
      ],
      [ViewContractSections.CONTRACT]: [
        ContractSectionProperties.ID,
        ContractSectionProperties.PRODUCT,
        ContractSectionProperties.INDIVIDUAL_LEASE_AGREEMENT_NUMBER,
        ContractSectionProperties.DURATION,
        ContractSectionProperties.ANNUAL_MILEAGE,
        ContractSectionProperties.INITIAL_MILEAGE,
        ContractSectionProperties.STATUS,
        ContractSectionProperties.START_DATE,
        ContractSectionProperties.END_DATE,
        ContractSectionProperties.PRICE,
        ContractSectionProperties.BROKER,
      ],
      [ViewContractSections.SERVICES]: [
        ServicesSectionProperties.MAINTENANCE,
        ServicesSectionProperties.INSURANCE,
        ServicesSectionProperties.ROAD_TAX,
        ServicesSectionProperties.ROADSIDE_ASSISTANCE,
        ServicesSectionProperties.ROADSIDE_ASSISTANCE_LEVEL,
        ServicesSectionProperties.REPLACEMENT_VEHICLE_WAITING_PERIOD,
        ServicesSectionProperties.REPLACEMENT_VEHICLE_ADDITIONAL_COVER_TYPE,
        ServicesSectionProperties.REPLACEMENT_VEHICLE,
        ServicesSectionProperties.REPLACEMENT_VEHICLE_COVER_TYPE,
        ServicesSectionProperties.MANAGEMENT_SERVICE_FEE,
      ],
      [ViewContractSections.CUSTOMER]: [
        CustomerSectionProperties.CUSTOMER_NUMBER,
        CustomerSectionProperties.CUSTOMER_NAME,
        CustomerSectionProperties.CUSTOMER_VAT_NUMBER,
        CustomerSectionProperties.DRIVER,
      ],
    },
  },
}
