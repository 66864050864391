import styled from 'styled-components'

export const ContentContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--vel-fixed-spacing-05);
`

export const FooterContainerStyled = styled.div`
  display: flex;
  gap: var(--vel-fixed-spacing-07);
`
