import _get from 'lodash/get'

import { config } from 'mlp-client/src/app-config'
import { getDebugTranslations } from 'mlp-client/src/debug/debugSlice'
import useDictionary from 'mlp-client/src/dictionary/hooks/useDictionary'
import { useAppSelector } from 'mlp-client/src/hooks'
import {
  isStringReplacementsArray,
  replaceContent,
  replaceContentStringByEntries,
  Replacement,
  ReplacementItem,
  StringReplacement,
} from 'mlp-client/src/utils'

interface TranslateFunction {
  (
    id: string,
    replace?: ReadonlyArray<ReplacementItem<StringReplacement>>,
  ): string
  (
    id: string,
    replace: ReadonlyArray<ReplacementItem<Replacement>>,
  ): React.ReactNode[]
}

const useTranslations = () => {
  const { data: dictionary, isLoading } = useDictionary()
  const debugTranslations = useAppSelector(getDebugTranslations)

  // @ts-ignore
  const translate: TranslateFunction = (
    id: string,
    replace?: ReadonlyArray<ReplacementItem<Replacement>>,
  ): string | React.ReactNodeArray => {
    if (!dictionary || !id) {
      return null
    }

    const translation: string = _get(dictionary, id.split('.'), id)

    if (translation === id) {
      return translation
    }

    if (replace) {
      if (isStringReplacementsArray(replace)) {
        const entries: Array<[string, StringReplacement]> = replace.reduce(
          (acc, { key, value }) => {
            acc.push([key, value])

            return acc
          },
          [],
        )

        return replaceContentStringByEntries(translation, entries)
      } else {
        const replacements = replace.reduce((acc, { key, value }) => {
          // Re-creating objects on each render can have performance implications
          // eslint-disable-next-line no-param-reassign
          acc[key] = value

          return acc
        }, {} as any)

        return replaceContent(translation, replacements)
      }
    }

    return translation
  }

  return {
    translate,
    debugTranslations,
    defaultDateFormat: config.DEFAULT_DATE_FORMAT,
    isLoading,
  }
}

export default useTranslations
