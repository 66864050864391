import { DocIcon, MaintenanceIcon, OptionIcon } from '@velocity/icons/system'
import { Grid } from '@velocity/ui'
import React from 'react'

import RouteLink from 'mlp-client/src/localization/RouteLink'
import Translation from 'mlp-client/src/localization/Translation'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'
import { makeTranslationPath } from 'mlp-client/src/utils'
import { VehicleTileLinks } from 'mlp-client/src/vehicles/components/vehicle-overview-tiles/types'
import { VehicleOverviewTileProps } from 'mlp-client/src/vehicles/components/vehicle-overview-tiles/VehicleOverviewTiles'
import {
  TileLinkStyled,
  VehicleOverviewTileText,
} from 'mlp-client/src/vehicles/components/vehicle-overview-tiles/VehicleOverviewTiles.styled'

interface VehicleOverviewTileLinksProps extends VehicleOverviewTileProps {}

const tileLinks: VehicleTileLinks = [
  {
    to: 'myPortal.dashboard',
    'data-e2e-id': 'bookService',
    Icon: () => <MaintenanceIcon size="m" color="neutral" />,
    translationId: 'service',
  },
  {
    to: 'myPortal.contract.detail',
    'data-e2e-id': 'viewContract',
    Icon: () => <DocIcon size="m" color="neutral" />,
    translationId: 'contract',
  },
  {
    to: 'myPortal.dashboard',
    'data-e2e-id': 'moreActions',
    Icon: () => <OptionIcon size="s" color="neutral" />,
    translationId: 'more',
  },
]

const translatePath = makeTranslationPath('myPortal.multipleCars.actions')

const VehicleOverviewTileLinks: React.FC<
  VehicleOverviewTileLinksProps
> = props => {
  const { contract } = props
  const accountId = useSelectedAccountId()
  const contractId = contract.id

  return (
    <Grid columns={6} data-e2e-id="vehicleOverViewTileLinks">
      {tileLinks.map((tileLink, index, array) => (
        <Grid.Item
          MD={{ columns: 2, offset: 0 }}
          LG={{ columns: 3, offset: index === array.length - 1 ? 3 : 0 }}
          key={tileLink.translationId}
        >
          <TileLinkStyled
            component={RouteLink}
            {...tileLink}
            params={{ accountId, contractId }}
          >
            <VehicleOverviewTileText variant="200">
              <Translation id={translatePath(tileLink.translationId)} />
            </VehicleOverviewTileText>
          </TileLinkStyled>
        </Grid.Item>
      ))}
    </Grid>
  )
}

export default VehicleOverviewTileLinks
