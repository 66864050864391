import React, { PropsWithChildren, useEffect } from 'react'

import Loader from 'mlp-client/src/components/loader/Loader'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { trackLoginEvent } from 'mlp-client/src/services/analytics/analytics'
import {
  useUser,
  getUserAnalyticsRole,
} from 'mlp-client/src/user/hooks/useUser'

const Initializer: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    data: user,
    isSuccess: isUserLoaded,
    isLoading: isUserLoading,
  } = useUser()

  const { isLoading: areTranslationsLoading } = useTranslations()

  useEffect(() => {
    if (isUserLoaded) {
      trackLoginEvent({
        userType: getUserAnalyticsRole(user),
        userId: user.id,
      })
    }
  }, [isUserLoaded])

  if (!isUserLoading && !areTranslationsLoading) {
    return <>{children}</>
  }

  return <Loader fixed={true} />
}

export default Initializer
