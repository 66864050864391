import React from 'react'
import { FormattedNumber } from 'react-intl'

interface Props {
  value: number
  unit?: string
  unitDisplay?: 'short' | 'long' | 'narrow'
}

export const Duration: React.FC<Props> = ({
  value,
  unit = 'month',
  unitDisplay = 'long',
}) => (
  <FormattedNumber
    value={value}
    style="unit"
    unit={unit}
    unitDisplay={unitDisplay}
  />
)
