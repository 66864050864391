import React from 'react'

import MyleaseplanFeature from 'mlp-client/src/components/myleaseplan-feature/MyleaseplanFeature'
import { useHasMultipleContracts } from 'mlp-client/src/contracts/hooks/useContracts'
import WelcomeScreen from 'mlp-client/src/welcome-screen/WelcomeScreen'

const Dashboard: React.FC = () => {
  const showMultipleVehicles = useHasMultipleContracts()

  return (
    <>
      {!showMultipleVehicles && <WelcomeScreen />}
      <MyleaseplanFeature feature="dashboard" />
    </>
  )
}

export default Dashboard
