import React from 'react'

import { MileageUpdate } from 'mlp-client/src/components/mileage-update-modal/MileageUpdate'
import useContractDetails from 'mlp-client/src/contracts/hooks/useContractDetails'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { trackMileageUpdateSubmittedEvent } from 'mlp-client/src/services/analytics/analytics'

interface Props {
  title: string
  closeModal(): void
  showCloseButton?: boolean
  summary?: React.ReactNode
}

const MileageUpdateWithContract: React.FC<Props> = ({
  title,
  closeModal,
  showCloseButton,
  summary,
}) => {
  const { translate } = useTranslations()
  const { data: contractDetails } = useContractDetails()
  const { mileageUnit, vehicle, leaseObjectId } = contractDetails
  const { lastKnownMileage } = vehicle

  const onSubmitSuccess = () =>
    trackMileageUpdateSubmittedEvent({
      data: {
        component_title: title,
        component_link_text: translate(
          'myPortal.mileageUpdate.modal.buttons.yes',
        ),
      },
    })

  return (
    <MileageUpdate
      title={title}
      leaseObjectId={leaseObjectId}
      lastKnownMileage={lastKnownMileage}
      mileageUnit={mileageUnit}
      onSubmitSuccessButton={onSubmitSuccess}
      closeModal={closeModal}
      showCloseButton={showCloseButton}
      summary={summary}
    />
  )
}

export default MileageUpdateWithContract
