import { Breadcrumbs } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { useLocalizedRoute } from 'mlp-client/src/localization/RoutesProvider'

interface Props {
  title: string
}

const BreadCrumbsContainer = styled.div`
  padding-block: var(--vel-fixed-spacing-03);
`

const TimelineBreadcrumbs: React.FC<Props> = ({ title }) => {
  const getLocalizedRoute = useLocalizedRoute()
  const { translate } = useTranslations()

  return (
    <BreadCrumbsContainer data-e2e-id="timelineBreadcrumbs">
      <Breadcrumbs>
        <Breadcrumbs.Link href={getLocalizedRoute('myPortal.timeline.base')}>
          {translate('myPortal.timeline.base.title')}
        </Breadcrumbs.Link>
        <Breadcrumbs.Link href="#">{translate(title)}</Breadcrumbs.Link>
      </Breadcrumbs>
    </BreadCrumbsContainer>
  )
}

export default TimelineBreadcrumbs
