import { CloseIcon } from '@velocity/icons/system'
import { Button, Text } from '@velocity/ui'
import styled from 'styled-components'

export const RootStyled = styled.div`
  padding: var(--vel-fixed-spacing-03);
  background: var(--vel-color-brand-support2-400);
  box-sizing: border-box;

  ~ div[data-e2e-id='topNavigationMenu'] > div > div {
    position: sticky;
  }
`

export const CloseIconStyled = styled(CloseIcon)`
  color: var(--vel-color-neutral-white);
`

export const ContentStyled = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: var(--vel-fixed-spacing-03);
`

export const LogoContainerStyled = styled.div`
  margin-right: var(--vel-fixed-spacing-03);

  @media (max-width: 320px) {
    display: none;
  }

  img {
    border-radius: 8px;
  }
`

export const MiddleStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--vel-fixed-spacing-02);
`

export const BannerLinkText = styled(Text)`
  color: var(--vel-color-neutral-white);
`

export const BannerButtonStyled = styled(Button)`
  white-space: nowrap;
`
