import { Text } from '@velocity/ui'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
  min-width: 0;
`
export const VehicleModel = styled(Text)`
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--vel-color-neutral-800);
`
