import { useGetContractDetailsQuery } from 'mlp-client/src/contracts/contractsApi'
import { useCurrentContractId } from 'mlp-client/src/contracts/hooks/useContracts'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

const useContractDetails = () => {
  const accountId = useSelectedAccountId()
  const contractId = useCurrentContractId()
  const skip = !contractId || !accountId

  return useGetContractDetailsQuery(
    {
      accountId,
      contractId,
    },
    { skip },
  )
}

export default useContractDetails
