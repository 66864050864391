import React, { ReactNode } from 'react'

import CellWrapper from './CellWrapper'
import { CellStyled } from './Table.styled'
import { Column } from './types'

interface Props {
  columns: readonly Column[]
  item: GenericObject
  defaultValue?: ReactNode | null
  rowsProps?: GenericObject
}

export const RowColumns: React.FC<Props> = ({
  columns,
  item,
  defaultValue,
  rowsProps,
}) => (
  <>
    {columns.map(({ key, cell: Cell }) => (
      <CellStyled key={key}>
        <CellWrapper
          columnKey={key}
          item={item}
          cell={Cell}
          defaultValue={defaultValue}
          rowsProps={rowsProps}
        />
      </CellStyled>
    ))}
  </>
)
