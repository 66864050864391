import { config } from 'mlp-client/src/app-config'
import useDictionary from 'mlp-client/src/dictionary/hooks/useDictionary'
import { FAQDetailsTranslationMap, FAQKeys } from 'mlp-client/src/FAQ/types'

const FAQDetails: FAQDetailsTranslationMap = {
  [FAQKeys.MAINTENANCE_AND_REPAIR_PAGE]: {
    linkUrl:
      'myPortal.timeline.serviceMaintenanceRepair.details.questions.linkUrl',
    title: 'myPortal.timeline.serviceMaintenanceRepair.details.questions.title',
    summary:
      'myPortal.timeline.serviceMaintenanceRepair.details.questions.summary',
    linkText:
      'myPortal.timeline.serviceMaintenanceRepair.details.questions.link',
  },
  [FAQKeys.VEHICLE_DOCUMENTS_PAGE]: {
    linkUrl: 'myPortal.vehicleDocuments.dashboard.overview.questions.linkUrl',
    title: 'myPortal.vehicleDocuments.dashboard.overview.questions.title',
    summary: 'myPortal.vehicleDocuments.dashboard.overview.questions.summary',
    linkText: 'myPortal.vehicleDocuments.dashboard.overview.questions.link',
  },
  [FAQKeys.INVOICE]: {
    linkUrl: 'myPortal.invoices.questions.linkUrl',
    title: 'myPortal.invoices.questions.title',
    summary: 'myPortal.invoices.questions.summary',
    linkText: 'myPortal.invoices.questions.link',
  },
  [FAQKeys.CONTRACT]: {
    linkUrl: 'myPortal.contract.contract.contract.questions.linkUrl',
    title: 'myPortal.contract.contract.contract.questions.title',
    summary: 'myPortal.contract.contract.contract.questions.summary',
    linkText: 'myPortal.contract.contract.contract.questions.link',
  },
  [FAQKeys.FAIR_WEAR_TEAR]: {
    linkUrl: 'myPortal.timeline.fairWearTear.questions.linkUrl',
    title: 'myPortal.timeline.fairWearTear.questions.title',
    summary: 'myPortal.timeline.fairWearTear.questions.summary',
    linkText: 'myPortal.timeline.fairWearTear.questions.link',
  },
  [FAQKeys.VEHICLE_COLLECTION_PAGE]: {
    linkUrl:
      'myPortal.dashboard.rescheduleCollection.modal.Details.Questions.linkURL',
    title:
      'myPortal.dashboard.rescheduleCollection.modal.Details.Questions.Title',
    summary:
      'myPortal.dashboard.rescheduleCollection.modal.Details.Questions.Summary',
    linkText:
      'myPortal.dashboard.rescheduleCollection.modal.Details.Questions.link',
  },
}

function getNested(dictionary: GenericObject, path: string) {
  try {
    return path.split('.').reduce((obj, property) => obj[property], dictionary)
  } catch (err) {
    return undefined
  }
}

const useFAQDetails = (faqKey: FAQKeys) => {
  const { data: dictionary } = useDictionary()

  const { title, summary, linkText } = FAQDetails[faqKey]

  const path = 'linkUrl'
  const linkUrl = getNested(dictionary, FAQDetails[`${faqKey}`][path])?.replace(
    '[API]',
    config.FAQ_API,
  )

  return { title, summary, linkText, linkUrl }
}

export default useFAQDetails
