import { eventDataToLowercase } from 'mlp-client/src/services/analytics/utils'

export const trackAnalyticsEvent = <T extends GenericObject>(event: T) => {
  const dataLayerName = 'dataLayer'
  if (typeof window === 'undefined') {
    return
  }

  if (
    !window[dataLayerName] ||
    typeof window[dataLayerName].push !== 'function'
  ) {
    throw new Error(`window.${dataLayerName} is not defined`)
  }

  const data = eventDataToLowercase(event)

  window[dataLayerName].push(data)

  return data
}
