import {
  ContractPictogram,
  FilesPictogram,
  MaintenancePictogram,
  RoadAssistancePictogram,
  TravelAbroadPictogram,
  NewTyresPictogram,
  MilagePictogram,
} from '@velocity/icons/pictogram'

import { DashboardServicesComponentTypes } from './enums'

import { config } from 'mlp-client/src/app-config'
import { TileType } from 'mlp-client/src/contracts/enums'
import Dashboard, {
  Config,
} from 'mlp-client/src/dashboard/components/configurable-dashboard/ConfigurableDashboard'
import { MaintenanceTile } from 'mlp-client/src/dashboard/components/tiles'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'

export const dashboardConfig: MyLeaseplanFeature<Config> = {
  enabled: true,
  view: Dashboard,
  configuration: {
    tileOptions: [
      {
        view: MaintenanceTile,
        title: 'myPortal.dashboard.default.allTiles.maintenanceRepair',
        icon: () => <MaintenancePictogram size="small" />,
        name: TileType.MAINTENANCE,
        componentType:
          DashboardServicesComponentTypes.SERVICES_TILE_MAINTENANCE_REPAIR_INSPECTION,
      },
      {
        name: TileType.TYRES,
        title: 'myPortal.dashboard.default.allTiles.tyres',
        to: config.TYRES_SERVICE_URL,
        target: '_blank',
        isRelativeLink: false,
        icon: () => <NewTyresPictogram size="small" />,
        componentType:
          DashboardServicesComponentTypes.SERVICES_TILE_TYRES_REPAIR_REPLACEMENT,
      },
      {
        title: 'myPortal.dashboard.default.allTiles.milageUpdates',
        icon: () => <MilagePictogram size="small" />,
        name: TileType.MILEAGE_UPDATE,
        to: 'mileage-update',
        isRelativeLink: true,
        componentType:
          DashboardServicesComponentTypes.SERVICES_TILE_MILEAGE_UPDATE,
      },
      {
        name: TileType.VEHICLE_DOCUMENTS,
        title: 'myPortal.dashboard.default.allTiles.documents',
        to: 'myPortal.documents.base',
        icon: () => <FilesPictogram size="small" />,
        componentType:
          DashboardServicesComponentTypes.SERVICES_TILE_VEHICLE_DOCUMENTS,
      },
      {
        title: 'myPortal.dashboard.default.allTiles.contractChanges',
        icon: () => <ContractPictogram size="small" />,
        name: TileType.CONTRACT_CHANGES,
        isRelativeLink: true,
        to: 'contract-changes',
        componentType:
          DashboardServicesComponentTypes.SERVICES_TILE_CHANGE_MY_CONTRACT,
      },
      {
        name: TileType.ROADSIDE_ASSISTANCE,
        title: 'myPortal.dashboard.default.allTiles.roadsideAssistance',
        to: 'roadside-assistance',
        isRelativeLink: true,
        icon: () => <RoadAssistancePictogram size="small" />,
        componentType:
          DashboardServicesComponentTypes.SERVICES_TILE_ROADSIDE_ASSISTANCE,
      },
      {
        name: TileType.TRAVEL_ABROAD,
        title: 'myPortal.dashboard.default.allTiles.travelAbroad',
        to: 'travel-abroad',
        isRelativeLink: true,
        icon: () => <TravelAbroadPictogram size="small" />,
        componentType:
          DashboardServicesComponentTypes.SERVICES_TILE_TRAVEL_ABROAD,
      },
    ],
  },
}
