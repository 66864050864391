import { DistanceUnit } from 'mlp-client/src/enums'
import { navigation } from 'mlp-client/src/features-configuration/default/navigation'
import { myleaseplanConfig as lp } from 'mlp-client/src/features-configuration/lp'
import {
  ApiType,
  CountryConfigList,
  MyleaseplanConfig,
  SharedMyLeaseplanConfig,
} from 'mlp-client/src/features-configuration/types'

const sharedMyLeaseplanConfig: SharedMyLeaseplanConfig = {
  enabled: true,
}

export const baseMyLeaseplanConfig: MyleaseplanConfig = {
  ...sharedMyLeaseplanConfig,
  navigation,
  apiType: ApiType.lpis,
  alternatePhoneDisplay: true,
  isInstallMobileAppEnabled: true,
  distanceUnit: DistanceUnit.KILOMETER,
  supplierAvailabilityTimeFrame: 3,

  // If the sub-feature is disabled, it will be enabled when showAllFeature flag exists.
  // if the sub-feature is not explicitly declared, it will not be enabled with showAllFeature.

  /**
   * MyLeasePlan sub-features
   */

  users: {
    enabled: true,
  },
}

export const baseConfig: CountryConfigList = {
  lp,
  gb: baseMyLeaseplanConfig,
  pt: baseMyLeaseplanConfig,
  nl: baseMyLeaseplanConfig,
}
