import { LicensePlate as VelocityLicensePlate } from '@velocity/ui'

import MaskedLicensePlate from 'mlp-client/src/utils/masks/MaskedLicensePlate'

interface Props {
  children: string
}

const VehicleLicense: React.FC<Props> = ({ children }) => {
  return (
    <MaskedLicensePlate data-e2e-id="licensePlate">
      <VelocityLicensePlate size="l">{children}</VelocityLicensePlate>
    </MaskedLicensePlate>
  )
}

export default VehicleLicense
