import { AUTH_ROUTES } from 'mlp-client/src/app-config'
import { useGetDictionaryQuery } from 'mlp-client/src/dictionary/dictionaryApi'
import { useLocale } from 'mlp-client/src/localization/LocaleProvider'
import { dictionary as fallbackDictionary } from 'mlp-client/static/dictionary'

const useDictionary = () => {
  const locale = useLocale()
  const pathname = document.location.pathname

  const { data, ...rest } = useGetDictionaryQuery(
    { locale },
    {
      skip: AUTH_ROUTES.includes(pathname),
    },
  )

  return {
    data: data ?? fallbackDictionary,
    ...rest,
  }
}

export default useDictionary
