import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { useAppSelector } from 'mlp-client/src/hooks'
import { AppState } from 'mlp-client/src/types'

export interface UserState {
  selectedAccountId?: string
}

export const initialState: UserState = {
  selectedAccountId: undefined,
}

const userSlice = createSlice({
  name: '@@user',
  initialState,
  reducers: {
    setSelectedAccountId: (state, action: PayloadAction<string>) => {
      state.selectedAccountId = action.payload
    },
    clearSelectedAccountId: state => {
      state.selectedAccountId = null
    },
  },
})

export const getSelectedAccountId = (state: AppState) =>
  state.user.selectedAccountId

export const { setSelectedAccountId, clearSelectedAccountId } =
  userSlice.actions

export default userSlice.reducer

export const useSelectedAccountId = () => {
  return useAppSelector(getSelectedAccountId)
}
