import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import {
  trackEndOfLeaseBannerEvent,
  trackEndOfLeaseCrossButtonEvent,
  trackEndOfLeaseLearnMoreEvent,
} from 'mlp-client/src/services/analytics/analytics'

const useEndOfLeaseActions = (titleId: string) => {
  const { translate } = useTranslations()

  const triggerShownAction = () =>
    trackEndOfLeaseBannerEvent({
      data: {
        component_title: titleId,
      },
    })

  const triggerClosedAction = () =>
    trackEndOfLeaseCrossButtonEvent({
      data: {
        component_title: titleId,
      },
    })

  const triggerLearnMoreClickAction = () =>
    trackEndOfLeaseLearnMoreEvent({
      data: {
        component_title: titleId,
        component_link_text: translate(
          'myPortal.dashboard.notifications.modal.link',
        ),
      },
    })

  return {
    triggerShownAction,
    triggerClosedAction,
    triggerLearnMoreClickAction,
  }
}

export default useEndOfLeaseActions
