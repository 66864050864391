import React from 'react'

import CellWrapper from './CellWrapper'
import { EmptyStateMobile } from './EmptyState'
import {
  CellStyled,
  HeaderCellStyled,
  MobileTableWrapperStyled,
  TableStyled,
} from './Table.styled'
import { TableProps } from './types'

const TableMobile: React.FC<TableProps> = ({
  columns,
  rows,
  data,
  emptyState,
  defaultValue,
}) => {
  if (!data?.length) {
    return (
      <EmptyStateMobile columnsAmount={columns.length}>
        {emptyState}
      </EmptyStateMobile>
    )
  }

  return (
    <>
      {data.map(item => (
        <MobileTableWrapperStyled key={item.key}>
          <TableStyled data-e2e-id="tableMobile">
            <tbody>
              {columns.map(({ header, key, cell: Cell }) =>
                rows && rows.TrComponent ? (
                  <rows.TrComponent
                    key={`${item.key}${key}`}
                    {...rows.props}
                    item={item}
                    columns={columns}
                    data-e2e-id={item.key}
                  >
                    <>
                      <HeaderCellStyled data-e2e-id={key}>
                        {header}
                      </HeaderCellStyled>
                      <CellStyled>
                        <CellWrapper
                          columnKey={key}
                          item={item}
                          cell={Cell}
                          rowsProps={rows && rows.props}
                          defaultValue={defaultValue}
                        />
                      </CellStyled>
                    </>
                  </rows.TrComponent>
                ) : (
                  <tr key={`${item.key}${key}`} data-e2e-id={item.key}>
                    <HeaderCellStyled data-e2e-id={key}>
                      {header}
                    </HeaderCellStyled>
                    <CellStyled>
                      <CellWrapper
                        columnKey={key}
                        item={item}
                        cell={Cell}
                        rowsProps={rows && rows.props}
                        defaultValue={defaultValue}
                      />
                    </CellStyled>
                  </tr>
                ),
              )}
            </tbody>
          </TableStyled>
        </MobileTableWrapperStyled>
      ))}
    </>
  )
}

export default TableMobile
