import { isBreakpointBelow, useBreakpoint } from '@velocity/styling'
import { LinkStandalone, Headline, Text } from '@velocity/ui'
import React, { useState } from 'react'

import { config } from 'mlp-client/src/app-config'
import {
  CloseIconStyled,
  ContentStyled,
  LogoContainerStyled,
  MiddleStyled,
  RootStyled,
  BannerLinkText,
  BannerButtonStyled,
} from 'mlp-client/src/components/instal-mobile-app/InstallMobileApp.styled'
import { useMyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'
import { trackAppStoreBannerEvent } from 'mlp-client/src/services/analytics/analytics'
import tenant from 'mlp-client/src/tenant/tenant'
import { isIOS } from 'mlp-client/src/utils/checks/checks'

const iconSrc =
  tenant === 'ayvens'
    ? '/static/images/app_icon_preview.png'
    : '/static/images/leaseplan/app_icon_preview.png'

const InstallMobileApp: React.FC = () => {
  const currentBreakpoint = useBreakpoint()

  const [isInstallPopupVisible, setIsInstallPopupVisible] = useState(
    isBreakpointBelow('LG', currentBreakpoint) &&
      window.sessionStorage.getItem('isInstallPopupVisible') !== 'false',
  )
  const { isInstallMobileAppEnabled } = useMyLeaseplanContext()

  const getInstallAppLink = (): string => {
    if (!isInstallMobileAppEnabled) {
      return
    }

    const iosInstallLink = config.IOS_INSTALL_APP_URL
    const androidInstallLink = config.ANDROID_INSTALL_APP_URL

    if (isIOS() && iosInstallLink) {
      return iosInstallLink
    }

    if (!isIOS() && androidInstallLink) {
      return androidInstallLink
    }
  }
  const installAppLink = getInstallAppLink()
  const { translate } = useTranslations()

  const hidePopup = () => {
    window.sessionStorage.setItem('isInstallPopupVisible', 'false')
    setIsInstallPopupVisible(false)

    trackAppStoreBannerEvent({
      data: {
        component_title: translate('myPortal.navigation.installApp.title'),
        component_link_text: translate(
          'myPortal.navigation.installApp.installButton',
        ),
        component_link_url: installAppLink,
      },
    })
  }

  if (!installAppLink || !isInstallPopupVisible) {
    return null
  }

  return (
    <RootStyled data-e2e-id="installMobileAppBanner">
      <ContentStyled>
        <LinkStandalone onClick={hidePopup}>
          <CloseIconStyled size="xs" />
        </LinkStandalone>
        <MiddleStyled>
          <LogoContainerStyled>
            <img alt="logo" src={iconSrc} height={48} width={48} />
          </LogoContainerStyled>
          <div>
            <Headline variant="100" component="p">
              <Translation id="myPortal.navigation.installApp.title" />
            </Headline>
            <Text variant="100" component="p">
              <Translation id="myPortal.navigation.installApp.subtitle" />
            </Text>
          </div>
        </MiddleStyled>
        <BannerButtonStyled variant="primary-filled" size="s">
          <LinkStandalone href={installAppLink} onClick={() => hidePopup()}>
            <BannerLinkText variant="100" bold={true} component="p">
              <Translation id="myPortal.navigation.installApp.installButton" />
            </BannerLinkText>
          </LinkStandalone>
        </BannerButtonStyled>
      </ContentStyled>
    </RootStyled>
  )
}

export default InstallMobileApp
