import { Accordion, Headline } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'

export interface Props {
  title: string
  dataE2eId: string
  children?: React.ReactNode
}

const SectionProperties: React.FC<Props> = ({ title, dataE2eId, children }) => {
  return (
    <Accordion initialExpanded={true} margin={{ bottom: '07' }}>
      <Accordion.Header data-e2e-id={title}>
        <Headline variant="200" data-e2e-id={dataE2eId} component="h1">
          <Translation id={title} />
        </Headline>
      </Accordion.Header>
      <Accordion.Content>{children}</Accordion.Content>
    </Accordion>
  )
}

export default SectionProperties
