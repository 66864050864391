import { Grid } from '@velocity/ui'
import React, { PropsWithChildren } from 'react'

import { CellStyled } from '../Table.styled'

interface Props extends PropsWithChildren {
  columnsAmount: number
}

const EmptyStateWide: React.FC<Props> = ({ columnsAmount, children }) => (
  <tbody data-e2e-id="tableBodyEmptyStateWide">
    <tr>
      <CellStyled colSpan={columnsAmount}>
        <Grid alignment="center">
          <Grid.Item>{children || null}</Grid.Item>
        </Grid>
      </CellStyled>
    </tr>
  </tbody>
)

export default EmptyStateWide
