import styled from 'styled-components'

export const TermsAndConditionsFooter = styled.footer`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  padding-top: var(--vel-fixed-spacing-07);
  padding-bottom: var(--vel-fixed-spacing-08);
  gap: var(--vel-fixed-spacing-07);
`
