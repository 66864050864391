import { Headline, DropdownList, DropdownListItem } from '@velocity/ui'
import styled from 'styled-components'

import VehicleLicense from 'mlp-client/src/components/vehicle-license/VehicleLicense'

export const Wrapper = styled.div<{
  selectable: boolean
  isOpen: boolean
}>`
  cursor: ${({ selectable }) => (selectable ? 'pointer' : 'auto')};
  width: 100%;
  background: ${({ isOpen }) =>
    isOpen ? 'var(--vel-color-neutral-white)' : 'auto'};
  box-shadow: ${({ isOpen }) =>
    isOpen ? 'var(--vel-elevation300-box_shadow)' : 'none'};
`

export const StyledDropdownList = styled(DropdownList)`
  padding: 0;
`

export const StyledDropdownListItem = styled(DropdownListItem)`
  border-top: 1px solid var(--vel-color-neutral-200);
`

export const SelectedItemWrapper = styled.div<{
  compact: boolean
}>`
  height: ${props => (props.compact ? '40px' : '80px')};
  display: flex;
  align-items: center;
  position: relative;
  z-index: 11;
  background-color: inherit;
  padding: var(--vel-fixed-spacing-03) var(--vel-fixed-spacing-04);
  margin-bottom: calc(-1 * var(--vel-fixed-spacing-05));
`

export const SelectedCarWrapper = styled.div<{
  flexWrap: boolean
  isMobile: boolean
}>`
  display: flex;
  flex-wrap: ${props => (props.flexWrap ? 'wrap' : 'nowrap')};
  margin-left: ${({ isMobile }) =>
    isMobile ? 'var(--vel-fixed-spacing-03)' : 'var(--vel-fixed-spacing-04)'};
  width: auto;
  min-width: 0;
  align-items: center;
  white-space: nowrap;
`

export const StyledSelectedVehicleModel = styled(Headline)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-right: var(--vel-fixed-spacing-03);
  font-weight: 700;
`

export const ChevronIconWrapper = styled.div<{ selectable?: boolean }>`
  margin-left: var(--vel-fixed-spacing-04);
  display: ${({ selectable }) => (selectable ? 'block' : 'none')};
`

export const StyledVehicleModel = styled(Headline)`
  margin-right: var(--vel-fixed-spacing-03);
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledLicensePlate = styled(VehicleLicense)`
  margin-left: var(--vel-fixed-spacing-04);
`
