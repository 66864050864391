import { NotificationBanner } from '@velocity/ui'
import React, { ReactNode } from 'react'

import { LinkStandaloneStyled } from '../components/EndOfLease.styled'

interface Props {
  title: string
  content: string | ReactNode
  link: ReactNode
  onHideNotification: () => void
}

const BaseNotification: React.FC<Props> = ({
  title,
  content,
  link,
  onHideNotification,
}) => (
  <>
    <NotificationBanner
      kind="information"
      title={title}
      onClose={onHideNotification}
      data-e2e-id="eolBanner"
    >
      {content}
      <LinkStandaloneStyled>{link}</LinkStandaloneStyled>
    </NotificationBanner>
  </>
)

export default BaseNotification
