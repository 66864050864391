import _merge from 'lodash/merge'

import {
  Country,
  CountryDefaults,
  CountrySpecific,
  Feature,
} from 'mlp-client/src/config/types'
import { defaultFormatAddress } from 'mlp-client/src/utils/formatters/formatters'

const defaults: CountryDefaults = {
  visible: true,
  minimalFooter: false,
  features: {
    myLeasePlan: {
      enabled: false,
      // Please don't add any default config for the sub-features.
      // Sub-features should be declared explicitly in the country config file.
    },
  },
  enableLanguageSelector: false,
  formatters: {
    address: defaultFormatAddress,
  },
}

const createConfig =
  (
    specificConfig: (intercom: readonly Feature[]) => CountrySpecific,
    defaultConfig: CountryDefaults = defaults,
  ) =>
  (intercom: readonly Feature[]): Country =>
    _merge({}, defaultConfig, specificConfig(intercom))

export default createConfig
