import { Grid } from '@velocity/ui'
import React, { useContext } from 'react'

import ContractChangeModal from 'mlp-client/src/components/contract-change-modal/ContractChangeModal'
import TechnicalError from 'mlp-client/src/components/error/TechnicalError'
import Loader from 'mlp-client/src/components/loader/Loader'
import UpdateMileageModal from 'mlp-client/src/components/mileage-update-modal/MileageUpdateModal'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import ContractDocuments from 'mlp-client/src/contracts/components/ContractDocuments/ContractDocuments'
import ContractPropertiesTable from 'mlp-client/src/contracts/components/ContractPropertiesTable/ContractPropertiesTable'
import ContractStatusBlock from 'mlp-client/src/contracts/components/ContractStatusBlock/ContractStatusBlock'
import SectionProperties from 'mlp-client/src/contracts/components/SectionProperties/SectionProperties'
import { getContractDetailsSectionData } from 'mlp-client/src/contracts/components/ViewContract/getContractDetailsSectionData'
import {
  ContractStatus,
  ViewContractSections,
  ViewContractTabs,
} from 'mlp-client/src/contracts/enums'
import useContractDetails from 'mlp-client/src/contracts/hooks/useContractDetails'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/types'
import { SectionPropertyList } from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { isServerError } from 'mlp-client/src/services/api/utils'
import { useIsUserClient } from 'mlp-client/src/user/hooks/useUser'

const ViewContract: React.FC = () => {
  const { data: contractDetails, error } = useContractDetails()
  const isUserClient = useIsUserClient()

  const {
    contracts: { configuration },
  } = useContext(MyLeaseplanContext)
  const shouldRenderDocuments = configuration.documents
  const shouldRenderContractStatusBlock = configuration.contractStatusBlock

  if (!configuration?.contractDetails) {
    return null
  }

  const { contractDetails: contractDetailsSections } = configuration

  if (isServerError(error)) {
    return <TechnicalError />
  }

  if (!contractDetails) {
    return <Loader />
  }

  return (
    <>
      <Grid columns={12} data-e2e-id="contractInfo">
        <Grid.Item
          XS={12}
          LG={shouldRenderDocuments || shouldRenderContractStatusBlock ? 8 : 12}
          key={ViewContractTabs.CONTRACT}
        >
          {Object.entries(contractDetailsSections).map(
            ([sectionKey, sectionConfig]: [
              ViewContractSections,
              SectionPropertyList,
            ]) => {
              const { properties, title } = getContractDetailsSectionData(
                contractDetails,
                sectionConfig,
                sectionKey,
                isUserClient,
              )

              if (!properties?.length) {
                return null
              }

              return (
                <SectionProperties
                  key={sectionKey}
                  dataE2eId={sectionKey}
                  title={title}
                >
                  <ContractPropertiesTable
                    titleTranslationId={title}
                    properties={properties}
                    isContractSection={sectionKey === 'contractDetails'}
                    isContractActive={
                      contractDetails.status === ContractStatus.ACTIVE
                    }
                  />
                </SectionProperties>
              )
            },
          )}
        </Grid.Item>
        {(shouldRenderDocuments || shouldRenderContractStatusBlock) && (
          <Grid.Item XS={12} LG={4}>
            {shouldRenderContractStatusBlock && <ContractStatusBlock />}
            {shouldRenderDocuments && <ContractDocuments />}
            <FAQ faqKey={FAQKeys.CONTRACT} />
          </Grid.Item>
        )}
      </Grid>
      <UpdateMileageModal />
      <ContractChangeModal />
    </>
  )
}

export default ViewContract
