import { trackAnalyticsEvent } from 'mlp-client/src/services/analytics/trackAnalyticsEvent'
import type {
  ShownEvent,
  ClickEvent,
  LinkEvent,
  DownloadEvent,
  AppStoreBannerPayload,
  AssessmentDetailsPayload,
  BookingButtonPayload,
  ContractChangeButtonPayload,
  ContractChangeOpenPayload,
  ContractChangeRequestPayload,
  DocumentDownloadsPayload,
  EndOfLeaseBannerPayload,
  EndOfLeaseContactPayload,
  EndOfLeaseCrossButtonPayload,
  EndOfLeaseLearnMorePayload,
  FooterNavigationPayload,
  InvoiceDownloadPayload,
  LoginPayload,
  MileageUpdateOpenPayload,
  MileageUpdateRequestPayload,
  MileageUpdateSubmitPayload,
  NavigationMobilePayload,
  NavigationPayload,
  PageViewPayload,
  RoadsideAssistanceCallPayload,
  RoadsideAssistanceOpenPayload,
  ServicesTileClickPayload,
  ServicesTileShownPayload,
  SupportLinkPayload,
  TravelAbroadMoreInformationPayload,
  TravelAbroadOpenPayload,
  TravelAbroadRequestDocumentsPayload,
  VehicleCollectionChangeContactPayload,
  VehicleCollectionChangePayload,
  VehicleSelectionPayload,
} from 'mlp-client/src/services/analytics/types'
import { getCIDFromCookie } from 'mlp-client/src/services/analytics/utils'

// Page view
// TODO: Could be an outdated event, need to check with data analyst

export const trackPageViewEvent = (payload: PageViewPayload) => {
  const { pageTitle, translatedPageTitle, language, country, userType } =
    payload

  const meta = {
    userType,
    pageURL: window.location.href,
    referrer: document.referrer,
    itemName: pageTitle,
    valueStream: 'myleaseplan',
    pageTitle: 'leaseplan',
    pageId: `${language}-${country} | myleaseplan | ${pageTitle}`,
    pathName: window.location.pathname,
    cid: getCIDFromCookie(),
    hostname: window.location.origin,
    redirect: 0,
    displayName: translatedPageTitle || '',
    language,
    country,
  }

  return trackAnalyticsEvent({
    event: 'pageView',
    meta,
  })
}

// Login

export const trackLoginEvent = (payload: LoginPayload) =>
  trackAnalyticsEvent({
    event: 'login',
    component: 'login',
    ...payload,
  })

// Internal navigation

export const trackInternalNavigationEvent = (
  payload: NavigationPayload,
): LinkEvent =>
  trackAnalyticsEvent({
    event: 'internal_navigation',
    component: 'main_menu',
    component_event_type: 'click',
    data: [
      {
        component_title: 'main menu',
        component_link_type: 'text',
        ...payload.data,
      },
    ],
  })

export const trackInternalNavigationMobileEvent = (
  payload: NavigationMobilePayload,
): ClickEvent =>
  trackAnalyticsEvent({
    event: 'internal_navigation',
    component: 'main_menu',
    component_event_type: 'click',
    data: [
      {
        component_title: 'main menu',
        component_link_type: 'icon',
        component_link_text: 'menu',
        ...payload.data,
      },
    ],
  })

// Support button

export const trackSupportButtonEvent = (
  payload: SupportLinkPayload,
): LinkEvent =>
  trackAnalyticsEvent({
    event: 'support_open',
    component: 'support',
    component_event_type: 'click',
    data: [
      {
        component_type: 'support_top_link',
        component_title: 'main_menu',
        component_link_type: 'text',
        ...payload.data,
      },
    ],
  })

// Vehicle Selection
// TODO: Could be an outdated event, need to check with data analyst

export const trackVehicleSelectionEvent = (payload: VehicleSelectionPayload) =>
  trackAnalyticsEvent({
    event: 'genericEvent',
    eventCategory: 'internal navigation',
    eventAction: 'choose vehicle from dropdown',
    ...payload,
  })

// Dashboard

export const trackServicesTileShownEvent = (
  payload: ServicesTileShownPayload,
): ShownEvent =>
  trackAnalyticsEvent({
    event: 'internal_navigation',
    component: 'services_tile',
    component_event_type: 'shown',
    data: payload.data,
  })

export const trackServicesTileClickEvent = (
  payload: ServicesTileClickPayload,
): ClickEvent =>
  trackAnalyticsEvent({
    event: 'internal_navigation',
    component: 'services_tile',
    component_event_type: 'click',
    data: [
      {
        component_title: 'services',
        component_link_type: 'tile',
        ...payload.data,
      },
    ],
  })

// Footer

export const trackFooterNavigationEvent = (payload: FooterNavigationPayload) =>
  trackAnalyticsEvent({
    event: 'internal_navigation',
    component: 'footer_menu',
    component_event_type: 'click',
    data: [
      {
        component_type: 'footer_link',
        component_link_type: 'text',
        ...payload.data,
      },
    ],
  })

// App store banner

export const trackAppStoreBannerEvent = (
  payload: AppStoreBannerPayload,
): LinkEvent =>
  trackAnalyticsEvent({
    event: 'appstore',
    component: 'appstore',
    component_event_type: 'click',
    data: [
      {
        component_type: 'app_store_click',
        component_link_type: 'text',
        ...payload.data,
      },
    ],
  })

// End of lease

export const trackEndOfLeaseBannerEvent = (
  payload: EndOfLeaseBannerPayload,
): ShownEvent =>
  trackAnalyticsEvent({
    event: 'end_of_lease',
    component: 'end_of_lease_banner',
    component_event_type: 'shown',
    data: [
      {
        component_type: 'end_of_lease_banner_view',
        ...payload.data,
      },
    ],
  })

export const trackEndOfLeaseCrossButtonEvent = (
  payload: EndOfLeaseCrossButtonPayload,
): ClickEvent =>
  trackAnalyticsEvent({
    event: 'end_of_lease',
    component: 'end_of_lease_banner',
    component_event_type: 'click',
    data: [
      {
        component_type: 'end_of_lease_banner_close',
        component_link_type: 'icon',
        component_link_text: '',
        ...payload.data,
      },
    ],
  })

export const trackEndOfLeaseLearnMoreEvent = (
  payload: EndOfLeaseLearnMorePayload,
): ClickEvent =>
  trackAnalyticsEvent({
    event: 'end_of_lease',
    component: 'end_of_lease_banner',
    component_event_type: 'click',
    data: [
      {
        component_type: 'end_of_lease_banner_click',
        component_link_type: 'button',
        ...payload.data,
      },
    ],
  })

export const trackEndOfLeaseContactEvent = (
  payload: EndOfLeaseContactPayload,
): ClickEvent =>
  trackAnalyticsEvent({
    event: 'end_of_lease',
    component: 'end_of_lease_banner',
    component_event_type: 'click',
    data: [
      {
        component_type: 'end_of_lease_banner_contact',
        component_link_type: 'button',
        ...payload.data,
      },
    ],
  })

// Contract change

export const trackContractChangeRequestEvent = (
  payload: ContractChangeRequestPayload,
): ClickEvent =>
  trackAnalyticsEvent({
    event: 'contract_changes',
    component: 'car_and_contract',
    component_event_type: 'click',
    data: [
      {
        component_type: 'car_and_contract_contract_changes',
        component_link_type: 'button',
        ...payload.data,
      },
    ],
  })

// Contract change modal

export const trackContractChangeOpenEvent = (
  payload: ContractChangeOpenPayload,
): ShownEvent =>
  trackAnalyticsEvent({
    event: 'contract_changes',
    component: 'contract_changes_popup',
    component_event_type: 'shown',
    data: [
      {
        component_type: 'contract_changes',
        ...payload.data,
      },
    ],
  })

export const trackContractChangeContactEvent = (
  payload: ContractChangeButtonPayload,
): LinkEvent =>
  trackAnalyticsEvent({
    event: 'contract_changes',
    component: 'contract_changes_card',
    component_event_type: 'click',
    data: [
      {
        component_type: 'contract_changes_contact',
        component_link_type: 'button',
        ...payload.data,
      },
    ],
  })

// Mileage update

export const trackMileageUpdateRequestEvent = (
  payload: MileageUpdateRequestPayload,
): ClickEvent =>
  trackAnalyticsEvent({
    event: 'mileage_updates',
    component: 'mileage_updates_card',
    component_event_type: 'click',
    data: [
      {
        component_type: 'mileage_update_update',
        component_link_type: 'text',
        ...payload.data,
      },
    ],
  })

// Mileage update modal

export const trackMileageUpdateOpenEvent = (
  payload: MileageUpdateOpenPayload,
): ShownEvent =>
  trackAnalyticsEvent({
    event: 'mileage_updates',
    component: 'mileage_updates_popup',
    component_event_type: 'shown',
    data: [
      {
        component_type: 'mileage_update_open',
        ...payload.data,
      },
    ],
  })

export const trackMileageUpdateSubmittedEvent = (
  payload: MileageUpdateSubmitPayload,
): ClickEvent =>
  trackAnalyticsEvent({
    event: 'mileage_updates',
    component: 'mileage_updates_popup',
    component_event_type: 'click',
    data: [
      {
        component_type: 'mileage_update_submit',
        component_link_type: 'button',
        ...payload.data,
      },
    ],
  })

// Roadside assistance modal

export const trackRoadsideAssistanceOpenEvent = (
  payload: RoadsideAssistanceOpenPayload,
): ShownEvent =>
  trackAnalyticsEvent({
    event: 'roadside_assistance',
    component: 'roadside_assistance_popup',
    component_event_type: 'shown',
    data: [
      {
        component_type: 'roadside_assistance_open',
        ...payload.data,
      },
    ],
  })

export const trackRoadsideAssistanceCallEvent = (
  payload: RoadsideAssistanceCallPayload,
): LinkEvent =>
  trackAnalyticsEvent({
    event: 'roadside_assistance',
    component: 'roadside_assistance_popup',
    component_event_type: 'click',
    data: [
      {
        component_type: 'roadside_assistance_call',
        component_link_type: 'button',
        ...payload.data,
      },
    ],
  })

// Travel abroad

export const trackTravelAbroadOpenEvent = (
  payload: TravelAbroadOpenPayload,
): ShownEvent =>
  trackAnalyticsEvent({
    event: 'travel_abroad',
    component: 'travel_abroad_popup',
    component_event_type: 'shown',
    data: [
      {
        component_type: 'travel_abroad_open',
        ...payload.data,
      },
    ],
  })

export const trackTravelAbroadRequestDocumentsEvent = (
  payload: TravelAbroadRequestDocumentsPayload,
): LinkEvent =>
  trackAnalyticsEvent({
    event: 'travel_abroad',
    component: 'travel_abroad_popup',
    component_event_type: 'click',
    data: [
      {
        component_type: 'travel_abroad_request_documents',
        component_link_type: 'button',
        ...payload.data,
      },
    ],
  })

export const trackTravelAbroadMoreInformationEvent = (
  payload: TravelAbroadMoreInformationPayload,
): LinkEvent =>
  trackAnalyticsEvent({
    event: 'travel_abroad',
    component: 'travel_abroad_popup',
    component_event_type: 'click',
    data: [
      {
        component_type: 'travel_abroad_more_information',
        component_link_type: 'button',
        ...payload.data,
      },
    ],
  })

export const trackTravelAbroadCardRequestDocumentsEvent = (
  payload: TravelAbroadRequestDocumentsPayload,
): LinkEvent =>
  trackAnalyticsEvent({
    event: 'travel_abroad',
    component: 'travel_abroad_card',
    component_event_type: 'click',
    data: [
      {
        component_type: 'travel_abroad_request_documents',
        component_link_type: 'button',
        ...payload.data,
      },
    ],
  })

export const trackTravelAbroadCardMoreInformationEvent = (
  payload: TravelAbroadMoreInformationPayload,
): LinkEvent =>
  trackAnalyticsEvent({
    event: 'travel_abroad',
    component: 'travel_abroad_card',
    component_event_type: 'click',
    data: [
      {
        component_type: 'travel_abroad_more_information',
        component_link_type: 'button',
        ...payload.data,
      },
    ],
  })

// Assessment details

export const trackAssessmentDetailsDownloadReportEvent = (
  payload: AssessmentDetailsPayload,
): DownloadEvent =>
  trackAnalyticsEvent({
    event: 'ewt_report',
    component: 'download',
    component_event_type: 'click',
    data: [
      {
        component_type: 'fwt_download_report',
        component_link_type: 'button',
        component_document_name: 'fwt_report',
        component_document_type: 'report',
        ...payload.data,
      },
    ],
  })

export const trackAssessmentDetailsSendReportEvent = (
  payload: AssessmentDetailsPayload,
): ClickEvent =>
  trackAnalyticsEvent({
    event: 'ewt_report',
    component: 'send_report_back',
    component_event_type: 'click',
    data: [
      {
        component_type: 'timeline_fwt_report',
        component_link_type: 'button',
        ...payload.data,
      },
    ],
  })

// Bookings

export const trackChangeBookingButtonEvent = (
  payload: BookingButtonPayload,
): ClickEvent =>
  trackAnalyticsEvent({
    event: 'change_booking',
    component: 'timeline',
    component_event_type: 'click',
    data: [
      {
        component_link_type: 'button',
        ...payload.data,
      },
    ],
  })

export const trackCancelBookingButtonEvent = (
  payload: BookingButtonPayload,
): ClickEvent =>
  trackAnalyticsEvent({
    event: 'cancel_booking',
    component: 'timeline',
    component_event_type: 'click',
    data: [
      {
        component_link_type: 'button',
        ...payload.data,
      },
    ],
  })

export const trackVehicleCollectionChangeEvent = (
  payload: VehicleCollectionChangePayload,
): ClickEvent =>
  trackAnalyticsEvent({
    event: 'vehicle_collection',
    component: 'change_booking',
    component_event_type: 'click',
    data: [
      {
        component_type: 'timeline_vehicle_collection',
        component_link_type: 'button',
        ...payload.data,
      },
    ],
  })

export const trackVehicleCollectionChangeContactEvent = (
  payload: VehicleCollectionChangeContactPayload,
): ClickEvent =>
  trackAnalyticsEvent({
    event: 'vehicle_collection',
    component_event_type: 'click',
    ...payload,
    data: [
      {
        component_type: 'timeline_vehicle_collection',
        component_link_type: 'button',
        ...payload.data,
      },
    ],
  })

// Documents

export const trackDocumentDownloadsEvent = (
  payload: DocumentDownloadsPayload,
): DownloadEvent =>
  trackAnalyticsEvent({
    event: 'download',
    component_event_type: 'click',
    ...payload,
    data: [
      {
        component_link_type: 'text',
        ...payload.data,
      },
    ],
  })

// Invoices

export const trackInvoiceDownloadsEvent = (
  payload: InvoiceDownloadPayload,
): DownloadEvent =>
  trackAnalyticsEvent({
    event: 'download',
    component: 'invoices',
    component_event_type: 'click',
    data: [
      {
        component_link_type: 'text',
        ...payload.data,
      },
    ],
  })
