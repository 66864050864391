import { DistanceUnit } from 'mlp-client/src/enums'
import { navigation } from 'mlp-client/src/features-configuration/default/navigation'
import { MyleaseplanConfig } from 'mlp-client/src/features-configuration/types'

/**
 * This is fallback config
 * In most cases we need it to show NotFound page for routes without the country code
 */
export const myleaseplanConfig: MyleaseplanConfig = {
  enabled: true,
  distanceUnit: DistanceUnit.KILOMETER,
  navigation,
  isInstallMobileAppEnabled: true,
}
