import { Tabs } from '@velocity/ui'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, generatePath } from 'react-router-dom'

import ViewContract from 'mlp-client/src/contracts/components/ViewContract/ViewContract'
import { useCurrentContract } from 'mlp-client/src/contracts/hooks/useContracts'
import DashboardContent from 'mlp-client/src/dashboard/components/dashboard-content/DashboardContent'
import { DashboardContentProps } from 'mlp-client/src/dashboard/types'
import {
  useLocalizedRouteMatch,
  useCurrentPath,
} from 'mlp-client/src/localization/RoutesProvider'
import Translation from 'mlp-client/src/localization/Translation'

interface Tab {
  id: string
  path: string
}

const DASHBOARD_TABS: Tab[] = [
  {
    id: 'myPortal.dashboard.serviceAndQuickActions',
    path: 'myPortal.dashboard',
  },
  {
    id: 'myPortal.subNavigation.carContract',
    path: 'myPortal.contract.detail',
  },
]

const DashboardTabs: React.FC<DashboardContentProps> = props => {
  const contract = useCurrentContract()
  const navigate = useNavigate()
  const params = useParams()
  const currentPath = useCurrentPath()
  const getLocalizedRouteMatch = useLocalizedRouteMatch()

  const [selected, setSelected] = useState(null)

  useEffect(() => {
    setSelected(
      DASHBOARD_TABS.find(
        tab => currentPath === getLocalizedRouteMatch(tab.path),
      )?.id,
    )
  }, [currentPath])

  const onChange = (value: string) => {
    const { path } = DASHBOARD_TABS.find(tab => tab.id === value)
    setSelected(value)
    navigate(generatePath(getLocalizedRouteMatch(path), params))
  }

  const tabIds = DASHBOARD_TABS.filter(
    tab => contract || tab.id !== 'myPortal.subNavigation.carContract',
  ).map(tab => tab.id)

  return (
    <>
      {selected ? (
        <Tabs value={selected} onChange={onChange} data-e2e-id="dashboardTabs">
          <Tabs.List aria-label="Dashboard tabs">
            {tabIds.map(tabId => (
              <Tabs.Tab
                value={tabId}
                key={tabId}
                style={{
                  maxWidth: '400px',
                }}
                data-e2e-id={tabId}
              >
                <Translation id={tabId} />
              </Tabs.Tab>
            ))}
          </Tabs.List>
          <Tabs.TabPanel value={tabIds[0]}>
            <DashboardContent {...props} />
          </Tabs.TabPanel>
          {contract && (
            <Tabs.TabPanel value={tabIds[1]}>
              <div style={{ paddingTop: 'var(--vel-fixed-spacing-07)' }}>
                <ViewContract />
              </div>
            </Tabs.TabPanel>
          )}
        </Tabs>
      ) : null}
    </>
  )
}

export default DashboardTabs
