import { Flag, FlagCode } from '@velocity/icons/flag'
import React from 'react'
import styled from 'styled-components'

import { Neutral700Text } from 'mlp-client/src/components/styled/TextStyle'
import {
  useCountry,
  useLanguage,
} from 'mlp-client/src/localization/LocaleProvider'

const StyledLanguageWrapper = styled.div`
  display: flex;
  text-transform: uppercase;
  align-items: center;
  gap: var(--vel-fixed-spacing-02);
`

const CurrentLanguage: React.FC = () => {
  const country = useCountry()
  const language = useLanguage()

  return (
    <StyledLanguageWrapper data-e2e-id="currentLanguage">
      <Flag size="m" code={country as unknown as FlagCode} />
      <Neutral700Text bold={true}>{language}</Neutral700Text>
    </StyledLanguageWrapper>
  )
}

export default CurrentLanguage
