import createConfig from './defaults'

import { CountrySpecific } from 'mlp-client/src/config/types'
import {
  CountryCode,
  LanguageCode,
  Locales,
} from 'mlp-client/src/localization/enums'

// The LP country is a fictional country added for global content.
export default createConfig(
  (): CountrySpecific => ({
    label: 'International',
    code: CountryCode.lp,
    tld: '.lp',
    visible: false,
    languages: [
      {
        label: 'English',
        code: LanguageCode.en,
        locale: Locales.EN_LP,
        preferred: true,
      },
    ],
    footerLinks: [],
  }),
)
