import moment from 'moment'
import React, { PropsWithChildren, useContext } from 'react'

import { config } from 'mlp-client/src/app-config'
import countryConfigs from 'mlp-client/src/config'
import {
  CountryCode,
  LanguageCode,
  Locales,
} from 'mlp-client/src/localization/enums'
import { isKnownLocale } from 'mlp-client/src/localization/utils'
import { ExistingLocales } from 'mlp-client/src/translations'

interface LocaleContextProps {
  locale: ExistingLocales
}

interface Props extends PropsWithChildren {
  locale?: ExistingLocales
}

let localeContext: LocaleContextProps = {
  locale: config.DEFAULT_LOCALE,
}

const LocaleContext = React.createContext<LocaleContextProps>(localeContext)

const getCountryConfigFromLocale = (locale: ExistingLocales) =>
  countryConfigs.find(country =>
    country.languages.some(language => locale === language.locale),
  )

export const useLocale = () => useContext(LocaleContext).locale
const useCountryConfig = () =>
  getCountryConfigFromLocale(useContext(LocaleContext).locale)
export const useIsCountryVisible = () => useCountryConfig().visible
export const useFooterLinksList = () => useCountryConfig().footerLinks
export const useAddressFormatter = () => useCountryConfig().formatters.address

const getLanguageFromContext = (context: LocaleContextProps) =>
  context.locale.split('-')[0]?.toLowerCase() as LanguageCode
const getCountryFromContext = (context: LocaleContextProps) =>
  context.locale.split('-')[1]?.toLowerCase() as CountryCode

export const useCountry = () => getCountryFromContext(useContext(LocaleContext))
export const useLanguage = () =>
  getLanguageFromContext(useContext(LocaleContext))

// Only used in prepareHeaders
export const getLocale = () => localeContext.locale
export const getCountry = () => getCountryFromContext(localeContext)
export const getLanguage = () => getLanguageFromContext(localeContext)

const LocaleProvider: React.FC<Props> = ({ locale: propsLocale, children }) => {
  const locationLocale = window.location.pathname.split('/')[1]?.toLowerCase()

  const locale =
    propsLocale ||
    (isKnownLocale(locationLocale) && (locationLocale as ExistingLocales)) ||
    localeContext.locale ||
    Locales.EN_LP

  const countryConfig = getCountryConfigFromLocale(locale)

  const { timezone } = countryConfig

  if (timezone && moment.tz) {
    moment.tz.setDefault(timezone)
  }

  moment.locale(locale)
  localeContext = { locale }

  return (
    <LocaleContext.Provider value={localeContext}>
      {children}
    </LocaleContext.Provider>
  )
}

export default LocaleProvider
