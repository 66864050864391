export enum ViewContractSections {
  VEHICLE_DETAILS = 'vehicleDetails',
  CONTRACT = 'contractDetails',
  TYRES = 'tyres',
  BROKER = 'broker',
  DEALER = 'dealer',
  VEHICLE = 'vehicle',
  EXTRA_OPTIONS_AND_ACCESSORIES = 'extraOptionsAndAccessories',
  CUSTOMER = 'customer',
  DRIVER = 'driver',
  INCLUDED_SERVICES = 'includedServices',
  SERVICES = 'Services',
}

export enum VehicleSectionProperties {
  LICENSE_PLATE = 'LICENSE_PLATE',
  MAKE = 'MAKE',
  MODEL = 'MODEL',
  COLOR = 'COLOR',
  VIN = 'VIN',
  FUEL = 'FUEL',
  TYPE = 'TYPE',
  POWER = 'POWER',
  CO2 = 'CO2',
  TRANSMISSION = 'TRANSMISSION',
  ESTIMATED_FUEL_KM = 'ESTIMATED_FUEL_KM',
  MODEL_YEAR = 'MODEL_YEAR',
  CO2_EMISSION = 'CO2_EMISSION',
  ENERGY_TYPE = 'ENERGY_TYPE',
  DOORS = 'DOORS',
  SEATS = 'SEATS',
  TAX_VALUE = 'TAX_VALUE',
  P11D_PRICE = 'P11D_PRICE',
  ADDITION = 'ADDITION',
  CONTRIBUTION = 'CONTRIBUTION',
  MOT_VALID_UNTIL = 'MOT_VALID_UNTIL',
  MODEL_TYPE = 'MODEL_TYPE',
  BODY_TYPE = 'BODY_TYPE',
  VEHICLE_TYPE = 'VEHICLE_TYPE',
  CHASSIS_NUMBER = 'CHASSIS_NUMBER',
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  NUMBER_OF_DOORS = 'NUMBER_OF_DOORS',
  FUEL_TANK_CAPACITY = 'FUEL_TANK_CAPACITY',
  BATTERY_CAPACITY = 'BATTERY_CAPACITY',
  EURO_EMISSION_STANDARD = 'EURO_EMISSION_STANDARD',
  MOT_EXPIRY_DATE = 'MOT_EXPIRY_DATE',
  REGISTRATION_DATE = 'REGISTRATION_DATE',
}

export enum ContractSectionProperties {
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  TAXATION_VALUE = 'TAXATION_VALUE',
  ENVIRONMENTAL_TAXATION_VALUE = 'ENVIRONMENTAL_TAXATION_VALUE',
  MAX_MILEAGE = 'MAX_MILEAGE',
  LAST_KNOWN_MILEAGE = 'LAST_KNOWN_MILEAGE',
  LAST_KNOWN_FUEL_MILEAGE = 'LAST_KNOWN_FUEL_MILEAGE',
  DATE_LAST_KNOWN_MILEAGE = 'DATE_LAST_KNOWN_MILEAGE',
  TYPE = 'TYPE',
  NUMBER = 'NUMBER',
  DISPLAY_NUMBER = 'DISPLAY_NUMBER',
  OBJECT_ID = 'OBJECT_ID',
  DURATION = 'DURATION',
  ANNUAL_MILEAGE = 'ANNUAL_MILEAGE',
  INITIAL_MILEAGE = 'INITIAL_MILEAGE',
  PRICE_EXCL_VAT = 'PRICE_EXCL_VAT',
  PRICE_INCL_VAT = 'PRICE_INCL_VAT',
  ID = 'ID',
  PRODUCT = 'PRODUCT',
  INDIVIDUAL_LEASE_AGREEMENT_NUMBER = 'INDIVIDUAL_LEASE_AGREEMENT_NUMBER',
  STATUS = 'STATUS',
  PRICE = 'PRICE',
  BROKER = 'BROKER',
}

export enum ContractVehicleType {
  CAR = 'Car',
  LIGHT_COMMERCIAL_VEHICLE = 'LightCommercialVehicle',
  MEDIUM_COMMERCIAL_VEHICLE = 'MediumCommercialVehicle',
  HEAVY_COMMERCIAL_VEHICLE = 'HeavyCommercialVehicle',
  BICYCLE = 'Bicycle',
  BUS = 'Bus',
  MOTORBIKE = 'Motorbike',
  SCOOTER = 'Scooter',
  TRACTOR = 'Tractor',
  TRAILER = 'Trailer',
  EQUIPMENT = 'Equipment',
  CAMPER = 'Camper',
  UNKNOWN = 'Unknown',
}

export enum ContractVehicleEnergyType {
  PETROL = 'Petrol',
  PETROL_HYBRID = 'PetrolHybrid',
  PETROL_PLUG_IN_HYBRID = 'PetrolPlugInHybrid',
  PETROL_MILD_HYBRID = 'PetrolMildHybrid',
  DIESEL = 'Diesel',
  DIESEL_HYBRID = 'DieselHybrid',
  DIESEL_PLUG_IN_HYBRID = 'DieselPlugInHybrid',
  DIESEL_MILD_HYBRID = 'DieselMildHybrid',
  LPG = 'LPG',
  NATURAL_GAS = 'NaturalGas',
  FULL_ELECTRIC = 'FullElectric',
  HYDROGEN = 'Hydrogen',
  ETHANOL = 'Ethanol',
  UNKNOWN = 'Unknown',
}

export enum ContractVehicleTransmissionType {
  MANUAL = 'Manual',
  AUTOMATIC = 'Automatic',
  OTHER = 'Other',
}

export enum ServicesSectionProperties {
  MAINTENANCE = 'MAINTENANCE',
  INSURANCE = 'INSURANCE',
  ROAD_TAX = 'ROAD_TAX',
  ROADSIDE_ASSISTANCE = 'ROADSIDE_ASSISTANCE',
  ROADSIDE_ASSISTANCE_LEVEL = 'ROADSIDE_ASSISTANCE_LEVEL',
  REPLACEMENT_VEHICLE_WAITING_PERIOD = 'REPLACEMENT_VEHICLE_WAITING_PERIOD',
  REPLACEMENT_VEHICLE_ADDITIONAL_COVER_TYPE = 'REPLACEMENT_VEHICLE_ADDITIONAL_COVER_TYPE',
  REPLACEMENT_VEHICLE = 'REPLACEMENT_VEHICLE',
  REPLACEMENT_VEHICLE_COVER_TYPE = 'REPLACEMENT_VEHICLE_COVER_TYPE',
  MANAGEMENT_SERVICE_FEE = 'MANAGEMENT_SERVICE_FEE',
}

export enum ContractOptionsListProperties {
  MAINTENANCE = 'maintenance',
  ROADSIDE_ASSISTANCE = 'roadsideAssistance',
  TYRES = 'tyres',
  RADIO_LICENCE_FEE = 'radioLicenceFee',
}

export enum ContractOptionsListMaintenanceProperties {
  MAINTENANCE_MODULE = 'maintenanceModule',
  REPLACEMENT_VEHICLE_MODULE = 'replacementVehicleModule',
  CAR_WASH_MODULE = 'carWashModule',
}

export enum ContractOptionsListRoadsideAssistanceProperties {
  EUROPEAN_COVERAGE_MODULE = 'europeanCoverageModule',
}

export enum ExtraOptionsAndAccessoriesSectionProperties {
  APPLE_CARPLAY_INTEGRATION = 'appleCarplayIntegration',
  WIRELESS_CHARGING = 'wirelessCharging',
  TRACTION_CONTROL = 'tractionControl',
  SPORT_FRONT_SEATS = 'sportFrontSeats',
  ON_WHEEL_AUDIO_CONTROLS = 'onWheelAudioControls',
}

export enum CustomerSectionProperties {
  CUSTOMER_NUMBER = 'CUSTOMER_NUMBER',
  COMPANY_NAME = 'COMPANY_NAME',
  VAT_NUMBER = 'VAT_NUMBER',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  CUSTOMER_VAT_NUMBER = 'CUSTOMER_VAT_NUMBER',
  DRIVER = 'DRIVER',
}

export enum DriverSectionProperties {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
}

export enum ViewContractTabs {
  CONTRACT = 'contractDetails',
  VEHICLE = 'carDetails',
}

export enum ServiceType {
  MAINTENANCE = 'Maintenance',
  TYRES = 'Tyres',
  ROADSIDE_ASSISTANCE = 'RoadsideAssistance',
  VEHICLE_REPLACEMENT = 'VehicleReplacement',
  RECURRING_ROAD_TAX = 'RecurringRoadTax',
  FINE_PROCESSING = 'FineProcessing',
}

export enum TileType {
  MAINTENANCE = 'Maintenance',
  TYRES = 'Tyres',
  ROADSIDE_ASSISTANCE = 'RoadsideAssistance',
  VEHICLE_DOCUMENTS = 'VehicleDocuments',
  TRAVEL_ABROAD = 'TravelAbroad',
  CONTRACT_CHANGES = 'ContractChanges',
  MILEAGE_UPDATE = 'MileageUpdate',
}

export enum ContractStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  PENDING_SALE = 'PendingSale',
}
