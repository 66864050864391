import {
  MyLeaseplanFeature,
  VehicleCollectionConfiguration,
} from 'mlp-client/src/features-configuration/types'

export const vehicleCollectionChangeModalFeature: MyLeaseplanFeature<VehicleCollectionConfiguration> =
  {
    enabled: true,
    configuration: {
      dialogModal: {
        title: 'myPortal.dashboard.rescheduleCollection.modal.title',
        buttonFirst: 'myPortal.dashboard.contractChanges.modal.button.email',
        buttonSecond: 'myPortal.dashboard.contractChanges.modal.button.call',
        summaryBeforeBrokerInfo:
          'myPortal.dashboard.rescheduleCollection.modal.summary',
        showBrokerInfo: true,
      },
    },
  }
