import React from 'react'

import ContractServices from 'mlp-client/src/contracts/components/ContractServices/ContractServices'
import Translation from 'mlp-client/src/localization/Translation'
import BaseTimelineCard, {
  TimelineItemProps,
} from 'mlp-client/src/timeline/components/TimelineCard/BaseTimelineCard'
import { TimelineEventTypes } from 'mlp-client/src/timeline/enums'
import { ChangeServicesTimelineItem } from 'mlp-client/src/timeline/types'

const ChangeServicesTimelineCard: React.FC<
  TimelineItemProps<ChangeServicesTimelineItem>
> = ({ item, config }) => {
  const services = item.serviceTypes
  const serviceTypeTranslationKey =
    item.type === TimelineEventTypes.ADD_SERVICES ? 'serviceRange' : 'noService'

  const translationId = `myPortal.timeline.events.mlc.subtitles.${serviceTypeTranslationKey}.${
    services.length > 1 ? 'multipleServices' : 'singleService'
  }`

  return (
    <BaseTimelineCard
      item={item}
      config={config}
      status={item.status}
      title={
        <Translation id="myPortal.timeline.events.mlc.titles.serviceRange" />
      }
    >
      <Translation
        id={translationId}
        replace={{
          serviceName: () => (
            <ContractServices services={services} key="services" />
          ),
        }}
      />
    </BaseTimelineCard>
  )
}

export default ChangeServicesTimelineCard
