import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

/**
 * This component is used in the My LeasePlan onboarding and password reset
 * flow. If we receive both a url and a desiredLocale passed as query params,
 * we store the desiredLocale in localstorage, and redirect the user to the
 * supplied url. This is a url pointing to auth0 password reset page, where the
 * user can set his/her password and upon submission of that form will be
 * redirected back to this page, without any query params.
 * Then this components retrieves the desiredLocale from the localStorage
 * and uses it to redirect the user to our localized trigger page to redirect to
 * the auth0 login page, in the language of their desired locale.
 *
 * If we for some reason don't find any of the properties needed for this trick
 * to work, we redirect the user to origin of the current url.
 *
 * This funky redirecting is needed because of a limitation of the auth0 API
 * when redirecting back from email templates.
 * See https://auth0.com/docs/email/templates#configuring-the-redirect-to-url
 * for more info.
 */

const RedirectToCorrectAuth0Locale: React.FC = () => {
  const location = useLocation()
  const { search } = location
  const searchParams = new URLSearchParams(search)
  const desiredLocale = searchParams.get('locale')
  const urlParam = searchParams.get('url')
  const navigate = useNavigate()

  let url: string | undefined

  if (urlParam) {
    const urlFromParam = new URL(urlParam)

    urlFromParam.searchParams.set('lang', desiredLocale.split('-')[0])
    urlFromParam.searchParams.set('ui_locales', desiredLocale.split('-')[0])
    url = urlFromParam.toString()
  }

  useEffect(() => {
    if (desiredLocale) {
      window.localStorage.setItem('desiredLocale', desiredLocale)
    }

    if (desiredLocale && url) {
      navigate(url)
    } else {
      const locale = window.localStorage.getItem('desiredLocale')

      if (locale) {
        window.localStorage.removeItem('desiredLocale')
        navigate(`/${locale}/auth/authorize/?path=%2F${locale}%2Fmy`)
      } else {
        window.location.replace(window.location.origin)
      }
    }
  }, [])

  return <div />
}

export default RedirectToCorrectAuth0Locale
