import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { Neutral800Text } from 'mlp-client/src/components/styled/TextStyle'

interface TooltipLabelProps extends PropsWithChildren {
  percentage: number
  arrowWidth?: number
}

interface TooltipLabelInnerProps {
  left: number
}

interface TooltipLabelArrowProps {
  left: number
  arrowWidth: number
}

const DEFAULT_ARROW_WIDTH = 6

const TooltipLabelWrapper = styled.div`
  position: relative;
  top: -4px;
`

const TooltipLabelInner = styled(Neutral800Text)<TooltipLabelInnerProps>`
  background-color: var(--vel-color-neutral-200);
  display: inline;
  padding: 4px 8px;
  border-radius: 4px;
  position: absolute;
  top: 16px;
  white-space: nowrap;
  left: ${props => props.left}px;
  transition: left 0.5s ease-out 0s;
`

const TooltipLabelArrow = styled.div<TooltipLabelArrowProps>`
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  top: 11px;
  border-left: ${props => props.arrowWidth}px solid transparent;
  border-right: ${props => props.arrowWidth}px solid transparent;
  border-bottom: ${props => props.arrowWidth}px solid
    var(--vel-color-neutral-200);
  left: ${props => props.left - props.arrowWidth}px;
`

const TooltipLabel: React.FC<TooltipLabelProps> = ({
  percentage,
  arrowWidth = DEFAULT_ARROW_WIDTH,
  children,
}) => {
  const labelRef = useRef<HTMLDivElement>(null)
  const [progressBarWidth, setProgressBarWidth] = useState(0)
  const [labelWidth, setLabelWidth] = useState(0)

  useEffect(() => {
    setProgressBarWidth(
      (labelRef.current?.offsetParent as HTMLDivElement | null)?.offsetWidth,
    )
    setLabelWidth(labelRef.current?.offsetWidth)
  }, [percentage, children])

  const arrowLeft = (progressBarWidth * percentage) / 100

  const labelLeft = Math.min(
    Math.max(-arrowWidth - 1, arrowLeft - labelWidth * 0.5),
    progressBarWidth - labelWidth + arrowWidth,
  )

  return (
    <TooltipLabelWrapper data-e2e-id="tooltipLabel">
      <TooltipLabelArrow
        left={arrowLeft}
        arrowWidth={arrowWidth}
        data-e2e-id="tooltipLabelArrow"
      />
      <TooltipLabelInner rootRef={labelRef} variant="100" left={labelLeft}>
        {children}
      </TooltipLabelInner>
    </TooltipLabelWrapper>
  )
}

export default TooltipLabel
