import React from 'react'

import {
  WhiteHeadline,
  WhiteText,
} from 'mlp-client/src/components/styled/TextStyle'
import { Show } from 'mlp-client/src/components/utils/Show'
import Translation from 'mlp-client/src/localization/Translation'
import MaskedData from 'mlp-client/src/utils/masks/MaskedData'
import {
  BannerContent,
  FullWidthBanner,
} from 'mlp-client/src/vehicles/components/TopBanner.styled'

export interface Props {
  name: string
}

const TopBanner: React.FC<Props> = ({ name }) => (
  <FullWidthBanner image="/static/images/leaseplan/journey-line.svg">
    <BannerContent>
      <WhiteHeadline
        variant="400"
        data-e2e-id="homepageHeader"
        withMarginBottom={true}
        component="h1"
      >
        <Translation
          replace={{
            name: () => (
              <MaskedData type="User Name" key="userName">
                {name}
              </MaskedData>
            ),
          }}
          id="myPortal.dashboard.welcome"
        />
      </WhiteHeadline>
      <Show from="MD">
        <WhiteText variant="400" data-e2e-id="desktopHomepageSubheader">
          <Translation id="myPortal.multipleCars.titles.subtitle" />
        </WhiteText>
      </Show>
    </BannerContent>
  </FullWidthBanner>
)

export default TopBanner
