import {
  BreakpointName,
  isBreakpointAboveIncluding,
  isBreakpointBelow,
  useBreakpoint,
} from '@velocity/styling'
import React from 'react'

interface ShowProps {
  /**
   * The breakpoint from which the children are shown
   */
  from?: BreakpointName
  /**
   * The breakpoint until which the children are shown
   */
  until?: BreakpointName
  /**
   * Children components guarded by Show's from/until breakpoint range
   */
  children: React.ReactNode
}

export const Show: React.FC<ShowProps> = ({ from, until, children }) => {
  const currentBreakpoint = useBreakpoint()

  if (from && isBreakpointBelow(from, currentBreakpoint)) {
    return null
  }

  if (until && isBreakpointAboveIncluding(until, currentBreakpoint)) {
    return null
  }

  return <>{children}</>
}
