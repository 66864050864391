import { Grid } from '@velocity/ui'
import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import VehicleSelect from 'mlp-client/src/components/vehicle-select/VehicleSelect'
import { setCurrentContractId } from 'mlp-client/src/contracts/contractsSlice'
import useContracts, {
  useCurrentContractId,
} from 'mlp-client/src/contracts/hooks/useContracts'
import { getFormattedVehicle } from 'mlp-client/src/contracts/utils'
import { RootStyled } from 'mlp-client/src/dashboard/components/DashboardTopBanner.styled'
import { useAppDispatch } from 'mlp-client/src/hooks'
import { useLocale } from 'mlp-client/src/localization/LocaleProvider'
import { useCurrentPath } from 'mlp-client/src/localization/RoutesProvider'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

const DashboardTopBanner: React.FC = () => {
  const locale = useLocale()
  const contractId = useCurrentContractId()
  const accountId = useSelectedAccountId()
  const { data: contracts } = useContracts()
  const options = contracts?.map(getFormattedVehicle)
  const navigate = useNavigate()
  const currentPath = useCurrentPath()
  const dispatch = useAppDispatch()

  const setActiveVehicle = (contractId: string) => {
    dispatch(setCurrentContractId(contractId))

    navigate(
      generatePath(currentPath, {
        accountId,
        contractId,
        locale,
      }),
    )
  }

  if (!contracts?.length) {
    return null
  }

  return (
    <RootStyled>
      <Grid>
        <Grid.Item MD={3} XS={12}>
          {contractId && (
            <VehicleSelect
              vehicles={options}
              selectedVehicleId={contractId}
              onSelect={setActiveVehicle}
            />
          )}
        </Grid.Item>
      </Grid>
    </RootStyled>
  )
}

export default DashboardTopBanner
