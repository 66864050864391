import { Column, RowsConfiguration, TableProps } from './types'

export const groupByValues = (
  objectArray: readonly GenericObject[],
  property: string,
): RowsConfiguration =>
  objectArray.reduce((acc: Record<keyof GenericObject, any>, obj) => {
    const key = obj[property]
    return {
      ...acc,
      [key]: acc[key] ? [...acc[key], obj] : [obj],
    }
  }, {})

type DatumArray = TableProps['data']

export const getNotExistValuesFunc =
  (data: DatumArray) =>
  (valuesObject: GenericObject, property: string): string => {
    const nullValue = 'null'
    const undefinedValue = 'undefined'

    if (
      valuesObject[nullValue] &&
      data.length === valuesObject[nullValue].length
    ) {
      return property
    } else if (
      valuesObject[undefinedValue] &&
      data.length === valuesObject[undefinedValue].length
    ) {
      return property
    } else {
      return ''
    }
  }

export const getEmptyKeys = (data: DatumArray, columns: readonly Column[]) => {
  const keysArray: string[] = []
  const getNotExistValues = getNotExistValuesFunc(data)

  columns.forEach(item => {
    const valuesObject = groupByValues(data, item.key)

    keysArray.push(getNotExistValues(valuesObject, item.key))
  })

  return keysArray.filter(item => item !== '')
}
