import { Headline } from '@velocity/ui'
import styled from 'styled-components'

export const StyledMainHeadline = styled(Headline)`
  margin-top: var(--vel-fixed-spacing-06);
  margin-bottom: var(--vel-fixed-spacing-08);
`

export const StyledHeadline = styled(Headline)`
  font-size: 20px;
  margin-bottom: var(--vel-fixed-spacing-04);
`
