import { LinkStandalone, Text } from '@velocity/ui'
import React from 'react'

import { config } from 'mlp-client/src/app-config'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { trackSupportButtonEvent } from 'mlp-client/src/services/analytics/analytics'

const SupportLink: React.FC = () => {
  const { translate } = useTranslations()
  const supportLink = `${config.FAQ_API}customer-help-and-support`

  const onSupportButton = () =>
    trackSupportButtonEvent({
      data: {
        /*TODO: put real translation id
         * https://leaseplan-digital.atlassian.net/browse/NGSMP1-3392 */
        component_link_text: translate('support'),
        component_link_url: supportLink,
      },
    })

  return (
    <LinkStandalone
      href={supportLink}
      target="_blank"
      kind="neutral"
      onClick={() => onSupportButton()}
      data-e2e-id="topNavigationSupportLink"
    >
      <Text variant="200" bold={true}>
        {/*TODO: put real translation id*/}
        Support
      </Text>
    </LinkStandalone>
  )
}

export default SupportLink
