import { useGetContractsQuery } from 'mlp-client/src/contracts/contractsApi'
import { getCurrentContractId } from 'mlp-client/src/contracts/contractsSlice'
import { useAppSelector } from 'mlp-client/src/hooks'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

const useContracts = () => {
  const accountId = useSelectedAccountId()

  return useGetContractsQuery(
    {
      accountId,
    },
    { skip: !accountId },
  )
}

export const useHasContracts = () => {
  const { data: contracts } = useContracts()
  return Boolean(contracts?.length)
}

export const useHasMultipleContracts = () => {
  const { data: contracts } = useContracts()

  return contracts?.length > 1
}

export const useHasManyContracts = () => {
  const { data: contracts } = useContracts()
  return contracts?.length > 6
}

export const useCurrentContractId = () => {
  const { data: contracts } = useContracts()
  let contractId = useAppSelector(getCurrentContractId)
  if (!contracts?.find(contract => contract.id === contractId)) {
    contractId = contracts?.length === 1 ? contracts?.[0].id : null
  }
  return contractId
}

export const useCurrentContract = () => {
  const contractId = useCurrentContractId()
  const { data: contracts } = useContracts()
  return contracts?.find(({ id }) => id === contractId)
}

export default useContracts
