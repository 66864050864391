import React from 'react'

import { Config as DashboardConfiguration } from 'mlp-client/src/dashboard/components/configurable-dashboard/ConfigurableDashboard'
import {
  RoadsideAssistanceConfiguration,
  TravelAbroadConfiguration,
} from 'mlp-client/src/dashboard/types'
import { EndOfLeaseNotificationFeatureConfiguration as EndOfLeaseConfiguration } from 'mlp-client/src/end-of-lease/types'
import { DistanceUnit } from 'mlp-client/src/enums'
import { NavigationStructure } from 'mlp-client/src/features-configuration/enums'
import {
  ContractCurrency,
  ContractsFeatureConfig as LpisContractsFeatureConfig,
  TimelineFeatureConfig,
} from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { NavigationItem } from 'mlp-client/src/types'
import { User } from 'mlp-client/src/user/types'

export enum ApiType {
  lpis = 'lpis',
}

export interface MyLeaseplanFeature<T> {
  enabled: boolean
  view?:
    | React.ComponentType<MyLeaseplanFeatureProps<T>>
    | string
    | React.ComponentType
  configuration?: T
}

export type AllowedServices = ReadonlyArray<
  'bookService' | 'fuelCard' | 'damage' | 'documents'
>

interface WelcomeScreenFeature extends MyLeaseplanFeature<{}> {
  allowedServices?: AllowedServices
}

interface VehicleDocumentsFeature extends MyLeaseplanFeature<{}> {
  lpis?: {
    estimatedTimeOfDelivery: number
  }
}

interface OrderStatus {
  showVehicleDetails: boolean
  showOrderNumber: boolean
  isBannerVisibleOnDashboard?: boolean
  firstWorkingDay?: number
  lastWorkingDay?: number
  openingHours?: number
  closingHours?: number
  showOrders?: boolean
}

export type Navigation = {
  [key in NavigationStructure]: NavigationItem[]
}

export interface CountryConfigList {
  [key: string]: MyleaseplanConfig
}

export interface SharedMyLeaseplanConfig {
  /**
   * The whole MyLP is enabled.
   */
  enabled: boolean
}

export interface MyleaseplanConfig
  extends MyLeaseplanFeatures,
    SharedMyLeaseplanConfig {
  showPrivateAddressLabel?: boolean
  showCompanyAddress?: boolean
  supplierAvailabilityTimeFrame?: number
  minSupplierDistance?: number
  servicePhoneNumber?: string
  alternatePhoneDisplay?: boolean
  isInstallMobileAppEnabled?: boolean
  distanceUnit: DistanceUnit
  apiType?: ApiType
  orderStatus?: OrderStatus
  navigation?: Navigation
  profile?: {
    hideCountryCode?: boolean
  }
  showSupplierNotFound?: boolean
}

interface MyLeaseplanFeatures {
  vehicleCollectionChangeModal?: MyLeaseplanFeature<VehicleCollectionConfiguration>
  timeline?: MyLeaseplanFeature<TimelineFeatureConfig>
  maintenance?: MyLeaseplanFeature<{}>
  damageAssessment?: MyLeaseplanFeature<{}>
  vehicleDocuments?: VehicleDocumentsFeature
  roadsideAssistance?: MyLeaseplanFeature<RoadsideAssistanceConfiguration>
  tyres?: MyLeaseplanFeature<{}>
  serviceCard?: MyLeaseplanFeature<{}>
  contracts?: MyLeaseplanFeature<LpisContractsFeatureConfig>
  mileageUpdate?: MyLeaseplanFeature<{}>
  users?: MyLeaseplanFeature<{}>
  vehicleDocumentsPage?: MyLeaseplanFeature<{}>
  welcomeScreen?: WelcomeScreenFeature
  endOfLease?: MyLeaseplanFeature<EndOfLeaseConfiguration>
  dashboard?: MyLeaseplanFeature<DashboardConfiguration>
  contractDetails?: MyLeaseplanFeature<ContractDetailsFeatureConfig>
  MLC?: MyLeaseplanFeature<MidLeaseFeatureConfiguration>
  travelAbroad?: MyLeaseplanFeature<TravelAbroadConfiguration>
}

export type MyLeaseplanFeatureName = keyof MyLeaseplanFeatures

export interface MyLeaseplanFeatureProps<T = {}> {
  feature: MyLeaseplanFeatureName
  featureConfig: MyLeaseplanFeature<T>
  view?:
    | React.ComponentType<MyLeaseplanFeatureProps<T>>
    | string
    | React.ComponentType
  user?: User
}

interface ContractDetailsFeatureConfig {
  documents?: boolean
  contractStatusBlock?: boolean
  currency?: ContractCurrency
}

export interface DialogModal {
  title: string
  buttonFirst: string
  buttonSecond: string
  showBrokerInfo: boolean
  summaryBeforeLink?: string
  summaryLink?: string
  summaryAfterLink?: string
  summaryBeforeBrokerInfo?: string
}

export interface MidLeaseFeatureConfiguration {
  dialogModal: DialogModal
}

export interface VehicleCollectionConfiguration {
  dialogModal: DialogModal
}
