import React, { useContext } from 'react'

import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import RoadsideAssistanceModalContent from 'mlp-client/src/components/roadside-assistance-modal/RoadsideAssistanceModalContent'
import RouteModal from 'mlp-client/src/components/route-modal/RouteModal'
import { TileType } from 'mlp-client/src/contracts/enums'
import { useProtectContractTiles } from 'mlp-client/src/routes/protectContractTilesRoute/protectContractTilesRoute'
import { withOneInstance } from 'mlp-client/src/utils/hocs/hocs'

function RoadsideAssistanceModal() {
  const { roadsideAssistance } = useContext(MyLeaseplanContext)

  return (
    <RouteModal
      title={roadsideAssistance.configuration.dialogModal.title}
      path="roadside-assistance"
      protectRoute={useProtectContractTiles(TileType.ROADSIDE_ASSISTANCE)}
      data-e2e-id="roadsideAssistanceModal"
    >
      {() => (
        <RoadsideAssistanceModalContent
          modalContent={roadsideAssistance.configuration.dialogModal}
        />
      )}
    </RouteModal>
  )
}

export default withOneInstance(RoadsideAssistanceModal)
