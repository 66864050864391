import { Text, Button } from '@velocity/ui'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { TravelAbroadDialogModal } from 'mlp-client/src/dashboard/types'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'
import {
  trackTravelAbroadMoreInformationEvent,
  trackTravelAbroadOpenEvent,
  trackTravelAbroadRequestDocumentsEvent,
} from 'mlp-client/src/services/analytics/analytics'

const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
  margin-right: 0.5rem;
`

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: left;
`

export interface Props {
  modalContent: TravelAbroadDialogModal
}

const TravelAbroadModalContent: React.FC<Props> = ({ modalContent }) => {
  const { translate } = useTranslations()
  const {
    title,
    summary,
    button,
    buttonURL,
    secondaryButton,
    secondaryButtonURL,
  } = modalContent

  const sendRequestDocumentsAnalytics = () =>
    trackTravelAbroadRequestDocumentsEvent({
      data: {
        component_title: translate(title),
        component_link_text: translate(button),
        component_link_url: translate(buttonURL),
      },
    })

  const sendRequestMoreInfoAnalytics = () =>
    trackTravelAbroadMoreInformationEvent({
      data: {
        component_title: translate(title),
        component_link_text: translate(secondaryButton),
        component_link_url: translate(secondaryButtonURL),
      },
    })

  useEffect(() => {
    trackTravelAbroadOpenEvent({
      data: {
        component_title: translate(title),
      },
    })
  }, [])

  return (
    <>
      <Text layoutMargin={{ bottom: '04' }}>
        <Translation id={summary} />
      </Text>
      <StyledButtonsWrapper>
        <StyledLink
          onClick={sendRequestDocumentsAnalytics}
          href={translate(buttonURL)}
          target="_blank"
          data-e2e-id="travelAbroadPrimaryButton"
        >
          <Button variant="primary-filled">
            <Translation id={button} />
          </Button>
        </StyledLink>

        <StyledLink
          onClick={sendRequestMoreInfoAnalytics}
          href={translate(secondaryButtonURL)}
          target="_blank"
          data-e2e-id="travelAbroadSecondaryButton"
        >
          <Button variant="secondary-outlined">
            <Translation id={secondaryButton} />
          </Button>
        </StyledLink>
      </StyledButtonsWrapper>
    </>
  )
}

export default TravelAbroadModalContent
