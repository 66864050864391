import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import BaseTimelineCard, {
  TimelineItemProps,
} from 'mlp-client/src/timeline/components/TimelineCard/BaseTimelineCard'
import { ChangeBrokerTimelineItem } from 'mlp-client/src/timeline/types'

const ChangeBrokerTimelineCard: React.FC<
  TimelineItemProps<ChangeBrokerTimelineItem>
> = ({ item, config }) => (
  <BaseTimelineCard
    item={item}
    config={config}
    status={item.status}
    title={<Translation id="myPortal.timeline.events.mlc.titles.newBroker" />}
  >
    <div>{item.broker.name}</div>
    <div>{item.broker.email}</div>
    <div>{item.broker.phone}</div>
  </BaseTimelineCard>
)

export default ChangeBrokerTimelineCard
