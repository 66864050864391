import { Grid, NativeSelect, Text } from '@velocity/ui'
import React from 'react'
import { Field } from 'react-final-form'

import { DamageAssessment } from 'mlp-client/src/assessments/types'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'

export const SELECTOR_OPTIONS = [
  'No objections',
  'myPortal.timeline.fairWearTear.objections.objectionA',
  'myPortal.timeline.fairWearTear.objections.objectionC',
]

interface Props {
  damageAssessments: DamageAssessment[]
}

const DamageAssessmentsOptions: React.FC<Props> = ({ damageAssessments }) => {
  const { translate } = useTranslations()

  return (
    <>
      {damageAssessments.map(({ id, damage: { description } }) => (
        <React.Fragment key={id}>
          <Grid columns={12} alignment="center" margin={{ y: '04' }}>
            <Grid.Item XS={12} MD={6} LG={5}>
              <Text variant="300" bold={true}>
                {description}
              </Text>
            </Grid.Item>
            <Grid.Item XS={12} MD={6} LG={7}>
              <Field name={description}>
                {({ input }) => (
                  <NativeSelect onChange={e => input.onChange(e.target.value)}>
                    {SELECTOR_OPTIONS.map(option => (
                      <option key={option} value={translate(option)}>
                        {translate(option)}
                      </option>
                    ))}
                  </NativeSelect>
                )}
              </Field>
            </Grid.Item>
          </Grid>
        </React.Fragment>
      ))}
    </>
  )
}

export default DamageAssessmentsOptions
