import { AccountIcon } from '@velocity/icons/system'
import { useBreakpoint, isBreakpointBelow } from '@velocity/styling'
import {
  LinkStandalone,
  Accordion,
  AccordionsContainer,
  BlankButton,
} from '@velocity/ui'
import { TopNavigation } from '@velocity/ui/draft'
import React from 'react'
import styled from 'styled-components'

import { setNextRoute } from 'mlp-client/src/auth/authSlice'
import { generateProfileManagementLink } from 'mlp-client/src/features-configuration/NGDACountries/utils'
import { useAppDispatch } from 'mlp-client/src/hooks'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import {
  useCurrentPath,
  useLocalizedRoute,
  useLocalizedRouteMatch,
} from 'mlp-client/src/localization/RoutesProvider'
import Translation from 'mlp-client/src/localization/Translation'
import { trackInternalNavigationEvent } from 'mlp-client/src/services/analytics/analytics'
import { logout } from 'mlp-client/src/services/auth/auth'
import { useIsSingleAccount } from 'mlp-client/src/user/hooks/useUser'
import { useUser } from 'mlp-client/src/user/hooks/useUser'
import { clearSelectedAccountId } from 'mlp-client/src/user/userSlice'
import MaskedData from 'mlp-client/src/utils/masks/MaskedData'

const StyledDropdownWrapper = styled.div`
  min-width: 200px;
`

const StyledAccordionWrapper = styled(AccordionsContainer)`
  width: 100%;

  > div > #account-accordion {
    box-shadow: none;
  }
`

interface LoggedInButtonContentProps {
  isMobile: boolean
}

const LoggedInButtonContent: React.FC<LoggedInButtonContentProps> = ({
  isMobile,
}) => {
  const dispatch = useAppDispatch()
  const { translate } = useTranslations()
  const isSingleAccount = useIsSingleAccount()
  const getLocalizedRoute = useLocalizedRoute()
  const nextRoute = getLocalizedRoute('myPortal.homepage')

  const profileTitle = 'myPortal.subNavigation.profile'
  const profileLink = generateProfileManagementLink({
    callbackUrl: window.location.href,
  })

  const selectAccountTitle = 'myPortal.accountSwitching.switchAccount'
  const selectAccountLink = 'myPortal.selectAccount'
  const currentPath = useCurrentPath()
  const getLocalizedRouteMatch = useLocalizedRouteMatch()
  const isSelectAccountRoute =
    currentPath === getLocalizedRouteMatch(selectAccountLink)

  const logoutTitle = 'myPortal.subNavigation.logout'

  const onLogout = async () => {
    dispatch(setNextRoute(nextRoute))
    await logout()
  }

  const sendAnalytics = (title: string, to: string) =>
    trackInternalNavigationEvent({
      data: {
        component_link_text: translate(title),
        component_link_url: to,
        component_type: isMobile
          ? 'mobile_menu_account_link'
          : 'main_menu_account_link',
      },
    })

  return (
    <>
      <LinkStandalone
        component={RouteLink}
        to={profileLink}
        data-e2e-id="profileLink"
        kind="neutral"
        margin={{ bottom: '05' }}
        onClick={() => sendAnalytics(profileTitle, profileLink)}
      >
        <Translation id={profileTitle} />
      </LinkStandalone>

      {!isSingleAccount && !isSelectAccountRoute && (
        <LinkStandalone
          component={RouteLink}
          to={selectAccountLink}
          data-e2e-id="selectAccountLink"
          kind="neutral"
          margin={{ bottom: '05' }}
          onClick={() => {
            // To hide the menu
            document.body.click()
            dispatch(clearSelectedAccountId())
            sendAnalytics(selectAccountTitle, selectAccountLink)
          }}
        >
          <Translation id={selectAccountTitle} />
        </LinkStandalone>
      )}

      <LinkStandalone
        data-e2e-id="logoutLink"
        kind="neutral"
        onClick={async () => {
          await onLogout()
          sendAnalytics(logoutTitle, '')
        }}
      >
        <Translation id={logoutTitle} />
      </LinkStandalone>
    </>
  )
}

const UserAccountMenu: React.FC = () => {
  const { data: user } = useUser()

  const breakpoint = useBreakpoint()
  const isMobile = isBreakpointBelow('SM', breakpoint)

  if (!user) {
    return null
  }

  const fullName = `${user.firstName} ${user.lastName}`

  if (isMobile) {
    return (
      <StyledAccordionWrapper
        allowMultipleExpanded={true}
        data-e2e-id="userAccountMenu"
      >
        <BlankButton
          style={{ width: '100%' }}
          onClick={event => event.stopPropagation()}
        >
          <Accordion id="account-accordion" initialExpanded={true}>
            <Accordion.Header Icon={AccountIcon}>
              <MaskedData type="User Name" data-e2e-id="accountMenuUserName">
                {fullName}
              </MaskedData>
            </Accordion.Header>
            <Accordion.Content>
              <StyledAccordionWrapper>
                <LoggedInButtonContent isMobile={isMobile} />
              </StyledAccordionWrapper>
            </Accordion.Content>
          </Accordion>
        </BlankButton>
      </StyledAccordionWrapper>
    )
  }

  return (
    <TopNavigation.Widgets.Widget
      Icon={AccountIcon}
      label={fullName}
      aria-label="Account"
      data-e2e-id="topNavigationAccountWidget"
      data-dd-action-name="User Name"
      containsDropdown={true}
    >
      <StyledDropdownWrapper>
        <LoggedInButtonContent isMobile={isMobile} />
      </StyledDropdownWrapper>
    </TopNavigation.Widgets.Widget>
  )
}

export default UserAccountMenu
