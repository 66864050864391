import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppState } from 'mlp-client/src/types'

export interface ContractsState {
  currentContractId: string | null
}

export const initialState: ContractsState = {
  currentContractId: null,
}

const contractsSlice = createSlice({
  name: '@@contracts',
  initialState,
  reducers: {
    setCurrentContractId: (state, action: PayloadAction<string>) => {
      state.currentContractId = action.payload
    },
  },
})

export const getCurrentContractId = (state: AppState): string =>
  state.contracts.currentContractId

export const { setCurrentContractId } = contractsSlice.actions

export default contractsSlice.reducer
