import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import BaseTimelineCard, {
  TimelineItemProps,
} from 'mlp-client/src/timeline/components/TimelineCard/BaseTimelineCard'
import { ChangeRegistrationNumberTimelineItem } from 'mlp-client/src/timeline/types'
import MaskedLicensePlate from 'mlp-client/src/utils/masks/MaskedLicensePlate'

const ChangeRegistrationNumberTimelineCard: React.FC<
  TimelineItemProps<ChangeRegistrationNumberTimelineItem>
> = ({ item, config }) => (
  <BaseTimelineCard
    item={item}
    config={config}
    status={item.status}
    title="myPortal.timeline.events.mlc.titles.newRegistration"
  >
    <Translation
      id="myPortal.timeline.events.mlc.subtitles.newRegistration"
      replace={{
        newLicensePlateNumber: () => (
          <MaskedLicensePlate>
            {item.vehicle.registrationNumber}
          </MaskedLicensePlate>
        ),
      }}
    />
  </BaseTimelineCard>
)

export default ChangeRegistrationNumberTimelineCard
