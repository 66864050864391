import React from 'react'

import { DateFormat } from 'mlp-client/src/localization/components/DateFormat'
import BaseTimelineCard, {
  TimelineItemProps,
} from 'mlp-client/src/timeline/components/TimelineCard/BaseTimelineCard'
import { ChangeCustomerTimelineItem } from 'mlp-client/src/timeline/types'

const ChangeCustomerTimelineCard: React.FC<
  TimelineItemProps<ChangeCustomerTimelineItem>
> = ({ item, config }) => (
  <BaseTimelineCard
    item={item}
    config={config}
    status={item.status}
    title="myPortal.timeline.events.mlc.titles.contractTransferred"
  >
    <DateFormat value={item.transferDate} literal="-" />
  </BaseTimelineCard>
)

export default ChangeCustomerTimelineCard
