import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { clearNextRoute } from 'mlp-client/src/auth/authSlice'
import { useNextRoute } from 'mlp-client/src/auth/hooks/useAuth'
import NoIndexLoader from 'mlp-client/src/components/loader/NoIndexLoader'
import { useAppDispatch } from 'mlp-client/src/hooks'
import { useLocalizedRoute } from 'mlp-client/src/localization/RoutesProvider'
import { redirectResult } from 'mlp-client/src/services/auth/auth'
import { authSessionStorage } from 'mlp-client/src/utils/authSessionStorage'

const LoginRedirect: React.FC = () => {
  const nextRoute = useNextRoute()
  const getLocalizedRoute = useLocalizedRoute()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const processLoginResponse = async () => {
    try {
      await redirectResult()
      authSessionStorage.init()
      navigate(nextRoute)
    } catch (e) {
      navigate(getLocalizedRoute('myPortal.somethingWentWrong'))
    } finally {
      dispatch(clearNextRoute())
    }
  }

  useEffect(() => {
    processLoginResponse()
  }, [])

  return <NoIndexLoader />
}

export default LoginRedirect
