import React from 'react'

import ContractService from 'mlp-client/src/contracts/components/ContractServices/ContractService'
import { ServiceType } from 'mlp-client/src/contracts/enums'

interface ContractServicesProps {
  services: ServiceType[]
}

const ContractServices: React.FC<ContractServicesProps> = ({ services }) =>
  services.map((service, index, array) =>
    index === array.length - 1 ? (
      <ContractService service={service} key={service} />
    ) : (
      <span key={service}>
        <ContractService service={service} />,{' '}
      </span>
    ),
  )

export default ContractServices
