import {
  CheckCircleFilledIcon,
  CloseCircleFilledIcon,
} from '@velocity/icons/system'
import { Button, Divider, Grid, Text } from '@velocity/ui'
import { camelCase } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { SecondaryDarkText } from 'mlp-client/src/components/styled/TextStyle'
import { Show } from 'mlp-client/src/components/utils/Show'
import {
  FIELD_TYPE_LINE,
  FIELD_TYPE_NOTE,
} from 'mlp-client/src/contracts/constants'
import { ListProperties } from 'mlp-client/src/contracts/types'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'
import { trackContractChangeRequestEvent } from 'mlp-client/src/services/analytics/analytics'
import { useIsUserClient } from 'mlp-client/src/user/hooks/useUser'

const CheckIconStyled = styled(CheckCircleFilledIcon)`
  color: var(--vel-color-semantic-success-high);
`

const CloseIconStyled = styled(CloseCircleFilledIcon)`
  color: var(--vel-color-semantic-error-high);
`

export interface Props {
  properties: ListProperties
  titleTranslationId?: string
  isContractSection?: boolean
  isContractActive: boolean
}

const ContractPropertiesTable: React.FC<Props> = ({
  properties,
  titleTranslationId,
  isContractSection,
  isContractActive,
}) => {
  const isUserClient = useIsUserClient()
  const { translate } = useTranslations()
  const navigate = useNavigate()

  const handleButtonClick = () => {
    trackContractChangeRequestEvent({
      data: {
        component_title: translate(titleTranslationId),
        component_link_text: translate(
          'myPortal.dashboard.contractChanges.button.requestContractChange',
        ),
      },
    })

    navigate('contract-changes')
  }

  return (
    <>
      <Grid alignment="center" columns={12}>
        {properties
          .map((property, index) => {
            const { key, value, translateValue, included } = property

            if (key === FIELD_TYPE_NOTE) {
              return (
                'label' in property &&
                property.label && (
                  <Grid.Item key={`${property.key}.label`}>
                    <Text
                      variant="300"
                      component="span"
                      layoutMargin={{ y: '02' }}
                    >
                      <Translation id={property.label} />
                    </Text>
                  </Grid.Item>
                )
              )
            }

            if (key === FIELD_TYPE_LINE && properties[index + 1]) {
              return (
                <Grid.Item key={`${key}${index}.label`}>
                  <Divider margin={undefined} />
                </Grid.Item>
              )
            }

            if ('label' in property && property.label) {
              const key = camelCase(property.key)
              return [
                <Grid.Item XS={12} MD={4} key={`${key}.label`}>
                  <Text variant="300" component="span">
                    <Translation id={property.label} />
                  </Text>
                </Grid.Item>,

                <Grid.Item
                  key={`${key}.value`}
                  XS={12}
                  MD={8}
                  data-e2e-id={`${key}Value`}
                >
                  <SecondaryDarkText variant="300" bold={true} component="span">
                    <Show from="MD">
                      {Object.prototype.hasOwnProperty.call(
                        property,
                        'included',
                      ) && (
                        <>
                          {included ? (
                            <CheckIconStyled
                              size="xs"
                              data-e2e-id={`${key}Included`}
                            />
                          ) : (
                            <CloseIconStyled
                              size="xs"
                              data-e2e-id={`${key}Excluded`}
                            />
                          )}
                        </>
                      )}
                    </Show>

                    {translateValue ? (
                      <Translation id={value.toString()} />
                    ) : (
                      value
                    )}
                  </SecondaryDarkText>
                </Grid.Item>,
              ]
            }

            return (
              <Grid.Item key={`${key}.value`}>
                <SecondaryDarkText variant="300">
                  {translateValue ? (
                    <Translation id={value.toString()} />
                  ) : (
                    value
                  )}
                </SecondaryDarkText>
              </Grid.Item>
            )
          })
          .flat(1)}
      </Grid>

      {isContractSection && isUserClient && isContractActive && (
        <Button
          margin={{ top: '07', bottom: '02' }}
          variant="primary-outlined"
          data-e2e-id="requestContractChangeButton"
          onClick={handleButtonClick}
        >
          <Translation id="myPortal.dashboard.contractChanges.button.requestContractChange" />
        </Button>
      )}
    </>
  )
}

export default ContractPropertiesTable
