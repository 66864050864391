import { Headline } from '@velocity/ui'
import React from 'react'

import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import TimelinePageContent from 'mlp-client/src/timeline/components/TimelinePage/TimelinePageContent'

const TimelinePage: React.FC = () => {
  const { translate } = useTranslations()

  const metaTitle = translate('myPortal.timeline.base.title')

  return (
    <MyleaseplanPage metaTitle={metaTitle}>
      <Headline component="h1" variant="400" withMarginBottom={true}>
        {metaTitle}
      </Headline>

      <TimelinePageContent />
    </MyleaseplanPage>
  )
}

export default TimelinePage
