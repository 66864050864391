import { rest } from 'msw'

import contractsError from 'mlp-client/src/mocks/json/contracts/error.json'
import contractsError403 from 'mlp-client/src/mocks/json/contracts/error403.json'
import singleContractError from 'mlp-client/src/mocks/json/contracts/id/error.json'
import singleContractError404 from 'mlp-client/src/mocks/json/contracts/id/error404.json'
import LP00018JRContract from 'mlp-client/src/mocks/json/contracts/id/LP00018JR.json'
import multipleContracts from 'mlp-client/src/mocks/json/contracts/multipleContracts.json'
import singleContract from 'mlp-client/src/mocks/json/contracts/singleContract.json'

export const handlers = [
  rest.get('*/api2/contracts/:accountId', async (req, res, ctx) => {
    const params = new URLSearchParams(window.location.search)
    const scenario = params.get('contracts')
    const error = params.get('error')

    if (scenario) {
      if (error === '403') {
        return res(ctx.status(403), ctx.json(contractsError403))
      }

      if (error) {
        return res(ctx.status(500), ctx.json(contractsError))
      }
    }

    if (scenario === 'multipleContracts') {
      return res(ctx.json(multipleContracts))
    }

    return res(ctx.json(singleContract))
  }),

  rest.get('*/api2/contracts/:accountId/:contractId', async (req, res, ctx) => {
    const params = new URLSearchParams(window.location.search)
    const scenario = params.get('contract')
    const error = params.get('error')

    if (scenario) {
      if (error === '404') {
        return res(ctx.status(404), ctx.json(singleContractError404))
      }

      if (error) {
        return res(ctx.status(500), ctx.json(singleContractError))
      }
    }

    return res(ctx.json(LP00018JRContract))
  }),
]
