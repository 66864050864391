import {
  MyLeaseplanFeature,
  MidLeaseFeatureConfiguration,
} from 'mlp-client/src/features-configuration/types'

export const midLeaseChangeFeature: MyLeaseplanFeature<MidLeaseFeatureConfiguration> =
  {
    enabled: true,
    configuration: {
      dialogModal: {
        title: 'myPortal.dashboard.contractChanges.modal.title',
        buttonFirst: 'myPortal.dashboard.contractChanges.modal.button.email',
        buttonSecond: 'myPortal.dashboard.contractChanges.modal.button.call',
        summaryBeforeLink:
          'myPortal.dashboard.contractChanges.modal.PT.summaryBeforeLink',
        summaryLink: 'myPortal.dashboard.contractChanges.modal.PT.summaryLink',
        summaryAfterLink:
          'myPortal.dashboard.contractChanges.modal.PT.summaryAfterLink',
        showBrokerInfo: false,
      },
    },
  }
