import { Modal, NotificationBanner } from '@velocity/ui'
import moment from 'moment/moment'
import React, { useContext, useEffect, useState } from 'react'

import MileageUpdateWithContract from 'mlp-client/src/components/mileage-update-modal/MileageUpdateWithContract'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { TileType } from 'mlp-client/src/contracts/enums'
import useContractDetails from 'mlp-client/src/contracts/hooks/useContractDetails'
import { DateFormat } from 'mlp-client/src/localization/components/DateFormat'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'
import { useIsUserDriver } from 'mlp-client/src/user/hooks/useUser'
import { authSessionStorage } from 'mlp-client/src/utils/authSessionStorage'
import { usePrevious } from 'mlp-client/src/utils/hooks/hooks'

const RequiredMileageUpdateModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true)
  const { data: contractDetails } = useContractDetails()
  const previousIlan = usePrevious(contractDetails?.id)
  const isDriver = useIsUserDriver()
  const { translate } = useTranslations()
  const { endOfLease } = useContext(MyLeaseplanContext)
  const { requiredMileageUpdateDays } = endOfLease.configuration
  const title = translate('myPortal.mileageUpdate.eoc.title')
  const hideRequiredMileageUpdateModal = authSessionStorage.getItem(
    'hideRequiredMileageUpdateModal',
  )

  useEffect(() => {
    if (previousIlan && previousIlan !== contractDetails?.id) {
      setIsOpen(true)
    }
  }, [contractDetails])

  if (!requiredMileageUpdateDays || !contractDetails) {
    return null
  }

  const { vehicle, endDate, tiles } = contractDetails
  const hasMileageUpdateTile = tiles.some(
    tile => tile.type === TileType.MILEAGE_UPDATE,
  )
  const { dateLastKnownMileage } = vehicle

  const momentEndDate = moment(endDate)
  const notificationDate = moment(endDate).subtract(
    requiredMileageUpdateDays,
    'days',
  )
  const isContractEnding =
    notificationDate.isBefore(moment()) && momentEndDate.isSameOrAfter(moment())
  const mileageWasUpdated = notificationDate.isBefore(
    moment(dateLastKnownMileage),
  )

  if (
    isDriver &&
    isContractEnding &&
    hasMileageUpdateTile &&
    !mileageWasUpdated &&
    !hideRequiredMileageUpdateModal
  ) {
    const onCloseModal = () => {
      setIsOpen(false)
      authSessionStorage.setItem('hideRequiredMileageUpdateModal', 'true')
    }

    return (
      <Modal
        title={title}
        open={isOpen}
        onClose={onCloseModal}
        size="s"
        data-e2e-id="requiredMileageUpdate"
      >
        <Modal.Content>
          <MileageUpdateWithContract
            title={title}
            closeModal={onCloseModal}
            showCloseButton={false}
            summary={
              <NotificationBanner
                data-e2e-id="mileageUpdateSummary"
                layoutMargin={{ bottom: '03' }}
                kind="warning"
              >
                <Translation
                  id="myPortal.mileageUpdate.eoc.summary"
                  replace={{
                    endDate: () => (
                      <span key="endDate">
                        (
                        <DateFormat value={endDate} month="short" literal=" " />
                        )
                      </span>
                    ),
                  }}
                />
              </NotificationBanner>
            }
          />
        </Modal.Content>
      </Modal>
    )
  }

  return null
}

export default RequiredMileageUpdateModal
