export interface InitAuth {
  audience: string
  clientId: string
  domain: string
}

export enum SupportedAuthenticationLanguages {
  EN = 'en',
  PT = 'pt',
  NL = 'nl',
}
