import { PropsWithChildren } from 'react'

import MaskedData from './MaskedData'

const MaskedLicensePlate: React.FC<PropsWithChildren> = ({
  children,
  ...props
}) => (
  <MaskedData type="License Plate" {...props}>
    {children}
  </MaskedData>
)

export default MaskedLicensePlate
