import en_lp from './en-lp/routes.json'
import NGDACountries from './NGDACountries/routes.json'

import { Locales } from 'mlp-client/src/localization/enums'

type LocaleRoutesMapType = { [K in Locales]?: GenericObject }

const LocaleRoutesMap: LocaleRoutesMapType = {
  [Locales.EN_LP]: en_lp,
  [Locales.EN_GB]: NGDACountries,
  [Locales.PT]: NGDACountries,
  [Locales.EN_PT]: NGDACountries,
  [Locales.NL]: NGDACountries,
  [Locales.EN_NL]: NGDACountries,
}

export type ExistingLocales = keyof typeof LocaleRoutesMap

export default LocaleRoutesMap
