import React from 'react'

import { DateFormat } from 'mlp-client/src/localization/components/DateFormat'
import { Mileage } from 'mlp-client/src/localization/components/Mileage'
import Translation from 'mlp-client/src/localization/Translation'
import BaseTimelineCard, {
  TimelineItemProps,
} from 'mlp-client/src/timeline/components/TimelineCard/BaseTimelineCard'
import { ChangeTermAndMileageTimelineItem } from 'mlp-client/src/timeline/types'

const ChangeTermAndMileageTimelineCard: React.FC<
  TimelineItemProps<ChangeTermAndMileageTimelineItem>
> = ({ item, config }) => (
  <BaseTimelineCard
    item={item}
    config={config}
    status={item.status}
    title="myPortal.timeline.events.mlc.titles.contractDuration"
  >
    <Translation
      id="myPortal.timeline.events.mlc.subtitles.contractDuration"
      replace={{
        endDate: () => (
          <DateFormat value={item.endDate} key="endDate" literal="-" />
        ),
        mileage: () => (
          <Mileage
            value={item.mileage.value}
            mileageUnit={item.mileage.unit}
            key="mileage"
          />
        ),
      }}
    />
  </BaseTimelineCard>
)

export default ChangeTermAndMileageTimelineCard
