import styled from 'styled-components'

export const ContentLayout = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex: 1 1 auto;
`

export const MainContentLayout = styled(ContentLayout)`
  max-width: 1278px;
  padding-right: var(--vel-fixed-spacing-07);
  padding-left: var(--vel-fixed-spacing-07);
`

export const MainWrapper = styled.div`
  flex: 1 1 auto;
  flex-flow: column nowrap;
  display: flex;
  min-height: 100vh;
`

export const CenteredContentWrapper = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: var(--vel-layout-spacing-08) 0;
  margin-bottom: var(--vel-layout-spacing-02);
`
