import { merge } from 'lodash'

import { EndOfLeaseNotificationFeatureConfiguration } from 'mlp-client/src/end-of-lease/types'
import { endOfLeaseFeature as defaultEndOfLeaseFeature } from 'mlp-client/src/features-configuration/NGDACountries/endOfLease'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'

export const endOfLeaseFeature: MyLeaseplanFeature<EndOfLeaseNotificationFeatureConfiguration> =
  merge({}, defaultEndOfLeaseFeature, {
    configuration: {
      dialogModal: {
        showBrokerInfo: false,
      },
    },
  })
