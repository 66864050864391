import React, { PropsWithChildren, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  useLocalizedRoute,
  useCurrentPath,
  useLocalizedRouteMatch,
} from 'mlp-client/src/localization/RoutesProvider'
import {
  getRedirectRoutePath,
  clearRedirectRoutePath,
} from 'mlp-client/src/routes/routesSlice'

export const SimpleRouteRedirectComponent: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const getLocalizedRoute = useLocalizedRoute()
  const redirectRoutePath = useSelector(getRedirectRoutePath)
  const getLocalizedRouteMatch = useLocalizedRouteMatch()
  const currentPath = useCurrentPath()

  useEffect(() => {
    if (
      redirectRoutePath &&
      currentPath !== getLocalizedRouteMatch(redirectRoutePath)
    ) {
      const redirectRoute = getLocalizedRoute(redirectRoutePath)
      navigate(redirectRoute)
    }
  }, [redirectRoutePath, getLocalizedRoute, navigate])

  useEffect(() => {
    // Redirect process is fully completed
    if (
      redirectRoutePath &&
      currentPath === getLocalizedRouteMatch(redirectRoutePath)
    ) {
      dispatch(clearRedirectRoutePath())
    }
  }, [redirectRoutePath, currentPath])

  return <>{children}</>
}

const simpleRouteRedirectHOC = <T extends PropsWithChildren>(
  ProtectedComponent: React.ComponentType<T>,
) => {
  const SimpleRouteRedirect: React.FC<T> = props => (
    <SimpleRouteRedirectComponent>
      <ProtectedComponent {...props} />
    </SimpleRouteRedirectComponent>
  )

  SimpleRouteRedirect.displayName = `simpleRouteRedirect(${
    ProtectedComponent.displayName ||
    ProtectedComponent.name ||
    'ProtectedComponent'
  })`

  return SimpleRouteRedirect
}

export default simpleRouteRedirectHOC
