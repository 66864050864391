import { LocationIcon } from '@velocity/icons/system'
import { mq } from '@velocity/styling'
import styled from 'styled-components'

import { BREAKPOINT_MOBILE } from 'mlp-client/src/utils/hooks/breakpoints'

export const MarkerTooltipWrapper = styled.div`
  width: 320px;
  transform: translate(-50%, -100%);

  ${mq.below(BREAKPOINT_MOBILE)} {
    width: 250px;
  }
`

export const MarkerTooltipContainer = styled.div`
  position: relative;
  border-radius: 5px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
  padding: var(--vel-fixed-spacing-04);
  margin-bottom: var(--vel-fixed-spacing-04);

  &:before {
    position: absolute;
    text-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
    bottom: -8px;
    left: 50%;
    margin-left: -7px;
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid white;
  }
`

export const MarkerTooltipIconWrapper = styled.div`
  width: 100%;
  text-align: center;
`

export const MarkerTooltipIcon = styled(LocationIcon)`
  color: var(--vel-color-primary-400);
  width: 42px;
  height: 42px;
  display: inline;
`
