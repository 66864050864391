import React, { PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet-async'

// import the following layout components from individual modules as
// importing them from single `mlp-client/src/components/layout` module
// creates a circular dependency
import { MainPage, MainPaddings } from './MyleaseplanPage.styled'

import { SomethingWentWrong } from 'mlp-client/src/components/something-went-wrong/SomethingWentWrong'
import { ErrorBoundary } from 'mlp-client/src/datadog/ErrorBoundary'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'

export interface Props extends PropsWithChildren {
  metaTitle?: string
  metaDescription?: string
  meta?: Array<{ name: string; content: string }>
  banner?: React.ReactNode
}

const MyleaseplanPage: React.FC<Props> = ({
  metaTitle,
  metaDescription,
  meta = [],
  banner,
  children,
}) => {
  const { translate } = useTranslations()

  const title = translate(metaTitle)

  const getMeta = () => {
    if (metaDescription) {
      return [...meta, { name: 'description', content: metaDescription }]
    }

    return meta
  }

  return (
    <MainPage data-e2e-id="myLeasePlanPage">
      <Helmet title={title} meta={getMeta()} />
      {banner}
      <MainPaddings>
        <ErrorBoundary fallback={<SomethingWentWrong title={title} />}>
          {children}
        </ErrorBoundary>
      </MainPaddings>
    </MainPage>
  )
}

export default MyleaseplanPage
