import { myleaseplanConfig as gb } from './gb/index'
import { myleaseplanConfig as lp } from './lp/index'
import { myleaseplanConfig as NGDACountries } from './NGDACountries'
import { myleaseplanConfig as pt } from './pt/index'
import { CountryConfigList } from './types'

export const config: CountryConfigList = {
  lp,
  gb,
  pt,
  nl: NGDACountries,
}

export const ALLOWED_LOCALES = ['en-GB', 'en-PT', 'pt-PT'] as const
