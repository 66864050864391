import { ChevronRightIcon } from '@velocity/icons/system'
import { Card, Divider, LinkStandalone, Text } from '@velocity/ui'
import React from 'react'

import RouteLink from 'mlp-client/src/localization/RouteLink'
import Translation from 'mlp-client/src/localization/Translation'

interface InfoCardProps {
  title: React.ReactNode
  summary: React.ReactNode
  linkURL?: string
  linkText?: React.ReactNode
  dataE2eId?: string
}

const InfoCard: React.FC<InfoCardProps> = ({
  title,
  summary,
  linkURL,
  linkText,
  dataE2eId,
}) => (
  <Card layoutMargin={{ bottom: '04' }} data-e2e-id={dataE2eId || 'infoCard'}>
    <Card.Header variant="200">
      {typeof title === 'string' ? <Translation id={title} /> : title}
    </Card.Header>
    <Card.Body>
      <Text>
        {typeof summary === 'string' ? <Translation id={summary} /> : summary}
      </Text>
      {linkText && linkURL && <Divider margin={{ top: '05' }} />}
    </Card.Body>
    {linkText && linkURL && (
      <Card.ActionsContainer>
        <LinkStandalone
          target="_blank"
          to={linkURL}
          Icon={ChevronRightIcon}
          component={RouteLink}
          margin={{ top: '02' }}
        >
          {typeof linkText === 'string' ? (
            <Translation id={linkText} />
          ) : (
            linkText
          )}
        </LinkStandalone>
      </Card.ActionsContainer>
    )}
  </Card>
)

export default InfoCard
