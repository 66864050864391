import { Headline, Text } from '@velocity/ui'
import styled from 'styled-components'

interface StyledTextProps {
  textAlign?: 'center' | 'end' | 'left' | 'right' | 'start'
  fontWeight?: '100' | '200' | '300' | '400' | '500' | '600' | '700'
}

const StyledText = styled(Text)<StyledTextProps>`
  text-align: ${({ textAlign }) => textAlign};
  font-weight: ${({ fontWeight }) => fontWeight};
`

export const SecondaryDarkText = styled(StyledText)`
  color: var(--vel-color-secondary-500);
`

export const WhiteText = styled(StyledText)`
  color: var(--vel-color-neutral-white);
`

export const DarkGrayText = styled(StyledText)`
  color: var(--vel-color-neutral-800);
`

export const Neutral800Text = styled(StyledText)`
  color: var(--vel-color-neutral-800);
`

export const Neutral700Text = styled(StyledText)`
  color: var(--vel-color-neutral-700);
`

export const Neutral500Text = styled(StyledText)`
  color: var(--vel-color-neutral-500);
`

export const Neutral400Text = styled(StyledText)`
  color: var(--vel-color-neutral-400);
`

export const WhiteHeadline = styled(Headline)`
  color: var(--vel-color-neutral-white);
`

export const GrayMainHeading = styled(Headline)`
  color: var(--vel-color-neutral-800);
`
