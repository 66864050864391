import { Grid } from '@velocity/ui'
import React, { useEffect } from 'react'

import ContractChangeModal from 'mlp-client/src/components/contract-change-modal/ContractChangeModal'
import TechnicalError from 'mlp-client/src/components/error/TechnicalError'
import MileageUpdateModal from 'mlp-client/src/components/mileage-update-modal/MileageUpdateModal'
import RequiredMileageUpdateModal from 'mlp-client/src/components/required-mileage-update-modal/RequiredMileageUpdateModal'
import RoadsideAssistanceModal from 'mlp-client/src/components/roadside-assistance-modal/RoadsideAssistanceModal'
import TravelAbroadModal from 'mlp-client/src/components/travel-abroad-modal/TravelAbroadModal'
import useContractDetails from 'mlp-client/src/contracts/hooks/useContractDetails'
import { ContractTile } from 'mlp-client/src/contracts/types'
import DashboardContentHeader from 'mlp-client/src/dashboard/components/dashboard-content/DashboardContentHeader'
import { DashboardTilesLoader } from 'mlp-client/src/dashboard/components/loaders/DashboardTilesLoader'
import {
  DashboardContentProps,
  TilesConfig,
} from 'mlp-client/src/dashboard/types'
import { EndOfLeaseNotificationsContainer } from 'mlp-client/src/end-of-lease/components/EndOfLeaseNotificationsContainer'
import { trackServicesTileShownEvent } from 'mlp-client/src/services/analytics/analytics'
import { ServicesTileInfo } from 'mlp-client/src/services/analytics/types'
import { isServerError } from 'mlp-client/src/services/api/utils'
import { usePrevious } from 'mlp-client/src/utils/hooks/hooks'

function getServicesTileAnalyticsData(
  tilesConfig: TilesConfig,
  tiles: ContractTile[],
): ServicesTileInfo[] {
  return tiles.reduce<ServicesTileInfo[]>((acc, tile) => {
    const config = tilesConfig.find(c => c.name === tile.type)

    if (config) {
      const { componentType, title } = config

      return [
        ...acc,
        {
          component_type: componentType,
          component_title: 'services',
          component_link_type: 'tile',
          component_link_text: title,
        },
      ]
    }

    return acc
  }, [])
}

const DashboardContent: React.FC<DashboardContentProps> = ({ tilesConfig }) => {
  const { data: contractDetails, isFetching, error } = useContractDetails()
  const prevContractDetails = usePrevious(contractDetails)

  useEffect(() => {
    if (prevContractDetails == null && contractDetails?.tiles.length > 0) {
      trackDashboardTilesShown()
    }
  }, [contractDetails])

  const trackDashboardTilesShown = () => {
    const servicesTilesData: ServicesTileInfo[] = getServicesTileAnalyticsData(
      tilesConfig,
      contractDetails?.tiles,
    )

    trackServicesTileShownEvent({
      data: servicesTilesData,
    })
  }

  let children = (
    <>
      <Grid data-e2e-id="contractTiles" alignment="stretch">
        {contractDetails?.tiles.map(tile => {
          const config = tilesConfig.find(c => c.name === tile.type)

          if (!config) {
            return null
          }

          const { title, view: TileView } = config
          return (
            <Grid.Item key={title} XS={12} MD={3} LG={3}>
              <TileView key={title} {...config} />
            </Grid.Item>
          )
        })}
      </Grid>
    </>
  )

  if (isServerError(error)) {
    children = <TechnicalError />
  }

  if (isFetching) {
    children = <DashboardTilesLoader />
  }

  return (
    <>
      <DashboardContentHeader />
      {children}
      {contractDetails && (
        <>
          <EndOfLeaseNotificationsContainer />
          <TravelAbroadModal />
          <RoadsideAssistanceModal />
          <ContractChangeModal />
          <MileageUpdateModal />
          <RequiredMileageUpdateModal />
        </>
      )}
    </>
  )
}

export default DashboardContent
