import React from 'react'
import { FormattedTime } from 'react-intl'

import { DateFormat } from 'mlp-client/src/localization/components/DateFormat'
import Translation from 'mlp-client/src/localization/Translation'
import BaseTimelineCard, {
  TimelineItemProps,
} from 'mlp-client/src/timeline/components/TimelineCard/BaseTimelineCard'
import { MaintenanceTimelineItem } from 'mlp-client/src/timeline/types'

const MaintenanceTimelineCard: React.FC<
  TimelineItemProps<MaintenanceTimelineItem>
> = ({ item, config }) => (
  <BaseTimelineCard
    item={item}
    config={config}
    id={item.bookingId}
    status={item.status}
    title="myPortal.timeline.events.rmt.titles.maintenance"
  >
    <Translation
      id="myPortal.timeline.events.rmt.subtitles.maintenance"
      replace={{
        appointmentDate: () => (
          <DateFormat
            value={item.appointmentDate}
            key="appointmentDate"
            literal="-"
          />
        ),
        appointmentStartTime: () => (
          <FormattedTime
            value={item.appointmentDate}
            key="appointmentStartTime"
            hour="numeric"
            minute="numeric"
          />
        ),
        location: () => <p key="location">{item.locationName}</p>,
        serviceBooked: () => (
          <p key="serviceBooked">{item.services.join(', ')}</p>
        ),
      }}
    />
  </BaseTimelineCard>
)

export default MaintenanceTimelineCard
