import { LinkInline } from '@velocity/ui'
import { snakeCase } from 'lodash'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useCancelBookingMutation } from 'mlp-client/src/bookings/bookingsApi'
import { BookingDetailsTypes } from 'mlp-client/src/bookings/enums'
import { useToastsWithTranslation } from 'mlp-client/src/components/toasts/useToasts'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { useLocalizedRoute } from 'mlp-client/src/localization/RoutesProvider'
import Translation from 'mlp-client/src/localization/Translation'
import { trackCancelBookingButtonEvent } from 'mlp-client/src/services/analytics/analytics'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

interface AnalyticsProps {
  type: BookingDetailsTypes
  titleId: string
  cancelButtonId: string
}

const useCancelBooking = ({
  type,
  titleId,
  cancelButtonId,
}: AnalyticsProps) => {
  const accountId = useSelectedAccountId()

  const navigate = useNavigate()
  const getLocalizedRoute = useLocalizedRoute()
  const { showSuccess, showError } = useToastsWithTranslation()
  const { translate } = useTranslations()

  const timelineRoute = getLocalizedRoute('myPortal.timeline.base', {
    accountId,
  })

  const [
    cancelBooking,
    {
      isSuccess: isCancelBookingSent,
      isLoading: isCancelBookingLoading,
      isError: isCancelBookingError,
      reset: resetCancelBooking,
    },
  ] = useCancelBookingMutation()

  useEffect(() => {
    const sendCancelBookingAnalytics = () =>
      trackCancelBookingButtonEvent({
        data: {
          component_type: `timeline_${snakeCase(type)}`,
          component_title: translate(titleId),
          component_link_text: translate(cancelButtonId),
        },
      })

    if (isCancelBookingSent) {
      navigate(timelineRoute)
      sendCancelBookingAnalytics()
      resetCancelBooking()
      showSuccess(
        'myPortal.timeline.serviceMaintenanceRepair.details.deletion.title',
        'myPortal.timeline.serviceMaintenanceRepair.details.deletion.summary',
      )
    }
  }, [isCancelBookingSent])

  useEffect(() => {
    if (isCancelBookingError) {
      resetCancelBooking()
      showError(
        'myPortal.error.cancelBooking.title',
        <Translation
          id="myPortal.error.cancelBooking.content"
          replace={{
            contentUrl: () => (
              <LinkInline
                target="_blank"
                href={translate('myPortal.error.cancelBooking.contentURL')}
                key="link"
              >
                <Translation id="myPortal.error.cancelBooking.contentUrlLabel" />
              </LinkInline>
            ),
          }}
        />,
      )
    }
  }, [isCancelBookingError])

  return {
    cancelBooking,
    isCancelBookingLoading,
    isCancelBookingError,
  }
}

export default useCancelBooking
