import React from 'react'
import { compose } from 'redux'

import withMyLeaseplanAppHOC from 'mlp-client/src/components/my-leaseplan-app/withMyLeaseplanApp'
import withContractsHOC from 'mlp-client/src/contracts/components/withContracts/withContracts'
import { withRum } from 'mlp-client/src/datadog/RumRouteComponentWrapper'
import errorRedirectHOC from 'mlp-client/src/routes/errorRedirect/errorRedirect'
import protectContractTilesRouteHOC from 'mlp-client/src/routes/protectContractTilesRoute/protectContractTilesRoute'
import protectUserCountryRouteHOC from 'mlp-client/src/routes/protectUserCountryRoute/protectUserCountryRoute'
import protectUserRolesRouteHOC from 'mlp-client/src/routes/protectUserRolesRoute/protectUserRolesRoute'
import simpleRouteRedirectHOC from 'mlp-client/src/routes/simpleRouteRedirect/simpleRouteRedirect'
import universalRouteRedirectHOC from 'mlp-client/src/routes/universalRouteRedirect/universalRouteRedirect'
import { Route } from 'mlp-client/src/types'

type HocFunc<TP = {}, TP1 = TP, TS = any> = (
  component: React.ComponentType<TP>,
  ...rest: Array<TS>
) => React.ComponentType<TP1>

type ConditionFunc = (route: Route) => boolean

type HOCDataFunc<T extends {}> = (route: Route) => T

const applyHoc =
  <T,>(
    hoc: HocFunc,
    condition: ConditionFunc = () => true,
    data?: HOCDataFunc<T>,
  ) =>
  (route: Route): Route =>
    condition(route)
      ? {
          ...route,
          component: hoc(route.component, data?.(route)),
        }
      : route

const protectUserCountryRoute = applyHoc(
  protectUserCountryRouteHOC,
  route => route.isAuthProtected,
)

const simpleRouteRedirect = applyHoc(simpleRouteRedirectHOC)

const universalRouteRedirect = applyHoc(universalRouteRedirectHOC)

const errorRedirect = applyHoc(errorRedirectHOC)

const protectContractTilesRoute = applyHoc(
  protectContractTilesRouteHOC,
  route => !!route.contractTileType,
  route => ({ tileType: route.contractTileType }),
)

const protectUserRolesRoute = applyHoc(
  protectUserRolesRouteHOC,
  route => !!route.userRoles,
  route => ({ userRoles: route.userRoles }),
)

const withContracts = applyHoc(
  withContractsHOC,
  route => route.requiresContract === true,
)

const withMyLeaseplanApp = applyHoc(withMyLeaseplanAppHOC)

const withRumRoute = applyHoc(withRum)

const wrapRouteComponent = compose(
  withRumRoute,
  simpleRouteRedirect,
  errorRedirect,
  protectUserCountryRoute,
  universalRouteRedirect,
  withMyLeaseplanApp,
  withContracts,
  protectUserRolesRoute,
  protectContractTilesRoute,
)

export default wrapRouteComponent
