import { rest } from 'msw'

import userError from 'mlp-client/src/mocks/json/users/error.json'
import userError403 from 'mlp-client/src/mocks/json/users/error403.json'
import multipleAccountsUser from 'mlp-client/src/mocks/json/users/multiple-accounts-user.json'
import user from 'mlp-client/src/mocks/json/users/user.json'

export const handlers = [
  rest.get('*/api2/user', async (req, res, ctx) => {
    const params = new URLSearchParams(window.location.search)
    const scenario = params.get('user')
    const error = params.get('error')

    if (scenario) {
      if (scenario === 'multipleAccounts') {
        return res(ctx.json(multipleAccountsUser))
      }

      if (error === '403') {
        return res(ctx.status(403), ctx.json(userError403))
      }

      if (error) {
        return res(ctx.status(500), ctx.json(userError))
      }
    }

    return res(ctx.json(user))
  }),
]
