import { Headline } from '@velocity/ui'
import styled from 'styled-components'

export const DocumentsContainerStyled = styled.div`
  color: var(--vel-color-primary-500);
  display: flex;
  flex-direction: column;
  gap: var(--vel-fixed-spacing-03);
  padding-top: var(--vel-fixed-spacing-03);
  padding-bottom: var(--vel-fixed-spacing-05);
`

export const BottomContainerStyled = styled.div`
  padding-top: var(--vel-fixed-spacing-06);
  padding-bottom: var(--vel-fixed-spacing-03);
`

export const HeadlineStyled = styled(Headline)`
  padding-top: var(--vel-fixed-spacing-02);
`
