import React, { ReactElement } from 'react'
import { FormattedNumber, FormattedNumberParts } from 'react-intl'

import { useCurrentContract } from 'mlp-client/src/contracts/hooks/useContracts'

interface Props {
  value: number
  mileageUnit?: string
  unitDisplay?: 'short' | 'long' | 'narrow'
  children?: (parts: Intl.NumberFormatPart[]) => ReactElement
}

export const Mileage: React.FC<Props> = ({
  value,
  mileageUnit,
  unitDisplay = 'short',
  children,
}) => {
  const contract = useCurrentContract()
  const unit = mileageUnit || contract?.vehicle?.unit

  if (!unit) {
    return null
  }

  const props = {
    value,
    unit,
    unitDisplay,
    style: 'unit' as const,
  }

  return children ? (
    <FormattedNumberParts {...props}>{children}</FormattedNumberParts>
  ) : (
    <FormattedNumber {...props} />
  )
}

export const MileageUnit: React.FC<Props> = ({ children, ...props }) => (
  <Mileage {...props}>
    {parts => <>{parts.find(part => part.type === 'unit').value}</>}
  </Mileage>
)
