import { Documents } from 'mlp-client/src/documents/types'
import { api } from 'mlp-client/src/services/api/api'

interface DocumentsParams {
  accountId: string
  contractId: string
}

export interface DocumentDownloadParams {
  accountId: string
  entity: string
  application: string
  gdid: string
}

const documentsApi = api.injectEndpoints({
  endpoints: builder => ({
    getDocuments: builder.query<Documents, DocumentsParams>({
      query: ({ accountId, contractId }) => ({
        url: `/documents/${accountId}/${contractId}`,
        method: 'GET',
      }),
      providesTags: ['Documents'],
    }),
    getDownloadDocument: builder.query<Blob, DocumentDownloadParams>({
      query: ({ accountId, entity, application, gdid }) => ({
        url: `/documents/${accountId}/${entity}/${application}/${gdid}`,
        method: 'GET',
        headers: {
          Accept: 'application/octet-stream',
          responseType: 'arraybuffer',
        },
        responseHandler: async (response: Body) => await response.blob(),
        cache: 'no-cache',
      }),
      providesTags: ['DocumentDownload'],
    }),
  }),
})

export const { useGetDocumentsQuery, useLazyGetDownloadDocumentQuery } =
  documentsApi
