import { Headline, Text } from '@velocity/ui'
import React from 'react'

import { SecondaryDarkText } from 'mlp-client/src/components/styled/TextStyle'
import Translation from 'mlp-client/src/localization/Translation'
import { useUser } from 'mlp-client/src/user/hooks/useUser'
import MaskedData from 'mlp-client/src/utils/masks/MaskedData'
import {
  SubtitleContainerStyled,
  TitleContainerStyled,
} from 'mlp-client/src/vehicles/components/vehicles-overview-table/VehiclesOverviewTable.styled'

export const VehiclesOverviewTableTitle: React.FC = () => {
  const { data: user } = useUser()

  return (
    <div data-e2e-id="vehiclesTableTitle">
      <TitleContainerStyled>
        <Headline component="h1" variant="400" withMarginBottom={true}>
          <Translation
            replace={{
              name: () => (
                <MaskedData type="User Name" key="userName">
                  {user?.firstName}
                </MaskedData>
              ),
            }}
            id="myPortal.dashboard.welcome"
          />
        </Headline>
        <Text data-e2e-id="vehicleTableSubheader">
          <Translation id="myPortal.multipleCars.titles.subtitle" />
        </Text>
      </TitleContainerStyled>
      <SubtitleContainerStyled>
        <SecondaryDarkText variant="500" bold={true}>
          <Translation id="myPortal.multipleCars.titles.featureTitle" />
        </SecondaryDarkText>
      </SubtitleContainerStyled>
    </div>
  )
}
