import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DEFAULT_NUMBER_OF_ITEMS_PER_PAGE } from 'mlp-client/src/constants'
import { TimelineState } from 'mlp-client/src/timeline/types'
import { AppState } from 'mlp-client/src/types'

export const initialState: TimelineState = {
  selectedVehicles: [],
  pagination: {
    currentPage: 0,
    itemsPerPage:
      Number(localStorage.getItem('itemsPerPage')) ||
      DEFAULT_NUMBER_OF_ITEMS_PER_PAGE,
  },
}

const timelineSlice = createSlice({
  name: '@@timeline',
  initialState,
  reducers: {
    setSelectedVehicles: (state, action: PayloadAction<string[]>) => {
      state.selectedVehicles = action.payload
    },
    setPaginationItemsPerPage: (state, action: PayloadAction<number>) => {
      state.pagination = {
        itemsPerPage: action.payload,
        currentPage: 0,
      }
    },
    setPaginationPage: (state, action: PayloadAction<number>) => {
      state.pagination.currentPage = action.payload
    },
  },
})

export const getSelectedVehicles = (state: AppState) =>
  state.timeline.selectedVehicles

export const getPagination = (state: AppState) => state.timeline.pagination

export const {
  setSelectedVehicles,
  setPaginationItemsPerPage,
  setPaginationPage,
} = timelineSlice.actions

export default timelineSlice.reducer
