import { BookingDetails } from 'mlp-client/src/bookings/types'
import { api } from 'mlp-client/src/services/api/api'
import { GenericResponse } from 'mlp-client/src/services/api/types'

interface BookingParams {
  accountId: string
  bookingId: string
}

const bookingsApi = api.injectEndpoints({
  endpoints: builder => ({
    getBooking: builder.query<BookingDetails, BookingParams>({
      query: ({ accountId, bookingId }) => ({
        url: `/bookings/${accountId}/${bookingId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: ['Booking'],
    }),
    cancelBooking: builder.mutation<GenericResponse, BookingParams>({
      query: ({ accountId, bookingId }) => ({
        url: `/bookings/${accountId}/${bookingId}`,
        method: 'PUT',
        body: {
          status: 'Cancelled',
          statusReason: 'User cancelled through timeline event details page',
        },
      }),
    }),
  }),
})

export const { useGetBookingQuery, useCancelBookingMutation } = bookingsApi
