import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'

import { StatusCode } from 'mlp-client/src/services/api/types'

export type ApiError = SerializedError | FetchBaseQueryError

const isStatusError = (status: StatusCode) => (error?: ApiError) => {
  return getStatusCode(error) === status
}

export const getStatusCode = (error?: ApiError) => {
  if (!error || !('status' in error)) return false

  return ((Object.values(StatusCode).includes(error.status) && error.status) ||
    ('originalStatus' in error && error.originalStatus)) as StatusCode
}

export const is404Error = isStatusError(StatusCode.NOT_FOUND)

export const is403Error = isStatusError(StatusCode.FORBIDDEN)

export const isServerError = isStatusError(StatusCode.SERVER_ERROR)

export const isBadRequestError = isStatusError(StatusCode.BAD_REQUEST)
