import React from 'react'
import { Navigate } from 'react-router-dom'

import { useLocalizedRoute } from 'mlp-client/src/localization/RoutesProvider'

export interface Props {
  to: string
  params?: string | GenericObject
}

const TranslatedRedirect: React.FC<Props> = ({ to, params }: Props) => {
  const getLocalizedRoute = useLocalizedRoute()
  const toRoute = getLocalizedRoute(to, params, false)

  if (!toRoute) {
    return null
  }

  return <Navigate to={toRoute} />
}

export default TranslatedRedirect
