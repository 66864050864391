import { camelCase } from 'lodash'
import React from 'react'

import { ServiceType } from 'mlp-client/src/contracts/enums'
import Translation from 'mlp-client/src/localization/Translation'

interface ContractServiceProps {
  service: ServiceType
}

const ContractService: React.FC<ContractServiceProps> = ({ service }) => (
  <Translation id={`myPortal.universalServiceCodes.${camelCase(service)}`} />
)

export default ContractService
