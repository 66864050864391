import { E2EBookingLinkParams, ProfileManagementLinkParams } from './types'

import { config } from 'mlp-client/src/app-config'
import { UrlPath } from 'mlp-client/src/localization/utils'

export const generateE2EBookingLink = (
  params: E2EBookingLinkParams,
  locale: string,
) => {
  const url = new UrlPath(config.E2E_BOOKING_API)

  Object.entries(params).forEach(([name, value]) =>
    url.addQueryParam(name, value),
  )

  url.addQueryParam('closeUrl', `${window.location.origin}/${locale}/my`)

  return url.toString()
}

export const generateProfileManagementLink = (
  params: ProfileManagementLinkParams,
) => {
  const url = new UrlPath(config.PROFILE_MANAGEMENT_API)

  Object.entries(params).forEach(([name, value]) =>
    url.addQueryParam(name, value),
  )

  return url.toString()
}
