import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import BaseTimelineCard, {
  TimelineItemProps,
} from 'mlp-client/src/timeline/components/TimelineCard/BaseTimelineCard'
import { SecondaryRentalTimelineItem } from 'mlp-client/src/timeline/types'

const SecondaryRentalTimelineCard: React.FC<
  TimelineItemProps<SecondaryRentalTimelineItem>
> = ({ item, config }) => (
  <BaseTimelineCard
    item={item}
    config={config}
    status={item.status}
    title={
      <Translation id="myPortal.timeline.events.mlc.titles.secondaryRental" />
    }
  />
)

export default SecondaryRentalTimelineCard
