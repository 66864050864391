import type { RumPublicApi } from '@datadog/browser-rum-core'
import React, { useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { getGlobalObject } from 'mlp-client/src/datadog/getGlobalObject'
import { useCurrentPath } from 'mlp-client/src/localization/RoutesProvider'

declare global {
  interface Window {
    DD_RUM: RumPublicApi
  }
}

export const withRum = <T extends {}>(Component: React.ComponentType<T>) =>
  function RumView(props: T) {
    const currentPath = useCurrentPath()
    const location = useLocation()
    useRef(
      (() => {
        if (!Component) {
          return
        }

        const globalObj = getGlobalObject<Window>()

        if (!globalObj.DD_RUM) {
          // eslint-disable-next-line no-console
          console.warn(
            '@datadog/rum-react-integration: Datadog RUM SDK is not initialized.',
          )

          return
        }

        if (!globalObj.DD_RUM?.startView) {
          // eslint-disable-next-line no-console
          console.warn(
            '@datadog/rum-react-integration: Manual tracking not supported. Try updating the Datadog RUM SDK.',
          )

          return
        }

        globalObj.DD_RUM.startView(currentPath || location.pathname)
      })(),
    )

    if (Component) {
      return <Component {...props} />
    }
    return null
  }
