import { createToast, ToastKind } from '@velocity/ui'
import React, { ReactNode } from 'react'

export type ToastDescription = string | ReactNode

const renderDescription = (
  kind: string,
  description?: ToastDescription,
): React.ReactNode => (
  <div data-e2e-id={`toastMessage${kind}`}>{description || ''}</div>
)

export const showToast = (
  kind: ToastKind,
  title: string,
  description?: ToastDescription,
) =>
  createToast({
    kind,
    title,
    description: renderDescription(
      `${kind[0].toUpperCase()}${kind.slice(1)}`,
      description,
    ),
    dismissible: true,
  })

export const showSuccessToast = (
  title: string,
  description?: ToastDescription,
) => showToast('success', title, description)

export const showWarningToast = (
  title: string,
  description?: ToastDescription,
) => showToast('warning', title, description)

export const showErrorToast = (title: string, description?: ToastDescription) =>
  showToast('error', title, description)

export const showInfoToast = (title: string, description?: ToastDescription) =>
  showToast('information', title, description)
