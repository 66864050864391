import {
  AssessmentDetails,
  GetAssessmentDetailsParams,
  SendAssessmentReportParams,
} from 'mlp-client/src/assessments/types'
import { api } from 'mlp-client/src/services/api/api'

const assessmentsApi = api.injectEndpoints({
  endpoints: builder => ({
    getAssessments: builder.query<
      AssessmentDetails,
      GetAssessmentDetailsParams
    >({
      query: ({ accountId, contractId, assessmentId }) => ({
        url: `/assessments/${accountId}/${assessmentId}/${contractId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: ['Assessment'],
    }),
    sendAssessmentReport: builder.mutation<
      AssessmentDetails,
      SendAssessmentReportParams
    >({
      query: ({ accountId, assessmentId, body }) => ({
        url: `/assessments/${accountId}/${assessmentId}/challenges`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useGetAssessmentsQuery, useSendAssessmentReportMutation } =
  assessmentsApi
