import { SkeletonLoader, useLoading } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import { TableStyled, CellStyled } from 'mlp-client/src/components/table'
import { Show } from 'mlp-client/src/components/utils/Show'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { VehiclesOverviewTableTitle } from 'mlp-client/src/vehicles/components/vehicles-overview-table/VehiclesOverviewTableTitle'

const Table = styled(TableStyled)`
  tbody {
    cursor: default;
  }

  tbody tr:nth-child(even),
  tbody tr:nth-child(odd) {
    opacity: 1;
    background-color: var(--vel-color-neutral-white);
    border-bottom: 1px solid transparent;
  }
`

const HeaderCellStyled = styled(CellStyled)`
  width: 40%;
`

const VehiclesLoader: React.FC = () => {
  const { translate } = useTranslations()

  const metaTitle = translate('myPortal.dashboard.title')
  const metaDescription = translate('myPortal.dashboard.subtitle')
  const loadingActiveMessage = translate('myPortal.loader.contracts')

  const { messageElement } = useLoading({
    initialLoading: true,
    giveInitialMessage: true,
    loadingActiveMessage,
    loadingDoneMessage: null,
  })

  const mobileLoader = (
    <SkeletonLoader
      on="alt"
      text={{
        variant: '300',
      }}
    ></SkeletonLoader>
  )

  return (
    <MyleaseplanPage metaTitle={metaTitle} metaDescription={metaDescription}>
      <div data-e2e-id="vehiclesLoader">
        {messageElement}
        <VehiclesOverviewTableTitle />
        <Show until="MD">
          {Array.from({ length: 6 }).map((_, tableIndex) => (
            <Table
              key={`table${tableIndex}`}
              data-e2e-id="vehiclesLoaderMobileTable"
            >
              <tbody>
                {Array.from({ length: 6 }).map((_, index) => (
                  <tr key={`row${tableIndex}${index}`}>
                    <HeaderCellStyled>{mobileLoader}</HeaderCellStyled>
                    <CellStyled>{mobileLoader}</CellStyled>
                  </tr>
                ))}
              </tbody>
            </Table>
          ))}
        </Show>

        <Show from="MD">
          <Table data-e2e-id="vehiclesLoaderTable">
            <tbody>
              {Array.from({ length: 6 }).map((_, rowIndex) => (
                <tr key={`row${rowIndex}`}>
                  {Array.from({ length: 6 }).map((_, index) => (
                    <CellStyled key={`cell${rowIndex}${index}`}>
                      <SkeletonLoader
                        on="alt"
                        text={{
                          variant: '500',
                        }}
                      ></SkeletonLoader>
                    </CellStyled>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </Show>
      </div>
    </MyleaseplanPage>
  )
}

export default VehiclesLoader
