import { Button, Card, Grid, LinkInline, Text } from '@velocity/ui'
import React from 'react'
import { useParams } from 'react-router-dom'

import { useGetAssessmentsQuery } from 'mlp-client/src/assessments/assessmentsApi'
import {
  StyledHeadline,
  StyledMainHeadline,
} from 'mlp-client/src/assessments/components/AssessmentDetailsPage.styled'
import { ReportedDamages } from 'mlp-client/src/assessments/components/ReportedDamages/ReportedDamages'
import MyleaseplanPage from 'mlp-client/src/components/layout/my-leaseplan-page/MyleaseplanPage'
import Loader from 'mlp-client/src/components/loader/Loader'
import { useToastsWithTranslation } from 'mlp-client/src/components/toasts/useToasts'
import { useLazyGetDownloadDocumentQuery } from 'mlp-client/src/documents/documentsApi'
import { PageTitle } from 'mlp-client/src/enums'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/types'
import { DateFormat } from 'mlp-client/src/localization/components/DateFormat'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'
import { trackAssessmentDetailsDownloadReportEvent } from 'mlp-client/src/services/analytics/analytics'
import TimelineBreadcrumbs from 'mlp-client/src/timeline/components/TimelineBreadcrumbs.tsx/TimelineBreadcrumbs'
import TimelineEventStatus from 'mlp-client/src/timeline/components/TimelineEventStatus/TimelineEventStatus'
import TimelineTechnicalError from 'mlp-client/src/timeline/components/TimelineTechnicalError/TimelineTechnicalError'
import { FairWearAndTearEventStatuses } from 'mlp-client/src/timeline/enums'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'
import { saveFile } from 'mlp-client/src/utils'

const AssessmentDetailsPage: React.FC = () => {
  const { translate } = useTranslations()
  const accountId = useSelectedAccountId()
  const params = useParams<{ contractId: string; id: string }>()
  const { id: assessmentId, contractId } = params
  const {
    data: assessmentDetails,
    isFetching,
    isSuccess,
    error,
  } = useGetAssessmentsQuery({ accountId, assessmentId, contractId })

  const [getDocument, { isLoading: isDocumentDownloading }] =
    useLazyGetDownloadDocumentQuery()

  const showReportedDamagesSection =
    assessmentDetails?.status ===
    FairWearAndTearEventStatuses.ASSESSMENT_CREATED
  const { showError } = useToastsWithTranslation()

  const pageTitle = PageTitle.ASSESSMENT_DETAILS

  const reportSectionTitle = 'myPortal.timeline.fairWearTear.report.title'
  const reportButtonText =
    'myPortal.timeline.fairWearTear.report.downloadButton'

  const onDownloadReportButton = async () => {
    const {
      data: documentBlob,
      isError: isDocumentDownloadError,
      isSuccess: isDocumentDownloaded,
    } = await getDocument({
      accountId,
      entity: assessmentDetails.entity,
      application: assessmentDetails.application,
      gdid: assessmentDetails.gdid,
    })

    if (isDocumentDownloadError) {
      showError(
        'myPortal.timeline.fairWearTear.error.title',
        <Translation
          id="myPortal.timeline.fairWearTear.error.summary"
          replace={{
            contentUrl: () => (
              <LinkInline
                target="_blank"
                href={translate('myPortal.timeline.fairWearTear.error.url')}
                key="link"
              >
                <Translation id="myPortal.timeline.fairWearTear.error.contentUrlLabel" />
              </LinkInline>
            ),
          }}
        />,
      )
    }

    if (isDocumentDownloaded) {
      saveFile(documentBlob, assessmentDetails.gdid, 'application/pdf')

      trackAssessmentDetailsDownloadReportEvent({
        data: {
          component_title: translate(reportSectionTitle),
          component_link_text: translate(reportButtonText),
        },
      })
    }
  }

  const reportSummary = translate(
    'myPortal.timeline.fairWearTear.report.summary',
  )
    .split(/\s{2,}/g)
    .map(text => (
      <React.Fragment key={text}>
        <Text component="p">{text}</Text>
        <br />
      </React.Fragment>
    ))

  return (
    <MyleaseplanPage metaTitle={pageTitle}>
      <TimelineBreadcrumbs title="myPortal.timeline.fairWearTear.title" />
      <StyledMainHeadline component="h1" variant="400">
        <Translation id="myPortal.timeline.fairWearTear.title" />
      </StyledMainHeadline>
      {isFetching && <Loader fixed={true} />}
      {!isFetching && isSuccess && (
        <Grid>
          <Grid.Item XS={4} MD={6} LG={8}>
            {assessmentDetails && (
              <>
                <Card data-e2e-id="downloadReportSection">
                  <Card.Body>
                    <StyledHeadline component="h2" variant="400">
                      <Translation id={reportSectionTitle} />
                    </StyledHeadline>
                    {reportSummary}
                    <br />
                    <Grid alignment="center" columns={6} gutter={{ x: '0' }}>
                      <Grid.Item MD={3} LG={2}>
                        <Text variant="300" bold={true}>
                          {contractId}.pdf
                        </Text>
                      </Grid.Item>
                      <Grid.Item MD={3} LG={2}>
                        <Text variant="300">
                          <Translation
                            id="myPortal.timeline.fairWearTear.report.objection"
                            replace={{
                              date: () => (
                                <DateFormat
                                  value={assessmentDetails.creationDate}
                                  month="numeric"
                                  key="creationDate"
                                />
                              ),
                            }}
                          />
                        </Text>
                      </Grid.Item>
                      <Grid.Item LG={2}>
                        <div>
                          <TimelineEventStatus
                            status={assessmentDetails.status}
                          />
                        </div>
                      </Grid.Item>
                    </Grid>
                    <br />
                    <Button
                      onClick={onDownloadReportButton}
                      data-e2e-id="downloadReportButton"
                      variant="primary-outlined"
                      size="m"
                      loading={isDocumentDownloading}
                      disabled={isDocumentDownloading}
                    >
                      {translate(reportButtonText)}
                    </Button>
                  </Card.Body>
                </Card>
                <br />
                {showReportedDamagesSection && (
                  <ReportedDamages assessmentDetails={assessmentDetails} />
                )}
              </>
            )}
          </Grid.Item>
          <Grid.Item XS={4} MD={6} LG={4}>
            <FAQ faqKey={FAQKeys.FAIR_WEAR_TEAR} />
          </Grid.Item>
        </Grid>
      )}
      {!isFetching && error && <TimelineTechnicalError />}
    </MyleaseplanPage>
  )
}

export default AssessmentDetailsPage
