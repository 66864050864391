import React from 'react'

import {
  CardStyled,
  ChevronRightIconStyled,
  MobileTileContainer,
  StyledLink,
  TextStyled,
} from 'mlp-client/src/dashboard/components/tiles/DashboardTile.styled'
import { TileProps } from 'mlp-client/src/dashboard/types'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { addTrailingSlash } from 'mlp-client/src/localization/utils'
import { trackServicesTileClickEvent } from 'mlp-client/src/services/analytics/analytics'

const MobileTile: React.FC<TileProps> = ({
  title,
  componentType,
  to,
  toParams,
  isRelativeLink,
}) => {
  const { translate } = useTranslations()

  const sendAnalytics = (toLink: string) =>
    trackServicesTileClickEvent({
      data: {
        component_type: componentType,
        component_link_text: translate(title),
        component_link_url: toLink,
      },
    })

  return (
    <CardStyled data-e2e-id="mobileTilePaper">
      <StyledLink
        data-e2e-id="mobileTileLink"
        sendAnalytics={sendAnalytics}
        to={
          isRelativeLink ? addTrailingSlash(window.location.pathname) + to : to
        }
        params={toParams}
      >
        <MobileTileContainer data-e2e-id="mobileTileContent">
          <TextStyled bold={true}>{title}</TextStyled>
          <ChevronRightIconStyled size="xs" />
        </MobileTileContainer>
      </StyledLink>
    </CardStyled>
  )
}

export default MobileTile
