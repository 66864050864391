import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppState } from 'mlp-client/src/types'

export interface AuthState {
  nextRoute: string | null
}

export const initialState: AuthState = {
  nextRoute: null,
}

const authSlice = createSlice({
  name: '@@auth',
  initialState,
  reducers: {
    setNextRoute: (state, action: PayloadAction<string>) => {
      state.nextRoute = action.payload
    },
    clearNextRoute: state => {
      state.nextRoute = initialState.nextRoute
    },
  },
})

export const getNextRoute = (state: AppState): string => state.auth.nextRoute

export const { setNextRoute, clearNextRoute } = authSlice.actions

export default authSlice.reducer
