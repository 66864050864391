import { AppConfiguration } from './types'

import {
  E2E_BOOKING_API as E2E_BOOKING_API_BUILD_ENV,
  TYRES_SERVICE_URL as TYRES_SERVICE_URL_BUILD_ENV,
  PROFILE_MANAGEMENT_API as PROFILE_MANAGEMENT_API_BUILD_ENV,
  FAQ_API as FAQ_API_BUILD_ENV,
  DD_APPLICATION_ID as DD_APPLICATION_ID_BUILD_ENV,
  DD_CLIENT_TOKEN as DD_CLIENT_TOKEN_BUILD_ENV,
  DD_SITE as DD_SITE_BUILD_ENV,
  DD_SERVICE as DD_SERVICE_BUILD_ENV,
  DD_ENV as DD_ENV_BUILD_ENV,
  ANDROID_INSTALL_APP_URL as ANDROID_INSTALL_APP_URL_ENV,
  IOS_INSTALL_APP_URL as IOS_INSTALL_APP_URL_ENV,
} from 'mlp-client/src/config/buildTimeEnv'
import { Locales } from 'mlp-client/src/localization/enums'

export const AUTH_ROUTES = [
  '/auth/login-redirect/',
  '/auth/logout-redirect/',
  '/auth/redirect-to-correct-auth0-locale/',
]

// TODO: temporary copy of `ClientEnv` and `deserializeClientEnv`
// from apps/mlp-server/src/universal/clientEnv.ts until MLP sources are moved to the new location.
// Remove them from here after the move.
interface ClientEnv {
  IDENTITY_API: string
  IDENTITY_AUDIENCE: string
  IDENTITY_CLIENT_ID: string
  ANDROID_INSTALL_APP_URL: string
  IOS_INSTALL_APP_URL: string
  GOOGLE_MAPS_API_KEY?: string
  E2E_BOOKING_API: string
  TYRES_SERVICE_URL: string
  PROFILE_MANAGEMENT_API: string
  FAQ_API: string
  DD_APPLICATION_ID: string
  DD_CLIENT_TOKEN: string
  DD_SITE: string
  DD_SERVICE: string
  DD_ENV: string
}

const deserializeClientEnv = (serializedClientEnv: string): ClientEnv =>
  JSON.parse(serializedClientEnv)

const {
  IDENTITY_API,
  IDENTITY_CLIENT_ID,
  IDENTITY_AUDIENCE,
  ANDROID_INSTALL_APP_URL,
  IOS_INSTALL_APP_URL,
  GOOGLE_MAPS_API_KEY,
  E2E_BOOKING_API,
  TYRES_SERVICE_URL,
  PROFILE_MANAGEMENT_API,
  FAQ_API,
  DD_APPLICATION_ID,
  DD_CLIENT_TOKEN,
  DD_SITE,
  DD_SERVICE,
  DD_ENV,
} = deserializeClientEnv(window.__CLIENT_RUNTIME_CONFIG__ ?? '{}')

const origin = window.location.origin

export const config: Readonly<AppConfiguration> = {
  IDENTITY_API,
  IDENTITY_CLIENT_ID,
  IDENTITY_AUDIENCE,
  ANDROID_INSTALL_APP_URL:
    ANDROID_INSTALL_APP_URL_ENV || ANDROID_INSTALL_APP_URL,
  IOS_INSTALL_APP_URL: IOS_INSTALL_APP_URL_ENV || IOS_INSTALL_APP_URL,
  DEFAULT_LOCALE: Locales.EN_GB,
  DEFAULT_DATE_FORMAT: 'DD-MM-YYYY',
  API_BASE_URL: `${origin}/api2`,
  GOOGLE_MAPS_API_KEY,
  E2E_BOOKING_API: E2E_BOOKING_API_BUILD_ENV || E2E_BOOKING_API,
  TYRES_SERVICE_URL: TYRES_SERVICE_URL_BUILD_ENV || TYRES_SERVICE_URL,
  PROFILE_MANAGEMENT_API:
    PROFILE_MANAGEMENT_API_BUILD_ENV || PROFILE_MANAGEMENT_API,
  FAQ_API: FAQ_API_BUILD_ENV || FAQ_API,
  DD_APPLICATION_ID: DD_APPLICATION_ID_BUILD_ENV || DD_APPLICATION_ID,
  DD_CLIENT_TOKEN: DD_CLIENT_TOKEN_BUILD_ENV || DD_CLIENT_TOKEN,
  DD_SITE: DD_SITE_BUILD_ENV || DD_SITE,
  DD_SERVICE: DD_SERVICE_BUILD_ENV || DD_SERVICE,
  DD_ENV: DD_ENV_BUILD_ENV || DD_ENV,
}
