import { handlers as assessmentsHandlers } from 'mlp-client/src/mocks/handlers/assessments'
import { handlers as bookingsHandlers } from 'mlp-client/src/mocks/handlers/bookings'
import { handlers as contractsHandlers } from 'mlp-client/src/mocks/handlers/contracts'
import { handlers as documentsHandlers } from 'mlp-client/src/mocks/handlers/documents'
import { handlers as invoicesHandlers } from 'mlp-client/src/mocks/handlers/invoices'
import { handlers as mileageHandlers } from 'mlp-client/src/mocks/handlers/mileage'
import { handlers as timelineHandlers } from 'mlp-client/src/mocks/handlers/timeline'
import { handlers as usersHandlers } from 'mlp-client/src/mocks/handlers/users'

export const handlers = [
  ...assessmentsHandlers,
  ...bookingsHandlers,
  ...contractsHandlers,
  ...documentsHandlers,
  ...invoicesHandlers,
  ...mileageHandlers,
  ...timelineHandlers,
  ...usersHandlers,
]
