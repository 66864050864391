import { IntlShape } from 'react-intl'

import { GroupedInvoices, Invoice } from 'mlp-client/src/invoices/types'
import { formatDate } from 'mlp-client/src/utils'

const getGroupKey = (
  invoiceCreationDate: string,
  formatDate: IntlShape['formatDate'],
  translate: (id: string) => string,
  currentMonthKey: string,
) => {
  const currentMonthYear = formatDate(Date.now(), {
    month: 'long',
    year: 'numeric',
  })

  const currentYear = formatDate(Date.now(), {
    year: 'numeric',
  })

  const invoiceYear = formatDate(invoiceCreationDate, {
    year: 'numeric',
  })

  const invoiceMonthYear = formatDate(invoiceCreationDate, {
    month: 'long',
    year: 'numeric',
  })

  if (invoiceMonthYear === currentMonthYear) {
    return translate(currentMonthKey)
  }

  if (invoiceYear === currentYear) {
    return formatDate(invoiceCreationDate, {
      month: 'long',
    })
  }

  return invoiceMonthYear
}

export const groupInvoicesByMonth = (
  invoices: Invoice[],
  formatDate: IntlShape['formatDate'],
  translate: (id: string) => string,
  currentMonthKey: string,
) =>
  invoices.reduce<GroupedInvoices>((acc, invoice) => {
    if (invoice) {
      const { invoiceCreationDate } = invoice
      const groupKey = getGroupKey(
        invoiceCreationDate,
        formatDate,
        translate,
        currentMonthKey,
      )
      const invoiceGroup = acc.find(group => group.key === groupKey)

      if (invoiceGroup) {
        invoiceGroup.invoices.push(invoice)
      } else {
        acc.push({ key: groupKey, invoices: [invoice] })
      }
    }

    return acc
  }, [])

export const formatDateString = (input = '', format = 'DD-MM-YYYY') => {
  const date = input === '' ? new Date(Date.now()) : new Date(input)

  return formatDate(date, format)
}

export const toInvoiceList = (invoice?: Invoice) => (invoice ? [invoice] : [])
