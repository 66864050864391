import { Contracts, ContractDetails } from 'mlp-client/src/contracts/types'
import { mapContracts } from 'mlp-client/src/contracts/utils'
import { api } from 'mlp-client/src/services/api/api'

interface ContractsParams {
  accountId: string
}

interface ContractDetailsParams {
  accountId: string
  contractId: string
}

interface UpdateMileageParams {
  accountId: string
  mileage: number
  unit: string
  leaseObjectId: string
  contractId: string
}

interface UpdateMileageResponse {
  mileage: number
}

export const contractsApi = api.injectEndpoints({
  endpoints: builder => ({
    getContracts: builder.query<Contracts, ContractsParams>({
      query: ({ accountId }) => ({
        url: `/contracts/${accountId}`,
        method: 'GET',
      }),
      providesTags: ['Contracts'],
      transformResponse: (contracts: Contracts) => mapContracts(contracts),
    }),
    getContractDetails: builder.query<ContractDetails, ContractDetailsParams>({
      query: ({ accountId, contractId }) => ({
        url: `/contracts/${accountId}/${contractId}`,
        method: 'GET',
      }),
      providesTags: ['ContractDetails'],
    }),
    updateMileage: builder.mutation<UpdateMileageResponse, UpdateMileageParams>(
      {
        query: ({ accountId, mileage, unit, leaseObjectId }) => ({
          url: `/cases/${accountId}/mileage`,
          method: 'POST',
          body: {
            leaseObjectId,
            mileage,
            unit,
          },
        }),
        async onQueryStarted(
          { leaseObjectId, accountId, contractId },
          { dispatch, queryFulfilled },
        ) {
          try {
            const {
              data: { mileage },
            } = await queryFulfilled
            dispatch(
              contractsApi.util.updateQueryData(
                'getContracts',
                { accountId },
                draftContracts => {
                  const contract = draftContracts.find(
                    contract => contract.leaseObjectId === leaseObjectId,
                  )
                  Object.assign(contract, {
                    vehicle: {
                      ...contract.vehicle,
                      lastKnownMileage: mileage,
                    },
                  })
                },
              ),
            )

            dispatch(
              contractsApi.util.updateQueryData(
                'getContractDetails',
                { accountId, contractId },
                draftContract => {
                  Object.assign(draftContract, {
                    vehicle: {
                      ...draftContract.vehicle,
                      lastKnownMileage: mileage,
                    },
                  })
                },
              ),
            )
          } catch {}
        },
      },
    ),
  }),
})

export const {
  useGetContractsQuery,
  useGetContractDetailsQuery,
  useUpdateMileageMutation,
} = contractsApi
