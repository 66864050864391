import { Card, Grid, Headline } from '@velocity/ui'
import React from 'react'

import BookingStatusNotificationBanner from './BookingStatusNotificationBanner'

import BookingMaintenanceMap from 'mlp-client/src/bookings/components/BookingMaintenanceMap'
import BookingNotFound from 'mlp-client/src/bookings/components/BookingNotFound/BookingNotFound'
import { BookingTable } from 'mlp-client/src/bookings/components/BookingTable'
import {
  BookingDetailsTypes,
  BookingEventStatuses,
} from 'mlp-client/src/bookings/enums'
import useBookingDetails from 'mlp-client/src/bookings/hooks/useBookingDetails'
import { TableSection } from 'mlp-client/src/bookings/types'
import MyleaseplanPage from 'mlp-client/src/components/layout/my-leaseplan-page/MyleaseplanPage'
import Loader from 'mlp-client/src/components/loader/Loader'
import { PageTitle } from 'mlp-client/src/enums'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/types'
import { useAddressFormatter } from 'mlp-client/src/localization/LocaleProvider'
import Translation from 'mlp-client/src/localization/Translation'
import { is404Error } from 'mlp-client/src/services/api/utils'
import TimelineBreadcrumbs from 'mlp-client/src/timeline/components/TimelineBreadcrumbs.tsx/TimelineBreadcrumbs'
import TimelineTechnicalError from 'mlp-client/src/timeline/components/TimelineTechnicalError/TimelineTechnicalError'
import { fromUpperSnakeToCamelCase } from 'mlp-client/src/utils/formatters/formatters'

interface Props {
  Buttons: React.FC
  metaTitle: PageTitle
  headerTitle: string
  dataE2eId: string
  faqKey: FAQKeys
  bookingTablePropertiesConfig: TableSection[]
}

const BookingDetailsPage: React.FC<Props> = ({
  Buttons,
  faqKey,
  metaTitle,
  headerTitle,
  dataE2eId,
  bookingTablePropertiesConfig,
}) => {
  const formatAddress = useAddressFormatter()

  const { data: bookingDetails, isFetching, error } = useBookingDetails()

  const showButtons = () =>
    bookingDetails?.status === BookingEventStatuses.CONFIRMED ||
    bookingDetails?.status === BookingEventStatuses.PENDING_CONFIRMATION

  const showMaintenanceMap =
    bookingDetails?.bookingDetailsType === BookingDetailsTypes.MAINTENANCE

  return (
    <MyleaseplanPage metaTitle={metaTitle}>
      <TimelineBreadcrumbs title={headerTitle} />

      <Headline
        component="h1"
        variant="500"
        layoutMargin={{ top: '02', bottom: '05' }}
      >
        <Translation id={headerTitle} />
      </Headline>

      {!isFetching && is404Error(error) && <BookingNotFound />}
      {!isFetching && error && !is404Error(error) && <TimelineTechnicalError />}

      {!error && (
        <Grid>
          <Grid.Item LG={8} data-e2e-id={`${dataE2eId}Details`}>
            {isFetching && <Loader />}
            {bookingDetails && (
              <>
                <BookingStatusNotificationBanner
                  status={bookingDetails.status}
                />
                {bookingTablePropertiesConfig.map((section: TableSection) => (
                  <Card
                    layoutMargin={{ bottom: '03' }}
                    key={section.name}
                    data-e2e-id={fromUpperSnakeToCamelCase(section.name)}
                  >
                    <Card.Header variant="400">
                      <Translation id={section.title} />
                    </Card.Header>
                    <Card.Body>
                      <BookingTable
                        properties={section
                          .getProperties(bookingDetails, formatAddress)
                          .filter(p => p)}
                      />
                      {section.hasMap && showMaintenanceMap && (
                        <BookingMaintenanceMap />
                      )}
                    </Card.Body>
                    {section.hasButtons && showButtons() && (
                      <Card.ActionsContainer data-e2e-id="bookingButtons">
                        <Buttons />
                      </Card.ActionsContainer>
                    )}
                  </Card>
                ))}
              </>
            )}
          </Grid.Item>
          <Grid.Item LG={4} data-e2e-id={`${dataE2eId}DetailsFAQ`}>
            <FAQ faqKey={faqKey} />
          </Grid.Item>
        </Grid>
      )}
    </MyleaseplanPage>
  )
}

export default BookingDetailsPage
