export enum StatusCode {
  SUCCESS = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  SERVER_ERROR = 500,
}

export interface ServerError {
  title: string
  status: StatusCode
  detail: string
  code: string
}

export interface GenericResponse {
  detail: string
  instance: string
  title: string
  status: StatusCode
  type: string
}
