import React, { PropsWithChildren, useEffect } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { useAppDispatch } from 'mlp-client/src/hooks'
import { useCurrentPath } from 'mlp-client/src/localization/RoutesProvider'
import TranslatedRedirect from 'mlp-client/src/localization/TranslatedRedirect'
import {
  useGetUserAccounts,
  useIsSingleAccount,
} from 'mlp-client/src/user/hooks/useUser'
import {
  clearSelectedAccountId,
  setSelectedAccountId,
  useSelectedAccountId,
} from 'mlp-client/src/user/userSlice'

type MatchParams = {
  accountId: string
  contractId: string
}

const SelectAccountRedirect: React.FC<PropsWithChildren> = ({ children }) => {
  const selectedAccountId = useSelectedAccountId()
  const userAccounts = useGetUserAccounts()
  const isSingleAccount = useIsSingleAccount()
  const dispatch = useAppDispatch()
  const currentParams = useParams<MatchParams>()
  const { accountId: paramsAccountId, contractId: paramsContractId } =
    currentParams
  const navigate = useNavigate()
  const currentPath = useCurrentPath()

  const redirectIfAccountIdIsNotFound = (contractId: string) => {
    const contractUserAccount = userAccounts?.find(({ contractIds }) =>
      contractIds.includes(contractId),
    )
    if (contractUserAccount) {
      const route = generatePath(currentPath, {
        ...currentParams,
        accountId: contractUserAccount.id,
        contractId: contractId,
      })
      navigate(route)
    } else {
      dispatch(clearSelectedAccountId())
    }
  }

  useEffect(() => {
    if (selectedAccountId !== paramsAccountId && paramsAccountId) {
      if (userAccounts?.find(({ id }) => paramsAccountId === id)) {
        dispatch(setSelectedAccountId(paramsAccountId))
      } else {
        // ContractId was passed instead of AccountId
        redirectIfAccountIdIsNotFound(paramsAccountId)
      }
    } else if (!selectedAccountId) {
      if (isSingleAccount) {
        dispatch(setSelectedAccountId(userAccounts[0].id))
      } else {
        redirectIfAccountIdIsNotFound(paramsContractId)
      }
    }
  }, [paramsAccountId, isSingleAccount, userAccounts, selectedAccountId])

  if (selectedAccountId === undefined) {
    return null
  }

  if (selectedAccountId === null) {
    return <TranslatedRedirect to="myPortal.selectAccount" />
  }

  return <>{children}</>
}

export default SelectAccountRedirect
