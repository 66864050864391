import { Headline, Text } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import LinkButton, {
  LinkButtonProps,
} from 'mlp-client/src/components/link-button/LinkButton'
import TranslatedButton, {
  TranslatedButtonProps,
} from 'mlp-client/src/localization/components/TranslatedButton'
import Translation from 'mlp-client/src/localization/Translation'

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: var(--vel-layout-spacing-06) var(--vel-layout-spacing-06);
  border: 1px solid var(--vel-color-secondary-50);
`

const ItemWrapper = styled.div`
  margin-top: var(--vel-fixed-spacing-05);
`

export interface Props {
  pictogram: React.ReactElement
  title: string
  subtitle?: React.ReactElement | string
  linkButton?: LinkButtonProps
  button?: TranslatedButtonProps
  dataE2eId: string
}

const PagePlaceholder: React.FC<Props> = ({
  pictogram,
  title,
  subtitle,
  linkButton,
  button,
  dataE2eId,
}) => {
  const subtitleElement =
    typeof subtitle === 'string' ? <Translation id={subtitle} /> : subtitle

  return (
    <StyledWrapper data-e2e-id={dataE2eId}>
      {pictogram}
      <Headline
        component="h1"
        variant="200"
        data-e2e-id={`${dataE2eId}Title`}
        margin={{ top: '05' }}
      >
        <Translation id={title} />
      </Headline>

      {subtitle && (
        <Text
          variant="300"
          component="p"
          data-e2e-id={`${dataE2eId}Subtitle`}
          margin={{ top: '05' }}
        >
          {subtitleElement}
        </Text>
      )}

      {linkButton && (
        <ItemWrapper>
          <LinkButton {...linkButton} data-e2e-id={`${dataE2eId}LinkButton`} />
        </ItemWrapper>
      )}

      {button && (
        <ItemWrapper>
          <TranslatedButton {...button} data-e2e-id={`${dataE2eId}Button`} />
        </ItemWrapper>
      )}
    </StyledWrapper>
  )
}

export default PagePlaceholder
