export {}

if (
  Intl.NumberFormat &&
  // @ts-expect-error: polyfill
  typeof Intl.NumberFormat.__addLocaleData === 'function'
) {
  // @ts-expect-error: polyfill
  Intl.NumberFormat.__addLocaleData({
    data: {
      units: {
        simple: {
          degree: {
            long: { other: '{0} degrees', one: '{0} degree' },
            short: { other: '{0} deg' },
            narrow: { other: '{0}°' },
            perUnit: {},
          },
          hectare: {
            long: { other: '{0} hectares', one: '{0} hectare' },
            short: { other: '{0} ha' },
            narrow: { other: '{0}ha' },
            perUnit: {},
          },
          acre: {
            long: { other: '{0} acres', one: '{0} acre' },
            short: { other: '{0} ac' },
            narrow: { other: '{0}ac' },
            perUnit: {},
          },
          percent: {
            long: { other: '{0} per cent' },
            short: { other: '{0}%' },
            narrow: { other: '{0}%' },
            perUnit: {},
          },
          'liter-per-kilometer': {
            long: {
              other: '{0} litres per kilometre',
              one: '{0} litre per kilometre',
            },
            short: { other: '{0} l/km' },
            narrow: { other: '{0}l/km' },
            perUnit: {},
          },
          'mile-per-gallon': {
            long: {
              other: '{0} miles per US gallon',
              one: '{0} mile per US gallon',
            },
            short: { other: '{0} mpg US' },
            narrow: { other: '{0}mpgUS' },
            perUnit: {},
          },
          petabyte: {
            long: { other: '{0} petabytes', one: '{0} petabyte' },
            short: { other: '{0} PB' },
            narrow: { other: '{0}PB' },
            perUnit: {},
          },
          terabyte: {
            long: { other: '{0} terabytes', one: '{0} terabyte' },
            short: { other: '{0} TB' },
            narrow: { other: '{0}TB' },
            perUnit: {},
          },
          terabit: {
            long: { other: '{0} terabits', one: '{0} terabit' },
            short: { other: '{0} Tb' },
            narrow: { other: '{0}Tb' },
            perUnit: {},
          },
          gigabyte: {
            long: { other: '{0} gigabytes', one: '{0} gigabyte' },
            short: { other: '{0} GB' },
            narrow: { other: '{0}GB' },
            perUnit: {},
          },
          gigabit: {
            long: { other: '{0} gigabits', one: '{0} gigabit' },
            short: { other: '{0} Gb' },
            narrow: { other: '{0}Gb' },
            perUnit: {},
          },
          megabyte: {
            long: { other: '{0} megabytes', one: '{0} megabyte' },
            short: { other: '{0} MB' },
            narrow: { other: '{0}MB' },
            perUnit: {},
          },
          megabit: {
            long: { other: '{0} megabits', one: '{0} megabit' },
            short: { other: '{0} Mb' },
            narrow: { other: '{0}Mb' },
            perUnit: {},
          },
          kilobyte: {
            long: { other: '{0} kilobytes', one: '{0} kilobyte' },
            short: { other: '{0} kB' },
            narrow: { other: '{0}kB' },
            perUnit: {},
          },
          kilobit: {
            long: { other: '{0} kilobits', one: '{0} kilobit' },
            short: { other: '{0} kb' },
            narrow: { other: '{0}kb' },
            perUnit: {},
          },
          byte: {
            long: { other: '{0} bytes', one: '{0} byte' },
            short: { other: '{0} byte' },
            narrow: { other: '{0}B' },
            perUnit: {},
          },
          bit: {
            long: { other: '{0} bits', one: '{0} bit' },
            short: { other: '{0} bit' },
            narrow: { other: '{0}bit' },
            perUnit: {},
          },
          year: {
            long: { other: '{0} years', one: '{0} year' },
            short: { other: '{0} yrs', one: '{0} yr' },
            narrow: { other: '{0}y' },
            perUnit: { long: '{0} per year', short: '{0}/y', narrow: '{0}/y' },
          },
          month: {
            long: { other: '{0} months', one: '{0} month' },
            short: { other: '{0} mths', one: '{0} mth' },
            narrow: { other: '{0}m' },
            perUnit: { long: '{0} per month', short: '{0}/m', narrow: '{0}/m' },
          },
          week: {
            long: { other: '{0} weeks', one: '{0} week' },
            short: { other: '{0} wks', one: '{0} wk' },
            narrow: { other: '{0}w' },
            perUnit: { long: '{0} per week', short: '{0}/w', narrow: '{0}/w' },
          },
          day: {
            long: { other: '{0} days', one: '{0} day' },
            short: { other: '{0} days', one: '{0} day' },
            narrow: { other: '{0}d' },
            perUnit: { long: '{0} per day', short: '{0}/d', narrow: '{0}/d' },
          },
          hour: {
            long: { other: '{0} hours', one: '{0} hour' },
            short: { other: '{0} hrs', one: '{0} hr' },
            narrow: { other: '{0}h' },
            perUnit: { long: '{0} per hour', short: '{0}/h', narrow: '{0}/h' },
          },
          minute: {
            long: { other: '{0} minutes', one: '{0} minute' },
            short: { other: '{0} mins', one: '{0} min' },
            narrow: { other: '{0}m' },
            perUnit: {
              long: '{0} per minute',
              short: '{0}/min',
              narrow: '{0}/min',
            },
          },
          second: {
            long: { other: '{0} seconds', one: '{0} second' },
            short: { other: '{0} secs', one: '{0} sec' },
            narrow: { other: '{0}s' },
            perUnit: {
              long: '{0} per second',
              short: '{0}/s',
              narrow: '{0}/s',
            },
          },
          millisecond: {
            long: { other: '{0} milliseconds', one: '{0} millisecond' },
            short: { other: '{0} ms' },
            narrow: { other: '{0}ms' },
            perUnit: {},
          },
          kilometer: {
            long: { other: '{0} kilometres', one: '{0} kilometre' },
            short: { other: '{0} km' },
            narrow: { other: '{0}km' },
            perUnit: {
              long: '{0} per kilometre',
              short: '{0}/km',
              narrow: '{0}/km',
            },
          },
          meter: {
            long: { other: '{0} metres', one: '{0} metre' },
            short: { other: '{0} m' },
            narrow: { other: '{0}m' },
            perUnit: { long: '{0} per metre', short: '{0}/m', narrow: '{0}/m' },
          },
          centimeter: {
            long: { other: '{0} centimetres', one: '{0} centimetre' },
            short: { other: '{0} cm' },
            narrow: { other: '{0}cm' },
            perUnit: {
              long: '{0} per centimetre',
              short: '{0}/cm',
              narrow: '{0}/cm',
            },
          },
          millimeter: {
            long: { other: '{0} millimetres', one: '{0} millimetre' },
            short: { other: '{0} mm' },
            narrow: { other: '{0}mm' },
            perUnit: {},
          },
          mile: {
            long: { other: '{0} miles', one: '{0} mile' },
            short: { other: '{0} mi' },
            narrow: { other: '{0}mi' },
            perUnit: {},
          },
          yard: {
            long: { other: '{0} yards', one: '{0} yard' },
            short: { other: '{0} yd' },
            narrow: { other: '{0}yd' },
            perUnit: {},
          },
          foot: {
            long: { other: '{0} feet', one: '{0} foot' },
            short: { other: '{0} ft' },
            narrow: { other: '{0}′' },
            perUnit: {
              long: '{0} per foot',
              short: '{0}/ft',
              narrow: '{0}/ft',
            },
          },
          inch: {
            long: { other: '{0} inches', one: '{0} inch' },
            short: { other: '{0} in' },
            narrow: { other: '{0}″' },
            perUnit: {
              long: '{0} per inch',
              short: '{0}/in',
              narrow: '{0}/in',
            },
          },
          'mile-scandinavian': {
            long: {
              other: '{0} Scandinavian miles',
              one: '{0} Scandinavian mile',
            },
            short: { other: '{0} smi' },
            narrow: { other: '{0}smi' },
            perUnit: {},
          },
          kilogram: {
            long: { other: '{0} kilograms', one: '{0} kilogram' },
            short: { other: '{0} kg' },
            narrow: { other: '{0}kg' },
            perUnit: {
              long: '{0} per kilogram',
              short: '{0}/kg',
              narrow: '{0}/kg',
            },
          },
          gram: {
            long: { other: '{0} grams', one: '{0} gram' },
            short: { other: '{0} g' },
            narrow: { other: '{0}g' },
            perUnit: { long: '{0} per gram', short: '{0}/g', narrow: '{0}/g' },
          },
          stone: {
            long: { other: '{0} stone' },
            short: { other: '{0} st' },
            narrow: { other: '{0}st' },
            perUnit: {},
          },
          pound: {
            long: { other: '{0} pounds', one: '{0} pound' },
            short: { other: '{0} lb' },
            narrow: { other: '{0}lb' },
            perUnit: {
              long: '{0} per pound',
              short: '{0}/lb',
              narrow: '{0}/lb',
            },
          },
          ounce: {
            long: { other: '{0} ounces', one: '{0} ounce' },
            short: { other: '{0} oz' },
            narrow: { other: '{0}oz' },
            perUnit: {
              long: '{0} per ounce',
              short: '{0}/oz',
              narrow: '{0}/oz',
            },
          },
          'kilometer-per-hour': {
            long: {
              other: '{0} kilometres per hour',
              one: '{0} kilometre per hour',
            },
            short: { other: '{0} km/h' },
            narrow: { other: '{0}km/h' },
            perUnit: {},
          },
          'meter-per-second': {
            long: {
              other: '{0} metres per second',
              one: '{0} metre per second',
            },
            short: { other: '{0} m/s' },
            narrow: { other: '{0}m/s' },
            perUnit: {},
          },
          'mile-per-hour': {
            long: { other: '{0} miles per hour', one: '{0} mile per hour' },
            short: { other: '{0} mph' },
            narrow: { other: '{0}mph' },
            perUnit: {},
          },
          celsius: {
            long: { other: '{0} degrees Celsius', one: '{0} degree Celsius' },
            short: { other: '{0}°C' },
            narrow: { other: '{0}°' },
            perUnit: {},
          },
          fahrenheit: {
            long: {
              other: '{0} degrees Fahrenheit',
              one: '{0} degree Fahrenheit',
            },
            short: { other: '{0}°F' },
            narrow: { other: '{0}°F' },
            perUnit: {},
          },
          liter: {
            long: { other: '{0} litres', one: '{0} litre' },
            short: { other: '{0} l' },
            narrow: { other: '{0}l' },
            perUnit: { long: '{0} per litre', short: '{0}/l', narrow: '{0}/l' },
          },
          milliliter: {
            long: { other: '{0} millilitres', one: '{0} millilitre' },
            short: { other: '{0} ml' },
            narrow: { other: '{0}ml' },
            perUnit: {},
          },
          gallon: {
            long: { other: '{0} US gallons', one: '{0} US gallon' },
            short: { other: '{0} US gal' },
            narrow: { other: '{0}galUS' },
            perUnit: {
              long: '{0} per US gallon',
              short: '{0}/US gal',
              narrow: '{0}/galUS',
            },
          },
          'fluid-ounce': {
            long: { other: '{0} US fluid ounces', one: '{0} US fluid ounce' },
            short: { other: '{0} US fl oz' },
            narrow: { other: '{0}fl oz' },
            perUnit: {},
          },
        },
        compound: {
          per: { long: '{0} per {1}', short: '{0}/{1}', narrow: '{0}/{1}' },
        },
      },
      currencies: {
        ADP: {
          displayName: { other: 'Andorran pesetas', one: 'Andorran peseta' },
          symbol: 'ADP',
          narrow: 'ADP',
        },
        AED: {
          displayName: { other: 'UAE dirhams', one: 'UAE dirham' },
          symbol: 'AED',
          narrow: 'AED',
        },
        AFA: {
          displayName: {
            other: 'Afghan afghanis (1927–2002)',
            one: 'Afghan afghani (1927–2002)',
          },
          symbol: 'AFA',
          narrow: 'AFA',
        },
        AFN: {
          displayName: { other: 'Afghan afghanis', one: 'Afghan afghani' },
          symbol: 'AFN',
          narrow: '؋',
        },
        ALK: {
          displayName: {
            other: 'Albanian lekë (1946–1965)',
            one: 'Albanian lek (1946–1965)',
          },
          symbol: 'ALK',
          narrow: 'ALK',
        },
        ALL: {
          displayName: { other: 'Albanian lekë', one: 'Albanian lek' },
          symbol: 'ALL',
          narrow: 'ALL',
        },
        AMD: {
          displayName: { other: 'Armenian drams', one: 'Armenian dram' },
          symbol: 'AMD',
          narrow: '֏',
        },
        ANG: {
          displayName: {
            other: 'Netherlands Antillean guilders',
            one: 'Netherlands Antillean guilder',
          },
          symbol: 'ANG',
          narrow: 'ANG',
        },
        AOA: {
          displayName: { other: 'Angolan kwanzas', one: 'Angolan kwanza' },
          symbol: 'AOA',
          narrow: 'Kz',
        },
        AOK: {
          displayName: {
            other: 'Angolan kwanzas (1977–1991)',
            one: 'Angolan kwanza (1977–1991)',
          },
          symbol: 'AOK',
          narrow: 'AOK',
        },
        AON: {
          displayName: {
            other: 'Angolan new kwanzas (1990–2000)',
            one: 'Angolan new kwanza (1990–2000)',
          },
          symbol: 'AON',
          narrow: 'AON',
        },
        AOR: {
          displayName: {
            other: 'Angolan readjusted kwanzas (1995–1999)',
            one: 'Angolan readjusted kwanza (1995–1999)',
          },
          symbol: 'AOR',
          narrow: 'AOR',
        },
        ARA: {
          displayName: {
            other: 'Argentine australs',
            one: 'Argentine austral',
          },
          symbol: 'ARA',
          narrow: 'ARA',
        },
        ARL: {
          displayName: {
            other: 'Argentine pesos ley (1970–1983)',
            one: 'Argentine peso ley (1970–1983)',
          },
          symbol: 'ARL',
          narrow: 'ARL',
        },
        ARM: {
          displayName: {
            other: 'Argentine pesos (1881–1970)',
            one: 'Argentine peso (1881–1970)',
          },
          symbol: 'ARM',
          narrow: 'ARM',
        },
        ARP: {
          displayName: {
            other: 'Argentine pesos (1983–1985)',
            one: 'Argentine peso (1983–1985)',
          },
          symbol: 'ARP',
          narrow: 'ARP',
        },
        ARS: {
          displayName: { other: 'Argentine pesos', one: 'Argentine peso' },
          symbol: 'ARS',
          narrow: '$',
        },
        ATS: {
          displayName: {
            other: 'Austrian schillings',
            one: 'Austrian schilling',
          },
          symbol: 'ATS',
          narrow: 'ATS',
        },
        AUD: {
          displayName: {
            other: 'Australian dollars',
            one: 'Australian dollar',
          },
          symbol: 'A$',
          narrow: '$',
        },
        AWG: {
          displayName: { other: 'Aruban florin' },
          symbol: 'AWG',
          narrow: 'AWG',
        },
        AZM: {
          displayName: {
            other: 'Azerbaijani manats (1993–2006)',
            one: 'Azerbaijani manat (1993–2006)',
          },
          symbol: 'AZM',
          narrow: 'AZM',
        },
        AZN: {
          displayName: {
            other: 'Azerbaijani manats',
            one: 'Azerbaijani manat',
          },
          symbol: 'AZN',
          narrow: '₼',
        },
        BAD: {
          displayName: {
            other: 'Bosnia-Herzegovina dinars (1992–1994)',
            one: 'Bosnia-Herzegovina dinar (1992–1994)',
          },
          symbol: 'BAD',
          narrow: 'BAD',
        },
        BAM: {
          displayName: {
            other: 'Bosnia-Herzegovina convertible marks',
            one: 'Bosnia-Herzegovina convertible mark',
          },
          symbol: 'BAM',
          narrow: 'KM',
        },
        BAN: {
          displayName: {
            other: 'Bosnia-Herzegovina new dinars (1994–1997)',
            one: 'Bosnia-Herzegovina new dinar (1994–1997)',
          },
          symbol: 'BAN',
          narrow: 'BAN',
        },
        BBD: {
          displayName: { other: 'Barbadian dollars', one: 'Barbadian dollar' },
          symbol: 'BBD',
          narrow: '$',
        },
        BDT: {
          displayName: { other: 'Bangladeshi takas', one: 'Bangladeshi taka' },
          symbol: 'BDT',
          narrow: '৳',
        },
        BEC: {
          displayName: {
            other: 'Belgian francs (convertible)',
            one: 'Belgian franc (convertible)',
          },
          symbol: 'BEC',
          narrow: 'BEC',
        },
        BEF: {
          displayName: { other: 'Belgian francs', one: 'Belgian franc' },
          symbol: 'BEF',
          narrow: 'BEF',
        },
        BEL: {
          displayName: {
            other: 'Belgian francs (financial)',
            one: 'Belgian franc (financial)',
          },
          symbol: 'BEL',
          narrow: 'BEL',
        },
        BGL: {
          displayName: {
            other: 'Bulgarian hard leva',
            one: 'Bulgarian hard lev',
          },
          symbol: 'BGL',
          narrow: 'BGL',
        },
        BGM: {
          displayName: {
            other: 'Bulgarian socialist leva',
            one: 'Bulgarian socialist lev',
          },
          symbol: 'BGM',
          narrow: 'BGM',
        },
        BGN: {
          displayName: { other: 'Bulgarian leva', one: 'Bulgarian lev' },
          symbol: 'BGN',
          narrow: 'BGN',
        },
        BGO: {
          displayName: {
            other: 'Bulgarian leva (1879–1952)',
            one: 'Bulgarian lev (1879–1952)',
          },
          symbol: 'BGO',
          narrow: 'BGO',
        },
        BHD: {
          displayName: { other: 'Bahraini dinars', one: 'Bahraini dinar' },
          symbol: 'BHD',
          narrow: 'BHD',
        },
        BIF: {
          displayName: { other: 'Burundian francs', one: 'Burundian franc' },
          symbol: 'BIF',
          narrow: 'BIF',
        },
        BMD: {
          displayName: { other: 'Bermudian dollars', one: 'Bermudian dollar' },
          symbol: 'BMD',
          narrow: '$',
        },
        BND: {
          displayName: { other: 'Brunei dollars', one: 'Brunei dollar' },
          symbol: 'BND',
          narrow: '$',
        },
        BOB: {
          displayName: {
            other: 'Bolivian bolivianos',
            one: 'Bolivian boliviano',
          },
          symbol: 'BOB',
          narrow: 'Bs',
        },
        BOL: {
          displayName: {
            other: 'Bolivian bolivianos (1863–1963)',
            one: 'Bolivian boliviano (1863–1963)',
          },
          symbol: 'BOL',
          narrow: 'BOL',
        },
        BOP: {
          displayName: { other: 'Bolivian pesos', one: 'Bolivian peso' },
          symbol: 'BOP',
          narrow: 'BOP',
        },
        BOV: {
          displayName: { other: 'Bolivian mvdols', one: 'Bolivian mvdol' },
          symbol: 'BOV',
          narrow: 'BOV',
        },
        BRB: {
          displayName: {
            other: 'Brazilian new cruzeiros (1967–1986)',
            one: 'Brazilian new cruzeiro (1967–1986)',
          },
          symbol: 'BRB',
          narrow: 'BRB',
        },
        BRC: {
          displayName: {
            other: 'Brazilian cruzados (1986–1989)',
            one: 'Brazilian cruzado (1986–1989)',
          },
          symbol: 'BRC',
          narrow: 'BRC',
        },
        BRE: {
          displayName: {
            other: 'Brazilian cruzeiros (1990–1993)',
            one: 'Brazilian cruzeiro (1990–1993)',
          },
          symbol: 'BRE',
          narrow: 'BRE',
        },
        BRL: {
          displayName: { other: 'Brazilian reals', one: 'Brazilian real' },
          symbol: 'R$',
          narrow: 'R$',
        },
        BRN: {
          displayName: {
            other: 'Brazilian new cruzados (1989–1990)',
            one: 'Brazilian new cruzado (1989–1990)',
          },
          symbol: 'BRN',
          narrow: 'BRN',
        },
        BRR: {
          displayName: {
            other: 'Brazilian cruzeiros (1993–1994)',
            one: 'Brazilian cruzeiro (1993–1994)',
          },
          symbol: 'BRR',
          narrow: 'BRR',
        },
        BRZ: {
          displayName: {
            other: 'Brazilian cruzeiros (1942–1967)',
            one: 'Brazilian cruzeiro (1942–1967)',
          },
          symbol: 'BRZ',
          narrow: 'BRZ',
        },
        BSD: {
          displayName: { other: 'Bahamian dollars', one: 'Bahamian dollar' },
          symbol: 'BSD',
          narrow: '$',
        },
        BTN: {
          displayName: {
            other: 'Bhutanese ngultrums',
            one: 'Bhutanese ngultrum',
          },
          symbol: 'BTN',
          narrow: 'BTN',
        },
        BUK: {
          displayName: { other: 'Burmese kyats', one: 'Burmese kyat' },
          symbol: 'BUK',
          narrow: 'BUK',
        },
        BWP: {
          displayName: { other: 'Botswanan pulas', one: 'Botswanan pula' },
          symbol: 'BWP',
          narrow: 'P',
        },
        BYB: {
          displayName: {
            other: 'Belarusian new roubles (1994–1999)',
            one: 'Belarusian new rouble (1994–1999)',
          },
          symbol: 'BYB',
          narrow: 'BYB',
        },
        BYN: {
          displayName: {
            other: 'Belarusian roubles',
            one: 'Belarusian rouble',
          },
          symbol: 'BYN',
          narrow: 'BYN',
        },
        BYR: {
          displayName: {
            other: 'Belarusian roubles (2000–2016)',
            one: 'Belarusian rouble (2000–2016)',
          },
          symbol: 'BYR',
          narrow: 'BYR',
        },
        BZD: {
          displayName: { other: 'Belize dollars', one: 'Belize dollar' },
          symbol: 'BZD',
          narrow: '$',
        },
        CAD: {
          displayName: { other: 'Canadian dollars', one: 'Canadian dollar' },
          symbol: 'CA$',
          narrow: '$',
        },
        CDF: {
          displayName: { other: 'Congolese francs', one: 'Congolese franc' },
          symbol: 'CDF',
          narrow: 'CDF',
        },
        CHE: {
          displayName: { other: 'WIR euros', one: 'WIR euro' },
          symbol: 'CHE',
          narrow: 'CHE',
        },
        CHF: {
          displayName: { other: 'Swiss francs', one: 'Swiss franc' },
          symbol: 'CHF',
          narrow: 'CHF',
        },
        CHW: {
          displayName: { other: 'WIR francs', one: 'WIR franc' },
          symbol: 'CHW',
          narrow: 'CHW',
        },
        CLE: {
          displayName: { other: 'Chilean escudos', one: 'Chilean escudo' },
          symbol: 'CLE',
          narrow: 'CLE',
        },
        CLF: {
          displayName: {
            other: 'Chilean units of account (UF)',
            one: 'Chilean unit of account (UF)',
          },
          symbol: 'CLF',
          narrow: 'CLF',
        },
        CLP: {
          displayName: { other: 'Chilean pesos', one: 'Chilean peso' },
          symbol: 'CLP',
          narrow: '$',
        },
        CNH: {
          displayName: { other: 'Chinese yuan (offshore)' },
          symbol: 'CNH',
          narrow: 'CNH',
        },
        CNX: {
          displayName: {
            other: 'Chinese People’s Bank dollars',
            one: 'Chinese People’s Bank dollar',
          },
          symbol: 'CNX',
          narrow: 'CNX',
        },
        CNY: {
          displayName: { other: 'Chinese yuan' },
          symbol: 'CN¥',
          narrow: '¥',
        },
        COP: {
          displayName: { other: 'Colombian pesos', one: 'Colombian peso' },
          symbol: 'COP',
          narrow: '$',
        },
        COU: {
          displayName: {
            other: 'Colombian real value units',
            one: 'Colombian real value unit',
          },
          symbol: 'COU',
          narrow: 'COU',
        },
        CRC: {
          displayName: {
            other: 'Costa Rican colóns',
            one: 'Costa Rican colón',
          },
          symbol: 'CRC',
          narrow: '₡',
        },
        CSD: {
          displayName: {
            other: 'Serbian dinars (2002–2006)',
            one: 'Serbian dinar (2002–2006)',
          },
          symbol: 'CSD',
          narrow: 'CSD',
        },
        CSK: {
          displayName: {
            other: 'Czechoslovak hard korunas',
            one: 'Czechoslovak hard koruna',
          },
          symbol: 'CSK',
          narrow: 'CSK',
        },
        CUC: {
          displayName: {
            other: 'Cuban convertible pesos',
            one: 'Cuban convertible peso',
          },
          symbol: 'CUC',
          narrow: '$',
        },
        CUP: {
          displayName: { other: 'Cuban pesos', one: 'Cuban peso' },
          symbol: 'CUP',
          narrow: '$',
        },
        CVE: {
          displayName: {
            other: 'Cape Verdean escudos',
            one: 'Cape Verdean escudo',
          },
          symbol: 'CVE',
          narrow: 'CVE',
        },
        CYP: {
          displayName: { other: 'Cypriot pounds', one: 'Cypriot pound' },
          symbol: 'CYP',
          narrow: 'CYP',
        },
        CZK: {
          displayName: { other: 'Czech korunas', one: 'Czech koruna' },
          symbol: 'CZK',
          narrow: 'Kč',
        },
        DDM: {
          displayName: { other: 'East German marks', one: 'East German mark' },
          symbol: 'DDM',
          narrow: 'DDM',
        },
        DEM: {
          displayName: { other: 'German marks', one: 'German mark' },
          symbol: 'DEM',
          narrow: 'DEM',
        },
        DJF: {
          displayName: { other: 'Djiboutian francs', one: 'Djiboutian franc' },
          symbol: 'DJF',
          narrow: 'DJF',
        },
        DKK: {
          displayName: { other: 'Danish kroner', one: 'Danish krone' },
          symbol: 'DKK',
          narrow: 'kr',
        },
        DOP: {
          displayName: { other: 'Dominican pesos', one: 'Dominican peso' },
          symbol: 'DOP',
          narrow: '$',
        },
        DZD: {
          displayName: { other: 'Algerian dinars', one: 'Algerian dinar' },
          symbol: 'DZD',
          narrow: 'DZD',
        },
        ECS: {
          displayName: { other: 'Ecuadorian sucres', one: 'Ecuadorian sucre' },
          symbol: 'ECS',
          narrow: 'ECS',
        },
        ECV: {
          displayName: {
            other: 'Ecuadorian units of constant value',
            one: 'Ecuadorian unit of constant value',
          },
          symbol: 'ECV',
          narrow: 'ECV',
        },
        EEK: {
          displayName: { other: 'Estonian kroons', one: 'Estonian kroon' },
          symbol: 'EEK',
          narrow: 'EEK',
        },
        EGP: {
          displayName: { other: 'Egyptian pounds', one: 'Egyptian pound' },
          symbol: 'EGP',
          narrow: 'E£',
        },
        ERN: {
          displayName: { other: 'Eritrean nakfas', one: 'Eritrean nakfa' },
          symbol: 'ERN',
          narrow: 'ERN',
        },
        ESA: {
          displayName: {
            other: 'Spanish pesetas (A account)',
            one: 'Spanish peseta (A account)',
          },
          symbol: 'ESA',
          narrow: 'ESA',
        },
        ESB: {
          displayName: {
            other: 'Spanish pesetas (convertible account)',
            one: 'Spanish peseta (convertible account)',
          },
          symbol: 'ESB',
          narrow: 'ESB',
        },
        ESP: {
          displayName: { other: 'Spanish pesetas', one: 'Spanish peseta' },
          symbol: 'ESP',
          narrow: '₧',
        },
        ETB: {
          displayName: { other: 'Ethiopian birr' },
          symbol: 'ETB',
          narrow: 'ETB',
        },
        EUR: {
          displayName: { other: 'euros', one: 'euro' },
          symbol: '€',
          narrow: '€',
        },
        FIM: {
          displayName: { other: 'Finnish markkas', one: 'Finnish markka' },
          symbol: 'FIM',
          narrow: 'FIM',
        },
        FJD: {
          displayName: { other: 'Fijian dollars', one: 'Fijian dollar' },
          symbol: 'FJD',
          narrow: '$',
        },
        FKP: {
          displayName: {
            other: 'Falkland Islands pounds',
            one: 'Falkland Islands pound',
          },
          symbol: 'FKP',
          narrow: '£',
        },
        FRF: {
          displayName: { other: 'French francs', one: 'French franc' },
          symbol: 'FRF',
          narrow: 'FRF',
        },
        GBP: {
          displayName: { other: 'British pounds', one: 'British pound' },
          symbol: '£',
          narrow: '£',
        },
        GEK: {
          displayName: {
            other: 'Georgian kupon larits',
            one: 'Georgian kupon larit',
          },
          symbol: 'GEK',
          narrow: 'GEK',
        },
        GEL: {
          displayName: { other: 'Georgian laris', one: 'Georgian lari' },
          symbol: 'GEL',
          narrow: '₾',
        },
        GHC: {
          displayName: {
            other: 'Ghanaian cedis (1979–2007)',
            one: 'Ghanaian cedi (1979–2007)',
          },
          symbol: 'GHC',
          narrow: 'GHC',
        },
        GHS: {
          displayName: { other: 'Ghanaian cedis', one: 'Ghanaian cedi' },
          symbol: 'GHS',
          narrow: 'GH₵',
        },
        GIP: {
          displayName: { other: 'Gibraltar pounds', one: 'Gibraltar pound' },
          symbol: 'GIP',
          narrow: '£',
        },
        GMD: {
          displayName: { other: 'Gambian dalasis', one: 'Gambian dalasi' },
          symbol: 'GMD',
          narrow: 'GMD',
        },
        GNF: {
          displayName: { other: 'Guinean francs', one: 'Guinean franc' },
          symbol: 'GNF',
          narrow: 'FG',
        },
        GNS: {
          displayName: { other: 'Guinean sylis', one: 'Guinean syli' },
          symbol: 'GNS',
          narrow: 'GNS',
        },
        GQE: {
          displayName: { other: 'Equatorial Guinean ekwele' },
          symbol: 'GQE',
          narrow: 'GQE',
        },
        GRD: {
          displayName: { other: 'Greek drachmas', one: 'Greek drachma' },
          symbol: 'GRD',
          narrow: 'GRD',
        },
        GTQ: {
          displayName: {
            other: 'Guatemalan quetzals',
            one: 'Guatemalan quetzal',
          },
          symbol: 'GTQ',
          narrow: 'Q',
        },
        GWE: {
          displayName: {
            other: 'Portuguese Guinea escudos',
            one: 'Portuguese Guinea escudo',
          },
          symbol: 'GWE',
          narrow: 'GWE',
        },
        GWP: {
          displayName: {
            other: 'Guinea-Bissau pesos',
            one: 'Guinea-Bissau peso',
          },
          symbol: 'GWP',
          narrow: 'GWP',
        },
        GYD: {
          displayName: { other: 'Guyanaese dollars', one: 'Guyanaese dollar' },
          symbol: 'GYD',
          narrow: '$',
        },
        HKD: {
          displayName: { other: 'Hong Kong dollars', one: 'Hong Kong dollar' },
          symbol: 'HK$',
          narrow: '$',
        },
        HNL: {
          displayName: { other: 'Honduran lempiras', one: 'Honduran lempira' },
          symbol: 'HNL',
          narrow: 'L',
        },
        HRD: {
          displayName: { other: 'Croatian dinars', one: 'Croatian dinar' },
          symbol: 'HRD',
          narrow: 'HRD',
        },
        HRK: {
          displayName: { other: 'Croatian kunas', one: 'Croatian kuna' },
          symbol: 'HRK',
          narrow: 'kn',
        },
        HTG: {
          displayName: { other: 'Haitian gourdes', one: 'Haitian gourde' },
          symbol: 'HTG',
          narrow: 'HTG',
        },
        HUF: {
          displayName: { other: 'Hungarian forints', one: 'Hungarian forint' },
          symbol: 'HUF',
          narrow: 'Ft',
        },
        IDR: {
          displayName: {
            other: 'Indonesian rupiahs',
            one: 'Indonesian rupiah',
          },
          symbol: 'IDR',
          narrow: 'Rp',
        },
        IEP: {
          displayName: { other: 'Irish pounds', one: 'Irish pound' },
          symbol: 'IEP',
          narrow: 'IEP',
        },
        ILP: {
          displayName: { other: 'Israeli pounds', one: 'Israeli pound' },
          symbol: 'ILP',
          narrow: 'ILP',
        },
        ILR: {
          displayName: {
            other: 'Israeli shekels (1980–1985)',
            one: 'Israeli shekel (1980–1985)',
          },
          symbol: 'ILR',
          narrow: 'ILR',
        },
        ILS: {
          displayName: {
            other: 'Israeli new shekels',
            one: 'Israeli new shekel',
          },
          symbol: '₪',
          narrow: '₪',
        },
        INR: {
          displayName: { other: 'Indian rupees', one: 'Indian rupee' },
          symbol: '₹',
          narrow: '₹',
        },
        IQD: {
          displayName: { other: 'Iraqi dinars', one: 'Iraqi dinar' },
          symbol: 'IQD',
          narrow: 'IQD',
        },
        IRR: {
          displayName: { other: 'Iranian rials', one: 'Iranian rial' },
          symbol: 'IRR',
          narrow: 'IRR',
        },
        ISJ: {
          displayName: {
            other: 'Icelandic krónur (1918–1981)',
            one: 'Icelandic króna (1918–1981)',
          },
          symbol: 'ISJ',
          narrow: 'ISJ',
        },
        ISK: {
          displayName: { other: 'Icelandic krónur', one: 'Icelandic króna' },
          symbol: 'ISK',
          narrow: 'kr',
        },
        ITL: {
          displayName: { other: 'Italian liras', one: 'Italian lira' },
          symbol: 'ITL',
          narrow: 'ITL',
        },
        JMD: {
          displayName: { other: 'Jamaican dollars', one: 'Jamaican dollar' },
          symbol: 'JMD',
          narrow: '$',
        },
        JOD: {
          displayName: { other: 'Jordanian dinars', one: 'Jordanian dinar' },
          symbol: 'JOD',
          narrow: 'JOD',
        },
        JPY: {
          displayName: { other: 'Japanese yen' },
          symbol: 'JP¥',
          narrow: '¥',
        },
        KES: {
          displayName: { other: 'Kenyan shillings', one: 'Kenyan shilling' },
          symbol: 'KES',
          narrow: 'KES',
        },
        KGS: {
          displayName: { other: 'Kyrgystani soms', one: 'Kyrgystani som' },
          symbol: 'KGS',
          narrow: 'KGS',
        },
        KHR: {
          displayName: { other: 'Cambodian riels', one: 'Cambodian riel' },
          symbol: 'KHR',
          narrow: '៛',
        },
        KMF: {
          displayName: { other: 'Comorian francs', one: 'Comorian franc' },
          symbol: 'KMF',
          narrow: 'CF',
        },
        KPW: {
          displayName: { other: 'North Korean won' },
          symbol: 'KPW',
          narrow: '₩',
        },
        KRH: {
          displayName: { other: 'South Korean hwan (1953–1962)' },
          symbol: 'KRH',
          narrow: 'KRH',
        },
        KRO: {
          displayName: { other: 'South Korean won (1945–1953)' },
          symbol: 'KRO',
          narrow: 'KRO',
        },
        KRW: {
          displayName: { other: 'South Korean won' },
          symbol: '₩',
          narrow: '₩',
        },
        KWD: {
          displayName: { other: 'Kuwaiti dinars', one: 'Kuwaiti dinar' },
          symbol: 'KWD',
          narrow: 'KWD',
        },
        KYD: {
          displayName: {
            other: 'Cayman Islands dollars',
            one: 'Cayman Islands dollar',
          },
          symbol: 'KYD',
          narrow: '$',
        },
        KZT: {
          displayName: {
            other: 'Kazakhstani tenges',
            one: 'Kazakhstani tenge',
          },
          symbol: 'KZT',
          narrow: '₸',
        },
        LAK: {
          displayName: { other: 'Laotian kips', one: 'Laotian kip' },
          symbol: 'LAK',
          narrow: '₭',
        },
        LBP: {
          displayName: { other: 'Lebanese pounds', one: 'Lebanese pound' },
          symbol: 'LBP',
          narrow: 'L£',
        },
        LKR: {
          displayName: { other: 'Sri Lankan rupees', one: 'Sri Lankan rupee' },
          symbol: 'LKR',
          narrow: 'Rs',
        },
        LRD: {
          displayName: { other: 'Liberian dollars', one: 'Liberian dollar' },
          symbol: 'LRD',
          narrow: '$',
        },
        LSL: {
          displayName: { other: 'Lesotho maloti', one: 'Lesotho loti' },
          symbol: 'LSL',
          narrow: 'LSL',
        },
        LTL: {
          displayName: { other: 'Lithuanian litai', one: 'Lithuanian litas' },
          symbol: 'LTL',
          narrow: 'Lt',
        },
        LTT: {
          displayName: {
            other: 'Lithuanian talonases',
            one: 'Lithuanian talonas',
          },
          symbol: 'LTT',
          narrow: 'LTT',
        },
        LUC: {
          displayName: {
            other: 'Luxembourgian convertible francs',
            one: 'Luxembourgian convertible franc',
          },
          symbol: 'LUC',
          narrow: 'LUC',
        },
        LUF: {
          displayName: {
            other: 'Luxembourgian francs',
            one: 'Luxembourgian franc',
          },
          symbol: 'LUF',
          narrow: 'LUF',
        },
        LUL: {
          displayName: {
            other: 'Luxembourg financial francs',
            one: 'Luxembourg financial franc',
          },
          symbol: 'LUL',
          narrow: 'LUL',
        },
        LVL: {
          displayName: { other: 'Latvian lati', one: 'Latvian lats' },
          symbol: 'LVL',
          narrow: 'Ls',
        },
        LVR: {
          displayName: { other: 'Latvian roubles', one: 'Latvian rouble' },
          symbol: 'LVR',
          narrow: 'LVR',
        },
        LYD: {
          displayName: { other: 'Libyan dinars', one: 'Libyan dinar' },
          symbol: 'LYD',
          narrow: 'LYD',
        },
        MAD: {
          displayName: { other: 'Moroccan dirhams', one: 'Moroccan dirham' },
          symbol: 'MAD',
          narrow: 'MAD',
        },
        MAF: {
          displayName: { other: 'Moroccan francs', one: 'Moroccan franc' },
          symbol: 'MAF',
          narrow: 'MAF',
        },
        MCF: {
          displayName: { other: 'Monegasque francs', one: 'Monegasque franc' },
          symbol: 'MCF',
          narrow: 'MCF',
        },
        MDC: {
          displayName: { other: 'Moldovan cupon' },
          symbol: 'MDC',
          narrow: 'MDC',
        },
        MDL: {
          displayName: { other: 'Moldovan lei', one: 'Moldovan leu' },
          symbol: 'MDL',
          narrow: 'MDL',
        },
        MGA: {
          displayName: { other: 'Malagasy ariary' },
          symbol: 'MGA',
          narrow: 'Ar',
        },
        MGF: {
          displayName: { other: 'Malagasy francs', one: 'Malagasy franc' },
          symbol: 'MGF',
          narrow: 'MGF',
        },
        MKD: {
          displayName: { other: 'Macedonian denari', one: 'Macedonian denar' },
          symbol: 'MKD',
          narrow: 'MKD',
        },
        MKN: {
          displayName: {
            other: 'Macedonian denari (1992–1993)',
            one: 'Macedonian denar (1992–1993)',
          },
          symbol: 'MKN',
          narrow: 'MKN',
        },
        MLF: {
          displayName: { other: 'Malian francs', one: 'Malian franc' },
          symbol: 'MLF',
          narrow: 'MLF',
        },
        MMK: {
          displayName: { other: 'Myanmar kyats', one: 'Myanmar kyat' },
          symbol: 'MMK',
          narrow: 'K',
        },
        MNT: {
          displayName: { other: 'Mongolian tugriks', one: 'Mongolian tugrik' },
          symbol: 'MNT',
          narrow: '₮',
        },
        MOP: {
          displayName: { other: 'Macanese patacas', one: 'Macanese pataca' },
          symbol: 'MOP',
          narrow: 'MOP',
        },
        MRO: {
          displayName: {
            other: 'Mauritanian ouguiyas (1973–2017)',
            one: 'Mauritanian ouguiya (1973–2017)',
          },
          symbol: 'MRO',
          narrow: 'MRO',
        },
        MRU: {
          displayName: {
            other: 'Mauritanian ouguiyas',
            one: 'Mauritanian ouguiya',
          },
          symbol: 'MRU',
          narrow: 'MRU',
        },
        MTL: {
          displayName: { other: 'Maltese lira' },
          symbol: 'MTL',
          narrow: 'MTL',
        },
        MTP: {
          displayName: { other: 'Maltese pounds', one: 'Maltese pound' },
          symbol: 'MTP',
          narrow: 'MTP',
        },
        MUR: {
          displayName: { other: 'Mauritian rupees', one: 'Mauritian rupee' },
          symbol: 'MUR',
          narrow: 'Rs',
        },
        MVP: {
          displayName: {
            other: 'Maldivian rupees (1947–1981)',
            one: 'Maldivian rupee (1947–1981)',
          },
          symbol: 'MVP',
          narrow: 'MVP',
        },
        MVR: {
          displayName: { other: 'Maldivian rufiyaa' },
          symbol: 'MVR',
          narrow: 'MVR',
        },
        MWK: {
          displayName: { other: 'Malawian kwachas', one: 'Malawian kwacha' },
          symbol: 'MWK',
          narrow: 'MWK',
        },
        MXN: {
          displayName: { other: 'Mexican pesos', one: 'Mexican peso' },
          symbol: 'MX$',
          narrow: '$',
        },
        MXP: {
          displayName: {
            other: 'Mexican silver pesos (1861–1992)',
            one: 'Mexican silver peso (1861–1992)',
          },
          symbol: 'MXP',
          narrow: 'MXP',
        },
        MXV: {
          displayName: {
            other: 'Mexican investment units',
            one: 'Mexican investment unit',
          },
          symbol: 'MXV',
          narrow: 'MXV',
        },
        MYR: {
          displayName: {
            other: 'Malaysian ringgits',
            one: 'Malaysian ringgit',
          },
          symbol: 'MYR',
          narrow: 'RM',
        },
        MZE: {
          displayName: {
            other: 'Mozambican escudos',
            one: 'Mozambican escudo',
          },
          symbol: 'MZE',
          narrow: 'MZE',
        },
        MZM: {
          displayName: {
            other: 'Mozambican meticals (1980–2006)',
            one: 'Mozambican metical (1980–2006)',
          },
          symbol: 'MZM',
          narrow: 'MZM',
        },
        MZN: {
          displayName: {
            other: 'Mozambican meticals',
            one: 'Mozambican metical',
          },
          symbol: 'MZN',
          narrow: 'MZN',
        },
        NAD: {
          displayName: { other: 'Namibian dollars', one: 'Namibian dollar' },
          symbol: 'NAD',
          narrow: '$',
        },
        NGN: {
          displayName: { other: 'Nigerian nairas', one: 'Nigerian naira' },
          symbol: 'NGN',
          narrow: '₦',
        },
        NIC: {
          displayName: {
            other: 'Nicaraguan córdobas (1988–1991)',
            one: 'Nicaraguan córdoba (1988–1991)',
          },
          symbol: 'NIC',
          narrow: 'NIC',
        },
        NIO: {
          displayName: {
            other: 'Nicaraguan córdobas',
            one: 'Nicaraguan córdoba',
          },
          symbol: 'NIO',
          narrow: 'C$',
        },
        NLG: {
          displayName: { other: 'Dutch guilders', one: 'Dutch guilder' },
          symbol: 'NLG',
          narrow: 'NLG',
        },
        NOK: {
          displayName: { other: 'Norwegian kroner', one: 'Norwegian krone' },
          symbol: 'NOK',
          narrow: 'kr',
        },
        NPR: {
          displayName: { other: 'Nepalese rupees', one: 'Nepalese rupee' },
          symbol: 'NPR',
          narrow: 'Rs',
        },
        NZD: {
          displayName: {
            other: 'New Zealand dollars',
            one: 'New Zealand dollar',
          },
          symbol: 'NZ$',
          narrow: '$',
        },
        OMR: {
          displayName: { other: 'Omani rials', one: 'Omani rial' },
          symbol: 'OMR',
          narrow: 'OMR',
        },
        PAB: {
          displayName: {
            other: 'Panamanian balboas',
            one: 'Panamanian balboa',
          },
          symbol: 'PAB',
          narrow: 'PAB',
        },
        PEI: {
          displayName: { other: 'Peruvian intis', one: 'Peruvian inti' },
          symbol: 'PEI',
          narrow: 'PEI',
        },
        PEN: {
          displayName: { other: 'Peruvian soles', one: 'Peruvian sol' },
          symbol: 'PEN',
          narrow: 'PEN',
        },
        PES: {
          displayName: {
            other: 'Peruvian soles (1863–1965)',
            one: 'Peruvian sol (1863–1965)',
          },
          symbol: 'PES',
          narrow: 'PES',
        },
        PGK: {
          displayName: { other: 'Papua New Guinean kina' },
          symbol: 'PGK',
          narrow: 'PGK',
        },
        PHP: {
          displayName: { other: 'Philippine pesos', one: 'Philippine peso' },
          symbol: '₱',
          narrow: '₱',
        },
        PKR: {
          displayName: { other: 'Pakistani rupees', one: 'Pakistani rupee' },
          symbol: 'PKR',
          narrow: 'Rs',
        },
        PLN: {
          displayName: { other: 'Polish zlotys', one: 'Polish zloty' },
          symbol: 'PLN',
          narrow: 'zł',
        },
        PLZ: {
          displayName: {
            other: 'Polish zlotys (PLZ)',
            one: 'Polish zloty (PLZ)',
          },
          symbol: 'PLZ',
          narrow: 'PLZ',
        },
        PTE: {
          displayName: {
            other: 'Portuguese escudos',
            one: 'Portuguese escudo',
          },
          symbol: 'PTE',
          narrow: 'PTE',
        },
        PYG: {
          displayName: {
            other: 'Paraguayan guaranis',
            one: 'Paraguayan guarani',
          },
          symbol: 'PYG',
          narrow: '₲',
        },
        QAR: {
          displayName: { other: 'Qatari rials', one: 'Qatari rial' },
          symbol: 'QAR',
          narrow: 'QAR',
        },
        RHD: {
          displayName: { other: 'Rhodesian dollars', one: 'Rhodesian dollar' },
          symbol: 'RHD',
          narrow: 'RHD',
        },
        ROL: {
          displayName: {
            other: 'Romanian Lei (1952–2006)',
            one: 'Romanian leu (1952–2006)',
          },
          symbol: 'ROL',
          narrow: 'ROL',
        },
        RON: {
          displayName: { other: 'Romanian lei', one: 'Romanian leu' },
          symbol: 'RON',
          narrow: 'lei',
        },
        RSD: {
          displayName: { other: 'Serbian dinars', one: 'Serbian dinar' },
          symbol: 'RSD',
          narrow: 'RSD',
        },
        RUB: {
          displayName: { other: 'Russian roubles', one: 'Russian rouble' },
          symbol: 'RUB',
          narrow: '₽',
        },
        RUR: {
          displayName: {
            other: 'Russian roubles (1991–1998)',
            one: 'Russian rouble (1991–1998)',
          },
          symbol: 'RUR',
          narrow: 'RUR',
        },
        RWF: {
          displayName: { other: 'Rwandan francs', one: 'Rwandan franc' },
          symbol: 'RWF',
          narrow: 'RF',
        },
        SAR: {
          displayName: { other: 'Saudi riyals', one: 'Saudi riyal' },
          symbol: 'SAR',
          narrow: 'SAR',
        },
        SBD: {
          displayName: {
            other: 'Solomon Islands dollars',
            one: 'Solomon Islands dollar',
          },
          symbol: 'SBD',
          narrow: '$',
        },
        SCR: {
          displayName: {
            other: 'Seychellois rupees',
            one: 'Seychellois rupee',
          },
          symbol: 'SCR',
          narrow: 'SCR',
        },
        SDD: {
          displayName: {
            other: 'Sudanese dinars (1992–2007)',
            one: 'Sudanese dinar (1992–2007)',
          },
          symbol: 'SDD',
          narrow: 'SDD',
        },
        SDG: {
          displayName: { other: 'Sudanese pounds', one: 'Sudanese pound' },
          symbol: 'SDG',
          narrow: 'SDG',
        },
        SDP: {
          displayName: {
            other: 'Sudanese pounds (1957–1998)',
            one: 'Sudanese pound (1957–1998)',
          },
          symbol: 'SDP',
          narrow: 'SDP',
        },
        SEK: {
          displayName: { other: 'Swedish kronor', one: 'Swedish krona' },
          symbol: 'SEK',
          narrow: 'kr',
        },
        SGD: {
          displayName: { other: 'Singapore dollars', one: 'Singapore dollar' },
          symbol: 'SGD',
          narrow: '$',
        },
        SHP: {
          displayName: { other: 'St Helena pounds', one: 'St Helena pound' },
          symbol: 'SHP',
          narrow: '£',
        },
        SIT: {
          displayName: { other: 'Slovenian tolars', one: 'Slovenian tolar' },
          symbol: 'SIT',
          narrow: 'SIT',
        },
        SKK: {
          displayName: { other: 'Slovak korunas', one: 'Slovak koruna' },
          symbol: 'SKK',
          narrow: 'SKK',
        },
        SLL: {
          displayName: {
            other: 'Sierra Leonean leones',
            one: 'Sierra Leonean leone',
          },
          symbol: 'SLL',
          narrow: 'SLL',
        },
        SOS: {
          displayName: { other: 'Somali shillings', one: 'Somali shilling' },
          symbol: 'SOS',
          narrow: 'SOS',
        },
        SRD: {
          displayName: {
            other: 'Surinamese dollars',
            one: 'Surinamese dollar',
          },
          symbol: 'SRD',
          narrow: '$',
        },
        SRG: {
          displayName: {
            other: 'Surinamese guilders',
            one: 'Surinamese guilder',
          },
          symbol: 'SRG',
          narrow: 'SRG',
        },
        SSP: {
          displayName: {
            other: 'South Sudanese pounds',
            one: 'South Sudanese pound',
          },
          symbol: 'SSP',
          narrow: '£',
        },
        STD: {
          displayName: {
            other: 'São Tomé & Príncipe dobras (1977–2017)',
            one: 'São Tomé & Príncipe dobra (1977–2017)',
          },
          symbol: 'STD',
          narrow: 'STD',
        },
        STN: {
          displayName: {
            other: 'São Tomé & Príncipe dobras',
            one: 'São Tomé & Príncipe dobra',
          },
          symbol: 'STN',
          narrow: 'Db',
        },
        SUR: {
          displayName: { other: 'Soviet roubles', one: 'Soviet rouble' },
          symbol: 'SUR',
          narrow: 'SUR',
        },
        SVC: {
          displayName: { other: 'Salvadoran colones', one: 'Salvadoran colón' },
          symbol: 'SVC',
          narrow: 'SVC',
        },
        SYP: {
          displayName: { other: 'Syrian pounds', one: 'Syrian pound' },
          symbol: 'SYP',
          narrow: '£',
        },
        SZL: {
          displayName: { other: 'Swazi emalangeni', one: 'Swazi lilangeni' },
          symbol: 'SZL',
          narrow: 'SZL',
        },
        THB: {
          displayName: { other: 'Thai baht' },
          symbol: 'THB',
          narrow: '฿',
        },
        TJR: {
          displayName: {
            other: 'Tajikistani roubles',
            one: 'Tajikistani rouble',
          },
          symbol: 'TJR',
          narrow: 'TJR',
        },
        TJS: {
          displayName: {
            other: 'Tajikistani somonis',
            one: 'Tajikistani somoni',
          },
          symbol: 'TJS',
          narrow: 'TJS',
        },
        TMM: {
          displayName: { other: 'Turkmenistani manat (1993–2009)' },
          symbol: 'TMM',
          narrow: 'TMM',
        },
        TMT: {
          displayName: { other: 'Turkmenistani manat' },
          symbol: 'TMT',
          narrow: 'TMT',
        },
        TND: {
          displayName: { other: 'Tunisian dinars', one: 'Tunisian dinar' },
          symbol: 'TND',
          narrow: 'TND',
        },
        TOP: {
          displayName: { other: 'Tongan paʻanga' },
          symbol: 'TOP',
          narrow: 'T$',
        },
        TPE: {
          displayName: { other: 'Timorese escudos', one: 'Timorese escudo' },
          symbol: 'TPE',
          narrow: 'TPE',
        },
        TRL: {
          displayName: {
            other: 'Turkish Lira (1922–2005)',
            one: 'Turkish lira (1922–2005)',
          },
          symbol: 'TRL',
          narrow: 'TRL',
        },
        TRY: {
          displayName: { other: 'Turkish Lira', one: 'Turkish lira' },
          symbol: 'TRY',
          narrow: '₺',
        },
        TTD: {
          displayName: {
            other: 'Trinidad & Tobago dollars',
            one: 'Trinidad & Tobago dollar',
          },
          symbol: 'TTD',
          narrow: '$',
        },
        TWD: {
          displayName: {
            other: 'New Taiwan dollars',
            one: 'New Taiwan dollar',
          },
          symbol: 'NT$',
          narrow: '$',
        },
        TZS: {
          displayName: {
            other: 'Tanzanian shillings',
            one: 'Tanzanian shilling',
          },
          symbol: 'TZS',
          narrow: 'TZS',
        },
        UAH: {
          displayName: {
            other: 'Ukrainian hryvnias',
            one: 'Ukrainian hryvnia',
          },
          symbol: 'UAH',
          narrow: '₴',
        },
        UAK: {
          displayName: {
            other: 'Ukrainian karbovantsiv',
            one: 'Ukrainian karbovanets',
          },
          symbol: 'UAK',
          narrow: 'UAK',
        },
        UGS: {
          displayName: {
            other: 'Ugandan shillings (1966–1987)',
            one: 'Ugandan shilling (1966–1987)',
          },
          symbol: 'UGS',
          narrow: 'UGS',
        },
        UGX: {
          displayName: { other: 'Ugandan shillings', one: 'Ugandan shilling' },
          symbol: 'UGX',
          narrow: 'UGX',
        },
        USD: {
          displayName: { other: 'US dollars', one: 'US dollar' },
          symbol: 'US$',
          narrow: '$',
        },
        USN: {
          displayName: {
            other: 'US dollars (next day)',
            one: 'US dollar (next day)',
          },
          symbol: 'USN',
          narrow: 'USN',
        },
        USS: {
          displayName: {
            other: 'US dollars (same day)',
            one: 'US dollar (same day)',
          },
          symbol: 'USS',
          narrow: 'USS',
        },
        UYI: {
          displayName: {
            other: 'Uruguayan pesos (indexed units)',
            one: 'Uruguayan peso (indexed units)',
          },
          symbol: 'UYI',
          narrow: 'UYI',
        },
        UYP: {
          displayName: {
            other: 'Uruguayan pesos (1975–1993)',
            one: 'Uruguayan peso (1975–1993)',
          },
          symbol: 'UYP',
          narrow: 'UYP',
        },
        UYU: {
          displayName: { other: 'Uruguayan pesos', one: 'Uruguayan peso' },
          symbol: 'UYU',
          narrow: '$',
        },
        UYW: {
          displayName: {
            other: 'Uruguayan nominal wage index units',
            one: 'Uruguayan nominal wage index unit',
          },
          symbol: 'UYW',
          narrow: 'UYW',
        },
        UZS: {
          displayName: { other: 'Uzbekistani som' },
          symbol: 'UZS',
          narrow: 'UZS',
        },
        VEB: {
          displayName: {
            other: 'Venezuelan bolívars (1871–2008)',
            one: 'Venezuelan bolívar (1871–2008)',
          },
          symbol: 'VEB',
          narrow: 'VEB',
        },
        VEF: {
          displayName: {
            other: 'Venezuelan bolívars (2008–2018)',
            one: 'Venezuelan bolívar (2008–2018)',
          },
          symbol: 'VEF',
          narrow: 'Bs',
        },
        VES: {
          displayName: {
            other: 'Venezuelan bolívars',
            one: 'Venezuelan bolívar',
          },
          symbol: 'VES',
          narrow: 'VES',
        },
        VND: {
          displayName: { other: 'Vietnamese dong' },
          symbol: '₫',
          narrow: '₫',
        },
        VNN: {
          displayName: { other: 'Vietnamese dong (1978–1985)' },
          symbol: 'VNN',
          narrow: 'VNN',
        },
        VUV: {
          displayName: { other: 'Vanuatu vatu' },
          symbol: 'VUV',
          narrow: 'VUV',
        },
        WST: {
          displayName: { other: 'Samoan tala' },
          symbol: 'WST',
          narrow: 'WST',
        },
        XAF: {
          displayName: {
            other: 'Central African CFA francs',
            one: 'Central African CFA franc',
          },
          symbol: 'FCFA',
          narrow: 'FCFA',
        },
        XAG: {
          displayName: {
            other: 'troy ounces of silver',
            one: 'troy ounce of silver',
          },
          symbol: 'XAG',
          narrow: 'XAG',
        },
        XAU: {
          displayName: {
            other: 'troy ounces of gold',
            one: 'troy ounce of gold',
          },
          symbol: 'XAU',
          narrow: 'XAU',
        },
        XBA: {
          displayName: {
            other: 'European composite units',
            one: 'European composite unit',
          },
          symbol: 'XBA',
          narrow: 'XBA',
        },
        XBB: {
          displayName: {
            other: 'European monetary units',
            one: 'European monetary unit',
          },
          symbol: 'XBB',
          narrow: 'XBB',
        },
        XBC: {
          displayName: {
            other: 'European units of account (XBC)',
            one: 'European unit of account (XBC)',
          },
          symbol: 'XBC',
          narrow: 'XBC',
        },
        XBD: {
          displayName: {
            other: 'European units of account (XBD)',
            one: 'European unit of account (XBD)',
          },
          symbol: 'XBD',
          narrow: 'XBD',
        },
        XCD: {
          displayName: {
            other: 'East Caribbean dollars',
            one: 'East Caribbean dollar',
          },
          symbol: 'EC$',
          narrow: '$',
        },
        XDR: {
          displayName: { other: 'special drawing rights' },
          symbol: 'XDR',
          narrow: 'XDR',
        },
        XEU: {
          displayName: {
            other: 'European currency units',
            one: 'European currency unit',
          },
          symbol: 'XEU',
          narrow: 'XEU',
        },
        XFO: {
          displayName: {
            other: 'French gold francs',
            one: 'French gold franc',
          },
          symbol: 'XFO',
          narrow: 'XFO',
        },
        XFU: {
          displayName: { other: 'French UIC-francs', one: 'French UIC-franc' },
          symbol: 'XFU',
          narrow: 'XFU',
        },
        XOF: {
          displayName: {
            other: 'West African CFA francs',
            one: 'West African CFA franc',
          },
          symbol: 'F CFA',
          narrow: 'F CFA',
        },
        XPD: {
          displayName: {
            other: 'troy ounces of palladium',
            one: 'troy ounce of palladium',
          },
          symbol: 'XPD',
          narrow: 'XPD',
        },
        XPF: {
          displayName: { other: 'CFP francs', one: 'CFP franc' },
          symbol: 'CFPF',
          narrow: 'CFPF',
        },
        XPT: {
          displayName: {
            other: 'troy ounces of platinum',
            one: 'troy ounce of platinum',
          },
          symbol: 'XPT',
          narrow: 'XPT',
        },
        XRE: {
          displayName: { other: 'RINET Funds units', one: 'RINET Funds unit' },
          symbol: 'XRE',
          narrow: 'XRE',
        },
        XSU: {
          displayName: { other: 'Sucres', one: 'Sucre' },
          symbol: 'XSU',
          narrow: 'XSU',
        },
        XTS: {
          displayName: {
            other: 'Testing Currency units',
            one: 'Testing Currency unit',
          },
          symbol: 'XTS',
          narrow: 'XTS',
        },
        XUA: {
          displayName: {
            other: 'ADB units of account',
            one: 'ADB unit of account',
          },
          symbol: 'XUA',
          narrow: 'XUA',
        },
        XXX: {
          displayName: {
            other: '(unknown currency)',
            one: '(unknown unit of currency)',
          },
          symbol: '¤',
          narrow: '¤',
        },
        YDD: {
          displayName: { other: 'Yemeni dinars', one: 'Yemeni dinar' },
          symbol: 'YDD',
          narrow: 'YDD',
        },
        YER: {
          displayName: { other: 'Yemeni rials', one: 'Yemeni rial' },
          symbol: 'YER',
          narrow: 'YER',
        },
        YUD: {
          displayName: {
            other: 'Yugoslavian hard dinars (1966–1990)',
            one: 'Yugoslavian hard dinar (1966–1990)',
          },
          symbol: 'YUD',
          narrow: 'YUD',
        },
        YUM: {
          displayName: {
            other: 'Yugoslavian new dinars (1994–2002)',
            one: 'Yugoslavian new dinar (1994–2002)',
          },
          symbol: 'YUM',
          narrow: 'YUM',
        },
        YUN: {
          displayName: {
            other: 'Yugoslavian convertible dinars (1990–1992)',
            one: 'Yugoslavian convertible dinar (1990–1992)',
          },
          symbol: 'YUN',
          narrow: 'YUN',
        },
        YUR: {
          displayName: {
            other: 'Yugoslavian reformed dinars (1992–1993)',
            one: 'Yugoslavian reformed dinar (1992–1993)',
          },
          symbol: 'YUR',
          narrow: 'YUR',
        },
        ZAL: {
          displayName: {
            other: 'South African rands (financial)',
            one: 'South African rand (financial)',
          },
          symbol: 'ZAL',
          narrow: 'ZAL',
        },
        ZAR: {
          displayName: { other: 'South African rand' },
          symbol: 'ZAR',
          narrow: 'R',
        },
        ZMK: {
          displayName: {
            other: 'Zambian kwachas (1968–2012)',
            one: 'Zambian kwacha (1968–2012)',
          },
          symbol: 'ZMK',
          narrow: 'ZMK',
        },
        ZMW: {
          displayName: { other: 'Zambian kwachas', one: 'Zambian kwacha' },
          symbol: 'ZMW',
          narrow: 'ZK',
        },
        ZRN: {
          displayName: {
            other: 'Zairean new zaires (1993–1998)',
            one: 'Zairean new zaire (1993–1998)',
          },
          symbol: 'ZRN',
          narrow: 'ZRN',
        },
        ZRZ: {
          displayName: {
            other: 'Zairean zaires (1971–1993)',
            one: 'Zairean zaire (1971–1993)',
          },
          symbol: 'ZRZ',
          narrow: 'ZRZ',
        },
        ZWD: {
          displayName: {
            other: 'Zimbabwean dollars (1980–2008)',
            one: 'Zimbabwean dollar (1980–2008)',
          },
          symbol: 'ZWD',
          narrow: 'ZWD',
        },
        ZWL: {
          displayName: {
            other: 'Zimbabwean dollars (2009)',
            one: 'Zimbabwean dollar (2009)',
          },
          symbol: 'ZWL',
          narrow: 'ZWL',
        },
        ZWR: {
          displayName: {
            other: 'Zimbabwean dollars (2008)',
            one: 'Zimbabwean dollar (2008)',
          },
          symbol: 'ZWR',
          narrow: 'ZWR',
        },
      },
      numbers: {
        nu: ['latn'],
        symbols: {
          latn: {
            decimal: '.',
            group: ' ',
            list: ';',
            percentSign: '%',
            plusSign: '+',
            minusSign: '-',
            approximatelySign: '~',
            exponential: 'E',
            superscriptingExponent: '×',
            perMille: '‰',
            infinity: '∞',
            nan: 'NaN',
            timeSeparator: ':',
            rangeSign: '–',
          },
        },
        percent: { latn: '#,##0%' },
        decimal: {
          latn: {
            standard: '#,##0.###',
            long: {
              1000: { other: '0 thousand' },
              10000: { other: '00 thousand' },
              100000: { other: '000 thousand' },
              1000000: { other: '0 million' },
              10000000: { other: '00 million' },
              100000000: { other: '000 million' },
              1000000000: { other: '0 billion' },
              10000000000: { other: '00 billion' },
              100000000000: { other: '000 billion' },
              1000000000000: { other: '0 trillion' },
              10000000000000: { other: '00 trillion' },
              100000000000000: { other: '000 trillion' },
            },
            short: {
              1000: { other: '0K' },
              10000: { other: '00K' },
              100000: { other: '000K' },
              1000000: { other: '0M' },
              10000000: { other: '00M' },
              100000000: { other: '000M' },
              1000000000: { other: '0B' },
              10000000000: { other: '00B' },
              100000000000: { other: '000B' },
              1000000000000: { other: '0T' },
              10000000000000: { other: '00T' },
              100000000000000: { other: '000T' },
            },
          },
        },
        currency: {
          latn: {
            currencySpacing: {
              beforeInsertBetween: ' ',
              afterInsertBetween: ' ',
            },
            standard: '¤#,##0.00',
            accounting: '¤#,##0.00;(¤#,##0.00)',
            unitPattern: '{0} {1}',
            short: {
              1000: { other: '¤0K' },
              10000: { other: '¤00K' },
              100000: { other: '¤000K' },
              1000000: { other: '¤0M' },
              10000000: { other: '¤00M' },
              100000000: { other: '¤000M' },
              1000000000: { other: '¤0B' },
              10000000000: { other: '¤00B' },
              100000000000: { other: '¤000B' },
              1000000000000: { other: '¤0T' },
              10000000000000: { other: '¤00T' },
              100000000000000: { other: '¤000T' },
            },
          },
        },
      },
      nu: ['latn'],
    },
    locale: 'en-PT',
  })
}
