import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js'

import {
  InitAuth,
  SupportedAuthenticationLanguages,
} from 'mlp-client/src/auth/types'
import tenant from 'mlp-client/src/tenant/tenant'

const supportedAuthenticationLanguages: SupportedAuthenticationLanguages[] =
  Object.values(SupportedAuthenticationLanguages)

export const defaultAuthenticationLanguage = SupportedAuthenticationLanguages.EN

let auth0: Auth0Client
let auth0ClientId: string
let logoutRedirectUri: string

const isSupportedAuthenticationLanguage = (
  lang: string,
): lang is SupportedAuthenticationLanguages =>
  supportedAuthenticationLanguages.includes(
    lang as SupportedAuthenticationLanguages,
  )

export const auth0TenantParams = () => {
  if (tenant === 'ayvens') {
    return {
      organization: 'ayvens',
    }
  }

  return {}
}

export const initAuth = async ({ domain, clientId, audience }: InitAuth) => {
  const callBackOrigin = window.location.origin

  auth0 = await createAuth0Client({
    domain,
    clientId,
    authorizationParams: {
      audience,
      redirect_uri: `${callBackOrigin}/auth/login-redirect/`,
      scope: 'profile email openid',
    },
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
  })

  auth0ClientId = clientId
  logoutRedirectUri = `${callBackOrigin}/auth/logout-redirect/`
}

export const authorize = (lang: string, connection?: string) =>
  auth0.loginWithRedirect({
    authorizationParams: {
      ui_locales: isSupportedAuthenticationLanguage(lang)
        ? lang
        : defaultAuthenticationLanguage,
      ...auth0TenantParams(),
      ...(connection ? { connection } : {}),
    },
  })

export const logout = () =>
  auth0.logout({
    clientId: auth0ClientId,
    logoutParams: {
      returnTo: logoutRedirectUri,
    },
  })

export const isUserAuthorized = () => auth0?.isAuthenticated()

export const getAccessToken = () => auth0?.getTokenSilently()

export const redirectResult = () => auth0?.handleRedirectCallback()
