import { rest } from 'msw'

import invoicesError from 'mlp-client/src/mocks/json/invoices/error.json'
import invoiceDocumentError from 'mlp-client/src/mocks/json/invoices/invoice/document/error.json'
import invoiceDocumentError404 from 'mlp-client/src/mocks/json/invoices/invoice/document/error404.json'
import invoiceError from 'mlp-client/src/mocks/json/invoices/invoice/error.json'
import invoiceError404 from 'mlp-client/src/mocks/json/invoices/invoice/error404.json'
import invoice from 'mlp-client/src/mocks/json/invoices/invoice/invoice.json'
import invoicesEmpty from 'mlp-client/src/mocks/json/invoices/invoices-empty.json'
import invoices from 'mlp-client/src/mocks/json/invoices/invoices.json'
import invoicesMultiplePage_0 from 'mlp-client/src/mocks/json/invoices/invoicesMultiplePage-0.json'
import invoicesMultiplePage_1 from 'mlp-client/src/mocks/json/invoices/invoicesMultiplePage-1.json'
import invoicesMultiplePage_2 from 'mlp-client/src/mocks/json/invoices/invoicesMultiplePage-2.json'

export const handlers = [
  // invoices
  rest.get('*/api2/invoices/:accountId', async (req, res, ctx) => {
    const params = new URLSearchParams(window.location.search)
    const isEmpty = params.get('empty')
    const isMultiplePages = params.get('multiplePages')
    const scenario = params.get('invoices')
    const error = params.get('error')
    const urlSearch = req.url.search

    if (scenario) {
      if (error) {
        return res(ctx.status(500), ctx.json(invoicesError))
      }
    }

    if (isEmpty === 'true') {
      return res(ctx.json(invoicesEmpty))
    }

    if (isMultiplePages === 'true') {
      switch (urlSearch) {
        case '?page=0':
          return res(ctx.json(invoicesMultiplePage_0))
        case '?page=1':
          return res(ctx.json(invoicesMultiplePage_1))
        case '?page=2':
          return res(ctx.json(invoicesMultiplePage_2))
        default:
          return res(ctx.json(invoicesMultiplePage_0))
      }
    }

    return res(ctx.json(invoices))
  }),

  // invoice
  rest.get(
    '*/api2/invoices/:accountId/:invoiceNumber',
    async (req, res, ctx) => {
      const params = new URLSearchParams(window.location.search)
      const scenario = params.get('invoice')
      const error = params.get('error')

      if (scenario) {
        if (error === '404') {
          return res(ctx.status(404), ctx.json(invoiceError404))
        }

        if (error) {
          return res(ctx.status(500), ctx.json(invoiceError))
        }
      }

      return res(ctx.json(invoice))
    },
  ),

  // invoice document
  rest.get(
    '*/api2/invoices/:accountId/:invoiceNumber/document',
    async (req, res, ctx) => {
      const params = new URLSearchParams(window.location.search)
      const scenario = params.get('invoiceDocument')
      const error = params.get('error')

      if (scenario) {
        if (error === '404') {
          return res(ctx.status(404), ctx.json(invoiceDocumentError404))
        }

        if (error) {
          return res(ctx.status(500), ctx.json(invoiceDocumentError))
        }
      }
    },
  ),
]
