import {
  BookingEventStatuses,
  BookingServiceTypes,
} from 'mlp-client/src/bookings/enums'
import { ServiceType } from 'mlp-client/src/contracts/enums'
import {
  CompletedStatuses,
  DoneStatuses,
  FairWearAndTearEventStatuses,
  TimelineEventTypes,
} from 'mlp-client/src/timeline/enums'
import { Address, Mileage } from 'mlp-client/src/types'

export interface TimelineParams {
  accountId: string
  contractIds?: string[]
  page: number
  itemsPerPage: number
}

export interface TimelineData {
  events: TimelineItem[]
  metadata: {
    totalCount: number
  }
}

interface Pagination {
  currentPage: number
  itemsPerPage: number
}

export interface TimelineState {
  selectedVehicles: string[]
  pagination: Pagination
}

interface TimelineItemVehicle {
  registrationNumber: string
  make: string
  model: string
}

export interface BaseTimelineItem {
  ilan: string
  type: TimelineEventTypes
  creationDate: string
  vehicle: TimelineItemVehicle
}

export const TimelineItemStatus = {
  ...CompletedStatuses,
  ...BookingEventStatuses,
  ...FairWearAndTearEventStatuses,
  ...DoneStatuses,
}

export type TimelineItemStatus =
  | CompletedStatuses
  | BookingEventStatuses
  | FairWearAndTearEventStatuses
  | DoneStatuses

export interface ContractActivatedTimelineItem extends BaseTimelineItem {
  status: CompletedStatuses
  startDate: string
  productDescription: string
}

export interface ChangeDriverTimelineItem extends BaseTimelineItem {
  status: CompletedStatuses
  startDate: string
  productDescription: string
}

export interface ChangeTermAndMileageTimelineItem extends BaseTimelineItem {
  status: CompletedStatuses
  endDate: string
  changeDate: string
  mileage: Mileage
}

export interface ChangeCostCenterTimelineItem extends BaseTimelineItem {
  status: CompletedStatuses
  changeDate: string
  costCenter: string
}

export interface ChangeBrokerTimelineItem extends BaseTimelineItem {
  status: CompletedStatuses
  broker: TimelineBroker
}

interface TimelineBroker {
  name: string
  phone: string
  email: string
  address?: Address
}

export interface ChangeRegistrationNumberTimelineItem extends BaseTimelineItem {
  status: CompletedStatuses
}

export interface FormalExtensionTimelineItem extends BaseTimelineItem {
  status: CompletedStatuses
  endDate: string
}

export interface EndOfContractTimelineItem extends BaseTimelineItem {
  status: CompletedStatuses
  endDate: string
}

export interface ChangeCustomerTimelineItem extends BaseTimelineItem {
  status: CompletedStatuses
  transferDate: string
}

export interface MaintenanceTimelineItem extends BaseTimelineItem {
  status: BookingEventStatuses
  bookingId: string
  appointmentDate: string
  locationName: string
  services: readonly BookingServiceTypes[]
}

export interface VehicleCollectionTimelineItem extends BaseTimelineItem {
  status: BookingEventStatuses
  bookingId: string
  appointmentDate: string
  locationName: string
}

export interface RoadsideAssistanceTimelineItem extends BaseTimelineItem {
  status: CompletedStatuses
  appointmentDate: string
}

export interface FairWearAndTearTimelineItem extends BaseTimelineItem {
  status: FairWearAndTearEventStatuses
  assessmentId: string
}

export interface ActualMileageUpdatedTimelineItem extends BaseTimelineItem {
  status: DoneStatuses
  mileage: Mileage
}

export interface BalloonRefinanceTimelineItem extends BaseTimelineItem {
  status: CompletedStatuses
  endDate: string
}

export interface ChangeServicesTimelineItem extends BaseTimelineItem {
  status: CompletedStatuses
  serviceTypes: ServiceType[]
}

export interface ChangeExtraordinaryPaymentTimelineItem
  extends BaseTimelineItem {
  status: CompletedStatuses
}

export interface SecondaryRentalTimelineItem extends BaseTimelineItem {
  status: CompletedStatuses
}

export type TimelineItem =
  | ContractActivatedTimelineItem
  | ChangeDriverTimelineItem
  | ChangeTermAndMileageTimelineItem
  | ChangeCostCenterTimelineItem
  | ChangeBrokerTimelineItem
  | ChangeRegistrationNumberTimelineItem
  | FormalExtensionTimelineItem
  | EndOfContractTimelineItem
  | ChangeCustomerTimelineItem
  | MaintenanceTimelineItem
  | VehicleCollectionTimelineItem
  | RoadsideAssistanceTimelineItem
  | FairWearAndTearTimelineItem
  | ActualMileageUpdatedTimelineItem
  | BalloonRefinanceTimelineItem
  | ChangeServicesTimelineItem
  | ChangeExtraordinaryPaymentTimelineItem
  | SecondaryRentalTimelineItem
