import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { v4 as uuid } from 'uuid'

import { Origin } from 'mlp-client/src/enums'
import {
  getLanguage,
  getCountry,
} from 'mlp-client/src/localization/LocaleProvider'
import { setApiError } from 'mlp-client/src/services/api/apiErrorsSlice'
import {
  isUserAuthorized,
  getAccessToken,
  logout,
} from 'mlp-client/src/services/auth/auth'
import RuntimeEnv from 'mlp-client/src/utils/runtimeEnv'

export const baseUrl = '/api2'

const getAuthHeader = async (): Promise<
  { Authorization: string } | undefined
> => {
  const isAuthorized = await isUserAuthorized()

  if (isAuthorized) {
    try {
      const accessToken = await getAccessToken()

      return { Authorization: `Bearer ${accessToken}` }
    } catch (e) {
      // On rare occasions, refreshing the access token fails which corrupts the local storage
      // Logout the user when this happens and throw an error
      logout()

      throw new Error(`Refreshing the access token failed: ${e}`)
    }
  }

  return undefined
}

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: async headers => {
    const authHeader = await getAuthHeader()

    const languageCode = getLanguage()
    const countryCode = getCountry().toUpperCase()

    const parsedLocale =
      languageCode && countryCode ? `${languageCode}-${countryCode}` : ''
    const mime = 'application/json'
    const referrerPolicy = 'no-referrer'

    headers.set('Authorization', authHeader?.Authorization)
    headers.set('X-LPD-Locale', parsedLocale)
    headers.set('X-LPD-CountryCode', countryCode)
    headers.set('X-LPD-Origin', Origin.Web)
    headers.set('Referrer-Policy', referrerPolicy)
    headers.set('correlation-id', uuid())

    // No cache
    headers.set('Cache-Control', 'no-cache, no-store, must-revalidate')
    headers.set('Expires', '-1')
    headers.set('Pragma', 'no-cache')

    // prepareHeaders is executed *after* the code in the endpoint, therefore overrides need to happen here
    if (!headers.has('Accept')) {
      headers.set('Accept', mime)
    }

    if (!headers.has('Content-Type')) {
      headers.set('Content-Type', mime)
    }

    return headers
  },
})

export const api = createApi({
  keepUnusedDataFor: RuntimeEnv.isTest ? 0 : 60,
  baseQuery: async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions)
    if (result.error) {
      api.dispatch(
        setApiError({
          error: result.error,
          endpoint: api.endpoint,
        }),
      )
    }
    return result
  },
  tagTypes: [
    'Assessment',
    'Booking',
    'ContractDetails',
    'Contracts',
    'Dictionary',
    'DocumentDownload',
    'Documents',
    'Invoices',
    'Timeline',
    'User',
  ],
  endpoints: () => ({}),
})
