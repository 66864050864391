import React, { PropsWithChildren, useEffect, useState } from 'react'

import countryConfigs from 'mlp-client/src/config'
import { CountryCode } from 'mlp-client/src/localization/enums'
import {
  useLocale,
  useCountry,
} from 'mlp-client/src/localization/LocaleProvider'
import { useLocalizedRoute } from 'mlp-client/src/localization/RoutesProvider'
import withUser from 'mlp-client/src/user/components/withUser'
import { useGetCountryCode } from 'mlp-client/src/user/hooks/useUser'

/**
 * The component redirects an authenticated user to myPortal dashboard page
 * if they try to access a page/flow in a country they don't belong to.
 * For instance, if a Finnish user tries to access a French myLP page he/she
 * will be redirected to Finnish dashboard page. The country a user belongs to
 * comes from users/me.
 */

export const UserCountryRouteProtector: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [shouldRedirect, setShouldRedirect] = useState(null)
  const [targetLocale, setTargetLocale] = useState('')
  const getLocalizedRoute = useLocalizedRoute()
  const userCountryCode = useGetCountryCode()
  const redirectRoute = getLocalizedRoute('myPortal.homepage')
  const currentCountry = useCountry()
  const currentCountryCode = CountryCode[currentCountry]
  const currentLocale = useLocale()

  const getShouldRedirect = () => {
    const getTargetLocale = (countryCode: CountryCode | undefined) => {
      const languages = countryConfigs.find(
        country => country.code === countryCode,
      )?.languages

      if (languages) {
        return (languages.find(lang => lang.preferred === true) || languages[0])
          ?.locale
      }
    }

    const newTargetLocale = getTargetLocale(userCountryCode)
    setTargetLocale(newTargetLocale)

    setShouldRedirect(
      userCountryCode &&
        newTargetLocale &&
        redirectRoute &&
        userCountryCode !== currentCountryCode,
    )
  }

  useEffect(() => {
    getShouldRedirect()
  }, [])

  if (shouldRedirect) {
    const targetRoute = redirectRoute.replace(currentLocale, targetLocale)

    window.location.replace(targetRoute)
  }

  if (shouldRedirect || shouldRedirect === null) {
    return null
  }

  return <>{children}</>
}

export default <T extends PropsWithChildren>(
  ProtectedComponent: React.ComponentType<T>,
) => {
  const ProtectedUserCountryRoute: React.FC<T> = props => (
    <UserCountryRouteProtector>
      <ProtectedComponent {...props} />
    </UserCountryRouteProtector>
  )

  ProtectedUserCountryRoute.displayName = `protectedUserCountryRoute(${
    ProtectedComponent.displayName || ProtectedComponent.name
  })`

  return withUser(ProtectedUserCountryRoute)
}
