import React from 'react'

import InfoCard from 'mlp-client/src/components/info-card/InfoCard'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'

const OldInvoices: React.FC = () => {
  const { translate } = useTranslations()

  const goLiveDate = translate('myPortal.invoices.oldInvoices.goLiveDate')
  const oldInvoicesURL = translate('myPortal.invoices.oldInvoices.link.url')

  return (
    <InfoCard
      title="myPortal.invoices.oldInvoices.missingInvoices"
      summary={
        <Translation
          id="myPortal.invoices.oldInvoices.summary"
          replace={{ goLiveDate }}
        />
      }
      linkURL={oldInvoicesURL}
      linkText="myPortal.invoices.oldInvoices.link.title"
    />
  )
}

export default OldInvoices
