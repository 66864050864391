import React from 'react'

import { HeadlineStyled } from 'mlp-client/src/dashboard/components/dashboard-content/DashboardContent.styled'
import Translation from 'mlp-client/src/localization/Translation'

const DashboardContentHeader: React.FC = () => (
  <HeadlineStyled variant="200" component="h2">
    <Translation id="myPortal.dashboard.default.dashboard.servicesTitle" />
  </HeadlineStyled>
)

export default DashboardContentHeader
