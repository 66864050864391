import { PhoneIcon } from '@velocity/icons/system'
import { Text, Button } from '@velocity/ui'
import { FlexBox } from '@velocity/ui/draft'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { RoadsideAssistanceDialogModal } from 'mlp-client/src/dashboard/types'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'
import {
  trackRoadsideAssistanceOpenEvent,
  trackRoadsideAssistanceCallEvent,
} from 'mlp-client/src/services/analytics/analytics'

const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
`

interface Props {
  modalContent: RoadsideAssistanceDialogModal
}

const RoadsideAssistanceModalContent: React.FC<Props> = ({ modalContent }) => {
  const { translate } = useTranslations()
  const { phoneNumber, button, title, summary } = modalContent

  const onClick = () =>
    trackRoadsideAssistanceCallEvent({
      data: {
        component_title: translate(title),
        component_link_text: translate(button),
        component_link_url: `tel:${translate(phoneNumber)}`,
      },
    })

  useEffect(() => {
    trackRoadsideAssistanceOpenEvent({
      data: {
        component_title: translate(title),
      },
    })
  }, [])

  return (
    <>
      <Text layoutMargin={{ bottom: '04' }}>
        <Translation id={summary} />
      </Text>
      <FlexBox justifyContent="flex-start">
        <StyledLink href={`tel:${translate(phoneNumber)}`}>
          <Button
            variant="primary-filled"
            data-e2e-id="phoneButtonLink"
            StartIcon={PhoneIcon}
            onClick={onClick}
          >
            {translate(phoneNumber)}
          </Button>
        </StyledLink>
      </FlexBox>
    </>
  )
}

export default RoadsideAssistanceModalContent
