import { camelCase } from 'lodash'

import { Address } from 'mlp-client/src/types'

export const makeTranslationPath = (path: string) => (definition: string) =>
  `${path}.${definition}`

export const fromCamelCaseToUpperSnake = (camelCase: string): string =>
  camelCase.replace(/([A-Z])/g, '_$1').toUpperCase()

export const fromCamelCaseToSpacedString = (camelCase: string): string =>
  `${camelCase[0].toUpperCase()}${camelCase
    .replace(/([A-Z])/g, ' $1')
    .toLowerCase()
    .slice(1)}`

export const fromPascalCaseToSpacedString = (pascalCase: string) =>
  fromCamelCaseToSpacedString(camelCase(pascalCase))

export const fromKebabCaseToUpperSnake = (KebabCase = ''): string =>
  KebabCase.replace(/-/g, '_').toUpperCase()

export const fromUpperSnakeToCamelCase = (string = ''): string =>
  string
    .toLowerCase()
    .replace(/([-_][a-z])/g, group =>
      group.toUpperCase().replace('-', '').replace('_', ''),
    )

export const leaveOnlyNumbers = (value: string): string => {
  const onlyNumbers = value.match(/\d+/g)

  return onlyNumbers ? onlyNumbers.join('') : ''
}

export type AddressFormatter = (address: Address, separator?: string) => string

export const defaultAddressOrder: (keyof Address)[] = [
  'houseNumber',
  'houseNumberSuffix',
  'streetLine1',
  'streetLine2',
  'city',
  'province',
  'zipCode',
  'country',
]

export const UKAddressOrder: (keyof Address)[] = [
  'streetLine1',
  'streetLine2',
  'city',
  'province',
  'zipCode',
  'country',
]

const formatAddressStreetToOneLine = (address?: Address) => {
  if (!address) {
    return address
  }
  const { houseNumber, houseNumberSuffix } = address
  let streetLine1 = address.streetLine1 || ''

  if (houseNumber) {
    if (houseNumberSuffix) {
      streetLine1 = `${houseNumber} ${houseNumberSuffix} ${streetLine1}`
    } else {
      streetLine1 = `${houseNumber} ${streetLine1}`
    }
  }
  return { ...address, streetLine1 }
}

export const formatAddress =
  (addressOrder: (keyof Address)[]) =>
  (address?: Address, separator = ', ') => {
    if (!address) {
      return ''
    }
    const orderedAddressFields = addressOrder.reduce(
      (acc, key: keyof Address) => {
        if (address[key]) {
          acc.push(address[key])
        }
        return acc
      },
      [],
    )
    return orderedAddressFields.join(separator)
  }

export const defaultFormatAddress: AddressFormatter =
  formatAddress(defaultAddressOrder)

export const formatUKAddress: AddressFormatter = (
  address: Address,
  separator: string,
) =>
  formatAddress(UKAddressOrder)(
    formatAddressStreetToOneLine(address),
    separator,
  )
