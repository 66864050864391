import React, { useState } from 'react'

import { EndOfLeaseBackdrop } from './EndOfLease.styled'

import EndOfLeaseModal from 'mlp-client/src/end-of-lease/modal/EndOfLeaseModal'
import EndOfLeaseNotification from 'mlp-client/src/end-of-lease/notifications/EndOfLeaseNotification'
import InformalExtensionNotification from 'mlp-client/src/end-of-lease/notifications/InformalExtensionNotification'

export const EndOfLeaseNotificationsContainer = () => {
  const [modalVisible, setModalVisible] = useState(false)

  const handleLearnMoreClick = () => {
    setModalVisible(true)
  }

  return (
    <EndOfLeaseBackdrop data-e2e-id="eolBackdrop">
      <EndOfLeaseNotification onLearnMoreClick={handleLearnMoreClick} />
      <InformalExtensionNotification onLearnMoreClick={handleLearnMoreClick} />

      {modalVisible && (
        <EndOfLeaseModal onClose={() => setModalVisible(false)} />
      )}
    </EndOfLeaseBackdrop>
  )
}
