import React from 'react'
import { FormattedTime } from 'react-intl'

import { DateFormat } from 'mlp-client/src/localization/components/DateFormat'
import Translation from 'mlp-client/src/localization/Translation'
import BaseTimelineCard, {
  TimelineItemProps,
} from 'mlp-client/src/timeline/components/TimelineCard/BaseTimelineCard'
import { RoadsideAssistanceTimelineItem } from 'mlp-client/src/timeline/types'

const RoadsideAssistanceTimelineCard: React.FC<
  TimelineItemProps<RoadsideAssistanceTimelineItem>
> = ({ item, config }) => (
  <BaseTimelineCard
    item={item}
    config={config}
    status={item.status}
    title="myPortal.timeline.events.roadsideAssistance.title"
  >
    <Translation
      id="myPortal.timeline.events.roadsideAssistance.subtitle"
      replace={{
        appointmentDate: () => (
          <DateFormat
            value={item.appointmentDate}
            key="appointmentDate"
            literal="-"
          />
        ),
        appointmentStart: () => (
          <FormattedTime
            value={item.appointmentDate}
            key="appointmentStart"
            hour="numeric"
            minute="numeric"
          />
        ),
      }}
    />
  </BaseTimelineCard>
)

export default RoadsideAssistanceTimelineCard
