import { rest } from 'msw'

import assessment9100 from 'mlp-client/src/mocks/json/assessments/89c3ecba-5b55-465b-b8a2-650d87619100.json'
import assessmentChallengesError from 'mlp-client/src/mocks/json/assessments/challenges/error.json'
import assessmentChallengesError400 from 'mlp-client/src/mocks/json/assessments/challenges/error400.json'
import assessmentError from 'mlp-client/src/mocks/json/assessments/error.json'
import assessmentError404 from 'mlp-client/src/mocks/json/assessments/error404.json'

export const handlers = [
  // assessments
  rest.get(
    '*/api2/assessments/:accountId/:assessmentId/:contractId',
    async (req, res, ctx) => {
      const params = new URLSearchParams(window.location.search)
      const scenario = params.get('assessments')
      const error = params.get('error')

      if (scenario) {
        if (error === '404') {
          return res(ctx.status(404), ctx.json(assessmentError404))
        }

        if (error) {
          return res(ctx.status(500), ctx.json(assessmentError))
        }
      }

      return res(ctx.json(assessment9100))
    },
  ),

  rest.post(
    '*/api2/assessments/:accountId/:assessmentId/challenges',
    async (req, res, ctx) => {
      const params = new URLSearchParams(window.location.search)
      const scenario = params.get('assessmentChallenges')
      const error = params.get('error')

      if (scenario) {
        if (error === '400') {
          return res(ctx.status(400), ctx.json(assessmentChallengesError400))
        }

        if (error) {
          return res(ctx.status(500), ctx.json(assessmentChallengesError))
        }
      }

      return res(ctx.json({}))
    },
  ),
]
