import React, { useEffect } from 'react'

import { BrokerInformationModalContent } from 'mlp-client/src/components/broker-information/BrokerInformationModalContent'
import { DialogModal } from 'mlp-client/src/features-configuration/types'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import {
  trackContractChangeOpenEvent,
  trackContractChangeContactEvent,
} from 'mlp-client/src/services/analytics/analytics'

interface Props {
  modalContent: DialogModal
}

const ContractChangeModalContent: React.FC<Props> = ({ modalContent }) => {
  const { translate } = useTranslations()

  useEffect(() => {
    trackContractChangeOpenEvent({
      data: {
        component_title: translate(modalContent.title),
      },
    })
  }, [])

  const handleButtonClick = (linkTextTranslationId: string, url: string) =>
    trackContractChangeContactEvent({
      data: {
        component_title: translate(modalContent.title),
        component_link_text: translate(linkTextTranslationId),
        component_link_url: url,
      },
    })

  return (
    <BrokerInformationModalContent
      dialogModal={modalContent}
      handleButtonFirstClick={handleButtonClick}
      handleButtonSecondClick={handleButtonClick}
    />
  )
}

export default ContractChangeModalContent
