import {
  Button,
  LinkInline,
  Loader,
  NotificationBanner,
  Text,
} from '@velocity/ui'
import React, { useState, useEffect } from 'react'

import MileageField, {
  MAX_MILEAGE,
  MIN_MILEAGE,
} from 'mlp-client/src/components/mileage-update-modal/mileage-field/MileageField'
import {
  InputContainerStyled,
  LabelTextStyled,
  StyledButtonsWrapper,
  StyledFormContainer,
  StyledWrapper,
  SummaryTextStyled,
} from 'mlp-client/src/components/mileage-update-modal/MileageUpdate.styled'
import { useToastsWithTranslation } from 'mlp-client/src/components/toasts/useToasts'
import { useUpdateMileageMutation } from 'mlp-client/src/contracts/contractsApi'
import { useCurrentContractId } from 'mlp-client/src/contracts/hooks/useContracts'
import { DistanceUnit } from 'mlp-client/src/enums'
import { Mileage } from 'mlp-client/src/localization/components/Mileage'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'
import { trackMileageUpdateOpenEvent } from 'mlp-client/src/services/analytics/analytics'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

// verify step will show a warning if the new milage is
// higher than lastKnownMileage + 10000
const MAX_ADDED_MILEAGE = 10000

export interface Props {
  title: string
  leaseObjectId: string
  lastKnownMileage?: number
  mileageUnit: DistanceUnit
  closeModal(): void
  onSubmitSuccessButton(): void
  showCloseButton?: boolean
  summary?: React.ReactNode
}

const MileageUpdateLoader: React.FC = () => {
  const { translate } = useTranslations()
  return (
    <StyledWrapper>
      <Loader
        variant="primary"
        size="l"
        data-e2e-id="loader"
        aria-label={translate('myPortal.mileageUpdate.modal.processing')}
      />
      <Text layoutMargin={{ top: '03' }}>
        <Translation id="myPortal.mileageUpdate.modal.processing" />
      </Text>
    </StyledWrapper>
  )
}

const MileageUpdateFailure: React.FC = () => (
  <NotificationBanner layoutMargin={{ bottom: '03' }} kind="error">
    <Translation id="myPortal.shared.formSubmitFailedMessage" />
  </NotificationBanner>
)

const getValidation =
  (testFn: (value: number) => boolean, message: string) => (value: number) =>
    (!testFn(value) && message) || undefined

export const MileageUpdate: React.FC<Props> = ({
  title,
  mileageUnit,
  leaseObjectId,
  lastKnownMileage,
  closeModal,
  onSubmitSuccessButton,
  showCloseButton = true,
  summary,
}) => {
  const [page, setPage] = useState(0)
  const [submitting, setSubmitting] = useState(false)
  const [mileage, setMileage] = useState<number | undefined>(undefined)
  const { showSuccess, showError } = useToastsWithTranslation()
  const accountId = useSelectedAccountId()
  const contractId = useCurrentContractId()
  const { translate } = useTranslations()
  const [
    updateMileage,
    {
      isSuccess: isMileageUpdated,
      isError: isUpdateMileageError,
      reset: resetUpdateMileage,
    },
  ] = useUpdateMileageMutation()

  const onUpdateMileage = () => {
    resetUpdateMileage()
    closeModal()
    setSubmitting(false)
    setPage(0)
  }

  useEffect(() => {
    if (isUpdateMileageError) {
      showError(
        'myPortal.error.technicalErrorNoMileage.title',
        <Translation
          id="myPortal.error.technicalErrorNoMileage.content"
          replace={{
            contentURL: () => (
              <LinkInline
                target="_blank"
                href={translate(
                  'myPortal.error.technicalErrorNoMileage.contentUrl',
                )}
                key="contentURL"
              >
                <Translation id="myPortal.error.technicalErrorNoMileage.contentUrlLabel" />
              </LinkInline>
            ),
          }}
        />,
      )
      onUpdateMileage()
    }
  }, [isUpdateMileageError])

  useEffect(() => {
    if (isMileageUpdated) {
      showSuccess(
        'myPortal.mileageUpdate.modal.toaster.title',
        'myPortal.mileageUpdate.modal.toaster.summary',
      )
      onSubmitSuccessButton()
      onUpdateMileage()
    }
  }, [isMileageUpdated])

  const handleSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (page === 0) {
      setPage(1)
      return
    }
    setSubmitting(true)

    updateMileage({
      accountId,
      contractId,
      mileage,
      unit: mileageUnit,
      leaseObjectId,
    })
  }

  const handleMileageChange = (mileage: string) => {
    if (!mileage) {
      setMileage(undefined)
      return
    }

    const updatedMileage = parseInt(mileage, 10)

    if (
      !isNaN(updatedMileage) &&
      updatedMileage <= MAX_MILEAGE &&
      updatedMileage >= MIN_MILEAGE
    ) {
      setMileage(updatedMileage)
    }
  }

  const renderNextButtons = () => (
    <>
      <Button
        variant="primary-filled"
        fullWidth={false}
        data-e2e-id="mileageUpdateNextButton"
        disabled={!mileage}
        type="submit"
      >
        <Translation id="myPortal.mileageUpdate.modal.buttons.updateMileage" />
      </Button>

      {showCloseButton && (
        <Button
          variant="secondary-outlined"
          onClick={closeModal}
          data-e2e-id="mileageUpdateCloseButton"
        >
          <Translation id="myPortal.mileageUpdate.modal.buttons.cancel" />
        </Button>
      )}
    </>
  )

  const renderSubmitButtons = () => (
    <>
      <Button
        variant="primary-filled"
        fullWidth={false}
        data-e2e-id="mileageUpdateSubmitButton"
        type="submit"
      >
        <Translation id="myPortal.mileageUpdate.modal.buttons.yes" />
      </Button>

      <Button variant="secondary-outlined" onClick={() => setPage(0)}>
        <Translation id="myPortal.mileageUpdate.modal.buttons.no" />
      </Button>
    </>
  )

  const renderVerify = () => {
    const tooHigh = lastKnownMileage + MAX_ADDED_MILEAGE

    const validations = [
      getValidation(
        num => num > (lastKnownMileage || 0),
        'myPortal.mileageUpdate.modal.lowWarning',
      ),
      getValidation(
        num => num < tooHigh,
        'myPortal.mileageUpdate.modal.highWarning',
      ),
    ].filter(Boolean)

    const error = validations
      .map(validation => validation(mileage))
      .filter(Boolean)

    return (
      <>
        {Boolean(error?.[0]) && (
          <NotificationBanner
            data-e2e-id="mileageUpdateVerifyWarning"
            layoutMargin={{ bottom: '03' }}
            kind="warning"
          >
            <Translation id={error[0]} />
          </NotificationBanner>
        )}
        <Text data-e2e-id="mileageUpdateVerifyText" variant="300">
          <Translation
            id="myPortal.mileageUpdate.modal.confirm"
            replace={{
              mileage: () => (
                <Text key="mileage" component="span" bold={true} variant="300">
                  <Mileage
                    value={mileage}
                    mileageUnit={mileageUnit}
                    unitDisplay="short"
                  />
                </Text>
              ),
            }}
          />
        </Text>
      </>
    )
  }

  const renderInput = () => (
    <InputContainerStyled>
      {summary || (
        <SummaryTextStyled component="p" variant="300">
          <Translation id="myPortal.mileageUpdate.modal.summary" />
        </SummaryTextStyled>
      )}
      <LabelTextStyled component="p" variant="200" bold={true}>
        <Translation id="myPortal.mileageUpdate.modal.currentMileage" />
      </LabelTextStyled>
      <MileageField
        mileage={mileage}
        onMileageChange={handleMileageChange}
        lastKnownMileage={lastKnownMileage}
        mileageUnit={mileageUnit}
      />
    </InputContainerStyled>
  )

  useEffect(() => {
    trackMileageUpdateOpenEvent({
      data: {
        component_title: title,
      },
    })
  }, [])

  if (!leaseObjectId) {
    return (
      <StyledWrapper>
        <MileageUpdateFailure />
      </StyledWrapper>
    )
  }

  if (submitting) {
    return <MileageUpdateLoader />
  }

  return (
    <>
      <form onSubmit={handleSubmit} data-e2e-id="mileageUpdateModalForm">
        <StyledFormContainer>
          {page === 0 && renderInput()}
          {page === 1 && renderVerify()}
        </StyledFormContainer>

        {/*
            This StyledButtonsWrapper imitates the Modal.Footer from velocity modals,
            couldn't get it to work without a lot of refactoring but eventually this should
            be in Modal.Footer
          */}
        <StyledButtonsWrapper>
          {page === 0 && renderNextButtons()}
          {page === 1 && renderSubmitButtons()}
        </StyledButtonsWrapper>
      </form>
    </>
  )
}
