import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { clearNextRoute } from 'mlp-client/src/auth/authSlice'
import { useNextRoute } from 'mlp-client/src/auth/hooks/useAuth'
import NoIndexLoader from 'mlp-client/src/components/loader/NoIndexLoader'
import { useAppDispatch } from 'mlp-client/src/hooks'
import { useLocalizedRoute } from 'mlp-client/src/localization/RoutesProvider'
import { clearSelectedAccountId } from 'mlp-client/src/user/userSlice'

const LogoutRedirect: React.FC = () => {
  const nextRoute = useNextRoute()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const getLocalizedRoute = useLocalizedRoute()

  const processLogoutResponse = () => {
    dispatch(clearSelectedAccountId())
    navigate(nextRoute || getLocalizedRoute('myPortal.login'))
    dispatch(clearNextRoute())
  }

  useEffect(() => {
    processLogoutResponse()
  }, [])

  return <NoIndexLoader />
}

export default LogoutRedirect
