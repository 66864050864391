import { rest } from 'msw'

import mileageError from 'mlp-client/src/mocks/json/mileage/error.json'
import mileageError404 from 'mlp-client/src/mocks/json/mileage/error404.json'

export const handlers = [
  rest.post('*/api2/cases/:accountId/mileage', async (req, res, ctx) => {
    const params = new URLSearchParams(window.location.search)
    const scenario = params.get('mileage')
    const error = params.get('error')

    if (scenario) {
      if (error === '404') {
        return res(ctx.status(404), ctx.json(mileageError404))
      }

      if (error) {
        return res(ctx.status(500), ctx.json(mileageError))
      }
    }

    return res(ctx.json({}))
  }),
]
