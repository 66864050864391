export enum BookingEventStatuses {
  CANCELLED = 'Cancelled',
  COMPLETED = 'Completed',
  CONFIRMED = 'Confirmed',
  FAILED = 'Failed',
  IN_PROGRESS = 'InProgress',
  PENDING_CONFIRMATION = 'PendingConfirmation',
  UNKNOWN = 'Unknown',
}

export enum BookingServiceTypes {
  MAINTENANCE = 'Maintenance',
  REPAIR = 'Repair',
  INSPECTION = 'Inspection',
  RECALL = 'Recall',
}

export enum BookingServicesGroups {
  SGP_RPRMN = 'SGP-RPRMN',
}

export enum ServiceCode {
  MYLP = 'MYLP',
}

export enum BookingDetailsTypes {
  MAINTENANCE = 'Maintenance',
  VEHICLE_COLLECTION = 'VehicleCollection',
}

export enum AdditionalServices {
  REPLACEMENT_VEHICLE = 'ReplacementVehicle',
  COLLECT_AND_RETURN = 'CollectAndReturn',
}
