import { Footer as VelocityFooter } from '@velocity/ui'
import React from 'react'

import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { useFooterLinksList } from 'mlp-client/src/localization/LocaleProvider'
import Translation from 'mlp-client/src/localization/Translation'
import { trackFooterNavigationEvent } from 'mlp-client/src/services/analytics/analytics'

const Footer: React.FC = () => {
  const footerItems = useFooterLinksList()
  const { translate } = useTranslations()
  return (
    <VelocityFooter
      layoutContainerSize="fixed-wide"
      data-e2e-id="footer"
      copyright={
        <Translation
          id="myPortal.shared.footer.copyright"
          replace={{ year: new Date().getFullYear() }}
          data-e2e-id="footerCopyright"
        />
      }
    >
      <VelocityFooter.Legal aria-label="Legal links">
        {/*The ToggleCookieSettings method is added by the OneTrust SDK in index.ejs.ejs*/}
        {window.ToggleCookieSettings && (
          <VelocityFooter.Legal.Link
            data-e2e-id="footerCookieSettings"
            onClick={() => {
              window.ToggleCookieSettings()
            }}
          >
            <Translation id="myPortal.footer.cookieSettings.label" />
          </VelocityFooter.Legal.Link>
        )}

        {footerItems.map(item => {
          const url = translate(`${item}.url`)
          const title = translate(`${item}.label`)

          const onLinkClick = () =>
            trackFooterNavigationEvent({
              data: {
                component_link_text: title,
                component_link_url: url,
              },
            })

          return (
            <VelocityFooter.Legal.Link
              key={title}
              href={url}
              data-e2e-id={url}
              onClick={onLinkClick}
              target="_blank"
            >
              <Translation id={title} />
            </VelocityFooter.Legal.Link>
          )
        })}
      </VelocityFooter.Legal>
    </VelocityFooter>
  )
}

export default Footer
