import { type StoreEnhancer } from '@reduxjs/toolkit'

// This is a very simple implementation of persistent session storage for Redux store

// Sync only selected paths from/to the store
const sessionStoragePaths = ['auth']

const getReduxInitialStateFromSessionStorage = (key: string) => {
  const persistedState = sessionStorage.getItem(key)
  if (persistedState) {
    return JSON.parse(persistedState)
  }
}

const sessionStorageEnhancer: StoreEnhancer =
  createStore => (reducer, initialState) => {
    const storageKey = 'redux'
    const storageInitialState =
      getReduxInitialStateFromSessionStorage(storageKey)
    const store = createStore(reducer, {
      ...initialState,
      ...storageInitialState,
    })

    store.subscribe(() => {
      const storeState = store.getState()
      const syncedState = Object.keys(storeState)
        .filter(key => sessionStoragePaths.includes(key))
        .reduce<Partial<typeof storeState>>((acc, current) => {
          acc[current as keyof typeof storeState] =
            storeState[current as keyof typeof storeState]
          return acc
        }, {})

      sessionStorage.setItem(storageKey, JSON.stringify(syncedState))
    })

    return store
  }

export default sessionStorageEnhancer
