import { api } from 'mlp-client/src/services/api/api'

export interface DictionaryParams {
  locale: string
}

const dictionaryApi = api.injectEndpoints({
  endpoints: builder => ({
    getDictionary: builder.query<GenericObject, DictionaryParams>({
      query: ({ locale }) => ({
        url: `/sitecore/sitedata/dictionary/myleaseplan/${locale}`,
        method: 'GET',
        params: {
          dlang: locale,
        },
      }),
      providesTags: ['Dictionary'],
    }),
  }),
})

export const { useGetDictionaryQuery } = dictionaryApi
