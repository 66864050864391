import { LinkStandalone } from '@velocity/ui'
import styled from 'styled-components'

export const EndOfLeaseBackdrop = styled.div`
  position: fixed;
  left: 50%;
  bottom: 72px;
  transform: translateX(-50%);
  z-index: var(--vel-zindex-modal);
`

export const LinkStandaloneStyled = styled(LinkStandalone)`
  padding-top: var(--vel-fixed-spacing-05);
`
