export interface User {
  id: string
  firstName: string
  lastName: string
  countryCode: string
  preferredLanguage?: PreferredLanguages
  accounts: UserAccount[]
}

export enum PreferredLanguages {
  EN_GB = 'en-GB',
  EN_PT = 'en-PT',
  PT_PT = 'pt-PT',
}

export interface UserAccount {
  id: string
  name: string
  contractsCount: number
  contractIds: string[]
  roles: UserRole[]
  accountType: AccountTypes
  vehicle?: UserVehicle
}

interface UserVehicle {
  registrationNumber: string
  make: string
  model: string
}

export enum AccountTypes {
  COMPANY = 'Company',
  PRIVATE = 'Private',
}

export enum UserRole {
  CLIENT = 'Client',
  DRIVER = 'Driver',
}
