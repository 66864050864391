import { Button } from '@velocity/ui'
import React from 'react'
import { useParams } from 'react-router-dom'

import {
  BookingDetailsTypes,
  BookingServicesGroups,
  ServiceCode,
} from 'mlp-client/src/bookings/enums'
import useBookingDetails from 'mlp-client/src/bookings/hooks/useBookingDetails'
import useCancelBooking from 'mlp-client/src/bookings/hooks/useCancelBooking'
import ModalTrigger from 'mlp-client/src/components/modal-trigger/ModalTrigger'
import { generateE2EBookingLink } from 'mlp-client/src/features-configuration/NGDACountries/utils'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { useLocale } from 'mlp-client/src/localization/LocaleProvider'
import Translation from 'mlp-client/src/localization/Translation'
import { trackChangeBookingButtonEvent } from 'mlp-client/src/services/analytics/analytics'
import { useGetUserCountryCode } from 'mlp-client/src/user/hooks/useUser'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

const titleId = 'myPortal.serviceRequest.maintenance.dashboard.title'
const cancelButtonId =
  'myPortal.timeline.serviceMaintenanceRepair.details.cancel.button.cancelBooking'

const MaintenanceButtons = () => {
  const { translate } = useTranslations()
  const params = useParams<{ id: string; contractId: string }>()
  const accountId = useSelectedAccountId()
  const countryCode = useGetUserCountryCode()

  const { data: bookingDetails } = useBookingDetails()
  const { cancelBooking, isCancelBookingLoading, isCancelBookingError } =
    useCancelBooking({
      type: BookingDetailsTypes.MAINTENANCE,
      titleId,
      cancelButtonId,
    })

  const locale = useLocale()

  const onConfirmCancelBooking = () =>
    cancelBooking({ bookingId: params?.id, accountId })

  const onChangeBookingButton = () =>
    trackChangeBookingButtonEvent({
      data: {
        component_type: `timeline_${BookingServicesGroups.SGP_RPRMN}`,
        component_title: translate(titleId),
        component_link_text: translate(
          'myPortal.timeline.serviceMaintenanceRepair.button.changeBooking',
        ),
      },
    })

  return (
    <>
      <div>
        <Button
          component="a"
          onClick={onChangeBookingButton}
          variant="primary-filled"
          data-e2e-id="maintenanceDetailsChangeBooking"
          fullWidth={true}
          href={generateE2EBookingLink(
            {
              ilan: params.contractId,
              service: BookingServicesGroups.SGP_RPRMN,
              bookingId: params.id,
              returnUrl: window.location.href,
              registration: bookingDetails?.registrationNumber,
              country: countryCode,
              systemCode: ServiceCode.MYLP,
            },
            locale,
          )}
        >
          <Translation id="myPortal.timeline.serviceMaintenanceRepair.button.changeBooking" />
        </Button>
      </div>

      {!isCancelBookingError && (
        <ModalTrigger
          isLoading={isCancelBookingLoading}
          modalTitle="myPortal.timeline.serviceMaintenanceRepair.details.cancel.title"
          triggerButtonText="myPortal.timeline.serviceMaintenanceRepair.button.cancelBooking"
          triggerButtonSize="s"
          triggerButtonVariant="primary-outlined"
          confirmButtonText={cancelButtonId}
          cancelButtonText="myPortal.timeline.serviceMaintenanceRepair.details.cancel.button.keepBooking"
          triggerButtonDataE2eId="maintenanceDetailsCancelBooking"
          confirmButtonDataE2eId="maintenanceDetailsModalConfirmCancelBooking"
          cancelButtonDataE2eId="maintenanceDetailsModalCloseCancelBooking"
          onConfirm={onConfirmCancelBooking}
        >
          <Translation id="myPortal.timeline.serviceMaintenanceRepair.details.cancel.summary" />
        </ModalTrigger>
      )}
    </>
  )
}

export default MaintenanceButtons
