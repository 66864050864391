export enum Currencies {
  CHF = 'CHF', // Swiss Franc
  CZK = 'CZK', // Czech Koruna
  DKK = 'DKK', // Danish Krone
  EUR = 'EUR',
  GBP = 'GBP', // Pound Sterling
  HUF = 'HUF', // Forint,
  NOK = 'NOK', // Norwegian Krone
  PLN = 'PLN', // Zloty
  RON = 'RON', // Romanian Leu
  SEK = 'SEK', // Swedish Krona
  TRY = 'TRY', // Turkish Lira
  USD = 'USD',
}

export enum Locales {
  CS_CZ = 'cs-cz',
  DA_DK = 'da-dk',
  DE = 'de-de',
  DE_AT = 'de-at',
  DE_CH = 'de-ch',
  DE_LU = 'de-lu',
  EL_GR = 'el-gr',
  EN_BE = 'en-be',
  EN_CH = 'en-ch',
  EN_DE = 'en-de',
  EN_DK = 'en-dk',
  EN_ES = 'en-es',
  EN_FI = 'en-fi',
  EN_GB = 'en-gb',
  EN_GR = 'en-gr',
  EN_HU = 'en-hu',
  EN_IE = 'en-ie',
  EN_IT = 'en-it',
  EN_LP = 'en-lp',
  EN_LU = 'en-lu',
  EN_NL = 'en-nl',
  EN_NO = 'en-no',
  EN_PT = 'en-pt',
  EN_RO = 'en-ro',
  EN_SK = 'en-sk',
  EN_US = 'en-us',
  ES = 'es-es',
  FI = 'fi-fi',
  FR = 'fr-fr',
  FR_BE = 'fr-be',
  FR_CH = 'fr-ch',
  FR_LU = 'fr-lu',
  HU = 'hu-hu',
  IT = 'it-it',
  IT_CH = 'it-ch',
  NB_NO = 'nb-no',
  NL = 'nl-nl',
  NL_BE = 'nl-be',
  PL = 'pl-pl',
  PT = 'pt-pt',
  RO = 'ro-ro',
  SK = 'sk-sk',
  SV_SE = 'sv-se',
  TR = 'tr-tr',
}

export enum CountryCode {
  be = 'be',
  de = 'de',
  es = 'es',
  fr = 'fr',
  gb = 'gb',
  it = 'it',
  lu = 'lu',
  nl = 'nl',
  no = 'no',
  pt = 'pt',
  us = 'us',
  pl = 'pl',
  gr = 'gr',
  se = 'se',
  tr = 'tr',
  cz = 'cz',
  fi = 'fi',
  dk = 'dk',
  at = 'at',
  au = 'au',
  br = 'br',
  ca = 'ca',
  hu = 'hu',
  in = 'in',
  ie = 'ie',
  mx = 'mx',
  ro = 'ro',
  nz = 'nz',
  ru = 'ru',
  sk = 'sk',
  ch = 'ch',
  ae = 'ae',
  lp = 'lp',
  ix = 'ix',
}

export enum LanguageCode {
  de = 'de',
  en = 'en',
  es = 'es',
  fr = 'fr',
  hi = 'hi',
  it = 'it',
  ja = 'ja',
  nb = 'nb',
  no = 'no',
  nl = 'nl',
  pt = 'pt',
  pl = 'pl',
  el = 'el',
  sv = 'sv',
  tr = 'tr',
  cs = 'cs',
  fi = 'fi',
  ar = 'ar',
  da = 'da',
  sk = 'sk',
  hu = 'hu',
  ro = 'ro',
  ru = 'ru',
  zh = 'zh',
}
