import React, { PropsWithChildren, useContext } from 'react'

import { DistanceUnit } from 'mlp-client/src/enums'
import { baseConfig } from 'mlp-client/src/features-configuration/baseConfig'
import { MyleaseplanConfig } from 'mlp-client/src/features-configuration/types'
import { useCountry } from 'mlp-client/src/localization/LocaleProvider'

interface Props extends PropsWithChildren {
  value?: MyleaseplanConfig
}

export const defaultContext = {
  enabled: true,
  distanceUnit: DistanceUnit.KILOMETER,
}

export const MyLeaseplanContext =
  React.createContext<MyleaseplanConfig>(defaultContext)

export const useMyLeaseplanContext = () =>
  useContext<MyleaseplanConfig>(MyLeaseplanContext)

export const MyLeaseplanContextProvider: React.FC<Props> = ({
  children,
  value = {},
}) => {
  const country = useCountry()
  const config = baseConfig[country]

  return (
    <MyLeaseplanContext.Provider value={{ ...config, ...value }}>
      {children}
    </MyLeaseplanContext.Provider>
  )
}
