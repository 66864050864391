import isEmpty from 'lodash/isEmpty'
import React, { PropsWithChildren } from 'react'

import { setNextRoute } from 'mlp-client/src/auth/authSlice'
import Loader from 'mlp-client/src/components/loader/Loader'
import { useAppDispatch } from 'mlp-client/src/hooks'
import { useLocalizedRoute } from 'mlp-client/src/localization/RoutesProvider'
import protectAuthRoute from 'mlp-client/src/routes/protectAuthRoute'
import { getStatusCode } from 'mlp-client/src/services/api/utils'
import { logout } from 'mlp-client/src/services/auth/auth'
import { useUser } from 'mlp-client/src/user/hooks/useUser'

export const LoadUserContainer: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const getLocalizedRoute = useLocalizedRoute()
  const dispatch = useAppDispatch()

  const { data: user, isFetching, error } = useUser()

  if (!isFetching && !isEmpty(user)) {
    return <>{children}</>
  }

  if (error) {
    const nextRoute =
      getLocalizedRoute('myPortal.login') +
      `?statusCode=${getStatusCode(error)}`
    dispatch(setNextRoute(nextRoute))

    logout()
  }

  return <Loader fixed={true} />
}

export default <T extends PropsWithChildren>(
  ChildComponent: React.ComponentType<T>,
) => {
  const WithUser: React.FC<T> = props => (
    <LoadUserContainer>
      <ChildComponent {...props} />
    </LoadUserContainer>
  )

  WithUser.displayName = `withUser(${
    ChildComponent.displayName || ChildComponent.name
  })`

  return protectAuthRoute(WithUser)
}
