import React from 'react'

import { ErrorBoundary } from 'mlp-client/src/datadog/ErrorBoundary'
import { Route } from 'mlp-client/src/types'

export const RouteComponent: React.FC<{ route: Route }> = ({ route }) => (
  <ErrorBoundary
    fallback={(error: Error) => (
      <div>
        Unable to load {route.pageTitle}: {error.message}
      </div>
    )}
  >
    <route.component
      {...route.props}
      pageTitle={route.pageTitle}
      pageTitleKey={route.pageTitleKey}
    />
  </ErrorBoundary>
)
