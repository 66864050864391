import { LinkInline } from '@velocity/ui'
import React from 'react'

import { LinkButtonProps } from 'mlp-client/src/components/link-button/LinkButton'
import { SomethingWentWrong } from 'mlp-client/src/components/something-went-wrong/SomethingWentWrong'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'

interface Props {
  linkButton?: LinkButtonProps
  dataE2eId?: string
}

const TechnicalError: React.FC<Props> = ({ linkButton, dataE2eId }) => {
  const { translate } = useTranslations()

  const title = 'myPortal.error.technicalError.title'

  const subtitle = (
    <Translation
      id="myPortal.error.technicalError.content"
      replace={{
        contentUrl: () => (
          <LinkInline
            target="_blank"
            href={translate('myPortal.error.technicalError.contentURL')}
            key="contentUrl"
          >
            <Translation id="myPortal.error.technicalError.contentUrlLabel" />
          </LinkInline>
        ),
      }}
    />
  )

  return (
    <SomethingWentWrong
      title={title}
      subtitle={subtitle}
      dataE2eId={dataE2eId || 'technicalError'}
      linkButton={linkButton}
    />
  )
}

export default TechnicalError
