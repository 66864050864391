import { InfoCircleIcon } from '@velocity/icons/system'
import { useBreakpoint } from '@velocity/styling'
import { Button, Grid, Card, Text, Divider, Tooltip } from '@velocity/ui'
import moment from 'moment'
import React from 'react'
import { FormattedNumber } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  Neutral800Text,
  Neutral500Text,
} from 'mlp-client/src/components/styled/TextStyle'
import MileageProgressBar from 'mlp-client/src/contracts/components/ContractStatusBlock/MileageProgressBar'
import { ContractStatus } from 'mlp-client/src/contracts/enums'
import useContractDetails from 'mlp-client/src/contracts/hooks/useContractDetails'
import { getMonthDiff } from 'mlp-client/src/contracts/utils'
import { DateFormat } from 'mlp-client/src/localization/components/DateFormat'
import { Duration } from 'mlp-client/src/localization/components/Duration'
import { Mileage } from 'mlp-client/src/localization/components/Mileage'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'
import { trackMileageUpdateRequestEvent } from 'mlp-client/src/services/analytics/analytics'

const StyledInfoIcon = styled(InfoCircleIcon)`
  float: right;
  margin-top: var(--vel-fixed-spacing-02);
`

const ContractStatusBlock: React.FC = () => {
  const navigate = useNavigate()
  const { data: contractDetails } = useContractDetails()
  const lastKnownMileage = contractDetails?.vehicle.lastKnownMileage
  const breakpoint = useBreakpoint()
  const { translate } = useTranslations()

  const changeActionsContainerDirectionToColumn =
    breakpoint === 'XS' || breakpoint === 'LG'

  const {
    startDate,
    duration,
    mileagePerContractDuration,
    proRataMileage,
    mileageUnit,
    vehicle: { dateLastKnownMileage },
    status,
  } = contractDetails

  const monthsExpired = getMonthDiff(moment.now(), startDate)
  const isContractActive = status === ContractStatus.ACTIVE

  const goToMileageUpdate = () => {
    trackMileageUpdateRequestEvent({
      data: {
        component_title: translate(
          'myPortal.contract.contract.contract.status',
        ),
        component_link_text: translate(
          'myPortal.mileageUpdate.steps.enterCarMileage.title',
        ),
      },
    })

    navigate('mileage-update')
  }

  return (
    <Card margin={{ bottom: '07' }} data-e2e-id="contractStatusBlock">
      <Card.Header>
        <Translation id="myPortal.contract.contract.contract.status" />
        <Tooltip
          position="start"
          text={translate(
            'myPortal.contract.contract.contract.toolTip.summary',
          )}
        >
          <StyledInfoIcon
            data-e2e-id="contractStatusTooltip"
            size="s"
            color="neutral"
          />
        </Tooltip>
      </Card.Header>
      <Card.Body>
        <Grid gutter={{ y: 'fixed-spacing-04' }}>
          <Grid.Item>
            <Text variant="200" bold={true}>
              <Translation id="myPortal.contract.contract.contract.proRata.duration" />
            </Text>
            <span data-e2e-id="contractDuration">
              <Neutral800Text variant="300" component="span" fontWeight="500">
                <FormattedNumber value={monthsExpired} />
              </Neutral800Text>
              <Neutral500Text variant="300" component="span" fontWeight="500">
                &nbsp;/&nbsp;
                <Duration value={duration} />
              </Neutral500Text>
            </span>
          </Grid.Item>
          <Grid.Item>
            <Text variant="200" bold={true}>
              <Translation id="myPortal.contract.contract.contract.lastKnown" />
            </Text>
            <span data-e2e-id="contractMileage">
              <Neutral800Text variant="300" component="span" fontWeight="500">
                <FormattedNumber value={lastKnownMileage} />
              </Neutral800Text>
              <Neutral500Text variant="300" component="span" fontWeight="500">
                &nbsp;/&nbsp;
                <Mileage
                  value={mileagePerContractDuration}
                  mileageUnit={mileageUnit}
                />
              </Neutral500Text>
            </span>
          </Grid.Item>
          <Grid.Item>
            <MileageProgressBar
              lastKnownMileage={lastKnownMileage}
              mileagePerContractDuration={mileagePerContractDuration}
              proRataMileage={proRataMileage}
              mileageUnit={mileageUnit}
              tooltip={
                <Translation
                  id="myPortal.contract.contract.contract.estimated"
                  replace={{
                    estimatedMileageValue: () => (
                      <Mileage
                        key="estimatedMileage"
                        value={proRataMileage}
                        mileageUnit={mileageUnit}
                      />
                    ),
                  }}
                ></Translation>
              }
            />
          </Grid.Item>
          <Grid.Item>
            <Translation id="myPortal.contract.proRata.disclaimer" />
          </Grid.Item>
        </Grid>
        <Divider margin={{ y: '07' }} />
      </Card.Body>
      <Card.ActionsContainer
        style={
          changeActionsContainerDirectionToColumn
            ? { justifyContent: 'center' }
            : { justifyContent: 'space-between' }
        }
      >
        {isContractActive && (
          <Button
            variant="primary-outlined"
            onClick={goToMileageUpdate}
            fullWidth={changeActionsContainerDirectionToColumn}
            size="s"
            component="a"
            data-e2e-id="mileageUpdateButton"
          >
            <Translation id="myPortal.mileageUpdate.steps.enterCarMileage.title" />
          </Button>
        )}
        <Neutral500Text variant="100" fontWeight="500" textAlign="center">
          <Translation
            id="myPortal.contract.contract.contract.proRata.lastUpdate"
            replace={{
              date: () => (
                <DateFormat
                  value={dateLastKnownMileage}
                  key={dateLastKnownMileage}
                  literal="-"
                />
              ),
            }}
          />
        </Neutral500Text>
      </Card.ActionsContainer>
    </Card>
  )
}

export default ContractStatusBlock
