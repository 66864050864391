import { useParams } from 'react-router-dom'

import { useGetBookingQuery } from 'mlp-client/src/bookings/bookingsApi'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

const useBookingDetails = () => {
  const accountId = useSelectedAccountId()
  const { id: bookingId } = useParams<{ id: string }>()

  return useGetBookingQuery({
    accountId,
    bookingId,
  })
}

export default useBookingDetails
