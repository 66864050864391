import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ApiError } from 'mlp-client/src/services/api/utils'
import { AppState } from 'mlp-client/src/types'

interface ApiErrorState {
  error: ApiError
  endpoint: string
}

export interface ApiErrorsState {
  errors: ApiErrorState[]
}

export const initialState: ApiErrorsState = {
  errors: [],
}

const apiErrorsSlice = createSlice({
  name: '@@apiErrors',
  initialState,
  reducers: {
    setApiError: (state, action: PayloadAction<ApiErrorState>) => {
      state.errors = state.errors.concat(action.payload)
    },
    clearApiErrors: () => initialState,
  },
})

export const getApiErrors = (state: AppState) => state.apiErrors.errors

export const { setApiError, clearApiErrors } = apiErrorsSlice.actions

export default apiErrorsSlice.reducer
