import React, { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'

import { TableData } from './types'

import { Column } from 'mlp-client/src/components/table/types'

interface Props extends PropsWithChildren {
  item: TableData
  columns: readonly Column[]
  dashboardRoute: string
  'data-e2e-id'?: string
  handleClick(item: TableData): void
}

const RowLink: React.FC<Props> = ({
  item,
  handleClick,
  dashboardRoute,
  children,
  ...restProps
}) => {
  const navigate = useNavigate()
  const handleOnClick = () => {
    handleClick(item)
    if (dashboardRoute) {
      navigate(dashboardRoute)
    }
  }

  return (
    <tr data-e2e-id={restProps['data-e2e-id']} onClick={handleOnClick}>
      {children}
    </tr>
  )
}

export default RowLink
