import React, { useEffect, useState } from 'react'

import useContractDetails from 'mlp-client/src/contracts/hooks/useContractDetails'
import useEndOfLeaseActions from 'mlp-client/src/end-of-lease/hooks/useEndOfLeaseActions'
import BaseNotification from 'mlp-client/src/end-of-lease/notifications/BaseNotification'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'
import { authSessionStorage } from 'mlp-client/src/utils/authSessionStorage'

interface Props {
  onLearnMoreClick: () => void
}

const InformalExtensionNotification: React.FC<Props> = ({
  onLearnMoreClick,
}) => {
  const { data: contractDetails } = useContractDetails()
  const { translate } = useTranslations()
  const [showNotification, setShowNotification] = useState(
    !authSessionStorage.getItem('hideInformalExtensionNotification'),
  )

  const title = translate(
    'myPortal.dashboard.notifications.modal.titleInformalExtension',
  )
  const content = translate('myPortal.dashboard.notifications.modal.summary')

  const {
    triggerLearnMoreClickAction,
    triggerClosedAction,
    triggerShownAction,
  } = useEndOfLeaseActions(title)

  const shouldRender = contractDetails?.informalExtension && showNotification

  useEffect(() => {
    if (shouldRender) {
      triggerShownAction()
    }
  }, [shouldRender])

  const handleHideNotification = () => {
    setShowNotification(false)
    authSessionStorage.setItem('hideInformalExtensionNotification', 'true')
    triggerClosedAction()
  }

  const handleLearnMoreClick = () => {
    triggerLearnMoreClickAction()
    onLearnMoreClick()
  }

  if (!shouldRender) return null

  return (
    <BaseNotification
      title={title}
      content={content}
      onHideNotification={handleHideNotification}
      link={
        <Translation
          id="myPortal.dashboard.notifications.modal.link"
          data-e2e-id="informalExtensionBannerLearnMore"
          onClick={handleLearnMoreClick}
        />
      }
    />
  )
}

export default InformalExtensionNotification
