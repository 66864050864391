import createConfig from './defaults'

import { CountrySpecific } from 'mlp-client/src/config/types'
import {
  CountryCode,
  LanguageCode,
  Locales,
} from 'mlp-client/src/localization/enums'
import { formatUKAddress } from 'mlp-client/src/utils/formatters/formatters'

const specificConfig = (): CountrySpecific => ({
  label: 'United Kingdom',
  code: CountryCode.gb,
  tld: '.gb',
  languages: [
    {
      label: 'English',
      code: LanguageCode.en,
      locale: Locales.EN_GB,
      preferred: true,
    },
  ],
  footerLinks: [
    'myPortal.footer.termsAndConditions',
    'myPortal.footer.globalPrivacyStatement',
    'myPortal.footer.personalDataRights',
    'myPortal.footer.complaintsCode',
  ],
  formatters: {
    address: formatUKAddress,
  },
})

export default createConfig(specificConfig)
