import { Card } from '@velocity/ui'
import React, { PropsWithChildren } from 'react'

import { ContentStyled } from './EmptyStateMobile.styled'

interface Props extends PropsWithChildren {
  columnsAmount: number
}

const EmptyStateMobile: React.FC<Props> = ({ children }) => (
  <Card data-e2e-id="emptyStateMobile">
    <ContentStyled>{children}</ContentStyled>
  </Card>
)

export default EmptyStateMobile
