import { Headline } from '@velocity/ui'
import React from 'react'

import TextCenter from 'mlp-client/src/components/styled/TextCenter'
import {
  IconComponentWrap,
  CardContentStyled,
  CardStyled,
  StyledLink,
} from 'mlp-client/src/dashboard/components/tiles/DashboardTile.styled'
import { TileProps } from 'mlp-client/src/dashboard/types'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { addTrailingSlash } from 'mlp-client/src/localization/utils'
import { trackServicesTileClickEvent } from 'mlp-client/src/services/analytics/analytics'

const TileWithIcons: React.FC<TileProps> = ({
  title,
  icon: IconComponent,
  to,
  toParams,
  target,
  componentType,
  isRelativeLink,
}) => {
  const { translate } = useTranslations()

  const toUrl = isRelativeLink
    ? addTrailingSlash(window.location.pathname) + to
    : to

  const sendAnalytics = (toLink: string) =>
    trackServicesTileClickEvent({
      data: {
        component_type: componentType,
        component_link_text: translate(title),
        component_link_url: toLink,
      },
    })

  return (
    <CardStyled data-e2e-id="tileWithIconCard">
      <StyledLink
        to={toUrl}
        params={toParams}
        target={target}
        sendAnalytics={sendAnalytics}
      >
        <CardContentStyled>
          <TextCenter>
            {IconComponent && (
              <IconComponentWrap data-e2e-id="dashboardIconWrapper">
                <IconComponent />
              </IconComponentWrap>
            )}
            <Headline variant="100" component="h2" withMarginBottom={true}>
              {title}
            </Headline>
          </TextCenter>
        </CardContentStyled>
      </StyledLink>
    </CardStyled>
  )
}

export default TileWithIcons
