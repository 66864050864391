import { camelCase } from 'lodash'
import React from 'react'

import { FormattedNumber } from 'react-intl'

import {
  ENERGY_TYPE_MAP,
  FIELD_TYPE_LINE,
  VEHICLE_TYPE_MAP,
} from 'mlp-client/src/contracts/constants'
import {
  ContractSectionProperties,
  CustomerSectionProperties,
  VehicleSectionProperties as VehicleContractSectionProperties,
  ViewContractSections,
} from 'mlp-client/src/contracts/enums'
import {
  ContractDetails as ContractDetailsData,
  ListProperties,
} from 'mlp-client/src/contracts/types'
import { SectionPropertyList } from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { DateFormat } from 'mlp-client/src/localization/components/DateFormat'
import { Duration } from 'mlp-client/src/localization/components/Duration'
import { Mileage } from 'mlp-client/src/localization/components/Mileage'
import Translation from 'mlp-client/src/localization/Translation'
import MaskedData from 'mlp-client/src/utils/masks/MaskedData'
import MaskedLicensePlate from 'mlp-client/src/utils/masks/MaskedLicensePlate'

const isAtLeastOneProperty = (...properties: string[]) =>
  properties.some(prop => prop)

const getContractVehicleProperties = (
  contract: ContractDetailsData,
): ListProperties => [
  contract.vehicle.make && {
    label: 'Make',
    key: VehicleContractSectionProperties.MAKE,
    value: contract.vehicle.make,
  },
  contract.vehicle.model && {
    label: 'Model',
    key: VehicleContractSectionProperties.MODEL,
    value: contract.vehicle.model,
  },
  contract.vehicle.modelYear && {
    label: 'Model year',
    key: VehicleContractSectionProperties.MODEL_YEAR,
    value: contract.vehicle.modelYear,
  },
  contract.vehicle.bodyType && {
    label: 'Body type',
    key: VehicleContractSectionProperties.BODY_TYPE,
    value: (
      <Translation
        id={`myPortal.vehicleBodyTypes.${contract.vehicle.bodyType}`}
      />
    ),
  },
  contract.vehicle.vehicleType && {
    label: 'Vehicle type',
    key: VehicleContractSectionProperties.VEHICLE_TYPE,
    value: (
      <Translation
        id={`myPortal.vehicleTypes.${
          VEHICLE_TYPE_MAP[contract.vehicle.vehicleType]
        }`}
      />
    ),
  },
  isAtLeastOneProperty(
    contract.vehicle.vehicleType,
    contract.vehicle.bodyType,
    contract.vehicle.modelYear,
    contract.vehicle.model,
    contract.vehicle.make,
  ) && {
    key: FIELD_TYPE_LINE,
  },
  contract.vehicle.licenseRegistration && {
    label: 'License plate',
    key: VehicleContractSectionProperties.LICENSE_PLATE,
    value: (
      <MaskedLicensePlate>
        {contract.vehicle.licenseRegistration}
      </MaskedLicensePlate>
    ),
  },
  contract.vehicle.exteriorColour && {
    label: 'Exterior',
    key: VehicleContractSectionProperties.EXTERIOR,
    value: contract.vehicle.exteriorColour,
  },
  contract.vehicle.interiorColour && {
    label: 'Interior',
    key: VehicleContractSectionProperties.INTERIOR,
    value: contract.vehicle.interiorColour,
  },
  contract.vehicle.numberOfDoors && {
    label: 'Number of doors',
    key: VehicleContractSectionProperties.NUMBER_OF_DOORS,
    value: contract.vehicle.numberOfDoors,
  },
  isAtLeastOneProperty(
    contract.vehicle.vehicleType,
    contract.vehicle.bodyType,
    contract.vehicle.modelYear,
    contract.vehicle.model,
    contract.vehicle.make,
  ) && {
    key: FIELD_TYPE_LINE,
  },
  contract.vehicle.energyTypeDescription && {
    label: 'Energy type',
    key: VehicleContractSectionProperties.ENERGY_TYPE,
    value: (
      <Translation
        id={`myPortal.energyTypes.${
          ENERGY_TYPE_MAP[contract.vehicle.energyTypeDescription]
        }`}
      />
    ),
  },
  contract.vehicle.transmissionType && {
    label: 'Transmission',
    key: VehicleContractSectionProperties.TRANSMISSION,
    value: (
      <Translation
        id={`myPortal.transmissions.${contract.vehicle.transmissionType.toLowerCase()}`}
      />
    ),
  },
  contract.vehicle.fuelTankCapacity && {
    label: 'Fuel tank capacity',
    key: VehicleContractSectionProperties.FUEL_TANK_CAPACITY,
    value: contract.vehicle.fuelTankCapacity,
  },
  contract.vehicle.co2 && {
    label: 'CO2 emission',
    key: VehicleContractSectionProperties.CO2,
    value: (
      <span>
        <FormattedNumber value={contract.vehicle.co2} />
        &nbsp;g/km
      </span>
    ),
  },
  contract.vehicle.euroEmissionStandard && {
    label: 'EuroEmission standard',
    key: VehicleContractSectionProperties.EURO_EMISSION_STANDARD,
    value: contract.vehicle.euroEmissionStandard,
  },
  contract.vehicle.motExpiryDate && {
    label: 'MOT expiry date',
    key: VehicleContractSectionProperties.MOT_EXPIRY_DATE,
    value: <DateFormat value={contract.vehicle.motExpiryDate} month="long" />,
  },
  contract.vehicle.registrationDate && {
    label: 'Registration date',
    key: VehicleContractSectionProperties.REGISTRATION_DATE,
    value: (
      <DateFormat value={contract.vehicle.registrationDate} month="long" />
    ),
  },
]

const getContractMainProperties = (
  contract: ContractDetailsData,
): ListProperties => [
  contract.id && {
    label: 'Contract ID',
    key: ContractSectionProperties.ID,
    value: contract.id,
  },
  contract.productName && {
    label: 'Product',
    key: ContractSectionProperties.PRODUCT,
    value: contract.productName,
  },
  contract.duration && {
    label: 'Duration',
    key: ContractSectionProperties.DURATION,
    value: <Duration value={contract.duration} />,
  },
  contract.mileagePerYear && {
    label: 'Annual mileage',
    key: ContractSectionProperties.ANNUAL_MILEAGE,
    value: (
      <Mileage
        value={contract.mileagePerYear}
        mileageUnit={contract.mileageUnit}
      />
    ),
  },
  contract.vehicle.initialMileage != null && {
    label: 'myPortal.mileageUpdate.startMileage',
    key: ContractSectionProperties.INITIAL_MILEAGE,
    value: (
      <Mileage
        value={contract.vehicle.initialMileage}
        mileageUnit={contract.mileageUnit}
      />
    ),
  },
  contract.status && {
    label: 'Status',
    key: ContractSectionProperties.STATUS,
    value: (
      <Translation
        id={`myPortal.contract.contract.status.${contract.status}`}
      />
    ),
  },
]

const getDateProperties = (contract: ContractDetailsData): ListProperties => [
  contract.startDate && {
    label: 'Start date',
    key: ContractSectionProperties.START_DATE,
    value: <DateFormat value={contract.startDate} month="long" />,
  },
  contract.endDate && {
    label: 'End date',
    key: ContractSectionProperties.END_DATE,
    value: <DateFormat value={contract.endDate} month="long" />,
  },
]

const getPropertiesBroker = (contract: ContractDetailsData): ListProperties => [
  contract.contractBroker.name && {
    label: 'Broker',
    key: ContractSectionProperties.BROKER,
    value: contract.contractBroker.name,
  },
]

const getContractDetailsProperties = (
  contract: ContractDetailsData,
): ListProperties => [
  ...getContractMainProperties(contract),
  isAtLeastOneProperty(
    contract.vehicle.vehicleType,
    contract.vehicle.bodyType,
    contract.vehicle.modelYear,
    contract.vehicle.model,
    contract.vehicle.make,
  ) && {
    key: FIELD_TYPE_LINE,
  },
  ...getDateProperties(contract),
  isAtLeastOneProperty(
    contract.vehicle.vehicleType,
    contract.vehicle.bodyType,
    contract.vehicle.modelYear,
    contract.vehicle.model,
    contract.vehicle.make,
  ) && {
    key: FIELD_TYPE_LINE,
  },
  ...getPropertiesBroker(contract),
]

const getServicesProperties = ({
  services,
}: ContractDetailsData): ListProperties =>
  services.map(({ type }) => ({
    label: `myPortal.universalServiceCodes.${camelCase(type)}`,
    key: type,
    included: true,
  }))

const getCustomerDetailsProperties = (
  { customer, drivers }: ContractDetailsData,
  isUserclient?: boolean,
): ListProperties => [
  customer.externalAccountId && {
    label: 'Customer number',
    key: CustomerSectionProperties.CUSTOMER_NUMBER,
    value: customer.externalAccountId,
  },
  customer.name && {
    label: 'Customer name',
    key: CustomerSectionProperties.CUSTOMER_NAME,
    value: <MaskedData type="User Name">{customer.name}</MaskedData>,
  },
  customer.vatNumber && {
    label: 'Customer VAT number',
    key: CustomerSectionProperties.CUSTOMER_VAT_NUMBER,
    value: <MaskedData type="VAT Number">{customer.vatNumber}</MaskedData>,
  },
  isUserclient &&
    drivers?.length && {
      label: 'Driver',
      key: CustomerSectionProperties.DRIVER,
      value: (
        <MaskedData type="User Name">
          {drivers[0].firstName} {drivers[0].lastName}
        </MaskedData>
      ),
    },
]

export const getContractDetailsSectionData = (
  contract: ContractDetailsData,
  sectionConfig: SectionPropertyList,
  sectionKey: ViewContractSections,
  isUserClient: boolean,
): { title: string; properties: ListProperties } => {
  let sectionProperties: ListProperties = []
  let title = ''

  // TODO: put real translation id
  switch (sectionKey) {
    case ViewContractSections.VEHICLE:
      sectionProperties = getContractVehicleProperties(contract).filter(Boolean)
      title = 'Vehicle'
      break
    case ViewContractSections.CONTRACT:
      sectionProperties = getContractDetailsProperties(contract).filter(Boolean)
      title = 'Contract'
      break
    case ViewContractSections.SERVICES:
      sectionProperties = getServicesProperties(contract)
      title = 'Services'
      break
    case ViewContractSections.CUSTOMER:
      sectionProperties = getCustomerDetailsProperties(
        contract,
        isUserClient,
      ).filter(Boolean)
      title = 'Customer'
      break
  }

  return {
    title,
    properties: sectionProperties,
  }
}
