import { GeoCoordinate } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import useBookingDetails from 'mlp-client/src/bookings/hooks/useBookingDetails'
import StyledMap from 'mlp-client/src/components/map/StyledMap'
import { useAddressFormatter } from 'mlp-client/src/localization/LocaleProvider'

const MapWrapper = styled.div`
  margin-top: var(--vel-fixed-spacing-05);
`

const BookingMaintenanceMap: React.FC = () => {
  const { data: bookingDetails } = useBookingDetails()

  const getLatAndLngCoordinates = (location: string): GeoCoordinate => {
    const [lat, lng] = location.split(',')

    return { lat: Number(lat), lng: Number(lng) }
  }

  const formatAddress = useAddressFormatter()

  if (!bookingDetails || !bookingDetails.supplier) {
    return null
  }

  const supplierAddress = formatAddress(bookingDetails.supplier.address)

  return (
    <MapWrapper data-e2e-id="bookingMap">
      <StyledMap
        marker={{
          title: bookingDetails.supplier.name,
          text: supplierAddress,
        }}
        center={getLatAndLngCoordinates(bookingDetails.supplier.location)}
      />
    </MapWrapper>
  )
}

export default BookingMaintenanceMap
