import React from 'react'

import {
  BookingServicesGroups,
  ServiceCode,
} from 'mlp-client/src/bookings/enums'
import useContractDetails from 'mlp-client/src/contracts/hooks/useContractDetails'
import { BasicTile } from 'mlp-client/src/dashboard/components/tiles/BasicTile'
import { TileProps } from 'mlp-client/src/dashboard/types'
import { generateE2EBookingLink } from 'mlp-client/src/features-configuration/NGDACountries/utils'
import { useLocale } from 'mlp-client/src/localization/LocaleProvider'
import { useGetUserCountryCode } from 'mlp-client/src/user/hooks/useUser'

export const MaintenanceTile: React.FC<TileProps> = props => {
  const { data: contractDetails } = useContractDetails()
  const {
    id,
    vehicle: { licenseRegistration },
  } = contractDetails

  const countryCode = useGetUserCountryCode()

  const locale = useLocale()

  const basicTileProps = {
    ...props,
    to: generateE2EBookingLink(
      {
        ilan: id,
        service: BookingServicesGroups.SGP_RPRMN,
        returnUrl: `${window.location.origin}/${locale}/my/timeline/`,
        registration: licenseRegistration,
        country: countryCode,
        systemCode: ServiceCode.MYLP,
      },
      locale,
    ),
  }

  return <BasicTile {...basicTileProps} />
}
