import { CarTrainingPictogram } from '@velocity/icons/pictogram'
import React from 'react'
import styled from 'styled-components'

import PagePlaceholder, {
  Props as PagePlaceholderProps,
} from 'mlp-client/src/components/page-placeholder/PagePlaceholder'
import Translation from 'mlp-client/src/localization/Translation'

type Props = Omit<PagePlaceholderProps, 'pictogram' | 'dataE2eId'> & {
  dataE2eId?: string
  withMarginTop?: boolean
}

const StyledWrapper = styled.div<{ withMarginTop?: boolean }>`
  width: 100%;
  margin-top: (
    ${props => (props.withMarginTop ? 'var(--vel-fixed-spacing-09)' : '0')}
  );
`

export const pictogram = (
  <CarTrainingPictogram
    size="large"
    style={{
      width: '128px',
      height: '128px',
    }}
  />
)

export const SomethingWentWrong: React.FC<Props> = ({
  subtitle,
  dataE2eId = 'somethingWentWrong',
  withMarginTop = true,
  ...props
}) => (
  <StyledWrapper withMarginTop={withMarginTop}>
    <PagePlaceholder
      pictogram={pictogram}
      subtitle={
        subtitle || <Translation id="myPortal.somethingWentWrong.intro" />
      }
      dataE2eId={dataE2eId}
      {...props}
    />
  </StyledWrapper>
)
