import { getCookies } from 'mlp-client/src/cookies'
import { Event } from 'mlp-client/src/services/analytics/types'

export const CID_KEY = '_ga'

export const getCIDFromCookie = () => {
  const cookies = getCookies()

  // GA1.1.22222222.11111111 -> 22222222.11111111, GA1.1. is redundant
  return cookies[CID_KEY]?.slice(6) ?? ''
}

const objectValuesToLowercase = <T extends GenericObject>(obj: T): T =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    let lowerCaseValue = value
    if (typeof value === 'string') {
      lowerCaseValue = value.toLowerCase()
    } else if (Array.isArray(value)) {
      lowerCaseValue = value.map(val => objectValuesToLowercase(val))
    } else if (typeof value === 'object') {
      lowerCaseValue = objectValuesToLowercase(value)
    }
    return {
      ...acc,
      [key]: lowerCaseValue,
    }
  }, {}) as T

export const eventDataToLowercase = <T extends Event>(event: T): T => {
  const eventDataProperties = ['data', 'meta'] as const

  eventDataProperties.map(eventDataProperty => {
    if (eventDataProperty in event) {
      event = {
        ...event,
        ...objectValuesToLowercase({
          [eventDataProperty]: event[eventDataProperty],
        }),
      }
    }
  })

  return event
}
