import { ChevronRightIcon } from '@velocity/icons/system'
import { Card, Text } from '@velocity/ui'
import styled, { css } from 'styled-components'

import RouteLink from 'mlp-client/src/localization/RouteLink'

const noUnderlineStyle = css`
  a {
    text-decoration: none;
  }
`

export const CardStyled = styled(Card)`
  height: 100%;
  transition: box-shadow var(--vel-animation-timing-large) ease;

  ${noUnderlineStyle}
  &:hover {
    box-shadow: var(--vel-elevation400-box_shadow);
  }
`

export const CardContentStyled = styled.div`
  padding: var(--vel-fixed-spacing-07);
`

export const StyledLink = styled(RouteLink)`
  display: block;
  color: var(--vel-color-secondary-500);
`

export const ChevronRightIconStyled = styled(ChevronRightIcon)<{
  $disabled?: boolean
}>`
  color: var(
    ${props =>
      props.$disabled
        ? '--vel-color-neutral-400'
        : '--vel-color-text-headlines'}
  );
`

export const MobileTileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--vel-fixed-spacing-07);
`

export const TextStyled = styled(Text)<{
  $disabled?: boolean
}>`
  color: var(
    ${props =>
      props.$disabled
        ? '--vel-color-neutral-700'
        : '--vel-color-text-headlines'}
  );
`

export const IconComponentWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  height: 64px;
`
