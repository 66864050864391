import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'

import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { ContractStatus } from 'mlp-client/src/contracts/enums'
import useContractDetails from 'mlp-client/src/contracts/hooks/useContractDetails'
import useEndOfLeaseActions from 'mlp-client/src/end-of-lease/hooks/useEndOfLeaseActions'
import BaseNotification from 'mlp-client/src/end-of-lease/notifications/BaseNotification'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'
import { useIsUserClient } from 'mlp-client/src/user/hooks/useUser'

interface Props {
  onLearnMoreClick: () => void
}

const EndOfLeaseNotification: React.FC<Props> = ({ onLearnMoreClick }) => {
  const { data: contractDetails } = useContractDetails()
  const isClient = useIsUserClient()
  const [showNotification, setShowNotification] = useState(
    !localStorage.getItem('hideEOLNotification'),
  )
  const { translate } = useTranslations()

  const { endOfLease } = useContext(MyLeaseplanContext)

  const title = translate('myPortal.dashboard.notifications.modal.title')
  const content = translate('myPortal.dashboard.notifications.modal.summary')

  const {
    triggerLearnMoreClickAction,
    triggerClosedAction,
    triggerShownAction,
  } = useEndOfLeaseActions(title)

  const monthLeft = moment
    .duration(Number(new Date(contractDetails?.endDate)) - Number(new Date()))
    .asMonths()

  const isContractEnding =
    monthLeft <= endOfLease.configuration.notifyBeforeMonths &&
    contractDetails?.status === ContractStatus.ACTIVE

  const shouldRender =
    isClient &&
    isContractEnding &&
    showNotification &&
    !!contractDetails?.startDate &&
    !!contractDetails?.endDate &&
    !contractDetails?.informalExtension

  useEffect(() => {
    if (shouldRender) {
      triggerShownAction()
    }
  }, [shouldRender])

  const handleHideNotification = () => {
    setShowNotification(false)
    localStorage.setItem('hideEOLNotification', 'true')
    triggerClosedAction()
  }

  const handleLearnMoreClick = () => {
    triggerLearnMoreClickAction()
    onLearnMoreClick()
  }

  if (!shouldRender) return null

  return (
    <BaseNotification
      title={title}
      content={content}
      onHideNotification={handleHideNotification}
      link={
        <Translation
          id="myPortal.dashboard.notifications.modal.link"
          data-e2e-id="eolBannerLearnMore"
          onClick={handleLearnMoreClick}
        />
      }
    />
  )
}

export default EndOfLeaseNotification
