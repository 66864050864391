import { NotificationBanner } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import TooltipLabel from 'mlp-client/src/components/tooltip/TooltipLabel'
import { DistanceUnit } from 'mlp-client/src/enums'
import { Mileage } from 'mlp-client/src/localization/components/Mileage'
import Translation from 'mlp-client/src/localization/Translation'

interface MileageProgressBarProps {
  lastKnownMileage: number
  mileagePerContractDuration: number
  proRataMileage: number
  mileageUnit: DistanceUnit
  tooltip: React.ReactNode
}

type MileageProgressBarFilledVariant = 'default' | 'warning' | 'error'

interface MileageProgressBarFilledProps {
  width: number
  variant: MileageProgressBarFilledVariant
}

interface VerticalDividerProps {
  left: number
}

const MileageProgressBarWrapper = styled.div`
  position: relative;
  height: 48px;
`

const MileageProgressBarBody = styled.div`
  height: 8px;
  border-radius: 8px;
  background-color: var(--vel-color-secondary-50);
  position: relative;
  overflow: hidden;
`

const getFilledColor = (color: MileageProgressBarFilledVariant) => {
  switch (color) {
    case 'warning':
      return '--vel-color-semantic-warning-high'
    case 'error':
      return '--vel-color-semantic-error-high'
    default:
      return '--vel-color-secondary-400'
  }
}

const MileageProgressBarFilled = styled.div<MileageProgressBarFilledProps>`
  height: 100%;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.width}%;
  background-color: var(${props => getFilledColor(props.variant)});
  transition:
    width 0.5s ease-out 0s,
    background-color 0.5s ease-out 0s;
`

const VerticalDivider = styled.div<VerticalDividerProps>`
  position: absolute;
  width: 2px;
  top: -4px;
  height: 16px;
  transform: translateX(-1px);
  background-color: var(--vel-color-neutral-700);
  left: ${props => props.left}%;
`

const MileageProgressBar: React.FC<MileageProgressBarProps> = ({
  lastKnownMileage,
  mileagePerContractDuration,
  proRataMileage,
  mileageUnit,
  tooltip,
}) => {
  const lengthInUnit = Math.max(lastKnownMileage, mileagePerContractDuration)
  const isWarning =
    proRataMileage < lastKnownMileage &&
    lastKnownMileage <= mileagePerContractDuration
  const isError = lastKnownMileage > mileagePerContractDuration

  const drivenPercentage = (lastKnownMileage / lengthInUnit) * 100
  const expectedPercentage = (proRataMileage / lengthInUnit) * 100

  let variant: MileageProgressBarFilledVariant = 'default'

  if (isWarning) {
    variant = 'warning'
  }

  if (isError) {
    variant = 'error'
  }

  return (
    <>
      <MileageProgressBarWrapper data-e2e-id="mileageProgressBar">
        <MileageProgressBarBody>
          <MileageProgressBarFilled
            variant={variant}
            width={drivenPercentage}
            data-e2e-id="mileageProgressBarFilled"
            aria-details={variant}
          ></MileageProgressBarFilled>
        </MileageProgressBarBody>
        <VerticalDivider left={expectedPercentage} />
        {tooltip && (
          <TooltipLabel percentage={expectedPercentage}>{tooltip}</TooltipLabel>
        )}
      </MileageProgressBarWrapper>
      {isWarning && (
        <NotificationBanner
          kind="warning"
          margin={{ top: '06' }}
          data-e2e-id="mileageProRataWarning"
        >
          <Translation id="myPortal.contract.contract.contract.mileageOver" />
        </NotificationBanner>
      )}
      {isError && (
        <NotificationBanner
          kind="error"
          margin={{ top: '06' }}
          data-e2e-id="overMileageError"
        >
          <Translation
            id="myPortal.contract.contract.contract.mileageError"
            replace={{
              number: () => (
                <span key="mileageError">
                  <Mileage
                    value={lastKnownMileage - mileagePerContractDuration}
                    mileageUnit={mileageUnit}
                  />
                </span>
              ),
            }}
          />
        </NotificationBanner>
      )}
    </>
  )
}

export default MileageProgressBar
