import React from 'react'

import { HeaderCellStyled } from './Table.styled'
import { HeaderCellComponent } from './types'

export interface Props {
  header: React.ReactNode
  headerCell: HeaderCellComponent
  'data-e2e-id': string
}

const HeaderCellWrapper: React.FC<Props> = ({
  header,
  headerCell: HeaderCellCustom,
  ...restProps
}) => {
  if (!HeaderCellCustom) {
    return (
      <HeaderCellStyled data-e2e-id={restProps['data-e2e-id']}>
        {header}
      </HeaderCellStyled>
    )
  }

  return <HeaderCellCustom header={header} {...restProps} />
}

export default HeaderCellWrapper
