import { Input } from '@velocity/ui'
import styled, { css } from 'styled-components'

interface StyledComponentProps {
  variant?: 'primary' | 'secondary'
}

const secondaryCss = css`
  font-size: 18px;
  margin: 0 auto 6px;

  input[type='text'] {
    font-size: 24px;
    letter-spacing: 1.7px;
    padding-right: 70px;
  }
`

export const StyledInput = styled(Input)<StyledComponentProps>`
  ${props => props.variant === 'secondary' && secondaryCss}
`
export const InputWrapper = styled.div`
  position: relative;
  overflow: visible;
  white-space: nowrap;
`
