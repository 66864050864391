import { FilesPictogram } from '@velocity/icons/pictogram'
import React from 'react'

import PagePlaceholder, {
  Props as PagePlaceholderProps,
} from 'mlp-client/src/components/page-placeholder/PagePlaceholder'

type Props = Omit<PagePlaceholderProps, 'pictogram' | 'dataE2eId'>

const pictogram = (
  <FilesPictogram
    size="large"
    style={{
      width: '128px',
      height: '128px',
    }}
  />
)

export const EmptyPage: React.FC<Props> = props => (
  <PagePlaceholder
    dataE2eId="emptyStateComponent"
    pictogram={pictogram}
    {...props}
  />
)
