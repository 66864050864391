import { DocIcon } from '@velocity/icons/system'
import {
  Accordion,
  AccordionsContainer,
  Breadcrumbs,
  Card,
  Grid,
  Headline,
  LinkStandalone,
  Text,
} from '@velocity/ui'

import React, { PropsWithChildren } from 'react'

import { EmptyPage } from 'mlp-client/src/components/empty-page/EmptyPage'
import TechnicalError from 'mlp-client/src/components/error/TechnicalError'
import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import Loader from 'mlp-client/src/components/loader/Loader'
import { TileType } from 'mlp-client/src/contracts/enums'
import useContractDetails from 'mlp-client/src/contracts/hooks/useContractDetails'
import {
  BreadcrumbsContainerStyled,
  ContainerWithBottomMargin,
  StyledLinksList,
} from 'mlp-client/src/documents/components/DocumentsPage.styled'
import TravelAbroadBanner from 'mlp-client/src/documents/components/travelAbroadBanner/TravelAbroadBanner'
import useDocumentDownload from 'mlp-client/src/documents/hooks/useDocumentDownload'
import useDocuments from 'mlp-client/src/documents/hooks/useDocuments'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/types'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { useLocalizedRoute } from 'mlp-client/src/localization/RoutesProvider'
import Translation from 'mlp-client/src/localization/Translation'
import {
  isBadRequestError,
  isServerError,
} from 'mlp-client/src/services/api/utils'
import { Route } from 'mlp-client/src/types'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

export type Props = PropsWithChildren<Route>

const DocumentsPage: React.FC<Props> = ({ pageTitle }) => {
  const { data: documents, isFetching, error, isSuccess } = useDocuments()
  const { translate } = useTranslations()
  const accountId = useSelectedAccountId()
  const { data: contractDetails } = useContractDetails()
  const getLocalizedRoute = useLocalizedRoute()

  const showTravelAbroadBanner = Boolean(
    contractDetails?.tiles.find(tile => tile.type === TileType.TRAVEL_ABROAD),
  )

  const { downloadDocument } = useDocumentDownload({
    pageTitle,
    component: 'documents',
  })

  const metaDescription = translate(
    'myPortal.vehicleDocuments.steps.selectDocument.description',
  )

  const hasContractDocuments = documents?.contract?.groups?.length > 0
  const hasVehicleDocuments = documents?.vehicle?.documents?.length > 0
  const hasDocuments = hasContractDocuments || hasVehicleDocuments

  const renderLoader = isFetching && !error
  const renderSidePanel = !isFetching && isSuccess
  const renderError = isServerError(error) || isBadRequestError(error)

  return (
    <MyleaseplanPage metaTitle={pageTitle} metaDescription={metaDescription}>
      <BreadcrumbsContainerStyled>
        <Breadcrumbs data-e2e-id="documentsBreadcrumbs">
          <Breadcrumbs.Link
            href={getLocalizedRoute('myPortal.dashboard', {
              accountId,
              contractId: contractDetails?.id,
            })}
          >
            {translate('myPortal.subNavigation.dashboard')}
          </Breadcrumbs.Link>
          <Breadcrumbs.Link href="#">{pageTitle}</Breadcrumbs.Link>
        </Breadcrumbs>
      </BreadcrumbsContainerStyled>
      <ContainerWithBottomMargin>
        <Headline variant="400" component="h1" withMarginBottom={true}>
          <Translation id="myPortal.dashboard.default.allTiles.documents" />
        </Headline>
        {isSuccess && <Text>{metaDescription}</Text>}
      </ContainerWithBottomMargin>

      {renderLoader && <Loader />}

      <Grid columns={12} data-e2e-id="documentsPage">
        <Grid.Item XS={12} LG={renderSidePanel ? 8 : 12}>
          {renderError && (
            <ContainerWithBottomMargin>
              <TechnicalError />
            </ContainerWithBottomMargin>
          )}

          {!isFetching && isSuccess && (
            <>
              {hasContractDocuments && (
                <>
                  <ContainerWithBottomMargin>
                    <Headline
                      variant="300"
                      withMarginBottom={true}
                      data-e2e-id="myContractTitle"
                    >
                      <Translation id="myPortal.documents.MyContract.title" />
                    </Headline>
                  </ContainerWithBottomMargin>
                  <ContainerWithBottomMargin>
                    <AccordionsContainer
                      allowMultipleExpanded={true}
                      data-e2e-id="contractDocuments"
                    >
                      {documents?.contract?.groups?.map(group => (
                        <Accordion
                          id={group.name}
                          key={group.name}
                          initialExpanded={true}
                          data-e2e-id="contractDocumentsGroup"
                        >
                          <Accordion.Header>
                            <Translation
                              id={`myPortal.documents.MyContract.${group.name}`}
                            />
                            {group.documents.length > 0
                              ? ` (${group.documents.length})`
                              : ''}
                          </Accordion.Header>
                          <Accordion.Content>
                            <StyledLinksList>
                              {group.documents.map(document => (
                                <LinkStandalone
                                  size="s"
                                  kind="secondary"
                                  download={true}
                                  Icon={DocIcon}
                                  onClick={downloadDocument(document)}
                                  key={document.id}
                                  data-e2e-id={`contractDocumentLink${document.id}`}
                                >
                                  {document.name}
                                </LinkStandalone>
                              ))}
                            </StyledLinksList>
                          </Accordion.Content>
                        </Accordion>
                      ))}
                    </AccordionsContainer>
                  </ContainerWithBottomMargin>
                </>
              )}
              {hasVehicleDocuments && (
                <>
                  <ContainerWithBottomMargin>
                    <Headline
                      variant="300"
                      withMarginBottom={true}
                      data-e2e-id="myVehicleTitle"
                    >
                      <Translation id="myPortal.documents.MyVehicle.title" />
                    </Headline>
                  </ContainerWithBottomMargin>
                  <ContainerWithBottomMargin>
                    <Card kind="elevated">
                      <Card.Body>
                        <StyledLinksList>
                          {documents.vehicle.documents.map(document => (
                            <LinkStandalone
                              size="s"
                              kind="secondary"
                              download={true}
                              Icon={DocIcon}
                              onClick={downloadDocument(document)}
                              key={document.id}
                              data-e2e-id="vehicleDocumentLink"
                            >
                              {document.name}
                            </LinkStandalone>
                          ))}
                        </StyledLinksList>
                      </Card.Body>
                    </Card>
                  </ContainerWithBottomMargin>
                </>
              )}
              {!hasDocuments && (
                <ContainerWithBottomMargin>
                  <EmptyPage
                    title="myPortal.documents.empty.title"
                    subtitle="myPortal.documents.empty.summary"
                  />
                </ContainerWithBottomMargin>
              )}
            </>
          )}
          {showTravelAbroadBanner && <TravelAbroadBanner />}
        </Grid.Item>
        {renderSidePanel && (
          <Grid.Item XS={12} LG={4}>
            <FAQ faqKey={FAQKeys.VEHICLE_DOCUMENTS_PAGE} />
          </Grid.Item>
        )}
      </Grid>
    </MyleaseplanPage>
  )
}

export default DocumentsPage
