import React from 'react'

import { TableLeaseVehicleData } from 'mlp-client/src/components/overview-table/types'
import { Mileage } from 'mlp-client/src/localization/components/Mileage'

interface Props {
  item: TableLeaseVehicleData
}

const MileageCell: React.FC<Props> = ({ item }) => {
  return item.mileage ? (
    <Mileage value={item.mileage} mileageUnit={item.unit} />
  ) : (
    <>-</>
  )
}

export default MileageCell
