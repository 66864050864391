import { midLeaseChangeFeature } from './midLeaseChange'
import { roadsideAssistanceFeature } from './roadsideAssistance'
import { vehicleCollectionChangeModalFeature } from './vehicleCollectionChangeModal'

import { myleaseplanConfig as myleaseplanNGDAConfig } from 'mlp-client/src/features-configuration/NGDACountries'
import { MyleaseplanConfig } from 'mlp-client/src/features-configuration/types'

export const myleaseplanConfig: MyleaseplanConfig = {
  ...myleaseplanNGDAConfig,
  MLC: midLeaseChangeFeature,
  vehicleCollectionChangeModal: vehicleCollectionChangeModalFeature,
  roadsideAssistance: roadsideAssistanceFeature,
}
