import _get from 'lodash/get'
import moment, { MomentInput } from 'moment'

import {
  Contracts,
  Contract,
  FormattedVehicle,
} from 'mlp-client/src/contracts/types'

export const getEndDateOfContract = (contract: Contract) => {
  const date = getEndDateOfContractAsString(contract)

  return date ? moment(date) : undefined
}

export const getEndDateOfContractAsString = (
  contract: Contract,
): string | undefined => {
  if (!contract) {
    return undefined
  }

  return contract.endDate
}

export const getLicensePlate = (contract: Contract): string | undefined =>
  _get(contract, ['vehicle', 'licenseRegistration'])

export const getMakeModel = (contract: Contract): string => {
  if (contract) {
    const { make, model } = contract.vehicle

    // `make` is sometimes empty, it seems, but it's copied into `model`
    const prefix = make ? `${make} ` : ''
    const modelValue = model ? `${model} ` : ''

    return `${prefix}${modelValue}`
  }

  return ''
}

export const getShortMakeModel = (contract: Contract): string =>
  getMakeModel(contract).trim().split(' ', 2).join(' ')

export const getFormattedVehicle = (contract: Contract): FormattedVehicle => ({
  id: contract.id,
  model: getShortMakeModel(contract),
  licensePlate: getLicensePlate(contract),
})

export const getExtension = (fileName: string) =>
  (fileName.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1] || null

export const getBaseFileName = (fileName: string) =>
  fileName.replace(/\.[^/.]+$/, '')

export const getLastKnownMileage = (contract: Contract): number | undefined =>
  contract?.vehicle?.lastKnownMileage

export const getMonthDiff = (from: MomentInput, to: MomentInput): number =>
  moment(from).diff(moment(to), 'months')

export const mapContracts = (contracts: Contracts): Contracts =>
  contracts.map(contract => ({
    ...contract,
    vehicle: {
      ...contract.vehicle,
      make: contract.vehicle?.make?.toUpperCase(),
      model: contract.vehicle?.model?.toUpperCase(),
    },
  }))
