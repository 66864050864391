import {
  MyLeaseplanFeature,
  VehicleCollectionConfiguration,
} from 'mlp-client/src/features-configuration/types'

export const vehicleCollectionChangeModalFeature: MyLeaseplanFeature<VehicleCollectionConfiguration> =
  {
    enabled: true,
    configuration: {
      dialogModal: {
        title: 'myPortal.dashboard.rescheduleCollection.modal.title',
        buttonFirst: 'myPortal.dashboard.contractChanges.modal.button.email',
        buttonSecond: 'myPortal.dashboard.contractChanges.modal.button.call',
        summaryBeforeLink:
          'You want to make changes to your vehicle collection appointment? please visit',
        summaryLink: '{faqApi}integration/pt-PT/202200000001207/',
        summaryAfterLink: ' for more information.',
        showBrokerInfo: false,
      },
    },
  }
