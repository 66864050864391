import { OverlayView } from '@react-google-maps/api'
import { UnstyledMap, MapProps } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import { mapStyles } from 'mlp-client/src/components/map/mapStyles'
import MarkerTooltip, {
  Props as MarkerTooltipProps,
} from 'mlp-client/src/components/map/MarkerTooltip'

export const MapWrapper = styled.div`
  height: 300px;
  position: relative;
`

export interface Props extends MapProps {
  marker?: MarkerTooltipProps
}

const StyledMap: React.FC<Props> = ({ marker, ...props }) => (
  <MapWrapper>
    <UnstyledMap
      {...props}
      pannable={true}
      zoomable={true}
      styles={mapStyles}
      zoom={12}
    >
      {marker && (
        <OverlayView
          position={props.center}
          mapPaneName={OverlayView.MARKER_LAYER}
        >
          <MarkerTooltip {...marker} />
        </OverlayView>
      )}
    </UnstyledMap>
  </MapWrapper>
)

export default StyledMap
