import { Button, Card, Headline, Text } from '@velocity/ui'
import React, { useContext } from 'react'

import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import {
  ButtonsWrapperStyled,
  ContainerWithBottomMargin,
  GridStyled,
  LinkStyled,
  TravelAbroadPictogramStyled,
} from 'mlp-client/src/documents/components/DocumentsPage.styled'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'
import {
  trackTravelAbroadCardMoreInformationEvent,
  trackTravelAbroadCardRequestDocumentsEvent,
} from 'mlp-client/src/services/analytics/analytics'

const TravelAbroadBanner: React.FC = () => {
  const { travelAbroad } = useContext(MyLeaseplanContext)
  const { translate } = useTranslations()
  const {
    title,
    summary,
    button,
    buttonURL,
    secondaryButton,
    secondaryButtonURL,
  } = travelAbroad.configuration.dialogModal

  const sendRequestDocumentsAnalytics = () =>
    trackTravelAbroadCardRequestDocumentsEvent({
      data: {
        component_title: translate(title),
        component_link_text: translate(button),
        component_link_url: translate(buttonURL),
      },
    })

  const sendRequestMoreInfoAnalytics = () =>
    trackTravelAbroadCardMoreInformationEvent({
      data: {
        component_title: translate(title),
        component_link_text: translate(secondaryButton),
        component_link_url: translate(secondaryButtonURL),
      },
    })

  return (
    <ContainerWithBottomMargin>
      <Card data-e2e-id="travelAbroadBanner">
        <Card.Body>
          <GridStyled>
            <div>
              <TravelAbroadPictogramStyled
                size="large"
                data-e2e-id="travelAbroadBannerPictogram"
              />
            </div>
            <div>
              <Headline
                variant="200"
                data-e2e-id="title"
                layoutMargin={{ bottom: '03' }}
              >
                <Translation id={title} />
              </Headline>
              <Text layoutMargin={{ top: '02', bottom: '03' }}>
                <Translation id={summary} />
              </Text>
              <ButtonsWrapperStyled>
                <LinkStyled
                  href={translate(buttonURL)}
                  data-e2e-id="travelAbroadDocumentsLink"
                >
                  <Button
                    variant="primary-filled"
                    onClick={sendRequestDocumentsAnalytics}
                    data-e2e-id="travelAbroadDocumentsButton"
                  >
                    <Translation id={button} />
                  </Button>
                </LinkStyled>
                <LinkStyled
                  href={translate(secondaryButtonURL)}
                  data-e2e-id="travelAbroadInfoLink"
                >
                  <Button
                    variant="secondary-outlined"
                    onClick={sendRequestMoreInfoAnalytics}
                    data-e2e-id="travelAbroadInfoButton"
                  >
                    <Translation id={secondaryButton} />
                  </Button>
                </LinkStyled>
              </ButtonsWrapperStyled>
            </div>
          </GridStyled>
        </Card.Body>
      </Card>
    </ContainerWithBottomMargin>
  )
}

export default TravelAbroadBanner
