import { Badge } from '@velocity/ui'
import React from 'react'

import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { TimelineItemStatus } from 'mlp-client/src/timeline/types'

interface Props {
  status: TimelineItemStatus
}

const TimelineEventStatus: React.FC<Props> = ({ status }) => {
  const { translate } = useTranslations()

  if (!status || status === TimelineItemStatus.UNKNOWN) {
    return null
  }

  const translatedStatus = translate(
    `myPortal.timeline.events.statuses.${status}`,
  )

  switch (status) {
    case TimelineItemStatus.CHALLENGE_ACCEPTED:
    case TimelineItemStatus.COMPLETED:
    case TimelineItemStatus.CONFIRMED:
    case TimelineItemStatus.DONE:
      return <Badge kind="positive">{translatedStatus}</Badge>
    case TimelineItemStatus.ASSESSMENT_CREATED:
    case TimelineItemStatus.IN_PROGRESS:
    case TimelineItemStatus.PENDING_CONFIRMATION:
      return <Badge kind="pending">{translatedStatus}</Badge>
    case TimelineItemStatus.ASSESSMENT_CHALLENGED:
    case TimelineItemStatus.CANCELLED:
    case TimelineItemStatus.CHALLENGE_REJECTED:
    case TimelineItemStatus.FAILED:
      return <Badge kind="negative">unconfirmed</Badge>
    default:
      return null
  }
}

export default TimelineEventStatus
