import { MailIcon, PhoneIcon } from '@velocity/icons/system'
import { Button, LinkStandalone, Text } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import { config } from 'mlp-client/src/app-config'
import BrokerInformation from 'mlp-client/src/components/broker-information/BrokerInformation'
import TechnicalError from 'mlp-client/src/components/error/TechnicalError'
import Loader from 'mlp-client/src/components/loader/Loader'
import useContractDetails from 'mlp-client/src/contracts/hooks/useContractDetails'
import { DialogModal } from 'mlp-client/src/features-configuration/types'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'
import MaskedData from 'mlp-client/src/utils/masks/MaskedData'

const LoaderWrapper = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledButtonsWrapper = styled.div`
  display: flex;
  gap: var(--vel-fixed-spacing-03);
  padding-top: var(--vel-fixed-spacing-05) var(--vel-fixed-spacing-07);
  margin-top: var(--vel-fixed-spacing-03);
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--vel-fixed-spacing-05);
`

interface Props {
  dialogModal: DialogModal
  handleButtonFirstClick?: (linkTextTranslationId: string, url: string) => void
  handleButtonSecondClick?: (linkTextTranslationId: string, url: string) => void
}

export const BrokerInformationModalContent: React.FC<Props> = ({
  dialogModal,
  handleButtonFirstClick,
  handleButtonSecondClick,
}) => {
  const { translate } = useTranslations()
  const {
    buttonFirst,
    buttonSecond,
    summaryBeforeLink,
    summaryLink,
    summaryAfterLink,
    showBrokerInfo,
    summaryBeforeBrokerInfo,
  } = dialogModal
  const { data: contractDetails, isFetching, isError } = useContractDetails()

  const linkFAQ =
    summaryLink &&
    translate(summaryLink, [
      {
        key: 'faqApi',
        value: config.FAQ_API,
      },
    ])

  if (isFetching) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    )
  }

  if (isError) {
    return <TechnicalError />
  }

  const emailUrl = `mailto:${contractDetails?.contractBroker?.email}`
  const phoneUrl = `tel:${contractDetails?.contractBroker?.phone}`

  return (
    <ContentWrapper data-e2e-id="brokerInformationModalContent">
      {summaryBeforeBrokerInfo && (
        <Text variant="300">
          <Translation id={summaryBeforeBrokerInfo} />
        </Text>
      )}

      {showBrokerInfo && contractDetails?.contractBroker?.id && (
        <BrokerInformation broker={contractDetails.contractBroker} />
      )}

      {linkFAQ && (
        <Text variant="300" component="div">
          <Translation id={summaryBeforeLink} />
          <LinkStandalone
            href={linkFAQ}
            target="_blank"
            size="m"
            style={{ display: 'inline' }}
            data-e2e-id="faqLink"
          >
            &nbsp;FAQ&nbsp;
          </LinkStandalone>
          <Translation id={summaryAfterLink} />
        </Text>
      )}

      {/*These buttons should be moved to the Modal.Footer*/}
      <StyledButtonsWrapper data-e2e-id="mlc-modal-buttons">
        <Button
          variant="primary-filled"
          href={emailUrl}
          component="a"
          data-e2e-id="emailButtonLink"
          onClick={() => handleButtonFirstClick?.(buttonFirst, emailUrl)}
          StartIcon={MailIcon}
        >
          <Translation id={buttonFirst} />
        </Button>
        <Button
          data-e2e-id="phoneButtonLink"
          href={phoneUrl}
          component="a"
          variant="secondary-outlined"
          onClick={() => handleButtonSecondClick?.(buttonSecond, phoneUrl)}
          StartIcon={PhoneIcon}
        >
          {contractDetails?.contractBroker?.phone ? (
            <MaskedData type="Phone Number">
              {contractDetails.contractBroker.phone}
            </MaskedData>
          ) : (
            <Translation id={buttonSecond} />
          )}
        </Button>
      </StyledButtonsWrapper>
    </ContentWrapper>
  )
}
