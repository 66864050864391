import { PreferredLanguages, User } from './types'

import { CountryCode, Locales } from 'mlp-client/src/localization/enums'

const capitalizeName = (name: string): string =>
  name
    .split(' ')
    .map(name =>
      name
        .split('-')
        .map(name => `${name[0].toUpperCase()}${name.slice(1).toLowerCase()}`)
        .join('-'),
    )
    .join(' ')

export const capitalizeUserNames = (user: User): User => ({
  ...user,
  firstName: capitalizeName(user.firstName),
  lastName: capitalizeName(user.lastName),
})

export const getPreferredCountryCode = (
  preferredLanguage: PreferredLanguages,
): CountryCode => {
  switch (preferredLanguage) {
    case PreferredLanguages.EN_GB:
      return CountryCode.gb
    case PreferredLanguages.EN_PT:
    case PreferredLanguages.PT_PT:
      return CountryCode.pt
  }
}

export const getBrowserCountryCode = (): CountryCode => {
  const locale = navigator.language.slice(0, -3).toLocaleLowerCase()

  return locale === 'en' ? CountryCode.gb : (locale as CountryCode)
}

export const getBrowserLocale = (): Locales =>
  navigator.language.toLocaleLowerCase() as Locales
