import { BreakpointName, useBreakpoint } from '@velocity/styling'

export const BREAKPOINT_MOBILE: BreakpointName = 'SM'
export const BREAKPOINT_TABLET: BreakpointName = 'MD'
export const BREAKPOINT_LAPTOP: BreakpointName = 'LG'
export const BREAKPOINT_DESKTOP: BreakpointName = 'XL'

export const useIsMobileView = () => {
  const breakpoint = useBreakpoint()
  return breakpoint === 'XS' || breakpoint === 'SM'
}

export const useIsDesktopView = () => {
  const breakpoint = useBreakpoint()
  return breakpoint === 'LG' || breakpoint === 'XL' || breakpoint === 'XXL'
}
