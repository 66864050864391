import _omit from 'lodash/omit'
import React from 'react'

import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { Replacements } from 'mlp-client/src/utils'

interface Props extends React.HTMLProps<HTMLElement> {
  id: string
  replace?: Replacements
  element?: string
  className?: string
  hideOnEmpty?: boolean
  optional?: boolean
}

const Translation: React.FC<Props> = props => {
  const { debugTranslations, translate } = useTranslations()
  const { id, replace, hideOnEmpty, element, className } = props

  const logTranslationId = () => {
    if (debugTranslations) {
      // eslint-disable-next-line no-console
      console.log(`translation id is ${id}`)
    }
  }

  const elementDebugProps = debugTranslations
    ? {
        title: id,
        onMouseEnter: logTranslationId,
        style: { border: '1px solid red' },
      }
    : {}

  const label = translate(
    id,
    replace && Object.entries(replace).map(([key, value]) => ({ key, value })),
  )

  const elementType: string = element || 'span'
  const elementProps = _omit(props, [
    'id',
    'replace',
    'element',
    'optional',
    'hideOnEmpty',
    'className',
  ])
  const elementClasses = className
    ? `${className} translation-label`
    : 'translation-label'

  if (elementType === 'input' || elementType === 'textarea') {
    return React.createElement(elementType, {
      ...elementProps,
      className: elementClasses,
      placeholder: label,
      ...elementDebugProps,
    })
  }

  // if the label is the same as the key, means the translation not found.
  if (hideOnEmpty && typeof label === 'string' && label === id) {
    return null
  }

  return React.createElement(
    elementType,
    {
      ...elementProps,
      className: elementClasses,
      ...elementDebugProps,
    },
    label,
  )
}

Translation.displayName = 'Translation'

export default Translation
