import { Card, LinkInline } from '@velocity/ui'
import React, { useEffect } from 'react'
import { Form as ReactFinalForm, FormRenderProps } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { useSendAssessmentReportMutation } from 'mlp-client/src/assessments/assessmentsApi'
import { StyledHeadline } from 'mlp-client/src/assessments/components/AssessmentDetailsPage.styled'
import DamageAssessmentsOptions from 'mlp-client/src/assessments/components/DamageAssessmentsOptions/DamageAssessmentsOptions'
import { AssessmentDetails } from 'mlp-client/src/assessments/types'
import ModalTrigger from 'mlp-client/src/components/modal-trigger/ModalTrigger'
import { useToastsWithTranslation } from 'mlp-client/src/components/toasts/useToasts'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { useLocalizedRoute } from 'mlp-client/src/localization/RoutesProvider'
import Translation from 'mlp-client/src/localization/Translation'
import { trackAssessmentDetailsSendReportEvent } from 'mlp-client/src/services/analytics/analytics'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

const StyledDamageAssessmentsOptionsWrapper = styled.div`
  margin-bottom: var(--vel-fixed-spacing-08);
`

interface Props {
  assessmentDetails: AssessmentDetails
}

interface FormData {
  [key: string]: string
}

export const ReportedDamages: React.FC<Props> = ({ assessmentDetails }) => {
  const { translate } = useTranslations()
  const navigate = useNavigate()
  const getLocalizedRoute = useLocalizedRoute()
  const { showSuccess, showError } = useToastsWithTranslation()
  const accountId = useSelectedAccountId()

  const timelineRoute = getLocalizedRoute('myPortal.timeline.base', {
    accountId,
  })

  const damagesSectionTitle = 'myPortal.timeline.fairWearTear.damages.title'
  const damagesButtonText = 'myPortal.timeline.fairWearTear.damages.button'

  const [
    sendAssessmentReport,
    {
      isSuccess: isAssessmentReportSent,
      isLoading: isSendAssessmentReportLoading,
      isError: isSendAssessmentReportError,
      reset: resetSendAssessmentReport,
    },
  ] = useSendAssessmentReportMutation()

  const initialValues = assessmentDetails.damageAssessments.reduce(
    (acc, { damage: { description } }) => ({
      ...acc,
      [description]: 'No objections',
    }),
    {},
  )

  const availableDamagesOptions = assessmentDetails.damageAssessments.reduce(
    (acc, { id, damage: { description } }) => ({
      ...acc,
      [description]: id,
    }),
    {},
  )

  const onConfirmModal = (data: FormData) => {
    sendAssessmentReport({
      accountId,
      assessmentId: assessmentDetails.id,
      body: {
        damageAssessmentChallenges: Object.entries(data).map(
          ([description, reason]: [
            keyof typeof availableDamagesOptions,
            string,
          ]) => ({
            damageAssessmentId: availableDamagesOptions[description],
            reason,
            description,
          }),
        ),
      },
    })
  }

  const onSendReportClick = () =>
    trackAssessmentDetailsSendReportEvent({
      data: {
        component_title: translate(damagesSectionTitle),
        component_link_text: translate(damagesButtonText),
      },
    })

  useEffect(() => {
    if (isAssessmentReportSent) {
      resetSendAssessmentReport()
      navigate(timelineRoute)
      showSuccess(
        'myPortal.timeline.toasters.fwtToaster.title',
        'myPortal.timeline.toasters.fwtToaster.summary',
      )
    }
  }, [isAssessmentReportSent])

  useEffect(() => {
    if (isSendAssessmentReportError) {
      resetSendAssessmentReport()
      showError(
        'myPortal.timeline.fairWearTear.report.error.title',
        <Translation
          id="myPortal.timeline.fairWearTear.report.error.summary"
          replace={{
            link: () => (
              <LinkInline
                target="_blank"
                href={translate(
                  'myPortal.timeline.fairWearTear.report.error.url',
                )}
                key="contentUrl"
              >
                <Translation id="myPortal.timeline.fairWearTear.report.error.urlLabel" />
              </LinkInline>
            ),
          }}
        />,
      )
    }
  }, [isSendAssessmentReportError])

  return (
    <Card data-e2e-id="reportedDamagesSection">
      <Card.Body>
        <StyledHeadline component="h2" variant="400" withMarginBottom={true}>
          <Translation id={damagesSectionTitle} />
        </StyledHeadline>
        <Translation
          id="myPortal.timeline.fairWearTear.damages.summary"
          element="p"
        />
        <br />
        <ReactFinalForm<FormData>
          initialValues={initialValues}
          onSubmit={onConfirmModal}
        >
          {({ handleSubmit }: FormRenderProps) => (
            <>
              <StyledDamageAssessmentsOptionsWrapper>
                <DamageAssessmentsOptions
                  damageAssessments={assessmentDetails.damageAssessments}
                />
              </StyledDamageAssessmentsOptionsWrapper>
              {!isSendAssessmentReportError && (
                <ModalTrigger
                  isLoading={isSendAssessmentReportLoading}
                  modalTitle="myPortal.timeline.fairWearTear.confirm.title"
                  triggerButtonText={damagesButtonText}
                  confirmButtonText="myPortal.timeline.fairWearTear.confirm.buttons.confirm"
                  cancelButtonText="myPortal.timeline.fairWearTear.confirm.buttons.cancel"
                  onOpen={onSendReportClick}
                  onConfirm={handleSubmit}
                  triggerButtonDataE2eId="sendReportBackButton"
                  confirmButtonDataE2eId="confirmSendAssessmentReport"
                  cancelButtonDataE2eId="cancelSendAssessmentReport"
                >
                  <Translation id="myPortal.timeline.fairWearTear.confirm.summary" />
                </ModalTrigger>
              )}
            </>
          )}
        </ReactFinalForm>
      </Card.Body>
    </Card>
  )
}
