import { mq } from '@velocity/styling'
import styled from 'styled-components'

import {
  DarkGrayText,
  Neutral500Text,
} from 'mlp-client/src/components/styled/TextStyle'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { BREAKPOINT_TABLET } from 'mlp-client/src/utils/hooks/breakpoints'

export const StyledRouteLink = styled(RouteLink)`
  text-decoration: none;
`

export const CardContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TimelineEventStatusWrapper = styled.div`
  margin-bottom: var(--vel-fixed-spacing-03);
`

export const VehicleInfo = styled(DarkGrayText)`
  margin-bottom: var(--vel-fixed-spacing-02);
`

export const RightBorderWrapper = styled.span`
  border-right: 1px solid var(--vel-color-neutral-300);
  padding-right: var(--vel-fixed-spacing-03);
  margin-right: var(--vel-fixed-spacing-03);
`

export const RelativeTime = styled(Neutral500Text)`
  position: absolute;
  top: var(--vel-layout-spacing-03);
  right: var(--vel-layout-spacing-03);

  ${mq.below(BREAKPOINT_TABLET)} {
    position: static;
    margin-bottom: var(--vel-fixed-spacing-02);
  }
`

export const ChildrenWrapper = styled(DarkGrayText)`
  line-height: var(--vel-fixed-spacing-07);
`

export const ButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  top: var(--vel-layout-spacing-05);
  right: var(--vel-layout-spacing-03);
  bottom: var(--vel-layout-spacing-03);
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;

  ${mq.below(BREAKPOINT_TABLET)} {
    position: static;
  }
`
