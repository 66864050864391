import { api } from 'mlp-client/src/services/api/api'
import { TimelineData, TimelineParams } from 'mlp-client/src/timeline/types'

const timelineApi = api.injectEndpoints({
  endpoints: builder => ({
    getTimeline: builder.query<TimelineData, TimelineParams>({
      query: ({ accountId, contractIds, page, itemsPerPage }) => ({
        url: `/timeline/${accountId}`,
        method: 'GET',
        params: {
          page,
          itemsPerPage,
          ilans: contractIds,
        },
      }),
      keepUnusedDataFor: 0,
      providesTags: ['Timeline'],
    }),
  }),
})

export const { useGetTimelineQuery } = timelineApi
