import { Text } from '@velocity/ui'
import styled from 'styled-components'

export const StyledButtonsWrapper = styled.div`
  display: flex;
  gap: var(--vel-fixed-spacing-04);
  padding: var(--vel-fixed-spacing-05) var(--vel-fixed-spacing-07)
    var(--vel-fixed-spacing-03);
  border-top: 1px solid var(--vel-color-neutral-200);
  margin: calc(-1 * var(--vel-fixed-spacing-05));
`
export const StyledFormContainer = styled.div`
  padding: var(--vel-fixed-spacing-03) var(--vel-fixed-spacing-03)
    var(--vel-fixed-spacing-09);
`
export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--vel-fixed-spacing-07);
`

export const InputContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
`

export const SummaryTextStyled = styled(Text)`
  margin-bottom: var(--vel-fixed-spacing-05);
`

export const LabelTextStyled = styled(Text)`
  margin-bottom: var(--vel-fixed-spacing-03);
`
