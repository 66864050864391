import { useEffect, useState, useCallback } from 'react'

import { useAppDispatch } from 'mlp-client/src/hooks'
import { CountryCode, Locales } from 'mlp-client/src/localization/enums'
import { isUserAuthorized } from 'mlp-client/src/services/auth/auth'
import { User, UserRole } from 'mlp-client/src/user/types'
import userApi, { useGetUserQuery } from 'mlp-client/src/user/userApi'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'
import {
  getBrowserCountryCode,
  getBrowserLocale,
  getPreferredCountryCode,
} from 'mlp-client/src/user/utils'

export const useUser = () => {
  const [isAuthorized, setIsAuthorized] = useState(false)

  const checkAuth = useCallback(async () => {
    const result = await isUserAuthorized()
    setIsAuthorized(result)
  }, [])

  useEffect(() => {
    checkAuth()
  }, [])

  return useGetUserQuery(undefined, { skip: !isAuthorized })
}

export const useCachedUser = () =>
  userApi.endpoints.getUser.useQueryState(undefined)

export const useGetCountryCode = (): CountryCode => {
  const { data: user } = useCachedUser()

  return (
    getPreferredCountryCode(user?.preferredLanguage) || getBrowserCountryCode()
  )
}

export const useGetUserLocale = (): Locales => {
  const { data: user } = useCachedUser()

  return (
    (user?.preferredLanguage.toLocaleLowerCase() as Locales) ||
    getBrowserLocale()
  )
}

export const useGetUserCountryCode = () => {
  const { data: user } = useCachedUser()

  return user?.countryCode
}

export const useGetUserAccounts = () => {
  const { data: user } = useCachedUser()

  return user?.accounts || []
}

export const useSelectedAccount = () => {
  const userAccounts = useGetUserAccounts()
  const selectedAccountId = useSelectedAccountId()

  return userAccounts.find(account => account.id === selectedAccountId)
}

export const useGetUserRoles = () => {
  const selectedAccount = useSelectedAccount()

  return selectedAccount?.roles
}

export const useIsSingleAccount = () => {
  const userAccounts = useGetUserAccounts()

  return userAccounts?.length === 1
}

export const useIsUserClient = (): boolean => {
  const userRoles = useGetUserRoles()

  return userRoles?.some(role => role === UserRole.CLIENT)
}

export const useIsUserDriver = (): boolean => {
  const userRoles = useGetUserRoles()

  return userRoles?.some(role => role === UserRole.DRIVER)
}

export const useGetUserContractIdsCount = () => {
  const selectedAccount = useSelectedAccount()

  return selectedAccount?.contractIds?.length
}

export const getUserAnalyticsRole = (user: User) => {
  if (!user) {
    return ''
  }

  const isClient = user.accounts?.some(
    userAccount => userAccount.roles?.some(role => role === UserRole.CLIENT),
  )

  return ((isClient && UserRole.CLIENT) || UserRole.DRIVER).toLowerCase()
}

export const useInvalidateUserOnUnmount = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(userApi.util.invalidateTags(['User']))
    }
  }, [])
}
