import React, { PropsWithChildren } from 'react'

import TechnicalError from 'mlp-client/src/components/error/TechnicalError'
import Loader from 'mlp-client/src/components/loader/Loader'
import SelectContractRedirect from 'mlp-client/src/contracts/components/SelectContractRedirect/SelectContractRedirect'
import ContractsLoader from 'mlp-client/src/contracts/components/withContracts/loaders/ContractsLoader'
import DashboardLoader from 'mlp-client/src/contracts/components/withContracts/loaders/DashboardLoader'
import VehiclesLoader from 'mlp-client/src/contracts/components/withContracts/loaders/VehiclesLoader'
import useContracts from 'mlp-client/src/contracts/hooks/useContracts'
import {
  useCurrentPath,
  useLocalizedRouteMatch,
} from 'mlp-client/src/localization/RoutesProvider'
import SelectAccountRedirect from 'mlp-client/src/select-account/SelectAccountRedirect'
import { useGetUserContractIdsCount } from 'mlp-client/src/user/hooks/useUser'

export const LoadContractsContainer: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { isFetching, isError } = useContracts()
  const currentPath = useCurrentPath()
  const getLocalizedRouteMatch = useLocalizedRouteMatch()

  const isDashboardRoute =
    currentPath === getLocalizedRouteMatch('myPortal.dashboard')
  const isHomePageRoute =
    currentPath === getLocalizedRouteMatch('myPortal.homepage')
  const isVehiclesRoute =
    currentPath === getLocalizedRouteMatch('myPortal.vehicles')

  const contractsCount = useGetUserContractIdsCount()

  if (isFetching) {
    if (contractsCount === 0 && (isHomePageRoute || isDashboardRoute)) {
      return <ContractsLoader />
    }

    if ((contractsCount === 1 && isHomePageRoute) || isDashboardRoute) {
      return <DashboardLoader hasBiggerMargin={contractsCount > 1} />
    }

    if ((contractsCount > 1 && isHomePageRoute) || isVehiclesRoute) {
      return <VehiclesLoader />
    }

    return <Loader fixed={true} />
  }

  if (isError) {
    return <TechnicalError />
  }

  return <>{children}</>
}

export default <T extends PropsWithChildren>(
  ProtectedComponent: React.ComponentType<T>,
) => {
  const WithContracts: React.FC<T> = props => (
    <SelectAccountRedirect>
      <LoadContractsContainer>
        <SelectContractRedirect>
          <ProtectedComponent {...props} />
        </SelectContractRedirect>
      </LoadContractsContainer>
    </SelectAccountRedirect>
  )

  WithContracts.displayName = `WithContracts(${
    ProtectedComponent.displayName || ProtectedComponent.name
  })`

  return WithContracts
}
