import { RoadsideAssistanceConfiguration } from 'mlp-client/src/dashboard/types'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'

export const roadsideAssistanceFeature: MyLeaseplanFeature<RoadsideAssistanceConfiguration> =
  {
    enabled: true,
    configuration: {
      dialogModal: {
        title: 'myPortal.dashboard.roadsideAssistance.modal.title',
        summary: 'myPortal.dashboard.roadsideAssistance.modal.summaryPT',
        button: 'myPortal.dashboard.roadsideAssistance.modal.button',
        phoneNumber:
          'myPortal.dashboard.roadsideAssistance.modal.phoneNumberPT',
      },
    },
  }
