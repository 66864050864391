import React from 'react'
import { FormattedNumber } from 'react-intl'

interface Props {
  value: number
  currency: string
}

export const Price: React.FC<Props> = ({ value, currency }) => (
  <FormattedNumber value={value} style="currency" currency={currency} />
)
