export enum PageTitle {
  HOME = 'Home Page',
  SELECT_ACCOUNT = 'Select Account',
  DASHBOARD = 'Dashboard',
  VEHICLES = 'Vehicles',
  TIMELINE = 'Timeline',
  INVOICES = 'Invoices',
  MAINTENANCE_BOOKING_DETAILS = 'Maintenance Booking Details',
  VEHICLE_COLLECTION_DETAILS = 'Vehicle Collection Details',
  ASSESSMENT_DETAILS = 'Assessment Details',
  LOGIN = 'Login',
  CONTRACT_DETAILS = 'Contract Details',
  END_OF_LEASE = 'End Of Lease',
  DOCUMENTS = 'Documents',
  UNDER_MAINTENANCE = 'Under Maintenance',
  SOMETHING_WENT_WRONG = 'Something Went Wrong',
  NOT_FOUND = 'Not Found',
  FORBIDDEN = 'Forbidden',
  AUTHORIZE = 'Authorize',
  LOGIN_REDIRECT = 'Login Redirect',
  LOGOUT_REDIRECT = 'Logout Redirect',
  REDIRECT_TO_CORRECT_LOCALE = 'Redirect To Correct Locale',
}

export const enum Origin {
  Web = 'MyLP Web',
}

export enum DistanceUnit {
  KILOMETER = 'kilometer',
  MILE = 'mile',
}
