import { PropsWithChildren, FC } from 'react'

import useDebugFlags from 'mlp-client/src/debug/hooks/useDebugFlags'

const Debug: FC<PropsWithChildren> = ({ children }) => {
  useDebugFlags()

  return <>{children}</>
}

export default Debug
