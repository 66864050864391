import { CarTrainingPictogram } from '@velocity/icons/pictogram'
import React from 'react'

import { CenteredContentWrapper } from 'mlp-client/src/components/layout/main-content-wrapper/MainContentWrapper.styled'
import PagePlaceholder from 'mlp-client/src/components/page-placeholder/PagePlaceholder'
import Translation from 'mlp-client/src/localization/Translation'
import { useInvalidateUserOnUnmount } from 'mlp-client/src/user/hooks/useUser'

const pictogram = (
  <CarTrainingPictogram
    size="large"
    style={{
      width: '128px',
      height: '128px',
    }}
  />
)

const Forbidden: React.FC = () => {
  useInvalidateUserOnUnmount()

  return (
    <CenteredContentWrapper>
      <PagePlaceholder
        pictogram={pictogram}
        // @TODO change to the actual translation key
        title="Forbidden"
        // @TODO change to the actual translation key
        subtitle={
          <Translation id="The information you are trying to access is not available." />
        }
        linkButton={{
          to: 'myPortal.dashboard',
          // @TODO change to the actual translation key
          label: 'Go to dashboard',
        }}
        dataE2eId="forbiddenPage"
      />
    </CenteredContentWrapper>
  )
}

export default Forbidden
