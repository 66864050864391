import { CarSedanPictogram } from '@velocity/icons/pictogram'
import styled from 'styled-components'

export const RootStyled = styled.div`
  display: flex;
  align-items: center;
  gap: var(--vel-fixed-spacing-02);
`

export const IconStyled = styled(CarSedanPictogram)`
  height: 2.25rem;
  width: 2.25rem;
`
