import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import BaseTimelineCard, {
  TimelineItemProps,
} from 'mlp-client/src/timeline/components/TimelineCard/BaseTimelineCard'
import { ChangeExtraordinaryPaymentTimelineItem } from 'mlp-client/src/timeline/types'

const ChangeExtraordinaryPaymentTimelineCard: React.FC<
  TimelineItemProps<ChangeExtraordinaryPaymentTimelineItem>
> = ({ item, config }) => (
  <BaseTimelineCard
    item={item}
    config={config}
    status={item.status}
    title={<Translation id="myPortal.timeline.events.mlc.titles.downpayment" />}
  >
    <Translation id="myPortal.timeline.events.mlc.subtitles.downpayment" />
  </BaseTimelineCard>
)

export default ChangeExtraordinaryPaymentTimelineCard
