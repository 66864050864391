import { Button, Modal } from '@velocity/ui'
import React, { useContext, useState } from 'react'

import { BrokerInformationModalContent } from 'mlp-client/src/components/broker-information/BrokerInformationModalContent'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'
import {
  trackVehicleCollectionChangeEvent,
  trackVehicleCollectionChangeContactEvent,
} from 'mlp-client/src/services/analytics/analytics'

export const VehicleCollectionModalTrigger: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const { translate } = useTranslations()
  const {
    vehicleCollectionChangeModal: {
      configuration: { dialogModal },
    },
  } = useContext(MyLeaseplanContext)

  const openModal = () => {
    setShowModal(true)
    trackVehicleCollectionChangeEvent({
      data: {
        component_title: translate('myPortal.timeline.vehicleCollection.title'),
        component_link_text: translate(
          'myPortal.timeline.vehicleCollection.button.changeAppointment',
        ),
      },
    })
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const handleButtonFirstClick = (
    linkTextTranslationId: string,
    url: string,
  ) => {
    trackVehicleCollectionChangeContactEvent({
      component: 'change_booking_email',
      data: {
        component_title: translate(dialogModal.title),
        component_link_text: translate(linkTextTranslationId),
        component_link_url: url,
      },
    })
  }

  const handleButtonSecondClick = (
    linkTextTranslationId: string,
    url: string,
  ) => {
    trackVehicleCollectionChangeContactEvent({
      component: 'change_booking_call',
      data: {
        component_title: translate(dialogModal.title),
        component_link_text: translate(linkTextTranslationId),
        component_link_url: url,
      },
    })
  }

  return (
    <>
      <Button
        variant="primary-filled"
        onClick={openModal}
        size="s"
        data-e2e-id="vehicleCollectionModalTriggerButton"
      >
        <Translation id="myPortal.timeline.vehicleCollection.button.changeAppointment" />
      </Button>
      <Modal
        title={translate('myPortal.dashboard.rescheduleCollection.modal.title')}
        open={showModal}
        onClose={closeModal}
        size="s"
        data-e2e-id="vehicleCollectionModal"
      >
        <Modal.Content>
          <BrokerInformationModalContent
            dialogModal={dialogModal}
            handleButtonFirstClick={handleButtonFirstClick}
            handleButtonSecondClick={handleButtonSecondClick}
          />
        </Modal.Content>
      </Modal>
    </>
  )
}
