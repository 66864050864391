import { PdfIcon } from '@velocity/icons/system'
import { FixedSpacings, useBreakpoint } from '@velocity/styling'
import { Card, Grid, LinkInline, LinkStandalone } from '@velocity/ui'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import {
  Neutral400Text,
  Neutral500Text,
  Neutral800Text,
} from 'mlp-client/src/components/styled/TextStyle'
import { useToastsWithTranslation } from 'mlp-client/src/components/toasts/useToasts'
import { useLazyGetInvoiceDocumentQuery } from 'mlp-client/src/invoices/invoicesApi'
import { Invoice } from 'mlp-client/src/invoices/types'
import { groupInvoicesByMonth } from 'mlp-client/src/invoices/utils'
import { DateFormat } from 'mlp-client/src/localization/components/DateFormat'
import { Price } from 'mlp-client/src/localization/components/Price'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'
import { trackInvoiceDownloadsEvent } from 'mlp-client/src/services/analytics/analytics'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'
import { saveFile } from 'mlp-client/src/utils'
import { fromCamelCaseToUpperSnake } from 'mlp-client/src/utils/formatters/formatters'

const StyledGroupTitle = styled(Neutral500Text)`
  margin: var(--vel-fixed-spacing-05) 0 var(--vel-fixed-spacing-02);
`

const StyledInvoiceLink = styled.div`
  display: flex;
  gap: var(--vel-fixed-spacing-02);
`

interface Props {
  invoices: Invoice[]
}

export const InvoicesList: React.FC<Props> = ({ invoices }) => {
  const accountId = useSelectedAccountId()

  const [getInvoiceDocument] = useLazyGetInvoiceDocumentQuery()

  const { showError } = useToastsWithTranslation()

  const intl = useIntl()
  const { translate } = useTranslations()
  const breakpoint = useBreakpoint()

  const invoiceNumberStyle =
    breakpoint === 'XS'
      ? {
          order: 1,
          marginTop: FixedSpacings['02'],
        }
      : {}

  const groupedInvoices = groupInvoicesByMonth(
    invoices,
    intl.formatDate,
    translate,
    'myPortal.invoices.invoices.thisMonth',
  )

  const downloadInvoiceFile = async ({
    accountId,
    invoiceNumber,
    gdmsFilename,
    documentTypeDescription,
  }: {
    accountId: string
    invoiceNumber: string
    gdmsFilename: string
    documentTypeDescription: string
  }) => {
    const {
      data: invoiceDocumentBlob,
      isSuccess,
      isError,
    } = await getInvoiceDocument({
      accountId,
      invoiceNumber,
    })

    if (isError) {
      showError(
        'myPortal.error.invoices.noDownload.title',
        <Translation
          id="myPortal.error.invoices.noDownload.content"
          replace={{
            contentUrl: () => (
              <LinkInline
                target="_blank"
                href={translate(
                  'myPortal.error.invoices.noDownload.contentLabelUrl',
                )}
                key="link"
              >
                <Translation id="myPortal.error.invoices.noDownload.contentLabel" />
              </LinkInline>
            ),
          }}
        />,
      )
    }

    if (isSuccess) {
      saveFile(invoiceDocumentBlob, gdmsFilename, 'application/pdf')

      trackInvoiceDownloadsEvent({
        data: {
          component_type: `invoices_${fromCamelCaseToUpperSnake(gdmsFilename)}`,
          component_title: translate('myPortal.invoices.title'),
          component_link_text: translate('myPortal.invoices.button.download'),
          component_document_name: gdmsFilename,
          component_document_type: documentTypeDescription,
        },
      })
    }
  }

  return (
    <>
      {groupedInvoices.map(invoiceGroup => (
        <div key={invoiceGroup.key}>
          <StyledGroupTitle
            variant="300"
            loose={true}
            data-e2e-id="invoiceGroup"
          >
            {invoiceGroup.key}
          </StyledGroupTitle>
          {invoiceGroup.invoices.map(
            ({
              invoiceNumber,
              invoiceCreationDate,
              documentTypeDescription,
              invoiceAmount,
              gdmsFilename,
              invoiceCurrency,
            }) => (
              <Card
                key={invoiceNumber}
                data-e2e-id="invoice"
                margin={{ bottom: '03' }}
              >
                <Grid
                  columns={{ XS: 10, MD: 12 }}
                  gutter={{ XS: { y: 'fixed-spacing-02' } }}
                  alignment="center"
                  margin={{ around: '04' }}
                  data-e2e-id="invoiceDate"
                >
                  <Grid.Item XS={10} MD={1}>
                    <Neutral400Text variant="100">
                      <DateFormat
                        value={invoiceCreationDate}
                        year={undefined}
                        literal="-"
                      />
                    </Neutral400Text>
                  </Grid.Item>
                  <Grid.Item XS={7} MD={5}>
                    <LinkStandalone
                      kind="secondary"
                      component="button"
                      onClick={() =>
                        downloadInvoiceFile({
                          accountId,
                          invoiceNumber,
                          gdmsFilename,
                          documentTypeDescription,
                        })
                      }
                      data-e2e-id="invoiceDownload"
                    >
                      <StyledInvoiceLink>
                        <PdfIcon size="s" />
                        {documentTypeDescription}
                      </StyledInvoiceLink>
                    </LinkStandalone>
                  </Grid.Item>
                  <Grid.Item
                    XS={10}
                    MD={2}
                    style={invoiceNumberStyle}
                    data-e2e-id="invoiceNumber"
                  >
                    <Neutral400Text variant="100">
                      {invoiceNumber}
                    </Neutral400Text>
                  </Grid.Item>
                  <Grid.Item XS={3} MD={3} data-e2e-id="invoiceAmount">
                    <Neutral800Text variant="300" textAlign="end">
                      <Price value={invoiceAmount} currency={invoiceCurrency} />
                    </Neutral800Text>
                  </Grid.Item>
                </Grid>
              </Card>
            ),
          )}
        </div>
      ))}
    </>
  )
}
