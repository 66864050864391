import React, { ReactNode } from 'react'

import { CellComponent } from './types'

interface Props {
  rowsProps?: GenericObject
  item: GenericObject
  columnKey: string
  cell?: CellComponent
  emptyState?: ReactNode | null
  defaultValue?: ReactNode | null
}

const CellWrapper: React.FC<Props> = ({
  cell,
  item,
  rowsProps,
  defaultValue,
  columnKey,
}) => {
  if (!cell) {
    return item[columnKey] || defaultValue || null
  }

  const Cell = cell as CellComponent

  return <Cell item={item} value={item[columnKey]} {...rowsProps} />
}

export default CellWrapper
