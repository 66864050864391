export interface FAQDetailsItem {
  title: string
  summary: string
  linkText: string
  linkUrl: string
}

export const enum FAQKeys {
  VEHICLE_DOCUMENTS_PAGE = 'vehicleDocumentsPage',
  MAINTENANCE_AND_REPAIR_PAGE = 'maintenanceAndRepairPage',
  VEHICLE_COLLECTION_PAGE = 'vehicleCollectionPage',
  FAIR_WEAR_TEAR = 'fairWearTear',
  INVOICE = 'invoice',
  CONTRACT = 'contract',
}

export type FAQDetailsTranslationMap = {
  [key in FAQKeys]?: FAQDetailsItem
}
