import { Toaster } from '@velocity/ui'
import React, { useEffect } from 'react'

import { useSearchParams } from 'react-router-dom'

import NoPermissionSubtitle from 'mlp-client/src/components/login/login-error/NoPermissionSubtitle'
import { useToastsWithTranslation } from 'mlp-client/src/components/toasts/useToasts'
import { StatusCode } from 'mlp-client/src/services/api/types'

const LoginErrorHandler: React.FC = () => {
  const [searchParams] = useSearchParams()
  const statusCode = Number(searchParams.get('statusCode'))

  const { showError } = useToastsWithTranslation()

  useEffect(() => {
    switch (statusCode) {
      case StatusCode.SERVER_ERROR:
      case StatusCode.BAD_REQUEST:
        showError(
          'myPortal.error.technicalError.title',
          'myPortal.error.technicalError.subtitle',
        )
        break
      case StatusCode.FORBIDDEN:
        showError(
          'myPortal.error.noAuthorization.title',
          <NoPermissionSubtitle />,
        )
        break
    }
  }, [])

  return <Toaster position="top-center" />
}

export default LoginErrorHandler
