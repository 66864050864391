export const enum TimelineEventTypes {
  ACTUAL_MILEAGE_UPDATED = 'ActualMileageUpdated',
  ADD_SERVICES = 'AddServices',
  BALLOON_REFINANCE = 'BalloonRefinance',
  CHANGE_BROKER = 'ChangeBroker',
  CHANGE_COST_CENTER = 'ChangeCostCenter',
  CHANGE_CUSTOMER = 'ChangeCustomer',
  CHANGE_DRIVER = 'ChangeDriver',
  CHANGE_EXTRAORDINARY_PAYMENT = 'ChangeExtraordinaryPayment',
  CHANGE_REGISTRATION_NUMBER = 'ChangeRegistrationNumber',
  CHANGE_TERM_AND_MILEAGE = 'ChangeTermAndMileage',
  CONTRACT_ACTIVATED = 'ContractActivated',
  END_OF_CONTRACT = 'EndOfContract',
  FAIR_WEAR_AND_TEAR = 'FairWearAndTear',
  FORMAL_EXTENSION = 'FormalExtension',
  MAINTENANCE = 'Maintenance',
  REMOVE_SERVICES = 'RemoveServices',
  ROADSIDE_ASSISTANCE = 'RoadsideAssistance',
  SECONDARY_RENTAL = 'SecondaryRental',
  VEHICLE_COLLECTION = 'VehicleCollection',
}

export enum CompletedStatuses {
  COMPLETED = 'Completed',
}

export enum FairWearAndTearEventStatuses {
  ASSESSMENT_CREATED = 'AssessmentCreated',
  ASSESSMENT_CHALLENGED = 'AssessmentChallenged',
  CHALLENGE_ACCEPTED = 'ChallengeAccepted',
  CHALLENGE_REJECTED = 'ChallengeRejected',
  UNKNOWN = 'Unknown',
}

export enum DoneStatuses {
  DONE = 'Done',
}
