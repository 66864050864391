import { LinkInline } from '@velocity/ui'
import React from 'react'

import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'

const NoPermissionSubtitle = () => {
  const { translate } = useTranslations()

  return (
    <Translation
      id="myPortal.error.noAuthorization.content"
      replace={{
        contentUrl: () => (
          <LinkInline
            target="_blank"
            href={translate('myPortal.error.noAuthorization.contentURL')}
            key="contentUrl"
          >
            <Translation id="myPortal.error.noAuthorization.contentUrlLabel" />
          </LinkInline>
        ),
      }}
    />
  )
}

export default NoPermissionSubtitle
