import { config as countries } from 'mlp-client/src/features-configuration'
import { MyleaseplanConfig } from 'mlp-client/src/features-configuration/types'

export const getMyLeaseplanConfig = (country: string): MyleaseplanConfig => {
  const config = countries[country.toLowerCase()]

  if (!config) {
    throw new Error(`No configuration found for this country: ${country}`)
  }

  return config
}
