import React from 'react'
import { Helmet } from 'react-helmet-async'

import Loader from 'mlp-client/src/components/loader/Loader'

const NoIndexLoader: React.FC = () => (
  <Loader fixed={true}>
    <Helmet meta={[{ name: 'robots', content: 'noindex,nofollow' }]} />
  </Loader>
)

export default NoIndexLoader
