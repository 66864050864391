import React, { PropsWithChildren, useContext } from 'react'
import { matchRoutes, useLocation } from 'react-router-dom'

import { useLocale } from 'mlp-client/src/localization/LocaleProvider'
import { Routes } from 'mlp-client/src/localization/types'
import {
  getLocalizedRoute as getLocalizedRouteUtils,
  getLocalizedRouteMatch,
  flattenRoutes,
} from 'mlp-client/src/localization/utils'
import { getRoutesByLocale } from 'mlp-client/src/translations/utils'

interface RoutesContextProps {
  routes: Routes
}

let routesContext: RoutesContextProps = {
  routes: undefined,
}

export const RoutesContext =
  React.createContext<RoutesContextProps>(routesContext)

export const useRoutes = () => useContext(RoutesContext).routes
export const useLocalizedRoute = () => {
  const locale = useLocale()
  const routes = useRoutes()
  return (
    path: string,
    params: string | GenericObject = {},
    isLocalizedOnly = true,
  ) => {
    return getLocalizedRouteUtils(routes, locale, path, params, isLocalizedOnly)
  }
}

export const useLocalizedRouteMatch = () => {
  const locale = useLocale()
  const routes = useRoutes()
  return (path: string) => {
    return getLocalizedRouteMatch(routes, locale, path)
  }
}

const useFlattenRoutes = () => {
  const locale = useLocale()
  return flattenRoutes(getRoutesByLocale(locale))
}

export const useMatchedRoute = () => {
  const location = useLocation()
  const routes = useFlattenRoutes()
  const matchedRoutes = matchRoutes(routes, location)

  return matchedRoutes?.length ? matchedRoutes[0] : null
}

export const useCurrentPath = () => {
  const matchedRoute = useMatchedRoute()

  if (matchedRoute) {
    const { route } = matchedRoute
    return route.path
  }
  return ''
}

const RoutesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const locale = useLocale()

  const routes = { [locale]: { myPortal: getRoutesByLocale(locale) } }
  routesContext = { routes }

  return (
    <RoutesContext.Provider value={routesContext}>
      {children}
    </RoutesContext.Provider>
  )
}

export default RoutesProvider
