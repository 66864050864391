import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { setNextRoute } from 'mlp-client/src/auth/authSlice'
import NoIndexLoader from 'mlp-client/src/components/loader/NoIndexLoader'
import { useAppDispatch } from 'mlp-client/src/hooks'
import { useLanguage } from 'mlp-client/src/localization/LocaleProvider'
import { authorize } from 'mlp-client/src/services/auth/auth'

/*
  Parse url search parameters for `connection` and `path`, then trigger auth0 authentication using `connection`, and
  redirect to `path`.

  Url search Parameters:
  - connection (optional): connection property to pass to auth0
  - path (optional): path to redirect to after authentication

  Example: https://www.leaseplan.com/nl-nl/auth/authorize?connection=Leaseplan-Corp&path=%2Fnl-nl%2Fmijn%2Fprofiel

*/

const Authorize: React.FC = () => {
  const language = useLanguage()
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const connection = searchParams.get('connection')
    const path = searchParams.get('path')

    dispatch(setNextRoute(path))
    authorize(language, connection)
  }, [])

  return <NoIndexLoader />
}
export default Authorize
