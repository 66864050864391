import { mq } from '@velocity/styling'
import { Headline } from '@velocity/ui'
import styled from 'styled-components'

import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_LAPTOP,
  BREAKPOINT_TABLET,
} from 'mlp-client/src/utils/hooks/breakpoints'

export const LoginSideBar = styled.div`
  background-color: var(--vel-color-background-main);
  padding-top: 220px;
  width: 33.33%;

  ${mq.below(BREAKPOINT_LAPTOP)} {
    width: 50%;
  }

  ${mq.below(BREAKPOINT_TABLET)} {
    width: 100%;
    padding-top: 120px;
  }
`

export const LoginSideBarBody = styled.section`
  padding: 0 var(--vel-layout-spacing-08) 0;

  ${mq.below(BREAKPOINT_DESKTOP)} {
    padding: 0 var(--vel-layout-spacing-06) 0;
  }

  ${mq.below(BREAKPOINT_LAPTOP)} {
    padding: 0 var(--vel-fixed-spacing-07) 0;
  }

  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const LoginSideBarHeadline = styled(Headline)`
  margin-bottom: var(--vel-fixed-spacing-04);
`

export const LoginButtonWrapper = styled.div`
  margin-top: var(--vel-fixed-spacing-09);
`

export const LoginInformationSection = styled.section`
  padding: 220px 10% 0;
  text-align: center;
  width: 66.67%;

  ${mq.below(BREAKPOINT_LAPTOP)} {
    width: 50%;
    padding-left: 5%;
    padding-right: 5%;
  }
`
