import React from 'react'

import { DateFormat } from 'mlp-client/src/localization/components/DateFormat'
import Translation from 'mlp-client/src/localization/Translation'
import BaseTimelineCard, {
  TimelineItemProps,
} from 'mlp-client/src/timeline/components/TimelineCard/BaseTimelineCard'
import { ChangeCostCenterTimelineItem } from 'mlp-client/src/timeline/types'

const ChangeCostCenterTimelineCard: React.FC<
  TimelineItemProps<ChangeCostCenterTimelineItem>
> = ({ item, config }) => (
  <BaseTimelineCard
    item={item}
    config={config}
    status={item.status}
    title="myPortal.timeline.events.mlc.titles.costCenter"
  >
    <DateFormat value={item.changeDate} literal="-" />
    <div>
      <Translation
        id="myPortal.timeline.events.mlc.subtitles.costCenter"
        replace={{
          newCostCenter: item.costCenter,
        }}
      />
    </div>
  </BaseTimelineCard>
)

export default ChangeCostCenterTimelineCard
