import { Button, ButtonProps, Modal, ModalProps } from '@velocity/ui'
import React, { PropsWithChildren, useState } from 'react'

import Loader from 'mlp-client/src/components/loader/Loader'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'

interface Props extends PropsWithChildren {
  modalTitle: string
  triggerButtonText: string
  confirmButtonText: string
  cancelButtonText: string

  onConfirm(): void

  isLoading?: boolean
  modalSize?: ModalProps['size']
  triggerButtonSize?: ButtonProps['size']
  confirmButtonSize?: ButtonProps['size']
  cancelButtonSize?: ButtonProps['size']
  triggerButtonVariant?: ButtonProps['variant']
  confirmButtonVariant?: ButtonProps['variant']
  cancelButtonVariant?: ButtonProps['variant']
  triggerButtonDataE2eId?: string
  confirmButtonDataE2eId?: string
  cancelButtonDataE2eId?: string

  onOpen?(): void

  onClose?(): void
}

const ModalTrigger: React.FC<Props> = ({
  isLoading = false,
  modalTitle,
  modalSize = 's',
  triggerButtonText,
  confirmButtonText,
  cancelButtonText,
  triggerButtonSize = 'm',
  triggerButtonVariant = 'primary-filled',
  confirmButtonVariant = 'primary-filled',
  cancelButtonVariant = 'secondary-outlined',
  confirmButtonSize,
  cancelButtonSize,
  triggerButtonDataE2eId,
  confirmButtonDataE2eId,
  cancelButtonDataE2eId,
  onConfirm,
  onOpen,
  onClose,
  children,
}) => {
  const [showModal, setShowModal] = useState(false)
  const { translate } = useTranslations()

  const openModal = () => {
    onOpen?.()
    setShowModal(true)
  }
  const closeModal = () => {
    onClose?.()
    setShowModal(false)
  }

  return (
    <>
      <Button
        variant={triggerButtonVariant}
        onClick={openModal}
        size={triggerButtonSize}
        data-e2e-id={triggerButtonDataE2eId}
      >
        <Translation id={triggerButtonText} />
      </Button>
      <Modal
        title={translate(modalTitle)}
        open={showModal}
        onClose={closeModal}
        size={modalSize}
      >
        <Modal.Content>
          {children}
          {isLoading && <Loader />}
        </Modal.Content>
        <Modal.Footer>
          <Button
            onClick={onConfirm}
            variant={confirmButtonVariant}
            disabled={isLoading}
            size={confirmButtonSize}
            data-e2e-id={confirmButtonDataE2eId}
          >
            <Translation id={confirmButtonText} />
          </Button>
          <Button
            variant={cancelButtonVariant}
            onClick={closeModal}
            disabled={isLoading}
            size={cancelButtonSize}
            data-e2e-id={cancelButtonDataE2eId}
          >
            <Translation id={cancelButtonText} />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalTrigger
