import React, { PropsWithChildren, useCallback } from 'react'
import { Navigate } from 'react-router-dom'

import Loader from 'mlp-client/src/components/loader/Loader'
import { TileType } from 'mlp-client/src/contracts/enums'
import useContractDetails from 'mlp-client/src/contracts/hooks/useContractDetails'
import useContracts from 'mlp-client/src/contracts/hooks/useContracts'
import { useLocalizedRoute } from 'mlp-client/src/localization/RoutesProvider'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

interface Props {
  tileType: TileType
}

export const ContractTilesRouteProtector: React.FC<
  Props & PropsWithChildren
> = ({ tileType, children }) => {
  const getLocalizedRoute = useLocalizedRoute()
  const accountId = useSelectedAccountId()
  const {
    data: contractDetails,
    isFetching: isContractLoading,
    isSuccess: isContractLoaded,
  } = useContractDetails()
  const {
    data: contracts,
    isFetching: areContractsLoading,
    isSuccess: areContractsLoaded,
  } = useContracts()
  const contractId = contractDetails?.id

  if (areContractsLoading || (areContractsLoaded && contracts.length)) {
    if (!isContractLoaded || isContractLoading) {
      return <Loader fixed={true} />
    }
  }

  if (!contractDetails?.tiles.some(tile => tile.type === tileType)) {
    const redirectRoute = getLocalizedRoute('myPortal.dashboard', {
      accountId,
      ...(contractId && { contractId }),
    })
    return <Navigate to={redirectRoute} />
  }

  return <>{children}</>
}

const protectContractTilesRoute = <T extends PropsWithChildren>(
  ProtectedComponent: React.ComponentType<T>,
  hocProps: Props,
) => {
  const ProtectContractTilesRoute: React.FC<T> = props => (
    <ContractTilesRouteProtector tileType={hocProps.tileType}>
      <ProtectedComponent {...props} />
    </ContractTilesRouteProtector>
  )

  ProtectContractTilesRoute.displayName = `protectContractTilesRoute(${
    ProtectedComponent.displayName ||
    ProtectedComponent.name ||
    'ProtectedComponent'
  })`

  return ProtectContractTilesRoute
}

export const useProtectContractTiles = <T extends PropsWithChildren>(
  tileType: TileType,
) =>
  useCallback(
    (ProtectedComponent: React.ComponentType<T>) =>
      protectContractTilesRoute(ProtectedComponent, { tileType }),
    [tileType],
  )

export default protectContractTilesRoute
