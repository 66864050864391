import { ContractEndPictogram } from '@velocity/icons/pictogram'
import React from 'react'
import styled from 'styled-components'

import PagePlaceholder from 'mlp-client/src/components/page-placeholder/PagePlaceholder'

const StyledWrapper = styled('div')`
  margin-top: var(--vel-fixed-spacing-05);
`

const pictogram = (
  <ContractEndPictogram
    size="large"
    style={{
      width: '128px',
      height: '128px',
    }}
  />
)

const NoContracts: React.FC = () => (
  <StyledWrapper>
    <PagePlaceholder
      dataE2eId="noContracts"
      pictogram={pictogram}
      title="myPortal.error.noContract.title"
      subtitle="myPortal.error.noContract.content"
      linkButton={{
        to: 'myPortal.error.noContract.buttonURL',
        label: 'myPortal.error.noContract.button',
      }}
    />
  </StyledWrapper>
)

export default NoContracts
