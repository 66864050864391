import React from 'react'
import { Outlet } from 'react-router-dom'

import Footer from 'mlp-client/src/components/footer/Footer'
import InstallMobileApp from 'mlp-client/src/components/instal-mobile-app/InstallMobileApp'
import {
  MainContentLayout,
  MainWrapper,
} from 'mlp-client/src/components/layout/main-content-wrapper/MainContentWrapper.styled'
import TopNavigationStructure from 'mlp-client/src/components/layout/top-navigation/TopNavigationStructure'

const Layout: React.FC = () => (
  <MainWrapper data-e2e-id="mainLayout">
    <TopNavigationStructure upperNavigationPopup={<InstallMobileApp />} />
    <MainContentLayout>
      <Outlet />
    </MainContentLayout>
    <Footer />
  </MainWrapper>
)

export default Layout
