import React, { useMemo } from 'react'

import TechnicalError from 'mlp-client/src/components/error/TechnicalError'
import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import NoContracts from 'mlp-client/src/components/no-contracts/NoContracts'
import useContracts, {
  useHasMultipleContracts,
} from 'mlp-client/src/contracts/hooks/useContracts'
import { useHasContracts } from 'mlp-client/src/contracts/hooks/useContracts'
import DashboardContent from 'mlp-client/src/dashboard/components/dashboard-content/DashboardContent'
import DashboardTabs from 'mlp-client/src/dashboard/components/DashboardTabs'
import DashboardTopBanner from 'mlp-client/src/dashboard/components/DashboardTopBanner'
import { BasicTile } from 'mlp-client/src/dashboard/components/tiles'
import { TileOptions } from 'mlp-client/src/dashboard/types'
import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { isServerError } from 'mlp-client/src/services/api/utils'

export interface Config {
  tileOptions: TileOptions
}

export type Props = MyLeaseplanFeatureProps<Config>

const ConfigurableDashboard: React.FC<Props> = (props: Props) => {
  const {
    featureConfig: {
      configuration: { tileOptions },
    },
  } = props
  const { translate } = useTranslations()
  const metaTitle = translate('myPortal.dashboard.title')
  const metaDescription = translate('myPortal.dashboard.subtitle')
  const { error } = useContracts()
  const userHasContract = useHasContracts()
  const showMultipleVehicles = useHasMultipleContracts()

  const getTilesConfig = (options: TileOptions = []) =>
    options.map(option => ({
      ...option,
      view: option.view || BasicTile,
      title: option.title && translate(option.title),
      to: option.to,
    }))

  const tilesConfig = useMemo(() => getTilesConfig(tileOptions), [tileOptions])

  let children = showMultipleVehicles ? (
    <DashboardTabs tilesConfig={tilesConfig} />
  ) : (
    <DashboardContent tilesConfig={tilesConfig} />
  )

  if (isServerError(error)) {
    children = <TechnicalError />
  } else if (!userHasContract) {
    children = <NoContracts />
  }

  return (
    <MyleaseplanPage
      metaTitle={metaTitle}
      metaDescription={metaDescription}
      banner={<DashboardTopBanner />}
    >
      {children}
    </MyleaseplanPage>
  )
}

export default ConfigurableDashboard
