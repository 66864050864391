import { mq } from '@velocity/styling'
import styled from 'styled-components'

import {
  BREAKPOINT_LAPTOP,
  BREAKPOINT_TABLET,
} from 'mlp-client/src/utils/hooks/breakpoints'

export const FullWidthBanner = styled.div<{
  image: string
}>`
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  hyphens: auto;
  word-break: break-word;
  background-size: auto 100%;
  background-position: 40% 100%;
  background-image: url(${p => p.image});
  background-color: var(--vel-color-secondary-400);
  align-items: center;
  height: 140px;

  ${mq.min(BREAKPOINT_LAPTOP)} {
    height: 200px;
    background-position: 50% 100%;
  }
`

export const BannerContent = styled.div`
  display: flex;
  flex: 1 0 100%;
  flex-flow: column nowrap;
  padding: 0 var(--vel-fixed-spacing-07);

  ${mq.below(BREAKPOINT_TABLET)} {
    text-align: center;
  }
`
