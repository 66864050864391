import { rest } from 'msw'

// documents
import documentError from 'mlp-client/src/mocks/json/documents/document/error.json'
import documentError404 from 'mlp-client/src/mocks/json/documents/document/error404.json'
import documents from 'mlp-client/src/mocks/json/documents/documents.json'
import documentsError from 'mlp-client/src/mocks/json/documents/error.json'
import documentsError404 from 'mlp-client/src/mocks/json/documents/error404.json'

export const handlers = [
  // documents
  rest.get('*/api2/documents/:accountId/:ilan', async (req, res, ctx) => {
    const params = new URLSearchParams(window.location.search)
    const scenario = params.get('documents')
    const error = params.get('error')

    if (scenario) {
      if (error === '404') {
        return res(ctx.status(404), ctx.json(documentsError404))
      }

      if (error) {
        return res(ctx.status(500), ctx.json(documentsError))
      }
    }

    return res(ctx.json(documents))
  }),

  // document
  rest.get(
    '*/api2/documents/:accountId/:entity/:application/:gdid',
    async (req, res, ctx) => {
      const params = new URLSearchParams(window.location.search)
      const scenario = params.get('document')
      const error = params.get('error')

      if (scenario) {
        if (error === '404') {
          return res(ctx.status(404), ctx.json(documentError404))
        }

        if (error) {
          return res(ctx.status(500), ctx.json(documentError))
        }
      }
    },
  ),
]
