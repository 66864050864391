import React from 'react'

import TableMobile from './TableMobile'
import TableWide from './TableWide'

import type { TableProps } from './types'

import { getEmptyKeys } from './utils'

import { useIsDesktopView } from 'mlp-client/src/utils/hooks/breakpoints'

const Table: React.FC<TableProps> = ({
  columns,
  rows,
  data,
  emptyState,
  defaultValue,
  ...restProps
}) => {
  const isDesktopView = useIsDesktopView()
  const TableComponent = isDesktopView ? TableWide : TableMobile

  const emptyKeys = getEmptyKeys(data, columns)
  const visibleColumns = columns.filter(item => !emptyKeys.includes(item.key))

  return (
    <div data-e2e-id={restProps['data-e2e-id']}>
      <TableComponent
        columns={visibleColumns}
        data={data}
        rows={rows}
        emptyState={emptyState}
        defaultValue={defaultValue}
      />
    </div>
  )
}

export default Table
