import { ToastKind } from '@velocity/ui'
import { useCallback } from 'react'

import { showToast, ToastDescription } from './toasts'

import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'

export const useToastsWithTranslation = () => {
  const { translate } = useTranslations()

  const show = useCallback(
    (kind: ToastKind, title: string, description?: ToastDescription) => {
      const titleTranslated = translate(title)
      const descTranslated =
        typeof description === 'string' ? translate(description) : description
      showToast(kind, titleTranslated, descTranslated)
    },
    [translate],
  )

  const showSuccess = useCallback(
    (title: string, description?: ToastDescription) =>
      show('success', title, description),
    [show],
  )
  const showWarning = useCallback(
    (title: string, description?: ToastDescription) =>
      show('warning', title, description),
    [show],
  )
  const showError = useCallback(
    (title: string, description?: ToastDescription) =>
      show('error', title, description),
    [show],
  )
  const showInfo = useCallback(
    (title: string, description?: ToastDescription) =>
      show('information', title, description),
    [show],
  )

  return {
    showSuccess,
    showWarning,
    showError,
    showInfo,
  }
}
