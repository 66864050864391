import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill'
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill'
import { shouldPolyfill as shouldPolyfillNumber } from '@formatjs/intl-numberformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill'
import { shouldPolyfill as shouldPolyfillRelativeTime } from '@formatjs/intl-relativetimeformat/should-polyfill'

import { ALLOWED_LOCALES } from 'mlp-client/src/features-configuration'

export async function polyfillPluralRules() {
  const unsupportedPluralRulesLocales = ALLOWED_LOCALES.map(
    locale =>
      (!Intl.PluralRules && locale) || shouldPolyfillPluralRules(locale),
  ).filter(result => result !== undefined)

  if (unsupportedPluralRulesLocales.length > 0) {
    await import('@formatjs/intl-pluralrules/polyfill-force')

    if (unsupportedPluralRulesLocales.includes('en')) {
      await import('@formatjs/intl-pluralrules/locale-data/en')
    }

    if (unsupportedPluralRulesLocales.includes('pt-PT')) {
      await import('@formatjs/intl-pluralrules/locale-data/pt-PT')
    }

    return unsupportedPluralRulesLocales
  }
}

async function polyfillNumberFormat() {
  const unsupportedNumberFormatLocales = ALLOWED_LOCALES.map(locale =>
    shouldPolyfillNumber(locale),
  ).filter(result => result !== undefined)

  if (unsupportedNumberFormatLocales.length > 0) {
    await import('@formatjs/intl-numberformat/polyfill-force')

    if (unsupportedNumberFormatLocales.includes('en-GB')) {
      await import('@formatjs/intl-numberformat/locale-data/en-GB')
    }

    if (unsupportedNumberFormatLocales.includes('en')) {
      await import('@formatjs/intl-numberformat/locale-data/en')
    }

    if (unsupportedNumberFormatLocales.includes('pt-PT')) {
      await import('@formatjs/intl-numberformat/locale-data/pt-PT')
    }
  }

  await import('./intl.numberFormat.en-PT')
}

async function polyfillRelativeTimeFormat() {
  const unsupportedRelativeTimeFormatLocales = ALLOWED_LOCALES.map(locale =>
    shouldPolyfillRelativeTime(locale),
  ).filter(result => result !== undefined)

  if (unsupportedRelativeTimeFormatLocales.length > 0) {
    await import('@formatjs/intl-relativetimeformat/polyfill-force')

    if (unsupportedRelativeTimeFormatLocales.includes('en-GB')) {
      await import('@formatjs/intl-relativetimeformat/locale-data/en-GB')
    }

    if (unsupportedRelativeTimeFormatLocales.includes('en')) {
      await import('@formatjs/intl-relativetimeformat/locale-data/en')
    }

    if (unsupportedRelativeTimeFormatLocales.includes('pt-PT')) {
      await import('@formatjs/intl-relativetimeformat/locale-data/pt-PT')
    }
  }
}

export async function polyfillLocale() {
  if (shouldPolyfillGetCanonicalLocales()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill')
  }

  if (shouldPolyfillLocale()) {
    await import('@formatjs/intl-locale/polyfill-force')
  }

  await polyfillPluralRules()
  await polyfillNumberFormat()
  await polyfillRelativeTimeFormat()
}
