const SUPPORTED_KEYS = [
  'hideInformalExtensionNotification',
  'hideRequiredMileageUpdateModal',
] as const

type SupportedLocalStorageKeys = (typeof SUPPORTED_KEYS)[number]

/**
 * authSessionStorage uses the browsers localstorage to store data
 * On every login, all SUPPORTED_KEYS entries are cleared from the localstorage
 * Keys are appended with 'authSession-' prefix
 */
export const authSessionStorage = {
  addPrefix(key: SupportedLocalStorageKeys): string {
    return `authSession-${key}`
  },
  removeItem(key: SupportedLocalStorageKeys): void {
    const prefixedKey = this.addPrefix(key)
    localStorage.removeItem(prefixedKey)
  },
  init(): void {
    SUPPORTED_KEYS.forEach(key => this.removeItem(key))
  },
  getItem(key: SupportedLocalStorageKeys) {
    const prefixedKey = this.addPrefix(key)
    return localStorage.getItem(prefixedKey)
  },
  setItem(key: SupportedLocalStorageKeys, value: string) {
    const prefixedKey = this.addPrefix(key)
    localStorage.setItem(prefixedKey, value)
  },
}
