import moment, { MomentInput } from 'moment'

export const formatDate = (
  date: moment.Moment | string | Date,
  dateFormat = 'dddd, LL',
) =>
  moment.isMoment(date)
    ? date.format(dateFormat)
    : moment(date).format(dateFormat)

export const formatRelativeDate = (date: MomentInput): string => {
  const dateObj = moment(date)

  return dateObj > moment('1900-01-01') ? dateObj.fromNow() : ''
}
