import { Loader, Text } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'

const ContractsLoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--vel-layout-spacing-07) 0;
  width: 100%;
`

const ContractsLoader: React.FC = () => (
  <ContractsLoaderWrapper data-e2e-id="contractsLoader">
    <Loader variant="primary" size="l" />
    <Text variant="500">
      <Translation id="myPortal.loader.contracts" />
    </Text>
  </ContractsLoaderWrapper>
)

export default ContractsLoader
