import { useEffect } from 'react'

import {
  COOKIE_EXPIRATION_DURATION,
  DebugFlags,
  getCookie,
  setCookie,
} from 'mlp-client/src/cookies'
import {
  enableDebugTranslations,
  enableShowAllFeatures,
} from 'mlp-client/src/debug/debugSlice'
import { useAppDispatch } from 'mlp-client/src/hooks'
import RuntimeEnv from 'mlp-client/src/utils/runtimeEnv'

const useDebugFlags = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    try {
      const params = new URLSearchParams(window.location.search)

      if (
        !getCookie(DebugFlags.SHOW_ALL_FEATURES) &&
        params.has(DebugFlags.SHOW_ALL_FEATURES)
      ) {
        setCookie(DebugFlags.SHOW_ALL_FEATURES, 'true', {
          'Max-Age': COOKIE_EXPIRATION_DURATION,
        })
      }

      if (
        !getCookie(DebugFlags.DEBUG_TRANSLATIONS) &&
        params.has(DebugFlags.DEBUG_TRANSLATIONS)
      ) {
        setCookie(DebugFlags.DEBUG_TRANSLATIONS, 'true', {
          'Max-Age': COOKIE_EXPIRATION_DURATION,
        })
      }

      if (
        getCookie(DebugFlags.SHOW_ALL_FEATURES) ||
        params.has(DebugFlags.SHOW_ALL_FEATURES)
      ) {
        dispatch(enableShowAllFeatures())
      }

      if (
        getCookie(DebugFlags.DEBUG_TRANSLATIONS) ||
        params.has(DebugFlags.DEBUG_TRANSLATIONS)
      ) {
        dispatch(enableDebugTranslations())
      }
    } catch (e) {
      if (RuntimeEnv.isDevelopment) {
        // eslint-disable-next-line no-console
        console.error('Setting debug flags failed: ', e)
      }
    }
  }, [])
}

export default useDebugFlags
