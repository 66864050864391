import { Card, Grid, SkeletonLoader, useLoading } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import TextCenter from 'mlp-client/src/components/styled/TextCenter'
import { Show } from 'mlp-client/src/components/utils/Show'
import {
  CardContentStyled,
  IconComponentWrap,
  MobileTileContainer,
} from 'mlp-client/src/dashboard/components/tiles/DashboardTile.styled'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'

const IconSkeletonLoader = styled(SkeletonLoader)`
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 36px;
  height: 36px;
`

const MobileSkeletonLoader = styled(SkeletonLoader)`
  width: 100%;
`

export const DashboardTilesLoader: React.FC = () => {
  const { translate } = useTranslations()

  const loadingActiveMessage = translate('myPortal.loader.contract')

  const { messageElement } = useLoading({
    initialLoading: true,
    giveInitialMessage: true,
    loadingActiveMessage,
    loadingDoneMessage: null,
  })

  return (
    <>
      {messageElement}
      <Grid alignment="stretch" data-e2e-id="dashboardTilesLoader">
        {Array.from({ length: 8 }).map((_, index) => (
          <Grid.Item key={index} XS={12} MD={3} LG={3}>
            <Card>
              <Show until="MD">
                <MobileTileContainer data-e2e-id="dashboardServicesMobileLoaderItem">
                  <MobileSkeletonLoader
                    on="alt"
                    text={{
                      variant: '200',
                    }}
                  ></MobileSkeletonLoader>
                </MobileTileContainer>
              </Show>

              <Show from="MD">
                <CardContentStyled data-e2e-id="dashboardTilesLoaderItem">
                  <TextCenter>
                    <IconComponentWrap data-e2e-id="dashboardIconWrapper">
                      <IconSkeletonLoader on="alt" />
                    </IconComponentWrap>

                    <SkeletonLoader
                      on="alt"
                      text={{
                        variant: '400',
                      }}
                    ></SkeletonLoader>
                  </TextCenter>
                </CardContentStyled>
              </Show>
            </Card>
          </Grid.Item>
        ))}
      </Grid>
    </>
  )
}
