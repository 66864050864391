import React from 'react'

import { CenteredContentWrapper } from 'mlp-client/src/components/layout/main-content-wrapper/MainContentWrapper.styled'
import { SomethingWentWrong } from 'mlp-client/src/components/something-went-wrong/SomethingWentWrong'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'

const SomethingWentWrongPage: React.FC = () => {
  const { translate } = useTranslations()
  const title = translate('myPortal.somethingWentWrong.title')

  const linkButton = {
    to: 'myPortal.external.mainHomePage',
    label: 'Leaseplan.com',
  }

  return (
    <CenteredContentWrapper>
      <SomethingWentWrong
        title={title}
        linkButton={linkButton}
        withMarginTop={false}
      />
    </CenteredContentWrapper>
  )
}

SomethingWentWrongPage.displayName = 'SomethingWentWrongPage'

export default SomethingWentWrongPage
