import { Modal, ModalProps } from '@velocity/ui'
import React, { useEffect, useMemo, useState } from 'react'
import {
  generatePath,
  Route,
  Routes,
  useNavigate,
  useLocation,
  useParams,
} from 'react-router-dom'

import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import { useCurrentPath } from 'mlp-client/src/localization/RoutesProvider'
import { addTrailingSlash } from 'mlp-client/src/localization/utils'

interface Props {
  path: string
  title: string
  children({ closeModal }: { closeModal(): void }): React.ReactNode
  protectRoute?(
    component: React.ComponentType<ModalProps>,
  ): React.ComponentType<ModalProps>
  'data-e2e-id'?: string
}

const RoutedModal: React.FC<Props> = ({
  path,
  title,
  children,
  protectRoute = component => component,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { translate } = useTranslations()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const currentPath = useCurrentPath()
  const { pathname } = location

  function closeModal() {
    const { '*': _, ...returnParams } = params
    const returnUrl = addTrailingSlash(generatePath(currentPath, returnParams))
    setIsOpen(false)
    navigate(returnUrl)
  }

  const generatedPath = addTrailingSlash(
    generatePath(currentPath, { ...params, '*': path }),
  )

  const isMatch = generatedPath === addTrailingSlash(pathname)

  useEffect(() => {
    if (isMatch && !isOpen) {
      setIsOpen(true)
    }
  }, [isOpen, isMatch])

  const ProtectedRouteModal = useMemo(() => protectRoute(Modal), [protectRoute])

  return (
    <Routes>
      <Route
        key={path}
        path={path}
        element={
          <ProtectedRouteModal
            disableInitialFocus={true}
            size="s"
            title={translate(title)}
            open={isOpen}
            onClose={closeModal}
            data-e2e-id={rest['data-e2e-id'] || 'routeModal'}
          >
            <Modal.Content>{children?.({ closeModal })}</Modal.Content>
          </ProtectedRouteModal>
        }
      ></Route>
    </Routes>
  )
}

export default RoutedModal
