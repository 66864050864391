import React, { useContext } from 'react'

import ContractChangeModalContent from 'mlp-client/src/components/contract-change-modal/ContractChangeModalContent'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import RouteModal from 'mlp-client/src/components/route-modal/RouteModal'
import { TileType } from 'mlp-client/src/contracts/enums'
import { useProtectContractTiles } from 'mlp-client/src/routes/protectContractTilesRoute/protectContractTilesRoute'
import { withOneInstance } from 'mlp-client/src/utils/hocs/hocs'

const ContractChangeModal = () => {
  const {
    MLC: {
      configuration: { dialogModal },
    },
  } = useContext(MyLeaseplanContext)

  return (
    <RouteModal
      title={dialogModal.title}
      path="contract-changes"
      protectRoute={useProtectContractTiles(TileType.CONTRACT_CHANGES)}
      data-e2e-id="contractChangesModal"
    >
      {() => <ContractChangeModalContent modalContent={dialogModal} />}
    </RouteModal>
  )
}

export default withOneInstance(ContractChangeModal)
