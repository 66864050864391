import { rest } from 'msw'

import bookingDetailsError from 'mlp-client/src/mocks/json/bookings/error.json'
import bookingDetailsError404 from 'mlp-client/src/mocks/json/bookings/error404.json'
import maintenanceDetails from 'mlp-client/src/mocks/json/bookings/maintenanceDetails.json'
import vehicleCollectionDetails from 'mlp-client/src/mocks/json/bookings/vehicleCollectionDetails.json'

export const handlers = [
  rest.get('*/api2/bookings/:accountId/:bookingId', async (req, res, ctx) => {
    const isVehicleCollectionPage = window.location.pathname.includes(
      'vehicle-collection-details',
    )
    const params = new URLSearchParams(window.location.search)
    const scenario = params.get('bookings')
    const error = params.get('error')

    if (scenario) {
      if (error === '404') {
        return res(ctx.status(404), ctx.json(bookingDetailsError404))
      }

      if (error) {
        return res(ctx.status(500), ctx.json(bookingDetailsError))
      }
    }

    return isVehicleCollectionPage
      ? res(ctx.json(vehicleCollectionDetails))
      : res(ctx.json(maintenanceDetails))
  }),

  rest.put('*/api2/bookings/:accountId/:bookingId', async (req, res, ctx) => {
    const params = new URLSearchParams(window.location.search)
    const scenario = params.get('booking')
    const error = params.get('error')

    if (scenario) {
      if (error === '404') {
        return res(ctx.status(404), ctx.json(bookingDetailsError404))
      }

      if (error) {
        return res(ctx.status(500), ctx.json(bookingDetailsError))
      }
    }

    return res(ctx.json({}))
  }),
]
