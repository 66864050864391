import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppState } from 'mlp-client/src/types'

export interface InvoicesState {
  searchTerm: string
  startDate: string | null
  endDate: string | null
  page: number
}

export const initialState: InvoicesState = {
  searchTerm: '',
  startDate: null,
  endDate: null,
  page: 0,
}

const invoicesSlice = createSlice({
  name: '@@invoices',
  initialState,
  reducers: {
    setInvoicesSearchFilter: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload
    },
    setInvoicesDateFilter: (
      state,
      action: PayloadAction<Pick<InvoicesState, 'startDate' | 'endDate'>>,
    ) => {
      const { startDate, endDate } = action.payload

      state.startDate = startDate
      state.endDate = endDate
      state.page = 0
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
  },
})

export const getSearchTerm = (state: AppState): string =>
  state.invoices.searchTerm

export const getStartDate = (state: AppState): string =>
  state.invoices.startDate

export const getEndDate = (state: AppState): string => state.invoices.endDate

export const getPage = (state: AppState): number => state.invoices.page

export const hasSearchFilter = (state: AppState): boolean =>
  state.invoices.searchTerm?.length > 0

export const hasDateFilter = (state: AppState): boolean =>
  !!(state.invoices.startDate || state.invoices.endDate)

export const hasSomeFilter = (state: AppState): boolean =>
  hasSearchFilter(state) || hasDateFilter(state)

export const { setInvoicesSearchFilter, setInvoicesDateFilter, setPage } =
  invoicesSlice.actions

export default invoicesSlice.reducer
