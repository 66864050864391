import { configureStore, StoreEnhancer } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { Action, Dispatch, Middleware } from 'redux'

import auth from 'mlp-client/src/auth/authSlice'
import contracts from 'mlp-client/src/contracts/contractsSlice'
import debug from 'mlp-client/src/debug/debugSlice'
import invoices from 'mlp-client/src/invoices/invoicesSlice'
import routes from 'mlp-client/src/routes/routesSlice'
import { api } from 'mlp-client/src/services/api/api'
import apiErrors from 'mlp-client/src/services/api/apiErrorsSlice'
import timeline from 'mlp-client/src/timeline/timelineSlice'
import { AppState } from 'mlp-client/src/types'
import user from 'mlp-client/src/user/userSlice'
import RuntimeEnv from 'mlp-client/src/utils/runtimeEnv'
import sessionStorageEnhancer from 'mlp-client/src/utils/sessionStorageEnhancer'

export const createAppStore = (preloadedState: Partial<AppState> = {}) => {
  const asPlainObjectMiddleware: Middleware =
    () => (next: Dispatch<Action>) => (action: Action) =>
      next({ ...action })

  const middlewares = [asPlainObjectMiddleware, api.middleware]

  const enhancers: StoreEnhancer[] = []

  // Persistence is not required when code is executed by Jest
  if (!RuntimeEnv.isTest) {
    enhancers.push(sessionStorageEnhancer)
  }

  const store = configureStore({
    reducer: {
      auth,
      contracts,
      debug,
      invoices,
      routes,
      timeline,
      user,
      apiErrors,
      [api.reducerPath]: api.reducer,
    },
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(middlewares),
    enhancers,
  })

  setupListeners(store.dispatch)

  return { store }
}

type AppStore = ReturnType<typeof createAppStore>['store']
export type AppDispatch = AppStore['dispatch']
