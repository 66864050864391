import styled from 'styled-components'

export const ContentStyled = styled.div`
  display: flex;
  margin-top: var(--vel-fixed-spacing-06);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--vel-fixed-spacing-07);

  > div:first-child {
    padding-bottom: var(--vel-fixed-spacing-07);
    align-self: center;
  }
`
