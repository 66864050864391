export const isExternal = (url: string): boolean =>
  /^https?:\/\//.test(url) || /^www/.test(url)

export const isMail = (url: string): boolean => /^mailto:/.test(url)

export const isPhone = (url: string): boolean => /^tel:/.test(url)

export const isIOS = () =>
  /iphone/i.test(window.navigator.userAgent) ||
  /ipad/i.test(window.navigator.userAgent)
