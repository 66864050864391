import { ChevronRightIcon } from '@velocity/icons/system'
import { mq } from '@velocity/styling'
import { Card, LicensePlate } from '@velocity/ui'
import styled from 'styled-components'

import { BREAKPOINT_TABLET } from 'mlp-client/src/utils/hooks/breakpoints'

export const CardStyled = styled(Card)`
  margin-top: var(--vel-fixed-spacing-05);
  min-width: 480px;
  padding: 0 var(--vel-fixed-spacing-07);

  ${mq.below(BREAKPOINT_TABLET)} {
    min-width: 360px;
    padding: 0 var(--vel-fixed-spacing-05);
  }
`

export const DropdownListItemWrapper = styled.div`
  position: relative;
  border-bottom: 1px var(--vel-color-neutral-300) solid;

  &:last-child {
    border-bottom-color: transparent;
  }
`

export const ChevronRightIconStyled = styled(ChevronRightIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  margin-top: calc(-1 * var(--vel-fixed-spacing-01));
`

export const LicensePlateStyled = styled(LicensePlate)`
  margin-left: var(--vel-fixed-spacing-03);
`
