import {
  ChevronUpIcon,
  ChevronDownIcon,
  SearchIcon,
} from '@velocity/icons/system'
import {
  Grid,
  Button,
  Input,
  DatePicker,
  FormField,
  DatePickerChangeEvent,
} from '@velocity/ui'
import React, { useState, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'mlp-client/src/hooks'
import {
  setInvoicesSearchFilter,
  setInvoicesDateFilter,
  setPage,
  hasSomeFilter,
} from 'mlp-client/src/invoices/invoicesSlice'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'

type Filter = 'search' | 'date' | null

const InvoiceFilters: React.FC = () => {
  const dispatch = useAppDispatch()

  const [filterSelection, setFilterSelection] = useState<Filter>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const isSomeFilterPresent = useAppSelector(hasSomeFilter)

  useEffect(() => {
    if (isSomeFilterPresent) {
      clearFiltersAndDispatch()
    }
  }, [filterSelection])

  useEffect(() => {
    if (!isSomeFilterPresent) {
      clearFilters()
    }
  }, [isSomeFilterPresent])

  const { translate, defaultDateFormat } = useTranslations()
  const getMinDate = () => {
    const goLiveDate = translate('myPortal.invoices.oldInvoices.goLiveDate')
      .split('-')
      .reverse()
      .join('-')

    const currentDate = new Date().toISOString()
    const fallbackDate = currentDate < goLiveDate ? null : goLiveDate

    return startDate ?? fallbackDate
  }
  const hasFilterSearchError =
    searchTerm?.length > 0 && !searchTerm.match(/\d{12}/)

  const hasFilterDateError = !!startDate && !!endDate && startDate > endDate

  const toggleFilter = (filter: Filter) => () => {
    setFilterSelection(filter !== filterSelection ? filter : null)
  }

  const handleStartDateChange = (e: DatePickerChangeEvent) => {
    setStartDate(e.target.value)
  }

  const handleEndDateChange = (e: DatePickerChangeEvent) => {
    setEndDate(e.target.value)
  }

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleSearchTermEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      e.preventDefault()
      applySearchFilter()
    }
  }

  const clearSearchFilter = () => {
    setSearchTerm('')
    dispatch(setInvoicesSearchFilter(''))
  }

  const clearDateFilter = () => {
    setStartDate(null)
    setEndDate(null)
    dispatch(
      setInvoicesDateFilter({
        startDate: null,
        endDate: null,
      }),
    )
  }

  const clearFilters = () => {
    clearSearchFilter()
    clearDateFilter()
    dispatch(setPage(0))
  }

  const clearFiltersAndDispatch = () => {
    clearFilters()
  }

  const applySearchFilter = () => {
    dispatch(setInvoicesSearchFilter(searchTerm))
  }

  const applyDateFilter = () => {
    dispatch(
      setInvoicesDateFilter({
        startDate,
        endDate,
      }),
    )
  }

  return (
    <Grid>
      <Grid.Item>
        <Button
          StartIcon={
            filterSelection === 'search' ? ChevronUpIcon : ChevronDownIcon
          }
          component="button"
          variant="dark-outlined"
          style={{
            marginRight: 'var(--vel-fixed-spacing-04)',
          }}
          onClick={toggleFilter('search')}
          data-e2e-id="searchFilterToggle"
        >
          <Translation id="myPortal.invoices.filter.search" />
        </Button>
        <Button
          StartIcon={
            filterSelection === 'date' ? ChevronUpIcon : ChevronDownIcon
          }
          component="button"
          variant="dark-outlined"
          onClick={toggleFilter('date')}
          data-e2e-id="dateFilterToggle"
        >
          <Translation id="myPortal.invoices.filter.sort" />
        </Button>
      </Grid.Item>
      {filterSelection === 'search' && (
        <Grid.Item>
          <form data-e2e-id="formInvoicesSearchFilter">
            <Grid>
              <Grid.Item XS={1}>
                <Input
                  placeholder={translate(
                    'myPortal.invoices.filter.placeholder',
                  )}
                  value={searchTerm}
                  error={hasFilterSearchError}
                  onChange={handleSearchTermChange}
                  onKeyDown={handleSearchTermEnter}
                  data-e2e-id="searchFilterInput"
                />
              </Grid.Item>
              <Grid.Item>
                <Button
                  StartIcon={SearchIcon}
                  component="button"
                  variant="primary-filled"
                  disabled={searchTerm === '' || hasFilterSearchError}
                  onClick={applySearchFilter}
                  style={{ marginRight: 'var(--vel-fixed-spacing-04)' }}
                  data-e2e-id="searchFilterButtonApply"
                >
                  <Translation id="myPortal.invoices.filter.buttons.search" />
                </Button>
                <Button
                  component="button"
                  variant="dark-outlined"
                  disabled={searchTerm === ''}
                  onClick={clearFiltersAndDispatch}
                  data-e2e-id="searchFilterButtonCancel"
                >
                  <Translation id="myPortal.invoices.filter.buttons.clear" />
                </Button>
              </Grid.Item>
            </Grid>
          </form>
        </Grid.Item>
      )}
      {filterSelection === 'date' && (
        <Grid.Item>
          <Grid>
            <Grid.Item>
              <form data-e2e-id="formInvoicesDateFilter">
                <Grid>
                  <Grid.Item XS={4}>
                    <FormField
                      label="Start date"
                      style={{
                        display: 'inline-block',
                        marginRight: 'var(--vel-fixed-spacing-04)',
                      }}
                    >
                      <DatePicker
                        name={translate(
                          'myPortal.invoices.filter.filters.startDate',
                        )}
                        format={defaultDateFormat}
                        value={startDate}
                        minDate={getMinDate()}
                        maxDate={
                          endDate ?? new Date().toISOString().split('T')[0]
                        }
                        error={hasFilterDateError}
                        onChange={handleStartDateChange}
                        data-e2e-id="startDateInput"
                      />
                    </FormField>
                    <FormField
                      label="End date"
                      style={{ display: 'inline-block' }}
                    >
                      <DatePicker
                        name={translate(
                          'myPortal.invoices.filter.filters.endDate',
                        )}
                        format={defaultDateFormat}
                        disableFuture={true}
                        value={endDate}
                        minDate={getMinDate()}
                        error={hasFilterDateError}
                        onChange={handleEndDateChange}
                        data-e2e-id="endDateInput"
                      />
                    </FormField>
                  </Grid.Item>
                  <Grid.Item>
                    <Button
                      component="button"
                      variant="primary-filled"
                      onClick={applyDateFilter}
                      disabled={
                        hasFilterDateError ||
                        (startDate === null && endDate === null)
                      }
                      style={{ marginRight: 'var(--vel-fixed-spacing-04)' }}
                      data-e2e-id="dateFilterButtonApply"
                    >
                      <Translation id="myPortal.invoices.filter.buttons.apply" />
                    </Button>
                    <Button
                      component="button"
                      variant="dark-outlined"
                      onClick={clearFiltersAndDispatch}
                      disabled={startDate === null && endDate === null}
                      data-e2e-id="dateFilterButtonCancel"
                    >
                      <Translation id="myPortal.invoices.filter.buttons.clear" />
                    </Button>
                  </Grid.Item>
                </Grid>
              </form>
            </Grid.Item>
          </Grid>
        </Grid.Item>
      )}
    </Grid>
  )
}

export default InvoiceFilters
