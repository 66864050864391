import { TimelineFeatureConfig } from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import ActualMileageUpdatedTimelineCard from 'mlp-client/src/timeline/components/TimelineCard/components/ActualMileageUpdatedTimelineCard'
import BalloonRefinanceTimelineCard from 'mlp-client/src/timeline/components/TimelineCard/components/BalloonRefinanceTimelineCard'
import ChangeBrokerTimelineCard from 'mlp-client/src/timeline/components/TimelineCard/components/ChangeBrokerTimelineCard'
import ChangeCostCenterTimelineCard from 'mlp-client/src/timeline/components/TimelineCard/components/ChangeCostCenterTimelineCard'
import ChangeCustomerTimelineCard from 'mlp-client/src/timeline/components/TimelineCard/components/ChangeCustomerTimelineCard'
import ChangeDriverTimelineCard from 'mlp-client/src/timeline/components/TimelineCard/components/ChangeDriverTimelineCard'
import ChangeExtraordinaryPaymentTimelineCard from 'mlp-client/src/timeline/components/TimelineCard/components/ChangeExtraordinaryPaymentTimelineCard'
import ChangeRegistrationNumberTimelineCard from 'mlp-client/src/timeline/components/TimelineCard/components/ChangeRegistrationNumberTimelineCard'
import ChangeServicesTimelineCard from 'mlp-client/src/timeline/components/TimelineCard/components/ChangeServicesTimelineCard'
import ChangeTermAndMileageTimelineCard from 'mlp-client/src/timeline/components/TimelineCard/components/ChangeTermAndMileageTimelineCard'
import ContractActivatedTimelineCard from 'mlp-client/src/timeline/components/TimelineCard/components/ContractActivatedTimelineCard'
import EndOfContractTimelineCard from 'mlp-client/src/timeline/components/TimelineCard/components/EndOfContractTimelineCard'
import FairWearAndTearTimelineCard from 'mlp-client/src/timeline/components/TimelineCard/components/FairWearAndTearTimelineCard'
import FormalExtensionTimelineCard from 'mlp-client/src/timeline/components/TimelineCard/components/FormalExtensionTimelineCard'
import MaintenanceTimelineCard from 'mlp-client/src/timeline/components/TimelineCard/components/MaintenanceTimelineCard'
import RoadsideAssistanceTimelineCard from 'mlp-client/src/timeline/components/TimelineCard/components/RoadsideAssistanceTimelineCard'
import SecondaryRentalTimelineCard from 'mlp-client/src/timeline/components/TimelineCard/components/SecondaryRentalTimelineCard'
import VehicleCollectionTimelineCard from 'mlp-client/src/timeline/components/TimelineCard/components/VehicleCollectionTimelineCard'
import TimelinePage from 'mlp-client/src/timeline/components/TimelinePage/TimelinePage'
import { TimelineEventTypes } from 'mlp-client/src/timeline/enums'

const linkToContract = 'myPortal.contract.detail'

export const timelineConfig: MyLeaseplanFeature<TimelineFeatureConfig> = {
  enabled: true,
  view: TimelinePage,
  configuration: {
    cards: {
      [TimelineEventTypes.CONTRACT_ACTIVATED]: {
        linkToDetails: linkToContract,
        component: ContractActivatedTimelineCard,
      },
      [TimelineEventTypes.CHANGE_BROKER]: {
        linkToDetails: linkToContract,
        component: ChangeBrokerTimelineCard,
      },
      [TimelineEventTypes.CHANGE_COST_CENTER]: {
        component: ChangeCostCenterTimelineCard,
      },
      [TimelineEventTypes.CHANGE_CUSTOMER]: {
        linkToDetails: linkToContract,
        component: ChangeCustomerTimelineCard,
      },
      [TimelineEventTypes.CHANGE_DRIVER]: {
        linkToDetails: linkToContract,
        component: ChangeDriverTimelineCard,
      },
      [TimelineEventTypes.CHANGE_REGISTRATION_NUMBER]: {
        linkToDetails: linkToContract,
        component: ChangeRegistrationNumberTimelineCard,
      },
      [TimelineEventTypes.CHANGE_TERM_AND_MILEAGE]: {
        linkToDetails: linkToContract,
        component: ChangeTermAndMileageTimelineCard,
      },
      [TimelineEventTypes.END_OF_CONTRACT]: {
        linkToDetails: linkToContract,
        component: EndOfContractTimelineCard,
      },
      [TimelineEventTypes.FORMAL_EXTENSION]: {
        linkToDetails: linkToContract,
        component: FormalExtensionTimelineCard,
      },
      [TimelineEventTypes.MAINTENANCE]: {
        linkToEventDetails: 'myPortal.timeline.maintenanceDetails',
        component: MaintenanceTimelineCard,
      },
      [TimelineEventTypes.VEHICLE_COLLECTION]: {
        linkToEventDetails: 'myPortal.timeline.vehicleCollectionDetails',
        component: VehicleCollectionTimelineCard,
      },
      [TimelineEventTypes.FAIR_WEAR_AND_TEAR]: {
        linkToEventDetails: 'myPortal.timeline.assessmentDetails',
        component: FairWearAndTearTimelineCard,
      },
      [TimelineEventTypes.ROADSIDE_ASSISTANCE]: {
        component: RoadsideAssistanceTimelineCard,
      },
      [TimelineEventTypes.ACTUAL_MILEAGE_UPDATED]: {
        linkToDetails: linkToContract,
        component: ActualMileageUpdatedTimelineCard,
      },
      [TimelineEventTypes.BALLOON_REFINANCE]: {
        linkToDetails: linkToContract,
        component: BalloonRefinanceTimelineCard,
      },
      [TimelineEventTypes.ADD_SERVICES]: {
        linkToDetails: linkToContract,
        component: ChangeServicesTimelineCard,
      },
      [TimelineEventTypes.REMOVE_SERVICES]: {
        linkToDetails: linkToContract,
        component: ChangeServicesTimelineCard,
      },
      [TimelineEventTypes.CHANGE_EXTRAORDINARY_PAYMENT]: {
        linkToDetails: linkToContract,
        component: ChangeExtraordinaryPaymentTimelineCard,
      },
      [TimelineEventTypes.SECONDARY_RENTAL]: {
        linkToDetails: linkToContract,
        component: SecondaryRentalTimelineCard,
      },
    },
  },
}
