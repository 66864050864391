import React from 'react'

import MobileTile from './MobileTile'
import TileWithIcons from './TileWithIcons'

import { Show } from 'mlp-client/src/components/utils/Show'
import { useCurrentContractId } from 'mlp-client/src/contracts/hooks/useContracts'
import { TileProps } from 'mlp-client/src/dashboard/types'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

export const BasicTile: React.FC<TileProps> = props => {
  const contractId = useCurrentContractId()
  const accountId = useSelectedAccountId()

  const tileProps = {
    ...props,
    toParams: { contractId, accountId },
    defaultContent: !contractId,
  }

  return (
    <span data-e2e-id={props.name}>
      <Show until="MD">
        <MobileTile {...tileProps} />
      </Show>
      <Show from="MD">
        <TileWithIcons {...tileProps} />
      </Show>
    </span>
  )
}
