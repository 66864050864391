import { Headline, Text } from '@velocity/ui'
import React from 'react'

import {
  MarkerTooltipWrapper,
  MarkerTooltipContainer,
  MarkerTooltipIconWrapper,
  MarkerTooltipIcon,
} from 'mlp-client/src/components/map/MarkerTooltip.styled'
import MaskedData from 'mlp-client/src/utils/masks/MaskedData'

export interface Props {
  title: React.ReactNode
  text: React.ReactNode
}

const MarkerTooltip: React.FC<Props> = ({ title, text }) => {
  return (
    <MarkerTooltipWrapper data-e2e-id="markerTooltip">
      <MarkerTooltipContainer>
        <Headline variant="100" component="h6" data-e2e-id="markerTooltipTitle">
          {title}
        </Headline>
        <Text variant="100" data-e2e-id="markerTooltipText">
          <MaskedData type="Address">{text}</MaskedData>
        </Text>
      </MarkerTooltipContainer>
      <MarkerTooltipIconWrapper data-e2e-id="markerTooltipIcon">
        <MarkerTooltipIcon size="l" />
      </MarkerTooltipIconWrapper>
    </MarkerTooltipWrapper>
  )
}

export default MarkerTooltip
