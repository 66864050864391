import { Button, ButtonProps, LinkStandalone } from '@velocity/ui'
import React from 'react'

import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { useLocalizedRoute } from 'mlp-client/src/localization/RoutesProvider'
import Translation from 'mlp-client/src/localization/Translation'
import { isLocalizedRouteIdentifier } from 'mlp-client/src/localization/utils'

export interface LinkButtonProps extends Omit<ButtonProps, 'variant'> {
  to: string
  label: string
  variant?: ButtonProps['variant']
}

const LinkButton: React.FC<LinkButtonProps> = ({
  to,
  label,
  variant = 'primary-filled',
  ...props
}) => {
  const { translate } = useTranslations()
  const getLocalizedRoute = useLocalizedRoute()
  const isInternalLink = isLocalizedRouteIdentifier(to)
  const isLocalizedRoute = getLocalizedRoute(to)

  if (isInternalLink && isLocalizedRoute) {
    return (
      <RouteLink to={to}>
        <Button variant={variant} data-e2e-id="linkButton" {...props}>
          <Translation id={label} />
        </Button>
      </RouteLink>
    )
  }

  const href = isInternalLink ? translate(to) : to

  return (
    <LinkStandalone target="_blank" href={href}>
      <Button variant={variant} data-e2e-id="linkButton" {...props}>
        <Translation id={label} />
      </Button>
    </LinkStandalone>
  )
}

export default LinkButton
