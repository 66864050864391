import {
  ContractVehicleType,
  ContractVehicleEnergyType,
} from 'mlp-client/src/contracts/enums'

export const FIELD_TYPE_NOTE = 'NOTE'
export const FIELD_TYPE_LINE = 'LINE'

export const VEHICLE_TYPE_MAP: { [key in ContractVehicleType]: string } = {
  Car: 'VEHVehicleTypeC',
  LightCommercialVehicle: 'VEHVehicleTypeV',
  MediumCommercialVehicle: 'VEHVehicleTypeD',
  HeavyCommercialVehicle: 'VEHVehicleTypeH',
  Bicycle: 'VEHVehicleTypeY',
  Bus: 'VEHVehicleTypeB',
  Motorbike: 'VEHVehicleTypeM',
  Scooter: 'VEHVehicleTypeS',
  Tractor: 'VEHVehicleTypeR',
  Trailer: 'VEHVehicleTypeT',
  Equipment: 'VEHVehicleTypeE',
  Camper: 'VEHVehicleTypeA',
  Unknown: 'VEHVehicleTypeU',
}

export const ENERGY_TYPE_MAP: {
  [key in ContractVehicleEnergyType]: string
} = {
  Petrol: 'FuelP',
  PetrolHybrid: 'FuelPH',
  PetrolPlugInHybrid: 'FuelPPH',
  PetrolMildHybrid: 'FuelPMH',
  Diesel: 'FuelD',
  DieselHybrid: 'FuelDH',
  DieselPlugInHybrid: 'FuelDPH',
  DieselMildHybrid: 'FuelDMH',
  LPG: 'FuelG',
  NaturalGas: 'FuelN',
  FullElectric: 'FuelE',
  Hydrogen: 'FuelH',
  Ethanol: 'FuelE85',
  Unknown: 'Unknown',
}
