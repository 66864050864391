import styled from 'styled-components'

export const TitleContainerStyled = styled.div`
  padding-top: var(--vel-fixed-spacing-07);
  padding-bottom: var(--vel-fixed-spacing-07);
`

export const SubtitleContainerStyled = styled.div`
  margin-bottom: var(--vel-fixed-spacing-07);
`
