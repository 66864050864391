import React from 'react'
import { FormattedTime } from 'react-intl'

import {
  DateFormat,
  Props,
} from 'mlp-client/src/localization/components/DateFormat'

export const DateTime: React.FC<Props> = ({ value, ...props }) => (
  <>
    <DateFormat value={value} {...props} />{' '}
    <FormattedTime value={value} hour="numeric" minute="numeric" />
  </>
)
