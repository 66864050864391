import { ChevronLeftBgIcon } from '@velocity/icons/system'
import React from 'react'

import TechnicalError from 'mlp-client/src/components/error/TechnicalError'

const TimelineTechnicalError: React.FC = () => {
  return (
    <TechnicalError
      dataE2eId="timelineTechnicalError"
      linkButton={{
        to: 'myPortal.timeline.base',
        label: 'myPortal.timeline.error.backButton',
        StartIcon: ChevronLeftBgIcon,
      }}
    />
  )
}

export default TimelineTechnicalError
