import { isBreakpointBelow, mq, useBreakpoint } from '@velocity/styling'
import { TopNavigation as VelocityTopNavigation } from '@velocity/ui/draft'
import React, { ReactNode, useCallback, useEffect } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import CurrentLanguage from 'mlp-client/src/components/layout/top-navigation/CurrentLanguage'
import SupportLink from 'mlp-client/src/components/layout/top-navigation/SupportLink'
import UserAccountMenu from 'mlp-client/src/components/layout/top-navigation/UserAccountMenu'
import { useCurrentContractId } from 'mlp-client/src/contracts/hooks/useContracts'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { useLocalizedRoute } from 'mlp-client/src/localization/RoutesProvider'
import {
  trackInternalNavigationEvent,
  trackInternalNavigationMobileEvent,
} from 'mlp-client/src/services/analytics/analytics'
import { NavigationItem } from 'mlp-client/src/types'
import { useUser } from 'mlp-client/src/user/hooks/useUser'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'
import { BREAKPOINT_MOBILE } from 'mlp-client/src/utils/hooks/breakpoints'

interface TopNavigationWrapperProps {
  'data-e2e-id': string
}

const TopNavigationWrapper = styled.div<TopNavigationWrapperProps>`
  z-index: var(--vel-zindex-top_navigation);
  position: sticky;
  top: 0;
  width: 100%;
`

const StyledMobileNavLink = styled.div`
  display: none;

  ${mq.below(BREAKPOINT_MOBILE)} {
    display: flex;
    height: 100%;
    align-items: center;
    padding: var(--vel-fixed-spacing-04) var(--vel-fixed-spacing-05);
    box-sizing: border-box;
  }
`

const StyleWidgetWrapper = styled.div`
  padding: 0 var(--vel-fixed-spacing-04);
`

const MobileNavLinkWrapper = <T,>(Component: React.FC<T>) =>
  React.forwardRef<HTMLDivElement, T>((props, ref) => (
    <StyledMobileNavLink ref={ref}>
      <Component {...props} />
    </StyledMobileNavLink>
  ))

const MobileSupportLinkNavLink = MobileNavLinkWrapper(SupportLink)
const MobileUserAccountMenuNavLink = MobileNavLinkWrapper(UserAccountMenu)
const MobileCurrentLanguageNavLink = MobileNavLinkWrapper(CurrentLanguage)

interface Props {
  navigationItems: NavigationItem[]
  upperNavigationPopup?: ReactNode
}

const TopNavigation: React.FC<Props> = ({
  navigationItems,
  upperNavigationPopup,
}) => {
  const location = useLocation()
  const { translate } = useTranslations()
  const contractId = useCurrentContractId()
  const { data: user } = useUser()
  const accountId = useSelectedAccountId()
  const getLocalizedRoute = useLocalizedRoute()
  const breakpoint = useBreakpoint()
  const isMobile = isBreakpointBelow('SM', breakpoint)

  const isRouteMatches = useCallback(
    (to: string, params: string | GenericObject = {}) =>
      !!matchPath(
        { path: getLocalizedRoute(to, params), end: false },
        location.pathname,
      ),
    [getLocalizedRoute, location.pathname],
  )

  const getParams = (useContractId: boolean) =>
    useContractId ? { contractId, accountId } : { accountId }

  const getPath = (navigationItem: NavigationItem) =>
    window.location.origin +
    getLocalizedRoute(
      navigationItem.to,
      getParams(navigationItem.useContractId),
    )

  const createNavigationItemProps = useCallback(
    (navigationItem: NavigationItem) => {
      const { to, useContractId } = navigationItem
      const params = getParams(useContractId)

      return {
        component: RouteLink,
        to,
        active: isRouteMatches(to, params),
        params,
      }
    },
    [contractId, isRouteMatches],
  )

  const sendInternalNavigationAnalytics = (navigationItem: NavigationItem) =>
    trackInternalNavigationEvent({
      data: {
        component_link_text: translate(navigationItem.title),
        component_link_url: getPath(navigationItem),
        component_type: isMobile
          ? 'mobile_menu_top_link'
          : 'main_menu_top_link',
      },
    })

  const sendInternalNavigationMobileAnalytics = (event: MouseEvent) => {
    const isOpen = !(event.currentTarget as Element).hasAttribute(
      'aria-controls',
    )

    trackInternalNavigationMobileEvent({
      data: {
        component_type: isOpen
          ? 'main_menu_hamburger_open'
          : 'main_menu_hamburger_close',
      },
    })
  }

  // TopNavigation doesn't provide onToggle when mobile navigation menu is opened or closed
  // Will be fixed in VEL-1890
  useEffect(() => {
    document
      .querySelector('[aria-label="Open main menu"]')
      ?.addEventListener('click', sendInternalNavigationMobileAnalytics)

    return () =>
      document
        .querySelector('[aria-label="Open main menu"]')
        ?.removeEventListener('click', sendInternalNavigationMobileAnalytics)
  }, [])

  return (
    <>
      <TopNavigationWrapper data-e2e-id="topNavigation">
        {upperNavigationPopup}
        <div data-e2e-id="topNavigationMenu">
          <VelocityTopNavigation layoutContainerSize="fixed">
            <VelocityTopNavigation.Logo
              component={RouteLink}
              to="myPortal.homepage"
              clipBehaviour="none"
              data-e2e-id="mainMenuLogoLink"
            />
            {navigationItems.length || isMobile ? (
              <VelocityTopNavigation.Nav data-e2e-id="mainMenuNavigation">
                {navigationItems.map(navigationItem => (
                  <VelocityTopNavigation.Nav.Link
                    key={navigationItem.title}
                    {...createNavigationItemProps(navigationItem)}
                    data-e2e-id={`mainMenuItem-${navigationItem.to}`}
                    onClick={() =>
                      sendInternalNavigationAnalytics(navigationItem)
                    }
                  >
                    {translate(navigationItem.title)}
                  </VelocityTopNavigation.Nav.Link>
                ))}
                <VelocityTopNavigation.Nav.Link
                  component={MobileSupportLinkNavLink}
                >
                  Support
                </VelocityTopNavigation.Nav.Link>
                {user && (
                  <VelocityTopNavigation.Nav.Link
                    component={MobileUserAccountMenuNavLink}
                  >
                    Account
                  </VelocityTopNavigation.Nav.Link>
                )}
                <VelocityTopNavigation.Nav.Link
                  component={MobileCurrentLanguageNavLink}
                >
                  Language
                </VelocityTopNavigation.Nav.Link>
              </VelocityTopNavigation.Nav>
            ) : (
              <></>
            )}
            {!isMobile ? (
              <VelocityTopNavigation.Widgets>
                <StyleWidgetWrapper>
                  <SupportLink />
                </StyleWidgetWrapper>
                {user && (
                  <StyleWidgetWrapper>
                    <UserAccountMenu />
                  </StyleWidgetWrapper>
                )}
                <StyleWidgetWrapper>
                  <CurrentLanguage />
                </StyleWidgetWrapper>
              </VelocityTopNavigation.Widgets>
            ) : (
              <></>
            )}
          </VelocityTopNavigation>
        </div>
      </TopNavigationWrapper>
    </>
  )
}

export default TopNavigation
