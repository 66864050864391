const isStorybook = !!process.env.STORYBOOK

const isTest = !!process.env.JEST_WORKER_ID || process.env.NODE_ENV === 'test'

const isDevelopment = process.env.NODE_ENV === 'development'

export default {
  isStorybook,
  isTest,
  isDevelopment,
}
