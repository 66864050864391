import AyvensTheme from '@velocity/styling/themes/ayvens/theme'
import LeaseplanTheme from '@velocity/styling/themes/leaseplan/theme'
import { VelocityProvider } from '@velocity/ui'
import React, { PropsWithChildren } from 'react'

import tenant from 'mlp-client/src/tenant/tenant'

const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const theme = tenant === 'leaseplan' ? LeaseplanTheme : AyvensTheme

  return <VelocityProvider theme={theme}>{children}</VelocityProvider>
}

export default ThemeProvider
