import React, { PropsWithChildren, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setRedirectRoutePath } from 'mlp-client/src/routes/routesSlice'
import {
  getApiErrors,
  clearApiErrors,
} from 'mlp-client/src/services/api/apiErrorsSlice'
import { is403Error } from 'mlp-client/src/services/api/utils'

export const ErrorRedirectComponent: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const dispatch = useDispatch()
  const errors = useSelector(getApiErrors)

  useEffect(() => {
    const has403Error = errors.find(
      error => is403Error(error.error) && error.endpoint !== 'getUser',
    )
    if (has403Error) {
      dispatch(setRedirectRoutePath('myPortal.forbidden'))
      dispatch(clearApiErrors())
    }
  }, [errors])

  return <>{children}</>
}

const errorRedirectHOC = <T extends PropsWithChildren>(
  ProtectedComponent: React.ComponentType<T>,
) => {
  const ErrorRedirect: React.FC<T> = props => (
    <ErrorRedirectComponent>
      <ProtectedComponent {...props} />
    </ErrorRedirectComponent>
  )

  ErrorRedirect.displayName = `errorRedirect(${
    ProtectedComponent.displayName ||
    ProtectedComponent.name ||
    'ProtectedComponent'
  })`

  return ErrorRedirect
}

export default errorRedirectHOC
