import React from 'react'

import InfoCard from 'mlp-client/src/components/info-card/InfoCard'
import useFAQDetails from 'mlp-client/src/FAQ/hooks/useFAQDetails'
import { FAQKeys } from 'mlp-client/src/FAQ/types'

interface Props {
  faqKey: FAQKeys
  dataE2eId?: string
}

const FAQ: React.FC<Props> = ({ faqKey, dataE2eId }) => {
  const { linkText, title, summary, linkUrl } = useFAQDetails(faqKey)

  return (
    <InfoCard
      title={title}
      summary={summary}
      dataE2eId={dataE2eId || `${faqKey}FAQ`}
      linkText={linkText}
      linkURL={linkUrl}
    />
  )
}

export default FAQ
