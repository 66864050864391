import React from 'react'

import { DateFormat } from 'mlp-client/src/localization/components/DateFormat'
import BaseTimelineCard, {
  TimelineItemProps,
} from 'mlp-client/src/timeline/components/TimelineCard/BaseTimelineCard'
import { FairWearAndTearTimelineItem } from 'mlp-client/src/timeline/types'

const FairWearAndTearTimelineCard: React.FC<
  TimelineItemProps<FairWearAndTearTimelineItem>
> = ({ item, config }) => (
  <BaseTimelineCard
    item={item}
    config={config}
    id={item.assessmentId}
    status={item.status}
    title="myPortal.timeline.fairWearTear.inspection.title"
  >
    <DateFormat value={item.creationDate} literal="-" />
  </BaseTimelineCard>
)

export default FairWearAndTearTimelineCard
