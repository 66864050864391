import React, { ReactNode } from 'react'

import { EmptyStateWide } from './EmptyState'
import { RowColumns } from './RowColumns'
import { Column, RowsConfiguration } from './types'

interface Props {
  columns: readonly Column[]
  rows?: RowsConfiguration
  data: readonly GenericObject[]
  emptyState?: ReactNode | null
  defaultValue?: ReactNode | null
}

const TableWideBody: React.FC<Props> = ({
  data,
  rows,
  columns,
  emptyState,
  defaultValue,
}) => {
  if (!data || !data.length) {
    return (
      <EmptyStateWide columnsAmount={columns.length}>
        {emptyState}
      </EmptyStateWide>
    )
  }

  if (rows && rows.TrComponent) {
    return (
      <tbody data-e2e-id="tableBodyWideWithCustomRow">
        {data.map(item => (
          <rows.TrComponent
            key={item.key}
            {...rows.props}
            item={item}
            columns={columns}
            data-e2e-id={item.key}
          >
            <RowColumns
              columns={columns}
              item={item}
              rowsProps={rows && rows.props}
              defaultValue={defaultValue}
            />
          </rows.TrComponent>
        ))}
      </tbody>
    )
  }

  return (
    <tbody data-e2e-id="tableBodyWideWithDefaultRow">
      {data.map(item => (
        <tr key={item.key}>
          <RowColumns
            columns={columns}
            item={item}
            defaultValue={defaultValue}
            rowsProps={rows && rows.props}
          />
        </tr>
      ))}
    </tbody>
  )
}

export default TableWideBody
