import { PropsWithChildren } from 'react'

interface Props extends PropsWithChildren {
  type?: string
}

const MaskedData: React.FC<Props> = ({
  type = 'Masked Data',
  children,
  ...props
}) => {
  return (
    <span data-dd-action-name={type} {...props}>
      {children}
    </span>
  )
}

export default MaskedData
