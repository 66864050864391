import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/query/react'
import { isEqual } from 'lodash'

import { Invoice, InvoicesItems } from 'mlp-client/src/invoices/types'
import { api } from 'mlp-client/src/services/api/api'

export interface GetInvoicesParams {
  accountId: string
  startDate?: string
  endDate?: string
  page: number
}

export interface FindInvoiceParams {
  accountId: string
  invoiceNumber: string
}

interface GetInvoiceDocumentParams {
  accountId: string
  invoiceNumber: string
}

const invoicesApi = api.injectEndpoints({
  endpoints: builder => ({
    getInvoices: builder.query<InvoicesItems, Partial<GetInvoicesParams>>({
      query: ({ accountId, startDate, endDate, page }) => ({
        url: `/invoices/${accountId}`,
        method: 'GET',
        params: {
          startDate,
          endDate,
          page,
        },
      }),
      providesTags: ['Invoices'],
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) => {
        const { page, ...newQueryArgs } = { ...queryArgs }

        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: newQueryArgs,
          endpointDefinition,
        })
      },
      merge: (currentCache: InvoicesItems, newItems: InvoicesItems) => {
        currentCache.invoices = [...newItems.invoices].reduce(
          (acc, cur) => {
            if (
              acc.findIndex(item => cur.invoiceNumber === item.invoiceNumber) <
              0
            ) {
              acc.push(cur)
            }

            return acc
          },
          [...currentCache.invoices],
        )
        currentCache.metadata = newItems.metadata
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        !isEqual(currentArg, previousArg),
    }),
    findInvoice: builder.query<Invoice, FindInvoiceParams>({
      query: ({ accountId, invoiceNumber }) => ({
        url: `/invoices/${accountId}/${invoiceNumber}`,
        method: 'GET',
        params: {
          invoiceNumber,
        },
      }),
    }),
    getInvoiceDocument: builder.query<Blob, GetInvoiceDocumentParams>({
      query: ({ accountId, invoiceNumber }) => ({
        url: `/invoices/${accountId}/${invoiceNumber}/document`,
        method: 'GET',
        headers: {
          Accept: 'application/pdf',
          'Content-Type': 'application/pdf',
        },
        // responseHandler is required when the response is to be parsed as anything other than a JSON (e.g. PDF)
        responseHandler: async (response: Body) => await response.blob(),
        cache: 'no-cache',
      }),
    }),
  }),
})

export const {
  useGetInvoicesQuery,
  useFindInvoiceQuery,
  useLazyGetInvoiceDocumentQuery,
} = invoicesApi
