import React, { useMemo } from 'react'

import AssessmentDetailsPage from 'mlp-client/src/assessments/components/AssessmentDetailsPage'
import Authorize from 'mlp-client/src/auth/components/Authorize/Authorize'
import LoginRedirect from 'mlp-client/src/auth/components/LoginRedirect/LoginRedirect'
import LogoutRedirect from 'mlp-client/src/auth/components/LogoutRedirect/LogoutRedirect'
import RedirectToCorrectAuth0Locale from 'mlp-client/src/auth/components/RedirectToCorrectAuth0Locale/RedirectToCorrectAuth0Locale'
import MaintenanceDetailsPage from 'mlp-client/src/bookings/components/MaintenanceDetailsPage/MaintenanceDetailsPage'
import VehicleCollectionDetailsPage from 'mlp-client/src/bookings/components/VehicleCollectionDetailsPage/VehicleCollectionDetailsPage'
import Forbidden from 'mlp-client/src/components/forbidden/Forbidden'
import Layout from 'mlp-client/src/components/layout/layout/Layout'
import Login from 'mlp-client/src/components/login/Login'
import MyleaseplanFeature from 'mlp-client/src/components/myleaseplan-feature/MyleaseplanFeature'
import NotFound from 'mlp-client/src/components/not-found/NotFound'
import SomethingWentWrongPage from 'mlp-client/src/components/something-went-wrong/SomethingWentWrongPage'
import { TileType } from 'mlp-client/src/contracts/enums'
import Dashboard from 'mlp-client/src/dashboard/Dashboard'
import { PageTitle } from 'mlp-client/src/enums'
import HomePage from 'mlp-client/src/homepage/HomePage'
import Invoices from 'mlp-client/src/invoices/components/Invoices'
import { Locales } from 'mlp-client/src/localization/enums'
import { useLocale } from 'mlp-client/src/localization/LocaleProvider'
import { useRoutes } from 'mlp-client/src/localization/RoutesProvider'
import { Routes } from 'mlp-client/src/localization/types'
import { getLocalizedRouteMatch } from 'mlp-client/src/localization/utils'
import { RouteComponent } from 'mlp-client/src/routes/RouteComponent'
import wrapRouteComponent from 'mlp-client/src/routes/routeWrappers'
import SelectAccount from 'mlp-client/src/select-account/SelectAccount'
import TimelinePage from 'mlp-client/src/timeline/components/TimelinePage/TimelinePage'
import { Route } from 'mlp-client/src/types'
import { useGetUserContractIdsCount } from 'mlp-client/src/user/hooks/useUser'
import { UserRole } from 'mlp-client/src/user/types'
import Vehicles from 'mlp-client/src/vehicles/Vehicles'

const TIMELINE_PAGE_TITLE_KEY = 'myPortal.timeline.base.title'

const mylpRoutes = (showMultipleVehicles: boolean): Route[] => [
  {
    path: '/',
    component: Layout,
    pageTitle: PageTitle.HOME,
    isAuthProtected: true,
    children: [
      {
        path: 'myPortal.homepage',
        isAuthProtected: true,
        requiresContract: true,
        component: HomePage,
        pageTitle: PageTitle.HOME,
        pageTitleKey: 'myPortal.shared.home',
      },
      {
        path: 'myPortal.selectAccount',
        isAuthProtected: true,
        component: SelectAccount,
        pageTitle: PageTitle.SELECT_ACCOUNT,
        pageTitleKey: 'myPortal.accountSwitching.title',
      },
      ...(showMultipleVehicles
        ? ['myPortal.dashboard', 'myPortal.contract.detail']
        : ['myPortal.dashboard']
      ).map(path => ({
        path,
        isAuthProtected: true,
        requiresContract: true,
        component: Dashboard,
        pageTitle: PageTitle.DASHBOARD,
        pageTitleKey: 'myPortal.subNavigation.dashboard',
      })),
      !showMultipleVehicles && {
        path: 'myPortal.contract.detail',
        isAuthProtected: true,
        requiresContract: true,
        component: MyleaseplanFeature,
        props: {
          feature: 'contracts',
        },
        pageTitle: PageTitle.CONTRACT_DETAILS,
        pageTitleKey: 'myPortal.contract.title',
      },
      {
        path: 'myPortal.vehicles',
        isAuthProtected: true,
        requiresContract: true,
        component: Vehicles,
        pageTitle: PageTitle.VEHICLES,
        pageTitleKey: 'myPortal.subNavigation.vehicles',
      },
      {
        path: 'myPortal.timeline.base',
        isAuthProtected: true,
        requiresContract: true,
        component: TimelinePage,
        pageTitle: PageTitle.TIMELINE,
        pageTitleKey: TIMELINE_PAGE_TITLE_KEY,
      },
      {
        path: 'myPortal.endOfLease.base',
        isAuthProtected: true,
        requiresContract: true,
        component: MyleaseplanFeature,
        props: {
          feature: 'endOfLease',
        },
        pageTitle: PageTitle.END_OF_LEASE,
        pageTitleKey: 'myPortal.endOfLease.dashboard.title',
      },
      {
        path: 'myPortal.documents.base',
        isAuthProtected: true,
        requiresContract: true,
        contractTileType: TileType.VEHICLE_DOCUMENTS,
        component: MyleaseplanFeature,
        props: {
          feature: 'vehicleDocumentsPage',
        },
        pageTitle: PageTitle.DOCUMENTS,
        pageTitleKey:
          'myPortal.vehicleDocuments.steps.selectDocument.pageTitle',
      },
      {
        path: 'myPortal.timeline.maintenanceDetails',
        isAuthProtected: true,
        requiresContract: true,
        component: MaintenanceDetailsPage,
        pageTitle: PageTitle.MAINTENANCE_BOOKING_DETAILS,
        pageTitleKey: TIMELINE_PAGE_TITLE_KEY,
      },
      {
        path: 'myPortal.timeline.assessmentDetails',
        isAuthProtected: true,
        requiresContract: true,
        component: AssessmentDetailsPage,
        pageTitle: PageTitle.ASSESSMENT_DETAILS,
        pageTitleKey: TIMELINE_PAGE_TITLE_KEY,
      },
      {
        path: 'myPortal.timeline.vehicleCollectionDetails',
        isAuthProtected: true,
        requiresContract: true,
        component: VehicleCollectionDetailsPage,
        pageTitle: PageTitle.VEHICLE_COLLECTION_DETAILS,
        pageTitleKey: TIMELINE_PAGE_TITLE_KEY,
      },
      {
        path: 'myPortal.invoices.base',
        isAuthProtected: true,
        requiresContract: true,
        userRoles: [UserRole.CLIENT],
        component: Invoices,
        pageTitle: PageTitle.INVOICES,
        pageTitleKey: 'myPortal.invoices.title',
      },
      {
        path: 'myPortal.somethingWentWrong',
        isAuthProtected: true,
        component: SomethingWentWrongPage,
        pageTitle: PageTitle.SOMETHING_WENT_WRONG,
        pageTitleKey: 'myPortal.somethingWentWrong.title',
      },
      {
        path: 'myPortal.notFound',
        isAuthProtected: true,
        component: NotFound,
        pageTitle: PageTitle.NOT_FOUND,
        pageTitleKey: 'myPortal.error.notFound.title',
      },
      {
        path: 'myPortal.forbidden',
        isAuthProtected: true,
        component: Forbidden,
        pageTitle: PageTitle.FORBIDDEN,
        // @TODO change to the actual translation key
        pageTitleKey: 'Forbidden',
      },
      {
        path: '*',
        isAuthProtected: true,
        component: NotFound,
        pageTitle: PageTitle.NOT_FOUND,
        pageTitleKey: 'myPortal.error.notFound.title',
      },
    ],
  },
  {
    path: 'myPortal.login',
    isAuthProtected: false,
    component: Login,
    pageTitle: PageTitle.LOGIN,
    pageTitleKey: 'myPortal.login.title',
  },

  // Used after login/logout to myLeasePlan to redirect the user to
  // the correct page
  {
    path: '/:locale/auth/authorize/',
    component: Authorize,
    pageTitle: PageTitle.AUTHORIZE,
    isAuthProtected: false,
  },
  {
    path: '/auth/login-redirect/',
    component: LoginRedirect,
    pageTitle: PageTitle.LOGIN_REDIRECT,
    isAuthProtected: false,
  },
  {
    path: '/auth/logout-redirect/',
    component: LogoutRedirect,
    pageTitle: PageTitle.LOGOUT_REDIRECT,
    isAuthProtected: false,
  },
  {
    path: '/auth/redirect-to-correct-auth0-locale/',
    component: RedirectToCorrectAuth0Locale,
    pageTitle: PageTitle.REDIRECT_TO_CORRECT_LOCALE,
    isAuthProtected: false,
  },
]

const localizeRoutes = (
  routes: Route[],
  countryRoutes: Routes,
  locale: Locales,
) => {
  return (
    routes
      .map(route => {
        if (route) {
          route.path = getLocalizedRouteMatch(countryRoutes, locale, route.path)
          if (route.children) {
            route.children = localizeRoutes(
              route.children,
              countryRoutes,
              locale,
            )
          }
        }
        return route
      })
      .filter(route => route)
      .map(wrapRouteComponent)
      .map(route => ({ ...route, element: <RouteComponent route={route} /> }))
      // use the same element for the same page to improve performance
      .map((route, _i, routes) => {
        route.element = (
          routes.find(
            r => r.pageTitle === route.pageTitle && r.element && route !== r,
          ) || route
        ).element
        return route
      })
  )
}

const useLocalizedRoutes = () => {
  const locale = useLocale()
  const countryRoutes = useRoutes()

  const contractsCount = useGetUserContractIdsCount()
  const showMultipleVehicles = contractsCount > 1

  return useMemo(
    () =>
      localizeRoutes(mylpRoutes(showMultipleVehicles), countryRoutes, locale),
    [locale, countryRoutes, showMultipleVehicles],
  )
}

export default useLocalizedRoutes
