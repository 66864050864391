import {
  MyLeaseplanFeature,
  MidLeaseFeatureConfiguration,
} from 'mlp-client/src/features-configuration/types'

export const midLeaseChangeFeature: MyLeaseplanFeature<MidLeaseFeatureConfiguration> =
  {
    enabled: true,
    configuration: {
      dialogModal: {
        title: 'myPortal.dashboard.contractChanges.modal.title',
        buttonFirst: 'myPortal.dashboard.contractChanges.modal.button.email',
        buttonSecond: 'myPortal.dashboard.contractChanges.modal.button.call',
        summaryBeforeBrokerInfo:
          'myPortal.dashboard.contractChanges.modal.UK.summaryBeforeBrokerInfo',
        summaryBeforeLink:
          'myPortal.dashboard.contractChanges.modal.UK.summaryBeforeLink',
        summaryLink: 'myPortal.dashboard.contractChanges.modal.UK.summaryLink',
        summaryAfterLink:
          'myPortal.dashboard.contractChanges.modal.UK.summaryAfterLink',
        showBrokerInfo: true,
      },
    },
  }
