import { ChevronRightIcon, DocIcon } from '@velocity/icons/system'
import { Card, LinkStandalone } from '@velocity/ui'
import React from 'react'

import Loader from 'mlp-client/src/components/loader/Loader'
import {
  BottomContainerStyled,
  DocumentsContainerStyled,
  HeadlineStyled,
} from 'mlp-client/src/contracts/components/ContractDocuments/ContractDocuments.styled'
import { ContentWrapper } from 'mlp-client/src/contracts/components/ContractPropertiesTable.styled'
import { useCurrentContractId } from 'mlp-client/src/contracts/hooks/useContracts'
import { getBaseFileName } from 'mlp-client/src/contracts/utils'
import useDocumentDownload from 'mlp-client/src/documents/hooks/useDocumentDownload'
import useDocuments from 'mlp-client/src/documents/hooks/useDocuments'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import Translation from 'mlp-client/src/localization/Translation'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

const ContractDocuments: React.FC = () => {
  const { data: documents, isFetching } = useDocuments()
  const contractId = useCurrentContractId()
  const accountId = useSelectedAccountId()
  const hasDocuments = documents?.contract?.groups?.length > 0

  const { downloadDocument } = useDocumentDownload({
    pageTitle: 'myPortal.contract.contract.contract.documentsButton',
    component: 'car_and_contract',
  })

  if (!hasDocuments) return null

  if (isFetching) {
    return <Loader />
  }

  const firstContractDocumentGroup = documents.contract?.groups[0].documents

  return (
    <Card data-e2e-id="contractDocuments" margin={{ bottom: '07' }}>
      <ContentWrapper>
        <HeadlineStyled variant="200" withMarginBottom={true}>
          <Translation id="myPortal.documents.title" />
        </HeadlineStyled>
        <DocumentsContainerStyled>
          {firstContractDocumentGroup.map((document, idx) => (
            <div key={document.id} data-e2e-id={`contractDocument${idx}`}>
              <LinkStandalone
                size="s"
                kind="secondary"
                Icon={DocIcon}
                onClick={downloadDocument(
                  document,
                  getBaseFileName(document.name),
                )}
                key={document.id}
                data-e2e-id={`downloadWithDocumentUrl${document.id}`}
              >
                <Translation
                  id={`myPortal.vehicleDocuments.titles.${document.documentType}`}
                />
              </LinkStandalone>
            </div>
          ))}
        </DocumentsContainerStyled>
        <hr />
        <BottomContainerStyled>
          <LinkStandalone
            to="myPortal.documents.base"
            Icon={ChevronRightIcon}
            component={RouteLink}
            size="s"
            params={{ accountId, contractId }}
          >
            <Translation id="myPortal.documents.viewAllDocuments" />
          </LinkStandalone>
        </BottomContainerStyled>
      </ContentWrapper>
    </Card>
  )
}

export default ContractDocuments
