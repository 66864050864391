import React, { useContext } from 'react'

import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import RouteModal from 'mlp-client/src/components/route-modal/RouteModal'
import TravelAbroadModalContent from 'mlp-client/src/components/travel-abroad-modal/TravelAbroadModalContent'
import { TileType } from 'mlp-client/src/contracts/enums'
import { useProtectContractTiles } from 'mlp-client/src/routes/protectContractTilesRoute/protectContractTilesRoute'
import { withOneInstance } from 'mlp-client/src/utils/hocs/hocs'

function TravelAbroadModal() {
  const { travelAbroad } = useContext(MyLeaseplanContext)

  return (
    <RouteModal
      title={travelAbroad.configuration.dialogModal.title}
      path="travel-abroad"
      protectRoute={useProtectContractTiles(TileType.TRAVEL_ABROAD)}
      data-e2e-id="travelAbroadModal"
    >
      {() => (
        <TravelAbroadModalContent
          modalContent={travelAbroad.configuration.dialogModal}
        />
      )}
    </RouteModal>
  )
}

export default withOneInstance(TravelAbroadModal)
