import { List, Text } from '@velocity/ui'
import React from 'react'

import { ContentStyled } from './NotFound.styled'

import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import { GrayMainHeading } from 'mlp-client/src/components/styled/TextStyle'
import Translation from 'mlp-client/src/localization/Translation'

const NotFound: React.FC = () => (
  <MyleaseplanPage
    metaTitle="404 Not Found"
    meta={[{ name: 'robots', content: 'noindex,follow' }]}
  >
    <ContentStyled data-e2e-id="notFound">
      <GrayMainHeading variant="500">
        <Translation id="myPortal.error.notFound.title" />
      </GrayMainHeading>
      <Text bold={true}>
        <Translation id="myPortal.error.notFound.subtitle" />
      </Text>
      <List>
        <List.Item>
          <Translation id="myPortal.error.notFound.bullet1" />
        </List.Item>
        <List.Item>
          <Translation id="myPortal.error.notFound.bullet2" />
        </List.Item>
        <List.Item>
          <Translation id="myPortal.error.notFound.bullet3" />
        </List.Item>
      </List>
    </ContentStyled>
  </MyleaseplanPage>
)

export default NotFound
