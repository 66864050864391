import React, { PropsWithChildren } from 'react'

import BookingDetailsPage from 'mlp-client/src/bookings/components/BookingDetailsPage'
import {
  CONTRACT_INFORMATION,
  SERVICE_REQUIRED,
  TIME_LOCATION_SECTION,
} from 'mlp-client/src/bookings/components/BookingTablePropertiesConfig'
import MaintenanceButtons from 'mlp-client/src/bookings/components/MaintenanceDetailsPage/MaintenanceButtons'
import { TableSection } from 'mlp-client/src/bookings/types'
import { FAQKeys } from 'mlp-client/src/FAQ/types'
import { Route } from 'mlp-client/src/types'

const bookingTablePropertiesConfig: TableSection[] = [
  TIME_LOCATION_SECTION,
  SERVICE_REQUIRED,
  CONTRACT_INFORMATION,
]

export type Props = PropsWithChildren<Route>

const MaintenanceDetailsPage: React.FC<Props> = ({ pageTitle }) => (
  <BookingDetailsPage
    Buttons={MaintenanceButtons}
    bookingTablePropertiesConfig={bookingTablePropertiesConfig}
    metaTitle={pageTitle}
    faqKey={FAQKeys.MAINTENANCE_AND_REPAIR_PAGE}
    headerTitle="myPortal.serviceRequest.maintenance.dashboard.title"
    dataE2eId="maintenance"
  />
)

export default MaintenanceDetailsPage
