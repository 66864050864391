import { Headline } from '@velocity/ui'
import React, { useMemo } from 'react'

import RowLink from 'mlp-client/src/components/overview-table/RowLink'
import { TableData } from 'mlp-client/src/components/overview-table/types'
import { Table } from 'mlp-client/src/components/table'
import { RowsConfiguration } from 'mlp-client/src/components/table/types'
import { setCurrentContractId } from 'mlp-client/src/contracts/contractsSlice'
import useContracts from 'mlp-client/src/contracts/hooks/useContracts'
import { useAppDispatch } from 'mlp-client/src/hooks'
import { useLocalizedRoute } from 'mlp-client/src/localization/RoutesProvider'
import Translation from 'mlp-client/src/localization/Translation'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'
import {
  getData,
  tableColumns,
} from 'mlp-client/src/vehicles/components/vehicles-overview-table/utils'
import { VehiclesOverviewTableTitle } from 'mlp-client/src/vehicles/components/vehicles-overview-table/VehiclesOverviewTableTitle'

const NoVehicles = () => (
  <Headline variant="200" data-e2e-id="multipleCarsNotFound">
    <Translation id="myPortal.vehicles.notFound.title" />
  </Headline>
)

export const VehiclesOverviewTable: React.FC = () => {
  const accountId = useSelectedAccountId()
  const { data: contracts } = useContracts()
  const dispatch = useAppDispatch()

  const selectContract = (item: TableData): void => {
    const contract = contracts.find(contract => contract.id === item.id)
    dispatch(setCurrentContractId(contract.id))
  }

  const getLocalizedRoute = useLocalizedRoute()
  const pageRoute = getLocalizedRoute('myPortal.dashboard', { accountId })

  const rowsConfig: RowsConfiguration = {
    TrComponent: RowLink,
    props: {
      handleClick: selectContract,
      dashboardRoute: pageRoute,
    },
  }

  const tableData = useMemo(() => {
    const tableData = contracts.map(contract => getData(contract))

    if (!tableData || tableData.length === 0) {
      return []
    }

    return tableData.map(tableItem => ({
      ...tableItem,
      key: tableItem.id,
    }))
  }, [contracts])

  return (
    <>
      <VehiclesOverviewTableTitle />
      <Table
        data={tableData}
        columns={tableColumns}
        rows={rowsConfig}
        defaultValue="-"
        data-e2e-id="vehiclesOverviewTable"
        emptyState={<NoVehicles />}
      />
    </>
  )
}

export default VehiclesOverviewTable
