import { EndOfLeaseNotificationsContainer } from 'mlp-client/src/end-of-lease/components/EndOfLeaseNotificationsContainer'
import { EndOfLeaseNotificationFeatureConfiguration } from 'mlp-client/src/end-of-lease/types'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'

export const endOfLeaseFeature: MyLeaseplanFeature<EndOfLeaseNotificationFeatureConfiguration> =
  {
    enabled: true,
    view: EndOfLeaseNotificationsContainer,
    configuration: {
      showBrokerInfo: true,
      notifyBeforeMonths: 6,
      requiredMileageUpdateDays: 14,
    },
  }
