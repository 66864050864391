import React, { PropsWithChildren, useEffect } from 'react'

import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { getMyLeaseplanConfig } from 'mlp-client/src/features-configuration/config'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import {
  useCountry,
  useLanguage,
} from 'mlp-client/src/localization/LocaleProvider'
import { trackPageViewEvent } from 'mlp-client/src/services/analytics/analytics'
import {
  useCachedUser,
  getUserAnalyticsRole,
} from 'mlp-client/src/user/hooks/useUser'

export interface Props extends PropsWithChildren {
  pageTitle: string
  pageTitleKey: string
}

const MyLeaseplanApp: React.FC<Props> = ({
  pageTitle,
  pageTitleKey,
  children,
}) => {
  const country = useCountry()
  const language = useLanguage()
  const { data: user } = useCachedUser()
  const config = getMyLeaseplanConfig(country)
  const { translate } = useTranslations()

  useEffect(() => {
    trackPageViewEvent({
      pageTitle,
      translatedPageTitle: translate(pageTitleKey),
      language,
      country,
      userType: getUserAnalyticsRole(user),
    })
  }, [])

  return (
    <MyLeaseplanContext.Provider value={config}>
      {children}
    </MyLeaseplanContext.Provider>
  )
}

export default MyLeaseplanApp
