import { LinkStandalone } from '@velocity/ui'
import React from 'react'

import { TermsAndConditionsFooter } from 'mlp-client/src/components/login/login-terms-and-conditions/LoginTermsAndConditions.styled'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import Translation from 'mlp-client/src/localization/Translation'

const LoginTermsAndConditions: React.FC = () => (
  <TermsAndConditionsFooter>
    <LinkStandalone
      component={RouteLink}
      kind="neutral"
      emphasis="low"
      target="_blank"
      to="myPortal.external.legal.privacyStatement"
    >
      <Translation id="myPortal.login.privacy" />
    </LinkStandalone>
    <LinkStandalone
      component={RouteLink}
      kind="neutral"
      emphasis="low"
      target="_blank"
      to="myPortal.external.legal.termsAndConditions"
    >
      <Translation id="myPortal.login.termsConditions" />
    </LinkStandalone>
  </TermsAndConditionsFooter>
)

export default LoginTermsAndConditions
