import { CarSedanPictogram } from '@velocity/icons/pictogram'
import { ChevronRightIcon } from '@velocity/icons/system'
import { mq } from '@velocity/styling'
import { Card, LinkStandalone, Text } from '@velocity/ui'
import styled from 'styled-components'

import {
  BREAKPOINT_LAPTOP,
  BREAKPOINT_TABLET,
} from 'mlp-client/src/utils/hooks/breakpoints'

export const FlexRowStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: var(--vel-fixed-spacing-07);
  gap: var(--vel-fixed-spacing-07);

  ${mq.below(BREAKPOINT_TABLET)} {
    padding: var(--vel-fixed-spacing-04);
    gap: var(--vel-fixed-spacing-03);
  }
`

export const LeftStyled = styled.div`
  margin-left: var(--vel-fixed-spacing-03);
`

export const ChevronRightIconStyled = styled(ChevronRightIcon)`
  margin-left: auto;
  margin-right: var(--vel-fixed-spacing-04);
  color: rgba(var(--vel-color-neutral-600-raw));
`

export const VehicleOverviewContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${mq.min(BREAKPOINT_LAPTOP)} {
    flex-wrap: nowrap;
  }
`

export const VehicleInfoContainerStyled = styled.div`
  display: flex;
  width: 80%;

  ${mq.below(BREAKPOINT_TABLET)} {
    width: 100%;
  }
`

export const LinkStyled = styled(LinkStandalone)`
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  align-items: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  ${mq.below(BREAKPOINT_TABLET)} {
    display: block;
  }
`

export const TileLinkStyled = styled(LinkStyled)`
  padding: var(--vel-fixed-spacing-05);
`

export const VehicleOverviewTileText = styled(Text)`
  line-height: 24px;
  color: var(--vel-color-neutral-700);
`

export const LinksContainerStyled = styled.div`
  padding: var(--vel-fixed-spacing-04);

  ${mq.min(BREAKPOINT_LAPTOP)} {
    border-left: 1px solid var(--vel-color-neutral-200);
  }

  ${mq.below(BREAKPOINT_LAPTOP)} {
    width: 100%;
    border-top: 1px solid var(--vel-color-neutral-200);
  }
`

export const TileContainerStyled = styled.div`
  margin-bottom: var(--vel-fixed-spacing-07);
`

export const CardStyled = styled(Card)`
  transition: box-shadow var(--vel-animation-timing-large) ease;

  &:hover {
    box-shadow: var(--vel-elevation400-box_shadow);
  }
`

export const CarSedanIconStyled = styled(CarSedanPictogram)`
  ${mq.below(BREAKPOINT_TABLET)} {
    width: 40px;
  }
`
