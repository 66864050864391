import { TextProps } from '@velocity/ui'
import React from 'react'

import VehicleLicense from 'mlp-client/src/components/vehicle-license/VehicleLicense'
import {
  Container,
  VehicleModel,
} from 'mlp-client/src/components/vehicle-model-license/VehicleModelLicense.styled'
import { FormattedVehicle } from 'mlp-client/src/contracts/types'

export interface Props {
  vehicle: FormattedVehicle
  textSize?: TextProps<'span'>['variant']
  bold?: TextProps<'span'>['bold']
  'data-e2e-id'?: string
}

const VehicleModelLicense: React.FC<Props> = props => {
  const { bold = false, textSize = '200', vehicle } = props

  return (
    <Container data-e2e-id={props['data-e2e-id'] || 'activeVehicle'}>
      <VehicleModel variant={textSize} bold={bold} data-e2e-id="model">
        {vehicle.model}
      </VehicleModel>
      <VehicleLicense>{vehicle.licensePlate}</VehicleLicense>
    </Container>
  )
}

export default VehicleModelLicense
