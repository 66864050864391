import { createSlice } from '@reduxjs/toolkit'

import { AppState } from 'mlp-client/src/types'

export interface DebugState {
  showAllFeatures: boolean
  debugTranslations: boolean
}

const initialState: DebugState = {
  showAllFeatures: false,
  debugTranslations: false,
}

const debugSlice = createSlice({
  name: '@@debug',
  initialState,
  reducers: {
    enableShowAllFeatures: state => {
      state.showAllFeatures = true
    },
    enableDebugTranslations: state => {
      state.debugTranslations = true
    },
  },
})

export const { enableShowAllFeatures, enableDebugTranslations } =
  debugSlice.actions

export default debugSlice.reducer

export const getShowAllFeatures = (state: AppState) =>
  state.debug.showAllFeatures

export const getDebugTranslations = (state: AppState) =>
  state.debug.debugTranslations
