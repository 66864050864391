import _get from 'lodash/get'
import qs from 'qs'
import { IntlShape } from 'react-intl'
import {
  generatePath,
  createSearchParams,
  matchRoutes,
  RouteObject,
} from 'react-router-dom'

import { Locales } from 'mlp-client/src/localization/enums'
import { Routes } from 'mlp-client/src/localization/types'
import log from 'mlp-client/src/log'
import { NavigationParams } from 'mlp-client/src/types'
import { isExternal, isMail, isPhone } from 'mlp-client/src/utils'

export const getLocalizedRouteMatch = (
  routes: Routes,
  currentLocale: string,
  path: string,
) => {
  // If path matches a routes lookup, get it from routes
  if (path.match(/^([a-z0-9]+\.){1,}/i)) {
    const routeLookup = String(_get(routes, `${currentLocale}.${path}`) || '')
    return routeLookup && `/:locale?${routeLookup}`
  } else {
    // otherwise return as the route itself (for example '/(select-country-language)?/' is not a route lookup)
    return path
  }
}

export const flattenRoutes = (routes: Routes) => {
  return Object.keys(routes).reduce<RouteObject[]>((acc, key) => {
    const value = routes[key]
    if (typeof value === 'object') {
      acc.push(...flattenRoutes(value))
    } else {
      acc.push({ path: `/:locale?${value}` })
    }
    return acc
  }, [])
}

/**
 * Returns true when the given string is a localized route identifier (LRI).
 *
 * We recognize a LRI by:
 *  - Should contain a . (But may not start with a .)
 *  - Should NOT contain a '/'
 *
 */
export const isLocalizedRouteIdentifier = (route: string): boolean =>
  !!route && !!route.match(/^[\w-]+\.[\w-]+/) && !route.match(/\//)

/**
 * Adds a trailing slash to a url if it is missing
 * For example:
 *
 * /nl-nl/overzicht/detail -> /nl-nl/overzicht/detail/
 * /nl-nl/overzicht/detail/ -> /nl-nl/overzicht/detail/
 * /nl-nl/overzicht/detail#hash -> /nl-nl/overzicht/detail/#hash
 * /nl-nl/overzicht/detail?query -> /nl-nl/overzicht/detail/?query
 * /nl-nl/overzicht/detail?query#hash -> /nl-nl/overzicht/detail/?query#hash
 * /nl-nl/overzicht/detail#query?hash -> Don't do this, it's crazy talk
 */

export const addTrailingSlash = (url: string): string => {
  if (!url || hasTrailingSlash(url)) {
    return url
  }

  const hasHash = url.match(/#/)
  const hasQuery = url.match(/\?/)

  if (hasQuery) {
    const urlParts = url.split('?')

    return `${urlParts[0]}/?${urlParts[1]}`
  } else if (hasHash) {
    const urlParts = url.split('#')

    return `${urlParts[0]}/#${urlParts[1]}`
  } else {
    return `${url}/`
  }
}

export const hasTrailingSlash = (url: string) => {
  if (!url) {
    return url
  }

  const queryPath = url.split('?')[0]
  const hashPath = url.split('#')[0]

  return (
    queryPath.charAt(queryPath.length - 1) === '/' ||
    hashPath.charAt(hashPath.length - 1) === '/'
  )
}

export class UrlPath {
  private _basePath: string
  private _directories: string[] = []
  private _queryParams: GenericObject = {}

  constructor(basePath = '') {
    this._basePath = basePath
  }

  addDirectory(directory: string) {
    this._directories.push(directory)
  }

  addQueryParam(name: string, value: any) {
    if (!name) {
      return
    }

    this._queryParams[name] = Array.isArray(value) ? value.join(',') : value
  }

  private _composeDirectories() {
    return addTrailingSlash(this._directories.join('/'))
  }

  private _composeQueryParams() {
    return qs.stringify(this._queryParams, {
      addQueryPrefix: true,
      encode: false,
    })
  }

  toString(): string {
    return (
      this._basePath + this._composeDirectories() + this._composeQueryParams()
    )
  }
}

// expand route with params if they are a part of a pathname
// otherwise, add the as a searchParams
const expandRoute = (
  route: string,
  currentLocale: string,
  params: NavigationParams = {},
): string => {
  if (isExternal(route) || isPhone(route) || isMail(route)) {
    return route
  }

  const routeMatch = matchRoutes([{ path: route }], {
    pathname: generatePath(route, params),
  })
  const routeParams = routeMatch ? routeMatch[0].params : {}

  const searchParams =
    typeof params === 'object'
      ? Object.keys(params).reduce<GenericObject>((acc, key) => {
          if (!routeParams[key] && params[key]) {
            acc[key] = params[key]
          }
          return acc
        }, {})
      : null

  const searchParamsQuery =
    searchParams && Object.keys(searchParams).length
      ? `?${createSearchParams(searchParams).toString()}`
      : ''

  return addTrailingSlash(
    `/${currentLocale}${addTrailingSlash(
      generatePath(route, params),
    )}${searchParamsQuery}`,
  )
}

// Returns a given route and replaces possible variables (such as /some/route/:carTypeId) with values from the given replace object
export const getLocalizedRoute = (
  routes: Routes,
  locale: string,
  path: string,
  params: NavigationParams = {},
  isLocalizedOnly = true,
) => {
  if (!path) {
    log.warn('getLocalizedRouteFromRouteList: Path empty')

    return null
  }

  if (!isLocalizedRouteIdentifier(path)) {
    if (isLocalizedOnly) {
      return null
    } else {
      return path
    }
  }

  const route = String(_get(routes, `${locale}.${path}`) || '')

  if (!route) {
    return null
  }

  return expandRoute(route, locale, params)
}

export const getParamsFromPath = (path = '') =>
  path
    .split(/\/+/)
    .map(segment => {
      const keyMatch = segment.match(/^:(\w+)(\??)$/)
      return keyMatch?.[1]
    })
    .filter(key => key)

export const isKnownLocale = (loc: string | undefined): loc is Locales =>
  Boolean(loc) && Object.values(Locales).includes(loc as Locales)

const getPartRegExp = (part: string) =>
  part === '\xa0' ? new RegExp(/\s/g) : new RegExp('\\' + part, 'g')

export const reverseFormatNumber = (
  value: string,
  formatNumberToParts: IntlShape['formatNumberToParts'],
) => {
  const parts = formatNumberToParts(111111.1)
  const group = parts.find(part => part.type === 'group')?.value
  const decimal = parts.find(part => part.type === 'decimal')?.value

  const reversedVal = value
    .replace(getPartRegExp(group), '')
    .replace(getPartRegExp(decimal), '.')

  return Number.isNaN(+reversedVal) ? 0 : +reversedVal
}
