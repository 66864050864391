export { isExternal, isMail, isPhone } from './checks/checks'

export {
  replaceContent,
  isStringReplacementsArray,
  replaceContentStringByEntries,
} from './content/content'

export type {
  Replacement,
  ReplacementItem,
  Replacements,
  StringReplacement,
  StringReplacementItem,
} from './content/content'

export { formatDate, formatRelativeDate } from './date/date'

export { saveFile } from './file/file'

export { makeTranslationPath } from './formatters/formatters'
