import { Grid, Pagination } from '@velocity/ui'
import React, { useEffect } from 'react'

import { EmptyPage } from 'mlp-client/src/components/empty-page/EmptyPage'
import TechnicalError from 'mlp-client/src/components/error/TechnicalError'
import { useMyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import {
  DEFAULT_NUMBER_OF_ITEMS_PER_PAGE,
  ITEMS_PER_PAGE_OPTIONS,
} from 'mlp-client/src/constants'
import { useHasMultipleContracts } from 'mlp-client/src/contracts/hooks/useContracts'

import { useAppDispatch, useAppSelector } from 'mlp-client/src/hooks'
import TimelineLoader from 'mlp-client/src/timeline/components/TimelineLoader/TimelineLoader'
import TimelineVehicleSelector from 'mlp-client/src/timeline/components/TimelineVehicleSelector/TimelineVehicleSelector'
import { useGetTimelineQuery } from 'mlp-client/src/timeline/timelineApi'
import {
  getPagination,
  getSelectedVehicles,
  setPaginationItemsPerPage,
  setPaginationPage,
} from 'mlp-client/src/timeline/timelineSlice'
import { TimelineItem } from 'mlp-client/src/timeline/types'
import { getTimelineEventType } from 'mlp-client/src/timeline/utils'
import { useSelectedAccountId } from 'mlp-client/src/user/userSlice'

const TimelinePageContent: React.FC = () => {
  const dispatch = useAppDispatch()

  const { timeline: timelineConfig } = useMyLeaseplanContext()

  const accountId = useSelectedAccountId()
  const pagination = useAppSelector(getPagination)
  const showMultipleVehicles = useHasMultipleContracts()
  const selectedVehicles = useAppSelector(getSelectedVehicles)

  const {
    data: timelineData,
    isFetching,
    error,
  } = useGetTimelineQuery({
    accountId,
    page: pagination.currentPage,
    itemsPerPage: pagination.itemsPerPage,
    contractIds: selectedVehicles,
  })

  const changePage = (page: number) => dispatch(setPaginationPage(page))

  const changeItemsPerPage = (itemsPerPage: number) =>
    dispatch(setPaginationItemsPerPage(itemsPerPage))

  useEffect(() => {
    dispatch(setPaginationPage(0))
  }, [selectedVehicles, dispatch])

  const filterTimelineByConfig = () => {
    const configuredCards = Object.keys(timelineConfig.configuration.cards)

    return (
      timelineData?.events
        // Filter timeline to only display configured cards
        .filter(item => configuredCards.includes(getTimelineEventType(item)))
    )
  }

  const timelineItems = filterTimelineByConfig()
  const totalCount = timelineData?.metadata.totalCount

  const pageCount = Math.ceil(totalCount / pagination.itemsPerPage)

  const changeTimelinePage = (page: number) => {
    changePage(page - 1)
    window.scrollTo(0, 0)
  }

  const changeTimelineItemsPerPage = (itemsPerPage: number) => {
    changeItemsPerPage(itemsPerPage)
    localStorage.setItem('itemsPerPage', itemsPerPage.toString())
  }

  const showPagination =
    !isFetching && totalCount > DEFAULT_NUMBER_OF_ITEMS_PER_PAGE

  const renderTimelineCards = () =>
    timelineItems?.map((item: TimelineItem, i: number) => {
      const delay = i * 0.05
      const animationDelay = `${delay < 0.5 ? delay : 0.5}s`

      const eventType = getTimelineEventType(item)
      const config = timelineConfig.configuration.cards[eventType]
      const Component = config?.component

      return (
        <div
          key={`${item.ilan}${item.type}${item.creationDate}`}
          style={{ animationDelay }}
          data-e2e-id={eventType}
        >
          <Component config={config} item={item} />
        </div>
      )
    })

  const showVehicleSelector =
    showMultipleVehicles &&
    (Boolean(timelineItems?.length) || Boolean(selectedVehicles.length))

  if (error) {
    return <TechnicalError />
  }

  return (
    <Grid>
      {showVehicleSelector && (
        <Grid.Item LG={4}>
          <TimelineVehicleSelector />
        </Grid.Item>
      )}
      <Grid.Item data-e2e-id="timeline">
        {!isFetching ? renderTimelineCards() : <TimelineLoader />}
        {!isFetching && !timelineItems.length && (
          <EmptyPage
            title="myPortal.timeline.empty.title"
            subtitle="myPortal.timeline.empty.summary"
            linkButton={{
              to: 'myPortal.dashboard',
              label: 'myPortal.timeline.empty.button.goToDashboard',
            }}
          />
        )}
      </Grid.Item>
      {showPagination && (
        <Grid.Item data-e2e-id="timelinePagination">
          <Pagination
            onChangeItemsPerPage={changeTimelineItemsPerPage}
            onLoadPage={changeTimelinePage}
            count={totalCount}
            currentPage={pagination.currentPage + 1}
            itemsPerPage={pagination.itemsPerPage}
            pageCount={pageCount}
            pageSizeOptions={ITEMS_PER_PAGE_OPTIONS}
          />
        </Grid.Item>
      )}
    </Grid>
  )
}

export default TimelinePageContent
