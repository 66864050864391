import { Card, SkeletonLoader } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--vel-fixed-spacing-02);
`

const TimelineLoader: React.FC = () => (
  <div data-e2e-id="timelineLoader">
    {Array.from({ length: 5 }).map((_, index) => (
      <Card margin={{ bottom: '04' }} key={index}>
        <Card.Body>
          <SkeletonWrapper>
            <SkeletonLoader
              on="alt"
              text={{
                variant: '300',
              }}
              style={{ width: '100px' }}
            ></SkeletonLoader>
            <SkeletonLoader
              on="alt"
              text={{
                variant: '400',
              }}
              style={{ width: '220px' }}
            ></SkeletonLoader>
            <SkeletonLoader
              on="alt"
              text={{
                variant: '200',
              }}
              style={{ width: '64px' }}
            ></SkeletonLoader>
            <SkeletonLoader
              on="alt"
              text={{
                variant: '200',
              }}
              style={{ width: '168px' }}
            ></SkeletonLoader>
          </SkeletonWrapper>
        </Card.Body>
      </Card>
    ))}
  </div>
)

export default TimelineLoader
