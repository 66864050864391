import React from 'react'

import { Mileage } from 'mlp-client/src/localization/components/Mileage'
import Translation from 'mlp-client/src/localization/Translation'
import BaseTimelineCard, {
  TimelineItemProps,
} from 'mlp-client/src/timeline/components/TimelineCard/BaseTimelineCard'
import { ActualMileageUpdatedTimelineItem } from 'mlp-client/src/timeline/types'

const ActualMileageUpdatedTimelineCard: React.FC<
  TimelineItemProps<ActualMileageUpdatedTimelineItem>
> = ({ item, config }) => (
  <BaseTimelineCard
    item={item}
    config={config}
    status={item.status}
    title="myPortal.timeline.events.mileage.title"
  >
    <Translation
      id="myPortal.timeline.events.mileage.subtitle"
      replace={{
        value: () => (
          <Mileage
            value={item.mileage.value}
            mileageUnit={item.mileage.unit}
            key="mileage"
          />
        ),
      }}
    />
  </BaseTimelineCard>
)

export default ActualMileageUpdatedTimelineCard
