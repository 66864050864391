import { Text } from '@velocity/ui'
import React from 'react'
import { useIntl } from 'react-intl'

import {
  InputWrapper,
  StyledInput,
} from 'mlp-client/src/components/mileage-update-modal/mileage-field/MileageField.styled'
import { DistanceUnit } from 'mlp-client/src/enums'
import { Mileage } from 'mlp-client/src/localization/components/Mileage'
import useTranslations from 'mlp-client/src/localization/hooks/useTranslations'
import Translation from 'mlp-client/src/localization/Translation'
import { reverseFormatNumber } from 'mlp-client/src/localization/utils'

interface Props {
  mileage: number
  onMileageChange(mileage: string): void
  lastKnownMileage?: number
  mileageUnit: DistanceUnit
}

export const MIN_MILEAGE = 0
export const MAX_MILEAGE = 999999

const MileageField: React.FC<Props> = ({
  mileageUnit,
  mileage,
  onMileageChange,
  lastKnownMileage,
}) => {
  const intl = useIntl()
  const { translate } = useTranslations()

  const placeholder = intl.formatNumber(
    Number(translate('myPortal.components.mileage.placeholder')),
  )
  const value = mileage ? intl.formatNumber(mileage) : ''
  const onChange = (event: React.FormEvent<HTMLInputElement>) =>
    onMileageChange(
      String(
        reverseFormatNumber(
          event.currentTarget.value,
          intl.formatNumberToParts,
        ),
      ),
    )
  // Used to display mileage unit inside the input
  const endAdornment = intl
    .formatNumberToParts(mileage, {
      style: 'unit',
      unit: mileageUnit,
      unitDisplay: 'short',
    })
    .find(part => part.type === 'unit').value

  return (
    <>
      <InputWrapper>
        <StyledInput
          min={MIN_MILEAGE}
          max={MAX_MILEAGE}
          value={value}
          onChange={onChange}
          name="mileage"
          required={true}
          type="text"
          pattern="^[0-9]"
          step={1}
          placeholder={placeholder}
          inputProps={{
            'data-e2e-id': 'mileageInput',
          }}
          variant="secondary"
          endAdornment={endAdornment}
        />
      </InputWrapper>
      {lastKnownMileage != null && (
        <Text component="p" variant="100" data-e2e-id="lastKnownMileage">
          <Translation id="myPortal.components.mileage.lastKnownMileageText" />{' '}
          <Mileage
            value={lastKnownMileage}
            mileageUnit={mileageUnit}
            unitDisplay="short"
          />
        </Text>
      )}
    </>
  )
}

export default MileageField
