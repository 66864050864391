import { rest } from 'msw'

import timelineError from 'mlp-client/src/mocks/json/timeline/error.json'
import timeline from 'mlp-client/src/mocks/json/timeline/timeline.json'

export const handlers = [
  rest.get('*/api2/timeline/:accountId', async (req, res, ctx) => {
    const params = new URLSearchParams(window.location.search)
    const scenario = params.get('timeline')
    const error = params.get('error')

    if (scenario) {
      if (error) {
        return res(ctx.status(500), ctx.json(timelineError))
      }
    }

    return res(ctx.json(timeline))
  }),
]
